import React, { useRef, useState } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import * as yup from 'yup';
import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    NavLink,
    Nav
} from "reactstrap";
import { useFormik } from 'formik';
import { Typeahead } from "react-bootstrap-typeahead";
import TransportService from '../services/transport.service'
import { Link } from "react-router-dom";
import BaseService from "services/base.service";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
//import moment from "react-moment";
//import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import AuthService from "services/auth.service";
import ModalSlider from "components/CustomComponents/ModalSlider";
import MasterService from "services/master.service";

function Tasks() {
    const navigate = useNavigate();
    const [transportList, setTransportList] = React.useState([]);
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [sliderShow, setSliderShow] = useState(false);
    const [fromLocation, setfromLocation] = useState(false);
    const [dueDateAndTime, setdueDateAndTime] = useState(new Date());
    const currentUser = AuthService.getCurrentUser();
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [seasonMaster, setSeasonMaster] = useState([]);

    const [task, setTask] = useState({
        "id": 0,
        "name": "",
        "dueDateAndTime": "",
        "description": "",
        "assingTaskTo": 1,
        "statusId": 1,
        "reminderId": 0,
        "estimatedTime": 0,        
    });

    const addForm = () => { setSliderShow(!sliderShow); }

    function pageRefresh() {
        TransportService.GetTransports().then(
            res => {
                if (currentUser.userTypeId == 1) {
                    setTransportList(res);
                }
                else {
                    setTransportList(res.filter(p => p.userId == currentUser.id));
                }
            }
        )

        MasterService.GetDestination().then(res => {
            setDestination(res)
            //setSelectedDestination(res.filter(p => p?.name == enquiryState?.destination))
        });

        MasterService.GetSeasonMaster().then(
            res => { setSeasonMaster(res); }
        )
    }

    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'leadCode', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'startsWith', type: 'string', value: '' },
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'phone', operator: 'startsWith', type: 'string', value: '' },
        { name: 'destination', operator: 'startsWith', type: 'string', value: '' },
        { name: 'proposalDate', operator: 'startsWith', type: 'date', value: '' },
        { name: 'plannedTravelDate', operator: 'startsWith', type: 'string', value: '' },
        { name: 'statusId', operator: 'startsWith', type: 'string', value: '' }
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        { name: 'description', header: 'Description', defaultWidth: 50, flex: 1 },
        { name: 'dueDateAndTime', header: 'Due Date', defaultWidth: 50, flex: 1 },
        { name: 'assingTaskTo', header: 'User', defaultWidth: 50, flex: 1 },
        { name: 'estimatedTime', header: 'EstimatedTime', defaultWidth: 50, flex: 1 },
        { name: 'statusId', header: 'Status', defaultWidth: 50, flex: 1 },
        {
            name: 'action', header: 'Action', sorrting: false, defaultWidth: 80, render: ({ data }) => {
                return <div><a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                </div>
            }
        },
    ];

    React.useEffect(() => {
        // pageRefresh();
    }, []);

    function onDelete(id) {
        TransportService.DeleteTransport(id).then(
            res => {
                pageRefresh();
            }
        )
    }

    const enquiryStatusOptions = [
        { value: 1, label: 'New' },
        { value: 2, label: 'Pending' },
        { value: 3, label: 'In-Progress' },
        { value: 4, label: 'Completed' }
    ]

    const onDownload = (proposal) => {
        //navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    };

    const onEdit = (transport) => {
        navigate(`${BaseService.NavigateToPath()}/add-transport`, { state: { transport: transport } });
    };

    function getStatus(statusId) {
        return enquiryStatusOptions.filter(s => s.value == statusId)[0]?.label
    }

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }

        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // name: yup.string().min(3).max(40).required("Name is required"),
        // phone: yup.string().min(10).required("Contact Number is required"),
        // email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    const formik = useFormik({
        initialValues: task,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            setTransportList([...transportList, values])
            //setTransportList( JSON.parse(JSON.stringify(transportList)));
            // saveEnquiry(values);  
            addForm();
            console.log(transportList)
        }
    });

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle> <h3>Tasks</h3> </CardTitle>
                                    </Col>
                                    <Col sm="6" >
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">

                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: "" === "data3",
                                                })}
                                                onClick={addForm}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Add New Task
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={transportList}
                                    //enableFiltering={enableFiltering}
                                    //defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ModalSlider open={sliderShow} width={500} onClick={addForm} className={sliderShow ? ` showing` : ''}>
                    <div className="content">
                        <Form onSubmit={formik.handleSubmit}>
                            <Card style={{ padding: "1em 2em" }} className="followupcard">
                                <Row>
                                    <Col style={{ marginBottom: "1em" }}><h5 className="title">Add New Task</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Name</label>
                                            <Input
                                                name="name"
                                                placeholder="Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="6">
                                        <FormGroup>
                                            <label>Task Type</label>
                                            <Input
                                                name="name"
                                                placeholder="Name"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            >
                                                <option value="">Reminder</option>
                                                <option value="">Booking</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Due Date and Time</label>
                                            <DatePicker
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="dueDateAndTime"
                                                selected={dueDateAndTime}
                                                onChange={setdueDateAndTime} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Assign to</label>
                                            <Input
                                                name="distance"
                                                placeholder="Distance"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.distance}>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                            <label>Estimated Time</label>
                                            <Input
                                                name="price"
                                                placeholder="Price"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.price}

                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Priority</label>
                                            <Input
                                                name="price"
                                                placeholder="Price"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.price}

                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: "1em" }}>
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Add
                                        </Button>
                                        <Button onClick={addForm} type="button" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </div>
                </ModalSlider>

            </div >
        </>
    );
}

export default Tasks;