export const NUMBER_OF_NIGHT = 30;
export const NUMBER_OF_ADULTS = 30;
export const NUMBER_OF_CHILD = 30;
export const NUMBER_OF_INFANT = 10;

export const BUDGET_PER_ADULT = 3000000;

export const utils = {
    preventMinus: function (e, max) {
        let value = 0;
        try {
            value = parseInt(e.target.value + e.key);
        }
        catch (err) { }
        if (e.code === "Minus" || value > max) {
            e.preventDefault();
        }
    },
}