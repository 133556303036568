import { React, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import FollowUpService from '../services/followup.service'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import { format, parseISO } from 'date-fns'
import "../assets/css/enqform.css"
import BaseService from "services/base.service";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,

} from "reactstrap";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from '../store/UserStore'
import { string } from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Timeline, TimelineItem } from 'rsuite';
import { Chrono } from "react-chrono";
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ValueService } from "ag-grid-community";
import MasterService from "services/master.service";
import AuthService from "services/auth.service";
import EnquiryService from '../services/enquiry.service'

function FollowUp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const userList = useRecoilState(userState);
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
    const editorRef = useRef(null);
    const [followDate, setFollowDate] = useState(new Date());
    const [followup, setFollowup] = useState([]);
    const [enquiryStatusoptions, setEnquiryStatusoptions] = useState([])

    const [followUpForm, setFollowUpForm] = useState({
        id: 0,
        reminderTypeId: 1,
        reminderMediumId: 1,
        entityId: 0,
        reminderDateTime: '',
        comment: '',
        userId: 0,
        statusId: 0
    });

    useEffect(() => {
        if (props.dataParentToChild) {
            FollowUpService.GetFollowUpsByEnquiryId(props.dataParentToChild).then(response => {
                const followups = response?.map(followUp => ({ 'title': followUp?.reminderDateTime && format(parseISO(followUp?.reminderDateTime), "dd MMM yy HH:mm"), cardTitle: followupOptions.filter(e => e.value == followUp.statusId)[0]?.label, cardDetailedText: followUp.comment }))
                setFollowup(followups);
            });
        }

        EnquiryService.GetEnquiryStatus().then(res => {
            setEnquiryStatusoptions(res);
        });

    }, [props.dataParentToChild]);

    

    const showForm = () => {

    }

    if (location.state?.followUp)
        followUpForm = location.state?.followUp;

    const saveEnquiry = async (followUpData) => {
        FollowUpService.CreateFollowUp(followUpData);
    };

    const NavigateToEnquiries = () => {
        navigate(`${BaseService.NavigateToPath()}/enquiries`);
    }

    const followupOptions = [
        { value: '1', label: 'Spoke' },
        { value: '2', label: 'Meeting Fixed' },
        { value: '3', label: 'Met' },
        { value: '4', label: 'Closed' },
        { value: '5', label: 'Lost' },
        { value: '6', label: 'Active' },
    ]
    const enquiryStatusOptions = MasterService.getEnquiryStatus()
    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
    }
    // handle input change event
    const handleInputChange = (date) => {
        setFollowDate(date);
        //formik.values.plannedTravelDate = date;        
    };

    //Set current User 
    const currentUser = AuthService.getCurrentUser();

    const schema = yup.object().shape({
        comment: yup.string().required()
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
    });
    
    const formik = useFormik({
        initialValues: followUpForm,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values, { resetForm }) {
            values.userId = currentUser?.id;
            values.reminderDateTime = followDate;
            values.entityId = props.dataParentToChild;
            props.refresh(values);
            resetForm({ values: '' });
            props.onCancel();
        }
    });

    return (
        <>
            <div className="content ">
                <Card className="followupcard">
                    <Tabs SelectedIndex={2}>
                        <TabList>
                            <Tab>Follow Up</Tab>
                            <Tab>Timeline</Tab>
                        </TabList>
                        <TabPanel>                            
                            <Form onSubmit={formik.handleSubmit} className="form">
                                <div className="followupform grid">
                                    <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }} className="fudate">
                                        <label htmlFor="exampleInputDatetime">
                                            FollowUp Date & Time
                                        </label>
                                        <DatePicker className="form-control"
                                            dateFormat={'dd-MM-yyyy HH:mm'}
                                            name="dateTime"
                                            selected={followDate}
                                            showTimeInput
                                            onChange={(date) => { handleInputChange(date) }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.dateTime} />
                                        {formik.touched.dateTime && formik.errors.dateTime && (
                                            <span className='text-red-400'>{formik.errors.dateTime}</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup style={{ gridColumn: "2/3" }}>
                                        <label htmlFor="exampleInputDatetime">
                                            Followup Status
                                        </label>
                                        <Input
                                            name="statusId"
                                            type="select"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.statusId}
                                        >
                                            {followupOptions.map(enquiryStatus => {
                                                return <option value={enquiryStatus.value}>{enquiryStatus.label}</option>;
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup style={{ gridColumn: "1/3" }}>
                                        <label htmlFor="exampleInputDatetime">
                                            Enquiry Status
                                        </label>
                                        <Input
                                            name="enquiryStatusId"
                                            type="select"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.enquiryStatusId}
                                        >
                                            {enquiryStatusoptions.map(enquiryStatus => {
                                                return <option value={enquiryStatus.id}>{enquiryStatus.status}</option>;
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup style={{ gridColumn: "1/3" }}>
                                        <label htmlFor="exampleInputDatetime">
                                            Comments*
                                        </label>
                                        <Input type="textarea"
                                            name="comment"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.comment}
                                        />
                                    </FormGroup>                                                                        
                                    {formik.touched.comment && formik.errors.comment && (
                                        <span className='text-red-400'>{formik.errors.comment}</span>
                                    )}
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <Button style={{ marginRight: "1em" }} type="submit" disabled={!formik.isValid} className="btn-fill" color="primary">
                                        Save
                                    </Button>
                                    <Button onClick={props.onCancel} className="btn-fill" color="secondary">
                                        Cancel
                                    </Button>

                                </div>
                            </Form>
                        </TabPanel>
                        <TabPanel>
                            <Chrono allowDynamicUpdate="true" fontSizes={{
                                cardSubtitle: '12px',
                                cardText: '12px',
                                cardTitle: '13px',
                                title: '13px',
                            }} titleDateFormat='dd MMM yy'
                                contentDetailsHeight="80"
                                cardHeight="100"
                                style={{ height: '950px' }} items={followup}
                                mode="VERTICAL" >
                            </Chrono>
                        </TabPanel>
                    </Tabs>
                </Card>

            </div >
        </>
    );
}

export default FollowUp;
