import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import _ from 'underscore';
import FollowUp from './FollowUp'
import BulkUpload from "../components/CustomComponents/BulkUpload";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    NavLink,
    Nav
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { useRecoilState, useRecoilValue } from "recoil";
import { enquiryState, filteredEnquiryState } from './../store/EnquiryState'
import NotificationAlert from "react-notification-alert";
import MoadalSlider from "components/CustomComponents/ModalSlider";
import AttractionService from "services/attraction.service";
import BaseService from "services/base.service";

function Attractions() {
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [enquiryCount, setenquiryCount] = React.useState([]);
    const [dropdownOpen, setDropdownOpen] = React.useState(true);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [sliderShow, setSliderShow] = useState(false);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [attractions, setAttractions] = useState([]);
    const notificationAlertRef = React.useRef(null);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const filteredEnquiries = [];
    let enquiryListCount = {};
    const [linkState, setLinkState] = useState(false)
    const showForm = () => { setSliderShow(!sliderShow) }
    const showForm1 = () => { setSliderShow1(!sliderShow1) }
    const [enableFiltering, setEnableFiltering] = useState(false);

    React.useEffect(() => {

        AttractionService.GetAttractions().then(
            res => {
                setAttractions(res);                
            }
        );
    }, []);

    const linkactivate = () => {
        setLinkState(!linkState)
    }
    const setCurrentPage = (name) => {
        navigate(`${BaseService.NavigateToPath()}/${name}`);

    };

    const deleteAttraction = (e, attraction) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete?")) {
            AttractionService.DeleteAttraction(attraction?.id).then(
                () => {
                    AttractionService.GetAttractions().then(
                        res => {
                            setAttractions(res);
                            notify('br');
                        }
                    );
                }
            );
        }
    };

    const navigatePage = (e, page) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/${page}`);
    };


    // enable sorting on all columns by default
    const defaultColDef = {
        sortable: true
    };

    const [columnDefs] = React.useState([
        { field: 'id', width: 30, },
        { field: 'name' },
        { field: 'phone' },
        { field: 'email' },
        { field: 'userId' },
        {
            field: 'statusId',
            cellRender: function (params) {
                return '<span><i title="Reminder" className="tim-icons icon-alert-circle-exc" />1234 ' + params.value + '</span>'
            },

            cellRenderer: params => {
                let eIconGui = document.createElement('span');
                return eIconGui.innerHTML = '<em class="material-icons">edit</em>' + ' ' + params.data.value;
            }

        },
        { field: 'destination' }
    ]);

    const notify = (place) => {
        var color = 2; //Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        The Enquiry has been deleted successfully!
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const getCount = (stausId) => {
        enquiryListCount = _.countBy(enquiryList, "statusId");
        console.log(enquiryListCount);

        var data = _.map(enquiryListCount, function (value, key) {
            console.log('value' + value);
            console.log('key' + value);
            return {
                name: key,
                y: value
            };
        });

        console.log(data);

        return data.filter((d => d.key == stausId));
    }

    const onFilterEnquiry = (type) => {
        EnquiryService.GetEnquiries().then(
            res => {
                (type) ? setEnquiryList(res.filter(e => e.statusId === type)) : setEnquiryList(res);
            }
        );
    }
    // const Attractions = [
    //     { id: 1, Name: "Attraction 1", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
    //     { id: 2, Name: "Attraction 2", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel3.jpg", Contact: "+123456789", Destination: "Himachal" },
    //     { id: 3, Name: "Attraction 3", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel2.jpg", Contact: "+123456789", Destination: "Himachal" },
    //     { id: 4, Name: "Attraction 4", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
    //     { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
    //     { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
    //     { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" }
    // ]

    let linkclass = `mappedlink ${linkState ? 'activated' : ''}`

    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'name', operator: 'contains', type: 'string', value: '' },
        { name: 'fromDestination', operator: 'contains', type: 'string', value: '' },
        { name: 'toDestination', operator: 'contains', type: 'string', value: '' },
        { name: 'fromDestination', operator: 'contains', type: 'string', value: '' },        
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'fromDestination', header: 'From', maxWidth: 100, flex: 1 },
        { name: 'toDestination', header: 'To', maxWidth: 100, flex: 1 },        
        { name: 'name', header: 'Name', maxWidth: 200, flex: 1 },
        { name: 'description', header: 'Description', defaultWidth: 50, flex: 1 },            
        {
            name: 'action', header: '', sorrting: false, maxWidth: 70, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(e, data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(e, data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>                    
                </div>
            }
        },
    ];

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
 
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const onDelete = (e, id) => {        
        e.preventDefault();
        if (window.confirm("Are you sure want to delete?")) {
            AttractionService.DeleteAttraction(id).then(
                () => {
                    AttractionService.GetAttractions().then(
                        res => {
                            setAttractions(res);
                            notify('br');
                        }
                    );
                }
            );
        }
    };

    
    const onEdit = (e, attraction) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/add-attraction`, { state: { attraction: attraction } });
    };
    

    


    return (
        <>
            <div className="content">
                <Table>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle>
                                                <h3>Attractions</h3>
                                                {/* <Nav>
                                                    <Link className="mappedlink activated" onClick={() => { onFilterEnquiry(0)}}> All &nbsp;</Link>
                                                    {enquiryStatusOptions.map((enquiryStatus) => {
                                                        return <Link className={linkclass} onClick={() => { onFilterEnquiry(enquiryStatus.value)}} >&nbsp;{enquiryStatus.label} ({
                                                            (enquiryCount.filter(f => (f.name == enquiryStatus.value) ? f.y : '').length > 0) ? enquiryCount.filter(f => (f.name == enquiryStatus.value) ? f.y : '')[0]?.y : 0
                                                        }) &nbsp;</Link>
                                                    })}
                                                </Nav> */}
                                            </CardTitle>
                                        </Col>
                                        <Col sm="6">
                                            <ButtonGroup
                                                className="btn-group-toggle float-right"
                                                data-toggle="buttons"
                                            >
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data1",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => showForm1()}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Bulk Upload
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data2",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={() => setEnableFiltering(!enableFiltering)}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                                {/* <Button
                                                    color="info"
                                                    id="1"
                                                    size="sm"
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data2",
                                                    })}
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Upload
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-gift-2" />
                                                    </span>
                                                </Button> */}
                                                <Button
                                                    color="info"
                                                    id="2"
                                                    size="sm"
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data3",
                                                    })}
                                                    onClick={() => setCurrentPage("add-attraction")}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Add New
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-tap-02" />
                                                    </span>
                                                </Button>
                                            </ButtonGroup>                                            
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={attractions}
                                    enableFiltering={enableFiltering}
                                    defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />      
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Table>
                <MoadalSlider open={sliderShow1} id='uploadSlider' onClick={showForm1} width='450' className={sliderShow1 ? ` showing` : ''}>
                    <BulkUpload></BulkUpload>
                </MoadalSlider>

            </div >
        </>
    );
}

export default Attractions;