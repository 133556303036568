import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import Pax from "./Pax";
import ModalSlider from "../ModalSlider";
import { useEffect, useState } from "react";
import uploadicon from "../../../assets/img/upload.png"
import minusicon from "../../../assets/img/minus-sign.png"
export default function PaxDetails(props) {
    const pax = props.pax
    
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }
    return <>
        <div className="custom-card">
            <h3 className="formheadings">Pax Details</h3>

            <hr style={{ margin: "0", marginBottom: "1em" }} />

            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>ID Type</th>
                        <th>ID Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {pax?.map(pax => {
                        return <tr>
                            <td>{pax?.id}</td>
                            <td>{pax?.name}</td>
                            <td>{pax?.age}</td>
                            <td>{pax?.gender}</td>
                            <td>Aadhaar</td>
                            <td>123432123</td>
                            <td> <button><i className="tim-icons icon-pencil" /></button>  | <button><i className="far fa-trash-o"></i></button> </td>
                        </tr>
                    })}

                </tbody>
            </Table>

            <ModalSlider open={sliderShow} onClick={showForm} id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>

                <div className="slider card">
                    <h3 className="formheadings">Add New Pax</h3>
                    <div className="custom-card-grid">
                        <FormGroup>
                            <label>Full Name</label>
                            <Input
                                type="text"
                                placeholder="full name"
                                name="Name"
                            >

                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label>Age</label>
                            <Input
                                type="number"
                                placeholder="age"
                                name="age">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>Gender</label>
                            <Input
                                type="select"
                                placeholder=""
                                name="gender">
                                <option value="">Male</option>
                                <option value="">Female</option>
                                <option value="">Others</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label>Contact Number</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="contact" />
                                
                        </FormGroup>

                        <hr />
                        <FormGroup>
                            <label>ID Type</label>
                            <Input
                                type="select"
                                placeholder=""
                                name="">
                                <option value="">Aadhaar</option>
                                <option value="">PAN</option>
                                <option value="">Passport</option>
                                <option value="">Others</option>
                            </Input>
                        </FormGroup>

                        <FormGroup >
                            <label>ID number</label>
                            <Input
                                type="text"
                                placeholder="Id Number"
                                name="amtrem">
                            </Input>
                        </FormGroup>
                        <FormGroup style={{ display: "flex", alignSelf: "flex-end" }}>

                            <button className="btn" ><img style={{ width: "20px", marginRight: ".5em" }} src={uploadicon} alt="1em" />Upload ID</button>

               


                        </FormGroup>
                        <div style={{ gridColumn: '1/3', justifySelf: 'end', color: 'var(--blue)', cursor: "pointer", marginTop: ".5em" }}> Attach Documents</div>
                    </div>
                    <button style={{ width: "max-content", marginTop: "2em" }} className="btn btn-primary"> Save and Close</button>

                </div>
            </ModalSlider>

            <div style={{ textAlign: "right", gridRow: '4/5', gridColumn: '3/4', justifySelf: 'end', color: 'var(--primary)', cursor: "pointer", marginTop: ".5em" }} onClick={showForm}> + Add Pax</div>



        </div>

    </>
}