import { useState, useEffect } from 'react';
import { userState } from '../store/UserStore'
import { enquiryState } from '../store/EnquiryState'
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL =  BaseService.API_URL;
const client = axios.create({
  baseURL: API_URL + `User/`
});

const GetUsers = async () => {
  const response = await client.get(`/GetUsers`);  
  console.info(response.data); 
  return response.data;
};

//delete User
const DeleteUserById = async (userId) => {
  await client.delete(`/DeleteUser?Id=${userId}`)
    // .then(response => {
    //   console.info(`Deleted - ${userId}`);      
    // })
    // .catch(error => {
    //   console.error(error);
    // });
};

//create new user function
async function CreateUser(user) {  
  //alert(JSON.stringify(user))
  const response = await client.post(`/AddUser`, user);
  return response?.data;
}

async function UploadImage(userFormData) {  
  //alert(11)
  const response = await client.post(`/UploadImage`, userFormData)
   //.then(res=>console.log(res))
  // .catch(error=>console.log(error));
  return response?.data;
}


const UserService=  {
  CreateUser,
  DeleteUserById,
  GetUsers,
  UploadImage
}

export default UserService;