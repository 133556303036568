import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import _ from 'underscore';
import FollowUp from './FollowUp'
import BulkUpload from "../components/CustomComponents/BulkUpload";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    NavLink,
    Nav
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { useRecoilState, useRecoilValue } from "recoil";
import { enquiryState, filteredEnquiryState } from '../store/EnquiryState'
import NotificationAlert from "react-notification-alert";
import MoadalSlider from "components/CustomComponents/ModalSlider";
import HotelService from "services/hotel.service";
import BaseService from "services/base.service";

function Hotel() {
    const [bigChartData, setbigChartData] = useState("data1");
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const [hotelList, setHotelList] = useState([]);
    const [sliderShow, setSliderShow] = useState(false);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const notificationAlertRef = React.useRef(null);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const filteredEnquiries = [];
    let enquiryListCount = {};
    const [linkState, setLinkState] = useState(false)
    const showForm = () => { setSliderShow(!sliderShow) }
    const showForm1 = () => { setSliderShow1(!sliderShow1) }

    React.useEffect(() => {
        HotelService.GetHotels().then(
            res => {
                //console.log(res)
                setHotelList(res)
            }
        )
    }, []);

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'name', header: 'Name', minWidth: 220, flex: 1 },
        { name: 'hotelType', header: 'Type', maxWidth: 120, flex: 1 },
        { name: 'rating', header: 'Rating', maxWidth: 90, flex: 1 },
        { name: 'checkInTime', header: 'check In', maxWidth: 120, flex: 1 },
        { name: 'checkOutTime', header: 'Check Out', maxWidth: 120, flex: 1 },
        { name: 'contact', header: 'Contact', maxWidth: 120, flex: 1 },
        { name: 'city', header: 'City', defaultWidth: 50, flex: 1 },
        { name: 'state', header: 'State', defaultWidth: 50, flex: 1 },
        { name: 'country', header: 'Country', defaultWidth: 50, flex: 1 },
        {
            name: 'action', header: 'Action', sorrting: false, defaultWidth: 100, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data)}><i title="Delete" className="fas fa-trash-o" /> </a>
                </div>
            }
        },
    ];

    const filterColumns = [
        { name: 'id', operator: 'gte', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'name', operator: 'startsWith', header: 'Name', minWidth: 220, flex: 1, type: 'string' },
        { name: 'hotelType', operator: 'startsWith', header: 'Type', maxWidth: 120, flex: 1, type: 'string' },
        { name: 'rating', operator: 'startsWith', header: 'Rating', maxWidth: 90, flex: 1, type: 'string' },
        { name: 'checkInTime', operator: 'startsWith',  header: 'check In', maxWidth: 100, flex: 1, type: 'string' },
        { name: 'checkOutTime',  operator: 'startsWith', header: 'Check Out', maxWidth: 100, flex: 1, type: 'string' },
        { name: 'contact', operator: 'startsWith', header: 'Contact', defaultWidth: 50, flex: 1, type: 'string' },
        { name: 'city', operator: 'startsWith', header: 'City', defaultWidth: 50, flex: 1, type: 'string' },
        { name: 'state', operator: 'startsWith', header: 'State', defaultWidth: 50, flex: 1, type: 'string' },
        { name: 'country', operator: 'startsWith', header: 'Country', defaultWidth: 50, flex: 1, type: 'string' },        
    ];

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const linkactivate = () => {
        setLinkState(!linkState)
    }
    const setCurrentPage = (name) => {
        navigate(`${BaseService.NavigateToPath()}/${name}`);

    };

    const onEdit = (hotel) => {
        navigate(`${BaseService.NavigateToPath()}/add-hotel`, { state: { hotel: hotel } });
    };

    const onDelete = (hotel) => {
        if (window.confirm("Are you sure want to delete?")) {
            HotelService.DeleteHotel(hotel.id).then(
                () => {
                    HotelService.GetHotels().then(
                        res => {
                            setHotelList(res)
                            //notify('br', 'The Enquiry has been deleted successfully!');
                        }
                    );
                }
            );
        }
    }

    const navigatePage = (e, page) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/${page}`);
    };



    let linkclass = `mappedlink ${linkState ? 'activated' : ''}`
    return (
        <>
            <div className="content">
                <Table>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle>
                                                <h3>Hotels</h3>
                                            </CardTitle>
                                        </Col>
                                        <Col sm="6">
                                            <ButtonGroup
                                                className="btn-group-toggle float-right"
                                                data-toggle="buttons"
                                            >
                                                <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data1" && enableFiltering,
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data2",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data2") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Import
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data3",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data3") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Bulk Upload
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data4",
                                                })}
                                                onClick={() => setCurrentPage("add-hotel")}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                Add Hotel
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>                                                
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                        showZebraRows={true}
                                        dataSource={hotelList}
                                        enableFiltering={enableFiltering}
                                        defaultFilterValue={filterColumns}
                                        pagination
                                        defaultLimit={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Table>

                <MoadalSlider open={sliderShow1} id='uploadSlider' onClick={showForm1} width='450' className={sliderShow1 ? ` showing` : ''}>
                    <BulkUpload></BulkUpload>
                </MoadalSlider>

            </div >
        </>
    );
}

export default Hotel;