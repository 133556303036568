import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import EnquiryService from '../services/enquiry.service'
import "../assets/css/enqform.css"
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MasterService from "services/master.service";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, } from "reactstrap";
import {  useRecoilValue } from "recoil";
import {  userState } from '../store/UserStore'
import UserService from "services/user.service";
import PackageService from "services/package.service";
import BaseService from "services/base.service";
import { Chrono } from "react-chrono";
import FollowUpService from '../services/followup.service'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import FollowUp from './FollowUp'
import NotificationAlert from "react-notification-alert";
import AuthService from "services/auth.service"
import { utils } from "common/utils";
import { NUMBER_OF_NIGHT } from "common/utils";
import { NUMBER_OF_ADULTS } from "common/utils";
import { NUMBER_OF_CHILD } from "common/utils";
import { NUMBER_OF_INFANT } from "common/utils";

function AddEnquiry() {
    const navigate = useNavigate();
    const notificationAlertRef = React.useRef(null);
    const location = useLocation();
    const userList = useRecoilValue(userState);
    const [enquiryType, setEnquiryType] = useState([]);
    const [enquirySource, setEnquirySource] = useState([]);
    const [userSelections, setUserSelections] = useState([]);
    const [packageSelections, setPackageSelections] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [selectedEnquiryType, setSelectedEnquiryType] = useState([]);
    const [destination, setDestination] = useState([])
    const [cities, setCities] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [selectedCity, setSelectedCity] = useState([])
    const [followupItems, setfollowupItems] = useState(
        []
    );
    const [parentToChild, setParentToChild] = useState('');
    const [sliderShow, setSliderShow] = useState(false);
    const currentUser = AuthService.getCurrentUser()
    const [enquiry, setEnquiry] = useState(
        {
            "id": 0,
            "name": "",
            "email": "",
            "phone": "",
            "userId": 0,
            "statusId": 1,
            "reminderId": 0,
            "modeId": 0,
            "leadType": "Holiday",
            "packageId": "",
            "plannedTravelDate": new Date(),
            "destination": "",
            "products": "",
            "plannedNoOfDays": 0,
            "numberOfAdults": 0,
            "numberOfChild": 0,
            "numberOfInfant": 0,
            "budgetPerAdults": 0,
            "budgetPerChild": 0,
            "overAllBudget": 0,
            "reference": '',
            "referenceLink": '',
            'leadSourceId': 0,
            'city': []
        });

    const [enquiryStatusoptions, setEnquiryStatusoptions] = useState([])

    const showForm = (enquiry) => {
        //setParentToChild(enquiry?.id)
        //setEnquiry(enquiry);
        setSliderShow(!sliderShow);
    }

    useEffect(() => {

        EnquiryService.GetEnquiryStatus().then(res => {
            setEnquiryStatusoptions(res);
        });

        EnquiryService.GetEnquirySource().then(res => {
            setEnquirySource(res);
            console.log(res);
        });

        if (location.state?.enquiry?.id) {
            const enquiryState = location.state?.enquiry;
            setEnquiry(location.state?.enquiry);
            setStartDate(new Date(enquiryState.plannedTravelDate));
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                    setUserSelections(res.filter(u => u.id == enquiryState.userId));
                });

            PackageService.GetPackages().then(res => {
                setPackages(res);
                setSelectedPackage(res?.filter(p => p.id == enquiryState.packageId))
            })

            FollowUpService.GetFollowUpsByEnquiryId(location.state?.enquiry?.id).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });

            MasterService.GetTypes('LeadType').then(res => {
                setEnquiryType(res);
                setSelectedEnquiryType(res?.filter(et => et?.id == enquiryState.leadType)[0]?.id)
            });

            MasterService.GetDestination().then(res => {
                setDestination(res)
                setSelectedDestination(res.filter(p => p?.name == enquiryState?.destination))
            });

            MasterService.GetCities().then(res => {
                setCities(res)
                //setSelectedCity(res.filter(p => p?.name == enquiryState?.destination))
            });

        }
        else {
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                });

            PackageService.GetPackages().then(res => {
                setPackages(res);
            })

            MasterService.GetTypes('LeadType').then(res => {
                setEnquiryType(res);
            });


            MasterService.GetDestination().then(res => {
                setDestination(res)
            });

            MasterService.GetCities().then(res => {
                setCities(res)
            });
        }
    }, []);


    const callback = () => {
        return enquiry;
    }

    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        name: yup.string().min(3).max(40).required("Name is required"),
        phone: yup.string().min(10).required("Contact Number is required"),
        email: yup.string().email().min(3).required("Email is required"),
        numberOfAdults: yup.number().max(NUMBER_OF_ADULTS, `Value should be 0 to ${NUMBER_OF_ADULTS}`).min(0, `Value should be 0 to ${NUMBER_OF_ADULTS}`),
        numberOfChild: yup.number().max(NUMBER_OF_CHILD, `Value should be 0 to ${NUMBER_OF_CHILD}`).min(0, `Value should be 0 to ${NUMBER_OF_CHILD}`),
        numberOfInfant: yup.number().max(NUMBER_OF_INFANT, `Value should be 0 to ${NUMBER_OF_INFANT}`).min(0, `Value should be 0 to ${NUMBER_OF_INFANT}`),
        plannedNoOfDays: yup.number().max(NUMBER_OF_NIGHT, `Value should be 0 to ${NUMBER_OF_NIGHT}`).min(0, `Value should be 0 to ${NUMBER_OF_NIGHT}`),
        plannedTravelDate: yup.date().required().min(new Date()),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    //saving enquiry after calling createEnquiry        
    const saveEnquiry = async (enquiryData) => {
        EnquiryService.CreateEnquiry(enquiryData).then(res => {
            //resetForm();            
            setEnquiry(JSON.parse(JSON.stringify(enquiryData)));
            notify('br', 'The Enquiry has been updated successfully!');
        })
            .catch(err => {
                notify('br', 'The Enquiry has been not updated successfully!', 3);
            })
    };

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/enquiries`);
    }

    const notify = (place, message, color = 2) => {
        var color = color;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const formik = useFormik({
        initialValues: enquiry,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.packageId = selectedPackage[0]?.id;
            values.destination = selectedDestination[0]?.name;
            values.city = selectedCity
            //values.leadType = selectedEnquiryType[0]?.id || 0
            if (userSelections?.length <= 0) {
                values.statusId = 2;
                values.userId = currentUser?.id;
            }
            else {
                values.userId = userSelections[0]?.id;
            }
            saveEnquiry(values);
        }
    });


    const hotelTypes = [{
        id: 1,
        rating: 'Deluxe',
    },
    {
        id: 2,
        rating: 'Semi Super Deluxe',
    },
    {
        id: 3,
        rating: 'Supper Deluxe',
    }]

    // handle input change event
    const handleInputChange = (date) => {
        setStartDate(date);
        formik.values.plannedTravelDate = date;
    };

    // // handle selection
    // const handleChange = (e) => {
    //     setUserSelections(e)
    //     formik.values.userId = e[0]?.id;
    // }

    // const handleOnChange = (e) => {
    //     console.log(e);
    //     setSelectedEnquiryType(e);
    //     formik.values.leadType = e;
    // }

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        PackageService.GetPackages()
            .then(res => {
                setPackages(res.filter(p => p.state == destination[0]?.name))
            });
    }

    const onSelectedCity = (city) => {
        setSelectedCity(city);
    }

    const addFollowUps = useCallback((followUps) => {
        followUps.entityId = location.state?.enquiry?.id;
        FollowUpService.CreateFollowUp(followUps).then(res => {
            FollowUpService.GetFollowUpsByEnquiryId(location.state?.enquiry?.id).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        });

    }, []);

    function convertToProspect(e) {
        e.preventDefault();
        //console.log(enquiry)
        // if (!location.state?.enquiry?.id)
        //     setEnquiry(location.state?.enquiry);

        const prospect = {
            "id": -1,
            "leadId": enquiry.id,
            "name": enquiry.name,
            "email": enquiry.email,
            "phone": enquiry.phone,
            "userId": enquiry.userId,
            "statusId": enquiry.statusId,
            "reminderId": enquiry.reminderId,
            "modeId": enquiry.modeId,
            "leadType": enquiry.leadType,
            "packageId": enquiry.packageId,
            "plannedTravelDate": enquiry.plannedTravelDate,
            "destination": enquiry.destination,
            "products": enquiry.products,
            "plannedNoOfDays": enquiry.plannedNoOfDays,
            "numberOfAdults": enquiry.numberOfAdults,
            "numberOfChild": enquiry.numberOfChild,
            "numberOfInfant": enquiry.numberOfInfant,
            "budgetPerAdults": enquiry.budgetPerAdults,
            "budgetPerChild": enquiry.budgetPerChild,
            "overAllBudget": enquiry.overAllBudget,
            "leadCode": enquiry.code,
            "lead": enquiry,
            "proposals": []
        }
        console.log(prospect)
        navigate(`${BaseService.NavigateToPath()}/add-prospect`, { state: { prospect: prospect } });
    }


    return (
        <>
            <div className="content">
                <NotificationAlert ref={notificationAlertRef} />
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "0em", marginBottom: '5px' }}>
                            <CardBody>
                                <Row>
                                    <Col style={{ alignItems: 'right' }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                            {
                                                enquiryStatusoptions?.map((eq) => {
                                                    return <><div
                                                        className={eq.id < enquiry?.statusId ? 'stage passed' : 'stage'}>
                                                        {
                                                            (eq.id == enquiry?.statusId) ? <span style={{ marginLeft: '5px', 'color': 'Blue' }}> {eq.status}</span> : <span style={{ marginLeft: '5px' }}>  {eq.status}</span>}
                                                    </div>
                                                        {eq.status != "Closed" && <div className=""> </div>}
                                                    </>
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Form onSubmit={formik.handleSubmit} className="form">
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Full Name<span className="requiredStar">*</span> </label>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    maxLength={50}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <span className='text-red-400'>{formik.errors.name}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Contact Number<span className="requiredStar">*</span></label>
                                                <Input
                                                    name="phone"
                                                    placeholder="Contact Number"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone}
                                                    maxLength={11}
                                                />
                                                {formik.touched.phone && formik.errors.phone && (
                                                    <span className='text-red-400'>{formik.errors.phone}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="5">
                                            <FormGroup>
                                                <label>
                                                    Email address <span className="requiredStar">*</span>
                                                </label>
                                                <Input
                                                    name="email"
                                                    placeholder="sample@email.com"
                                                    type="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    maxLength={150}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <span className='text-red-400'>{formik.errors.email}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup className="">
                                                <label>Adults</label>
                                                <Input
                                                    name="numberOfAdults"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.numberOfAdults}
                                                    maxLength={2}
                                                    onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_ADULTS) }}
                                                />
                                                {formik.touched.numberOfAdults && formik.errors.numberOfAdults && (
                                                    <span className='text-red-400'>{formik.errors.numberOfAdults}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup className="">
                                                <label>Kids[6-10]</label>
                                                <Input
                                                    name="numberOfChild"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.numberOfChild}
                                                    maxLength={2}
                                                    onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_CHILD) }}
                                                />
                                                {formik.touched.numberOfChild && formik.errors.numberOfChild && (
                                                    <span className='text-red-400'>{formik.errors.numberOfChild}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup className="">
                                                <label>Infant[0-6]</label>
                                                <Input
                                                    name="numberOfInfant"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.numberOfInfant}
                                                    maxLength={2}
                                                    onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_INFANT) }}
                                                />
                                                {formik.touched.numberOfInfant && formik.errors.numberOfInfant && (
                                                    <span className='text-red-400'>{formik.errors.numberOfInfant}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup className="">
                                                <label>Nights</label>
                                                <Input
                                                    name="plannedNoOfDays"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.plannedNoOfDays}
                                                    maxLength={2}
                                                    onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_NIGHT) }}
                                                />
                                                {formik.touched.plannedNoOfDays && formik.errors.plannedNoOfDays && (
                                                    <span className='text-red-400'>{formik.errors.plannedNoOfDays}</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                                <label>Tour Date</label>
                                                <DatePicker className="form-control"
                                                    isClearable
                                                    showIcon
                                                    dateFormat={'dd-MM-yyyy'}
                                                    name="plannedTravelDate"
                                                    selected={startDate}
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        handleInputChange(date)
                                                    }} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup className="">
                                                <label>Prefer Hotel Type</label>
                                                <Input
                                                    name="hotelType"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hotelType}
                                                    maxLength={2}
                                                >
                                                    {hotelTypes.map(hotel => {
                                                        return <option value={hotel.id}>{hotel.rating}</option>;
                                                    })}
                                                </Input>
                                                {/* 
                                                {formik.touched.package && formik.errors.package && (
                                                    <span className='text-red-400'>{formik.errors.package}</span>
                                                )} */}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="5">
                                            <FormGroup className="">
                                                <label>Destination</label>
                                                <Typeahead
                                                    id="destination"
                                                    labelKey={(option) => `${option.name}`}
                                                    onChange={onSelectedDestination}
                                                    options={destination}
                                                    placeholder="Select Destination"
                                                    selected={selectedDestination}
                                                    //multiple
                                                    clearButton
                                                ></Typeahead>
                                                {/* {formik.touched.package && formik.errors.package && (
                                                    <span className='text-red-400'>{formik.errors.package}</span>
                                                )} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md="7">
                                            <FormGroup className="">
                                                <label>Package Name</label>
                                                <Typeahead
                                                    id="Package"
                                                    labelKey={(option) => `${option.packageName}`}
                                                    onChange={setSelectedPackage}
                                                    options={packages}
                                                    placeholder="Select Package "
                                                    selected={selectedPackage}
                                                    clearButton
                                                />
                                                {formik.touched.package && formik.errors.package && (
                                                    <span className='text-red-400'>{formik.errors.package}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="5">
                                            <FormGroup className="">
                                                <label>City</label>
                                                <Typeahead
                                                    id="city"
                                                    labelKey={(option) => `${option.name}`}
                                                    onChange={onSelectedCity}
                                                    options={cities}
                                                    placeholder="Select City"
                                                    selected={selectedCity}
                                                    multiple
                                                    clearButton
                                                ></Typeahead>                                                
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>
                                                    Enquiry Status
                                                </label>
                                                <Input
                                                    name="statusId"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.statusId}
                                                    maxLength={150}
                                                >
                                                    {enquiryStatusoptions.map(enquiryStatus => {
                                                        return <option value={enquiryStatus.id}>{enquiryStatus.status}</option>;
                                                    })}
                                                </Input>
                                                {formik.touched.enquiryStatus && formik.errors.enquiryStatus && (
                                                    <span className='text-red-400'>{formik.errors.enquiryStatus}</span>
                                                )}

                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>User/Executive</label>
                                                <Typeahead
                                                    id="UserId"
                                                    labelKey={(option) => `${option.firstName} ${option.lastName}`}
                                                    onChange={setUserSelections}
                                                    options={users}
                                                    placeholder="Select User"
                                                    selected={userSelections}
                                                    clearButton
                                                />
                                                {formik.touched.executiveId && formik.errors.executiveId && (
                                                    <span className='text-red-400'>{formik.errors.executiveId}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <label>Source</label>
                                                <Input
                                                    width={200}
                                                    name="leadSourceId"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values?.leadSourceId}>
                                                    {
                                                        enquirySource.map((source) => (<option value={source?.id} >{source?.name}</option>)
                                                        )
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <label>Type</label>
                                                <Input
                                                    width={200}
                                                    name="leadType"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values?.leadType}>
                                                    {
                                                        enquiryType.map((source) => (<option value={source?.id} >{source?.name}</option>)
                                                        )
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup style={{ gridColumn: "1/-1" }}>
                                                <label >
                                                    Message
                                                </label>
                                                <Input
                                                    name="message"
                                                    type="textarea"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.message}
                                                    maxLength={2000}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div style={{ marginTop: "2em" }}>
                                                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                                    Save
                                                </Button>
                                                <Button onClick={resetForm} className="btn-fill" color="secondary">
                                                    Cancel
                                                </Button>
                                                {/* <Button onClick={resetForm} className="btn-fill" color="secondary">
                                                    Send Quotation
                                                </Button> */}

                                                <Button onClick={() => { showForm(enquiry) }} className="btn-fill" color="secondary">
                                                    Add Follow Up
                                                </Button>

                                                <Button onClick={(e) => convertToProspect(e)} className="btn-fill" color="secondary">
                                                    Convert To Prospect
                                                </Button>

                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader><h4 className="title new">Timeline</h4></CardHeader>
                            <CardBody>
                                <Chrono allowDynamicUpdate="true" fontSizes={{
                                    cardSubtitle: '12px',
                                    cardText: '11px',
                                    cardTitle: '12px',
                                    title: '13px',
                                }} titleDateFormat='dd MMM yy'
                                    contentDetailsHeight="80"
                                    cardHeight="80"
                                    //style={{ height: '950px' }}
                                    items={followupItems}
                                    mode="VERTICAL" >
                                </Chrono>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </div >
            <Drawer
                open={sliderShow}
                onClose={() => setSliderShow((prevState) => !prevState)}
                direction='right'
                className='bla bla bla'
                style={{ 'width': '500px', "zIndex": '10000' }}
            >
                <FollowUp enquiry={enquiry} dataParentToChild={parentToChild} refresh={addFollowUps} onCancel={showForm} />
            </Drawer>
        </>
    );
}

export default AddEnquiry;
