import { Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap"
import { useState, useRef, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MoadalSlider from "components/CustomComponents/ModalSlider";
import HotelMiniView from "components/CustomComponents/Hotel/HotelMiniView";
import Dropzone from "components/Common/Dropzone";

import { components } from "react-select";
import { backgroundColors } from "contexts/BackgroundColorContext";
import { GrapesjsReact } from "grapesjs-react";
import 'grapesjs/dist/css/grapes.min.css';


export default function ViewPackage(props) {
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }

    const [dragedHotel, setDragedHotel] = useState({});
    const [dragedActivity, setDragedActivity] = useState({});

    let currentPackage = {
        id: 0,
        name: 'Shimla Volvo Tour',
        description: 'This package is very good.',
        coverPhoto: 'https://images.cnbctv18.com/wp-content/uploads/2022/08/Shimla-shutterstock-1019x573.jpg?impolicy=website&width=617&height=264',
        destination: 'Shimla',
        duration: '2D/3N',
        itenary: [
            { day: 1, hotel: 'Shimla Guest House', attraction: 'Roller Coaster' },

        ]
    }
    const cardheaderstyles = {
        display: "flex",
        background: `url(${currentPackage.coverPhoto}), hsla(245, 100%, 5%, 0.5)`,
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundPosition: 'right top',
        alignItems: "flex-end",
        justifyContent: 'space-between',
        padding: '2em 1.5em',
        height: '180px',
        width: 'auto'



    }

    const Hotels = [
        { id: 1, Name: "Hotel 1", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 2, Name: "Hotel 2", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel3.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 3, Name: "Hotel 3", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel2.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 4, Name: "Hotel 4", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" }
    ]
    const Attractions = [
        { id: 1, Name: "Attraction 1", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 2, Name: "Attraction 2", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel3.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 3, Name: "Attraction 3", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel2.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 4, Name: "Attraction 4", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" }
    ]

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [list, setList] = useState(['Attractions 1', 'Attractions 2', 'Attractions 3', 'Attractions 4', 'Attractions 5', 'Attractions 6']);

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target);
        setDragedHotel(e.target)
        setDragedActivity(e.target)

    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target);
    };

    // onDrop function  
    const onDrop = useCallback(acceptedFiles => {
        // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
        console.log(acceptedFiles);
    }, []);

    const handleDrop = e => {
        e.preventDefault();
        try {
            document.getElementById('HotelInnertext').innerText = '';
        }
        catch {

        }
        e.target.appendChild(dragedHotel);
        e.stopPropagation();
    };

    const handleActivityDrop = e => {
        e.preventDefault();

        e.target.appendChild(dragedActivity);

        if (dragedActivity?.id) {


        }
        e.stopPropagation();
    };

    return <>
        <div className="content">
            <div>
                <div style={{ display: "flex" }}>
                    <Card style={{ height: "min-content", width: "68%" }}>
                        <CardHeader style={cardheaderstyles}>
                            <CardTitle tag="h4" style={{ color: "white", fontSize: '32px', fontWeight: '500', marginBottom: '0' }}>{currentPackage.name}</CardTitle> <button className="btn ">Change cover photo</button>
                        </CardHeader>

                        <CardBody>
                            <div className="pkg-overview">
                                <h4 className="formheadings">Overview</h4>
                                <p>{currentPackage.description}</p>
                                <p>Duration: {currentPackage.duration}</p>
                            </div>

                            <h4 className="formheadings">Itenary</h4>
                            <Tabs>
                                <TabList>
                                    <Tab>Day1</Tab>
                                    <Tab>Day2</Tab>
                                    <Tab>Day3</Tab>
                                    <Tab>+ New Day</Tab>
                                </TabList>
                                <TabPanel style={{ height: "40vh", overflowY: "scroll" }} >
                                    <div className="itenaryView">
                                        <div className="itHotel">

                                            <GrapesjsReact
                                                id='grapesjs-react'
                                                plugins={[
                                                    'gjs-blocks-basic'
                                                ]}
                                                pluginsOpts={{
                                                    blocks: ['link-block', 'quote', 'text-basic']
                                                }}
                                                blockManager={
                                                    {
                                                        blocks: [
                                                            //Hotels.map((hotel, index) => ())
                                                            {
                                                                id: 'section1', // id is mandatory
                                                                label: `<b>Section</b>`, // You can use HTML/SVG inside labels
                                                                attributes: { class: 'gjs-block-section' },
                                                                content: `<div>Abc                                                                                                                                                                                                           
                                                                </div>`
                                                            }
                                                        ]
                                                    }
                                                }
                                            />;

                                            <h5 className="formheadings">Hotels</h5>

                                            <div style={{ height: 'auto', paddingBottom: "3em" }} id="target"
                                                onDrop={e => handleDrop(e)}
                                            >
                                                <p id="HotelInnertext">Drag files here to upload</p>

                                            </div>

                                        </div>
                                        <div className="itAttraction">
                                            <h5 className="formheadings">Attractions</h5>

                                            <Dropzone onDrop={handleDrop}></Dropzone>

                                            <div style={{ backgroundColors: 'Red', height: 'auto', paddingBottom: "3em" }} id="target123" className={'drag-drop-zone'}
                                                onDrop={e => handleActivityDrop(e)}
                                            >
                                                <p id="HotelInnertext">Drag files here to upload</p>
                                            </div>

                                        </div>
                                    </div></TabPanel>
                            </Tabs>


                        </CardBody>
                    </Card>
                    <Card style={{ width: "30%", marginLeft: "1em" }}>
                        <CardHeader>
                            <h3 className="formheadings">Library</h3>
                        </CardHeader>
                        <CardBody>
                            <div >
                                <Tabs>
                                    <TabList>
                                        <Tab>Hotels</Tab>
                                        <Tab>Attractions</Tab>
                                        <Tab>Images</Tab>
                                    </TabList>
                                    <TabPanel style={{ height: "60vh", overflowY: "scroll" }}>
                                        {
                                            Hotels.map((hotel, index) => (
                                                <div>
                                                    {
                                                        <div
                                                            onDragStart={(e) => dragStart(e, index)}
                                                            onDragEnter={(e) => dragEnter(e, index)}
                                                            key={index}
                                                            draggable>
                                                            {<HotelMiniView isminiview={true} type="Hotel" id={'Hotel' + index} Hotel={hotel} />}
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </TabPanel>
                                    <TabPanel style={{ height: "60vh", overflowY: "scroll" }}>
                                        {
                                            list &&
                                            Attractions.map((attraction, index) => (
                                                <div
                                                    onDragStart={(e) => dragStart(e, index)}
                                                    onDragEnter={(e) => dragEnter(e, index)}
                                                    key={index}
                                                    draggable>
                                                    {<HotelMiniView isminiview={true} type="Hotel" id={'Hotel' + index} Hotel={attraction} />}

                                                </div>
                                            ))}
                                    </TabPanel>
                                </Tabs></div>
                        </CardBody>
                    </Card>
                </div>
                <Button type="submit" className="btn-fill" color="primary">
                    Download
                </Button>
                <Button type="submit" className="btn-fill" color="primary">
                    Preview
                </Button>
            </div>
            <MoadalSlider  open={sliderShow} onClick={showForm} id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>
                <div>Hello</div>
            </MoadalSlider>
        </div>
    </>
}