import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

async function GetTemplates() {
  const response = await axios.get(API_URL + `Template/GetTemplate`);
  console.info(response.data);
  return response?.data;
}

async function CreateTemplate(template) {
  //alert(JSON.stringify(template))
  const response = await axios.post(API_URL + `Template/AddTemplate`, template);
  return response?.data;
}

async function DeleteTemplate(template) {
  const response = await axios.delete(API_URL + `Template/DeleteTemplate?Id=${template.id}`);
  return response?.data;
}
async function UploadImage(userFormData) {
  // const response = await client.post(`/UploadImage`, userFormData);
  // return response.data;
}

const TemplateService = {
  GetTemplates,
  CreateTemplate,
  DeleteTemplate,
  UploadImage
}

export default TemplateService;