import React, { useRef, useState } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate, Link } from 'react-router-dom';
import MoadalSlider from "components/CustomComponents/ModalSlider";
import CustomerService from '../services/customer.service'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { enquiryState } from './../store/EnquiryState'
import BaseService from "services/base.service";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    Form,
    Nav,
    NavLink
} from "reactstrap";

function Customer() {
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [customerList, setCustomerList] = useRecoilState(enquiryState);
    const navigate = useNavigate();
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }
    const [enableFiltering, setEnableFiltering] = useState(false);
    React.useEffect(() => {
        CustomerService.GetCustomer().then(
            res => setCustomerList(res)
        );
    }, []);

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },
        {
            name: 'proposals', header: 'Proposals', defaultWidth: 110, render: ({ value, cellProps }) => {
                return value && value?.length
            }
        },
        {
            name: 'plannedTravelDate', header: 'Travel Date', defaultWidth: 150, render: ({ value, cellProps }) => {
                //return moment(value).format('MM-DD-YYYY')
                return value && format(parseISO(value), "dd MMM yyyy")
            }
        },
        { name: 'destination', header: 'Destination' },
        { name: 'overAllBudget', header: 'Budget', defaultWidth: 100, type: 'number' },
        {
            name: 'executive', header: 'User', defaultWidth: 80, render: ({ value, cellProps }) => {
                //return value?.firstName
                return value && <div className="photo"><img height={35} width={35} alt="..." title={value?.firstName} src={(value?.picture) ? BaseService.API_URL + `app-images/${value?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            }
        },
        {
            name: 'action', header: 'Action', sorrting: false, minWidth: 60, 
            render: ({ data }) => {
                return data && <div><a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                    <a href="#" onClick={(e) => onViewProfile(data)}><i title="View Profile" className="fas fas fa-eye" /> </a>
                    <a href="#" onClick={(e) => onCreateBooking(data)}><i title="Booking" class="fa-solid fa-person-walking-luggage"></i> </a>
                </div>
            }
        },
    ];

    const gridStyle = { minHeight: 550 }
    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    function onViewProfile(customer) {
        navigate(`${BaseService.NavigateToPath()}/client-profile`, { state: { customer: customer } });
    }

    function onDelete(id) {
        if (window.confirm("Are you sure want to delete?")) {
            CustomerService.DeleteCustomer(id).then(
                res => {
                    //pageRefresh();
                }
            )
        }
    }

    const onEdit = (customer) => {
        navigate(`${BaseService.NavigateToPath()}/add-customer`, { state: { customer: customer } });
    };

    const onCreateBooking=(customer)=>{
        navigate(`${BaseService.NavigateToPath()}/booking-details`, { state: { customer: customer } });
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle><h3>Customers</h3></CardTitle>
                                    </Col>
                                    <Col sm="6" >
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">

                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data1" && enableFiltering,
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data2",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { showForm(); setbigChartData("data2") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Import
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data4",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data4"); BaseService.ExportToExcel(customerList); }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Export
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data3",
                                                })}
                                                onClick={(e) => {
                                                    navigate(`${BaseService.NavigateToPath()}/add-customer`);
                                                }}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Add New
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={customerList}
                                    enableFiltering={enableFiltering}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default Customer;