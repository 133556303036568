import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});

async function GetReminders() {
  const response = await client.get(`reminder/GetReminders`);
  //console.info(response.data);
  return response?.data;
}


async function DeleteReminder(id) {
  const response = await client.delete(`reminder/DeleteReminder?id=${id}`);
  return response?.data;
}

async function CreateReminder(reminder) {  
  const response = await client.post(`reminder/AddReminder`, reminder);    
  return response?.data;
}

async function GetReminderDocuments(id) {
  const response = await client.get(`reminder/getReminderDocument?id=${id}`);
  //console.info(response.data);
  return response?.data;
}

async function GetReminderPayments(id) {
  const response = await client.get(`reminder/getReminderPayment?id=${id}`);
  //console.info(response.data);
  return response?.data;
}

async function GetRemindersByUserId(userId) {  
  const response = await client.get(`reminder/GetRemindersByUserId?userId=${userId}`);
  //console.info(response.data);
  return response?.data;
}

async function GetLatestRemindersByUserId(userId) {
  const response = await client.get(`reminder/GetLatestRemindersByUserId?userId=${userId}`);
  //console.info(response.data);
  return response?.data;
}

async function GetLatestLeadAndRemindersByUserId(userId) {
  const response = await client.get(`reminder/GetLatestLeadAndRemindersByUserId?userId=${userId}`);
  //console.info(response.data);
  return response?.data;
}

async function GetReminderBankAccounts(id) {
  const response = await client.get(`reminder/GetReminderContact?id=${id}`);
  //console.info(response.data);
  return response?.data;
}

const Reminderservice = {
  GetReminders,
  CreateReminder,
  DeleteReminder,
  GetReminderDocuments,
  GetReminderPayments,
  GetRemindersByUserId,
  GetReminderBankAccounts,
  GetLatestRemindersByUserId,
  GetLatestLeadAndRemindersByUserId
}

export default Reminderservice;