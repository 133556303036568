import { React, useEffect, useState, useRef } from "react";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Editor } from "@tinymce/tinymce-react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    NavLink,
    Nav
} from "reactstrap";
import TemplateService from 'services/template.service';
import BaseService from 'services/base.service';
import * as yup from 'yup';
import ImageUploading from "react-images-uploading";
import PackageService from "services/package.service";
import { CKEditor } from 'ckeditor4-react';


export default function Template() {
    const [templates, setTemplates] = useState([]);
    const editorRef = useRef(null);
    const [content, setContent] = useState("<p>Hello World</p>");
    const [coverImage, setCoverImage] = useState("");
    const [template, setTemplate] = useState(
        {
            id: 0,
            title: "",
            description: "",
            content: "",
        }
    );

    const onPageRefresh = () => {
        TemplateService.GetTemplates().then(
            res => {
                setTemplates(res)
            }
        );
    };


    useEffect(() => {
        onPageRefresh();
    }, []);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: template,
        //validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.content = content;
            values.coverImages = coverImage;
            TemplateService.CreateTemplate(values).then(res => {
                onPageRefresh();
            });
        }
    });

    //cancel
    const resetForm = () => {
        setTemplate(
            {
                id: 0,
                title: "",
                description: "",
                content: "",
            });
        setContent("");
        setCoverImage("");
    }

    const onEdit = (template) => {
        // setTemplate(template);
        // setContent(template.content);
        // setCoverImage(template.coverImages);
        navigate(`${BaseService.NavigateToPath()}/create-templates`);
        
    };

    const onDelete = (template) => {

        TemplateService.DeleteTemplate(template).then(
            res => {
                onPageRefresh();

            }
        );
    };

    const onImgChange = (imageList) => {
        const formData = new FormData();
        formData.append("userId", template.id);
        formData.append("formFile", imageList[0].file);
        formData.append("fileName", imageList[0].file.name);
        PackageService.UploadImage(formData).then(
            res => {
                setCoverImage('app-images/' + res)
            }
        );
    };

    return <>
        <div className="content ">
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="12">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader >
                                <h4 className="title new">All Templates</h4>
                            </CardHeader>
                            <CardBody>
                                <Table className="tablesorter" responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templates.map((template) => (
                                            <tr>
                                                <td>TP{template.id}</td>
                                                <td>{template.title}</td>
                                                <td>
                                                    <Nav>
                                                        <NavLink onClick={(e) => { onEdit(template) }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                        <NavLink onClick={(e) => onDelete(template)}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                    </Nav>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}