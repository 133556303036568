import Drawer from 'react-modern-drawer'

export default function ModalSlider(props) {
    let className = props.className
    let width = props.width
    let buttonstyles = {
        cursor: "pointer",
        display: "inline-block",
        fontSize: '16px', backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        right: '2em',
        top: '1.35em',
        zIndex: '100'
    }


    return <>
        {/* <div id={props.id} className={`modalslider ${className}`} >
            <div style={{ width: `${width}px` }} className="modalcontent">

                <button style={buttonstyles} onClick={props.onClick}>
                    <h4>&times;</h4>
                </button>
                {props.children}

            </div>
        </div > */}

        <Drawer
            open={props.open}
            //onClose={() => setSliderShow((prevState) => !prevState)}
            onClose={props.onClick}
            direction='right'
            className='bla bla bla'
            style={{ 'width': '500px', "zIndex": '10000' }}
        >
            {props.children}
        </Drawer>
    </>
}