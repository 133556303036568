export default function BulkUpload(props) {
    return <>
        <div className="card" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: "space-between", padding: '1.5em' }}>
            <div >
                <h3 className="formheadings">Upload Enquiry</h3>
                <button style={{ width: '100%',marginTop:'1em' }} className="btn">Download Sample format</button>
                <p style={{marginTop:'1em'}}>Kindly Download the enquiry Format and fill your bilk Enquiry in the proper format and then upload it for proper importing of the data.</p>

                <p style={{marginTop:'1em'}}>Select Excel file <button className="btn btn-primary">Choose File</button> </p>
            </div>


            <button className="btn">Import</button>
        </div>
    </>
}