import axios from "axios";
import useToken from './../common/useToken';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const user = {
  "id": 3,
  "userId": "theasko.id",
  "firstName": "Ashok",
  "lastName": "Kumar",
  "email": "Ashok.kumar@gmail.com",
  "phone": "9958591552",
  "address": "13/1, Shyam Vihar",
  "city": "New Delhi",
  "country": "India",
  "pinCode": "110043",
  "aboutMe": "Testing ",
  "faceBook": "facebook",
  "tweeter": "Tweeter",
  "gmail": "gmail",
  "picture": "",
  "company": "Abc Company",
  "title": "CTO/Co-Founder"
};



const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login1 = async (username, password) => {
  const response = await axios.post(API_URL + "User/login", { 'userId': username, 'password': password });
  //alert(JSON.stringify(response.data));
  localStorage.setItem("token", JSON.stringify({ token: response.data.userId }));
  localStorage.setItem("user", response.data);
};

function getUserPath(roleId) {
  switch (roleId) {
    case 1:
      return "/admin"
      break;

    case 2:
      return "/user"
      break;
    case 3:
      return "/client"
      break;
    default:
      return "/"
      break;
  }
}



const login = (username, password) => {
  return axios
    .post(API_URL + "User/signin", { 'userId': username, 'password': password })
    .then((response) => {
      if (response?.data?.userId) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("path", getUserPath(response.data.userTypeId));
        
      }
      return response?.data;
    }).catch(error => {

    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "User/signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  login1,
}

export default AuthService;