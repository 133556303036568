import React, { useState, useCallback } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import _, { reduce } from 'underscore';
import FollowUp from './FollowUp'
import BulkUpload from "../components/CustomComponents/BulkUpload";
import en from 'date-fns/locale/en-IN';
import { Chrono } from "react-chrono";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import * as XLSX from "xlsx";
import NotificationAlert from "react-notification-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { Oval } from 'react-loader-spinner'

import {
    Alert,
    UncontrolledAlert,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    Nav,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { useRecoilState, useRecoilValue } from "recoil";
import { enquiryState, filteredEnquiryState } from './../store/EnquiryState'
import MoadalSlider from "components/CustomComponents/ModalSlider";
import BaseService from '../services/base.service'
import FollowUpService from '../services/followup.service'
import UserService from "services/user.service";
//import moment from 'moment';
import moment, * as moments from 'moment';
//import moment from 'moment/src/moment'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import { date } from "yup";

function Enquiry() {
    window.moment = moment
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [enquiryCount, setEnquiryCount] = React.useState([]);
    const notificationAlertRef = React.useRef(null);
    const navigate = useNavigate();
    const [selectedEnquiry, setSelectedEnquiry] = React.useState([]);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [sliderShow, setSliderShow] = useState(false);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [sliderShow2, setSliderShow2] = useState(false);
    const [parentToChild, setParentToChild] = useState('');
    const [userList, setUserList] = useState([])
    const [filterUserList, setFilterUserList] = useState([])
    const [loading, setLoading] = useState(false)
    const [followupItems, setfollowupItems] = useState([]);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [selected, setSelected] = useState([]);
    const [showColumnMenuTool, setShowColumnMenuTool] = useState(true);
    const [enableColumnFilterContextMenu, setEnableColumnFilterContextMenu] = useState(true);
    const showForm = (enquiry) => {
        setParentToChild(enquiry?.id)
        setSelectedEnquiry(enquiry);
        setSliderShow(!sliderShow);
    }
    const [enquiryStatusoptions1, setEnquiryStatusoptions1] = useState([])
    const [enquiryStatusoptions2, setEnquiryStatusoptions2] = useState([])    

    const enquiryStatusoptions = [
        { value: '1', label: 'Spoke' },
        { value: '2', label: 'Meeting Fixed' },
        { value: '3', label: 'Met' },
        { value: '4', label: 'Closed' },
        { value: '5', label: 'Lost' },
        { value: '6', label: 'Active' },
    ]

    const showForm1 = () => { setSliderShow1(!sliderShow1) }
    const defaultSelected = {};
    const defaultFilterValue = [
        //{ name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'code', operator: 'contains', type: 'string', value: '' },
        { name: 'email', operator: 'contains', type: 'string', value: '' },
        { name: 'name', operator: 'contains', type: 'string', value: '' },
        //{ name: 'message', operator: 'contains', type: 'string', value: '' },
        { name: 'phone', operator: 'contains', type: 'string', value: '' },
        { name: 'referenceLink', operator: 'contains', type: 'string', value: '' },
        { name: 'package', operator: 'contains', type: 'string', value: '' },
        { name: 'plannedTravelDate', operator: 'contains', type: 'string', value: '' },
        { name: 'statusName', operator: 'eq', type: 'select', value: null },
        { name: 'userId', operator: 'eq', type: 'select', value: null },
        { name: 'createdDate', operator: 'eq', type: 'date', value: '' },
    ];

    const columns = [
        { name: 'code', header: 'Code', defaultWidth: 100 },
        {
            name: 'createdDate',
            header: 'Created Date',
            dateFormat: 'YYYY-MMM-DD',
            defaultFlex: 1,
            minWidth: 160,
            maxWidth: 160,
            enableColumnFilterContextMenu: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'YYYY-MMM-DD',
                    cancelButton: false,
                    highlightWeekends: false,
                    placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat) + ' ' + moment(value).format("HH:mm"),
        },
        {
            name: 'statusName',
            header: 'Status',
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 150,
            enableColumnFilterContextMenu: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Select Status',
                dataSource: enquiryStatusoptions2,
            }
        },
        { name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, maxWidth: 150, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },
        {
            name: 'userId',
            header: 'User',
            defaultFlex: 1,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Select User',
                dataSource: filterUserList
            },
            //render: ({ value })=> value && <div className="photo"><img height={35} width={35} alt="..." title={value?.firstName} src={(value?.picture) ? BaseService.API_URL + `app-images/${value?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            //render: ({ value })=> filterUserList.find(u=> u.id == value)?.label
            render: ({ value }) => userList.find(u => u.id == value)?.firstName

        },
        { name: 'referenceLink', header: 'Source', minWidth: 50, maxWidth: 250, flex: 1 },
        {
            name: '1s', header: '', sortable: false, minWidth: 90, maxWidth: 120, render: ({ data }) => {
                return <span>
                    <span className="photo"><i className="fa-solid fa-comment-dots" title={data?.message} /></span>
                    <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                    <a href="#" onClick={(e) => showForm(data)}><i title="Follow-up" className="far fa-bell" /> </a>
                    <a href="#" onClick={(e) => onTimeline(data.id)}><i title="Timeline" className="fas fa-clock" /> </a>
                    <a href="#" onClick={(e) => convertToProspect(e, data)}><i title="Convert To Prospect" className="fa-solid fa-users" /> </a>
                    {/* <a href="#" onClick={(e) => SendQuotation(e, data)}><i title="Send Proposal" className="far fa-edit" /> </a> */}
                    {/* <Nav style={{ flexDirection: "column" }}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                caret
                                color="default"
                                nav
                                onClick={(e) => e.preventDefault()}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className="nav-item">Convert To Prospect</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav> */}
                </span>
            }
        },
    ];

    function SendQuotation(e, data) {
        //alert( JSON.stringify(data));
        e.preventDefault();
        const proposal = {
            id: -1,
            proposalDate: '', //new date(),
            validTillDate: '',//new date(),
            discountType: "",
            destination: data?.destination,
            packageId: data?.packageId,
            subject: "Quotation for your trip",
            message: `        
            <p> Hi <br />
            Greetings from Tripper Hub.<br /><br />
            Our sales team has put up this Quote regarding your upcoming trip. Please go through it and let's know if you
            would like any changes in any of the provided services. <br /><br />Contact details are provided at the end. 
            <br /><br />
                    
            <br /><br />
    
            Thanks<br />
            Tripper Hub Team
            </p>                                                                 
            `,
            leadId: data?.id,
            userId: data?.userId,
            name: data?.name,
            phone: data?.phone,
            email: data?.email,
            total: 0.00,
            discount: 0.00,
            tax: 0.00,
            grandTotal: 0.0
        }

        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    }

    const gridStyle = { height: 'calc(100vh - 180px)' }
    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#3b2f02',
            '2': '#696969',
            '3': '#36486b',
            '4': '#273b02',
            '5': '#696969',
            '6': '#36486b',
            '7': '#588c7e'
        }

        const bgColorMap = {
            '1': '#f5f3eb',
            '2': '#faf0f2',
            '3': '#eae1fa',
            '4': '#e1f1fa',
            '5': '#b6b5bd',
            '6': '#bdbaaa',
            '7': '#c5d6c3'
        }

        return {
            color: data.isDuplicate ? "#0d0606" : colorMap[data.statusId],
            backgroundColor: data.isDuplicate ? "#ffabab" : bgColorMap[data.statusId]
        }
    }

    React.useEffect(() => {
        EnquiryService.GetEnquiryStatus().then(res => {
            setEnquiryStatusoptions1(res);
            const status = res.reduce((status, p) => {
                status.push({
                    id: p.status,
                    value: p.id,
                    label: p.status
                })
                return status
            }, []);

            setEnquiryStatusoptions2(status);
        });
        
        setLoading(true);
        EnquiryService.GetEnquiries().then(
            res => {
                setEnquiryCount(res?.length);
                setEnquiryList(res);
                setLoading(false);
            }
        );
        UserService.GetUsers().then(
            res => {
                setUserList(res);
                const filterUserList1 = res.reduce((filterUserList1, p) => {
                    filterUserList1.push({
                        id: p.id,
                        label: p.firstName
                    })
                    return filterUserList1
                }, []);
                setFilterUserList(filterUserList1);
            }
        )
    }, []);


    const callback = () => {
        return selectedEnquiry;
    }

    const onEdit = (enquiry) => {
        navigate(`${BaseService.NavigateToPath()}/add-enquiry`, { state: { enquiry: enquiry } });
    };

    const onTimeline = (id) => {
        setSliderShow2(!sliderShow2);
        if (id) {
            FollowUpService.GetFollowUpsByEnquiryId(id).then(response => {
                let followups = response?.map(followUp => ({ 'title': format(parseISO(followUp.reminderDateTime), "dd MMM yy HH:mm"), cardTitle: enquiryStatusoptions.filter(e => e.value == followUp.statusId)[0]?.label, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        }
    }

    const onDelete = (id) => {
        if (window.confirm("Are you sure want to delete?")) {
            EnquiryService.DeleteEnquiry(id).then(
                () => {
                    EnquiryService.GetEnquiries().then(
                        res => {
                            setEnquiryList(res);
                            //handleEnquiryFilter(res);
                            notify('br', 'The Enquiry has been deleted successfully!');
                        }
                    );
                }
            );
        }
    };

    const navigatePage = (e, page) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/${page}`);
    };


    const notify = (place, message) => {
        var color = 2;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    function convertToProspect(e, enquiry) {
        e.preventDefault();

        console.log(enquiry)

        const prospect = {
            "id": -1,
            "leadId": enquiry.id,
            "name": enquiry.name,
            "email": enquiry.email,
            "phone": enquiry.phone,
            "userId": enquiry.userId,
            "statusId": enquiry.statusId,
            "reminderId": enquiry.reminderId,
            "modeId": enquiry.modeId,
            "leadType": enquiry.leadType,
            "packageId": enquiry.packageId,
            "plannedTravelDate": enquiry.plannedTravelDate,
            "destination": enquiry.destination,
            "products": enquiry.products,
            "plannedNoOfDays": enquiry.plannedNoOfDays,
            "numberOfAdults": enquiry.numberOfAdults,
            "numberOfChild": enquiry.numberOfChild,
            "numberOfInfant": enquiry.numberOfInfant,
            "budgetPerAdults": enquiry.budgetPerAdults,
            "budgetPerChild": enquiry.budgetPerChild,
            "overAllBudget": enquiry.overAllBudget,
            "leadCode": enquiry.code,
            "lead": enquiry,
            "proposals": []
        }
        navigate(`${BaseService.NavigateToPath()}/add-prospect`, { state: { prospect: prospect } });
    }

    function convertToCustomer(e, enquiry) {
        e.preventDefault();
        EnquiryService.ConvertToCustomer(enquiry.id).then(res => {
            EnquiryService.GetEnquiries().then(
                res => {
                    setEnquiryList(res);
                    notify('br', 'The Enquiry has been converted to Customer!');
                }
            );
        })
    }


    const assignLeads = (enquiryIds, userIds) => {
        EnquiryService.AssignLeads(enquiryIds, userIds).then(
            () => {
                EnquiryService.GetEnquiries().then(
                    res => {
                        setEnquiryList(res);
                        //handleEnquiryFilter(res);
                        notify('br', 'The Enquiry has been deleted successfully!');
                    }
                );
            }
        );
    }

    //const [selected, setSelected] = useState({ 1: true, 2: true })

    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        setSelected(selectedMap)
        //selectedEnquiryList.puch(selectedMap);                
        //selEnq.push(data?.id)
        //console.log(selEnq)

    }, [])



    const onUserAssign = (data) => {
        let id = data[0]?.id;
        if (id) {
            let selEnq = []
            for (const [key, value] of Object.entries(selected)) {
                console.log(key, value);
                selEnq.push(key);
            }

            EnquiryService.AssignLeads(selEnq, id).then(() => {
                EnquiryService.GetEnquiries().then(
                    res => {
                        setEnquiryList(res);
                    }
                );
            }
            )
        }
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(enquiryList);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const renderRowContextMenu = (menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Row ' + rowProps.rowIndex
            }
        ]
    }

    const usersWithProfiles = userList.map((user) => {
        const enquiry = enquiryList.filter((enquiry) => (user.id === enquiry.userId)).length;
        return { ...user, enquiry: enquiry };
    });

    //console.log(usersWithProfiles)
    const dataSource = useCallback(EnquiryService.LoadData, [])

    const addFollowUps = useCallback((followUps) => {    
        console.log(followUps)
        FollowUpService.CreateFollowUp(followUps).then(res => {
            FollowUpService.GetFollowUpsByEnquiryId(followUps?.entityId).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        });

    }, []);

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Table>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="2">
                                            <FormGroup>
                                                <Typeahead
                                                    id="UserId"
                                                    labelKey={(option) => `${option.firstName} ${option.lastName} [${option.enquiry}]`}
                                                    onChange={onUserAssign}
                                                    options={usersWithProfiles}
                                                    placeholder="Select User to Assign Lead"
                                                    //selected={singleSelections}
                                                    clearButton
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="10" >

                                            <ButtonGroup
                                                className="btn-group-toggle float-right"
                                                data-toggle="buttons">
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data1" && enableFiltering,
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Filter
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data2",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { showForm1(); setbigChartData("data2") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Import
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data4",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { exportToExcel(); setbigChartData("data4") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Export
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    color="info"
                                                    id="2"
                                                    size="sm"
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data3",
                                                    })}
                                                    onClick={(e) => { navigatePage(e, "add-enquiry") }}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Add New
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-tap-02" />
                                                    </span>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>


                                </CardHeader>
                                <CardBody>
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                        showZebraRows={true}
                                        dataSource={enquiryList}
                                        //dataSource={dataSource}
                                        //pagination
                                        //livePagination
                                        enableFiltering={enableFiltering}
                                        showColumnMenuTool={showColumnMenuTool}
                                        onSelectionChange={onSelectionChange}
                                        defaultLimit={10}
                                        checkboxColumn
                                        defaultFilterValue={defaultFilterValue}
                                        selected={selected}
                                        enableColumnFilterContextMenu={enableColumnFilterContextMenu}
                                        onLoadingChange={setLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Table>
            </div >
            <MoadalSlider open={sliderShow} onClick={showForm} id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>
                <FollowUp dataParentToChild={parentToChild} callback={callback} refresh={addFollowUps} onCancel={showForm} />
            </MoadalSlider>

            <MoadalSlider open={sliderShow1} id='uploadSlider' onClick={showForm1} width='450' className={sliderShow1 ? ` showing` : ''}>
                <BulkUpload></BulkUpload>
            </MoadalSlider>

            <MoadalSlider open={sliderShow2} id='timeline' onClick={onTimeline} width='450' className={sliderShow2 ? ` showing` : ''}>
                <div className="content ">
                    <Card className="followupcard">
                        <Chrono allowDynamicUpdate="true" fontSizes={{
                            cardSubtitle: '12px',
                            cardText: '12px',
                            cardTitle: '13px',
                            title: '13px',
                        }} titleDateFormat='dd MMM yy'
                            contentDetailsHeight="80"
                            cardHeight="100"
                            style={{ height: '950px' }}
                            items={followupItems}
                            renderRowContextMenu={renderRowContextMenu}
                            mode="VERTICAL" >
                        </Chrono>
                    </Card>
                </div>
            </MoadalSlider>

            {/* <div style={{ zIndex: '100100', 'position': 'fixed', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }}>
                <Oval
                    visible={loading}
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                />
            </div> */}

        </>
    );
}

export default Enquiry;
