import { Link } from "react-router-dom";
export default function HotelMiniView(props) {
    //alert(props)
    if (props.isminiview) {
        return <div className="hotelmini" draggable="false" >
            <div draggable="false"> <img draggable="false" style={{objectFit:"cover"}} width="100" height="100%" src={props.Hotel.img}></img> </div>
            <div draggable="false">
                <label style={{fontWeight:"600"}}> {props.Hotel.Name}</label>
                <label>{props.Hotel.Contact}</label>
                <label>{props.Hotel.Destination}</label>
                <label>{props.Hotel.Destination}</label>
            </div>
        </div>
    }
    else {
        return <div draggable="false" style={{ display: "flex", height: '100px' }}>
            <div draggable="false"> <img draggable="false" width="100" height="80" src={props.Hotel.img}></img> </div>
            <div draggable="false">
                <label> {props.Hotel.Name}</label>
                <label>{props.Hotel.Contact}</label>
                <label>{props.Hotel.Destination}</label>
            </div>
        </div>
    }

}