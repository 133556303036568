import { useEffect } from "react";
import { json, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import HotelService from "services/hotel.service";
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import ImageUploading from "react-images-uploading";
import { Typeahead } from "react-bootstrap-typeahead";
import BaseService from "services/base.service";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import ModalSlider from "components/CustomComponents/ModalSlider";
import MasterService from "services/master.service";
export default function AddHotel() {
    const location = useLocation();
    const [roomList, setRoomList] = useState([]);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [rateForOptions, setRateForOptions] = useState([{ id: 1, name: "Base - Deluxe Room (2P)" }, { id: 2, name: "Extra Bed (Adult)" }, { id: 3, name: "Extra Bed (Child)" }, { id: 4, name: "Child without Bed (CNB)" }]);
    const [selectedRateFor, setSelectedRateFor] = useState([]);
    const [roomTypeOptions, setRoomTypeOptions] = useState([{ id: 1, name: "Deluxe" }, { id: 2, name: "Super Deluxe" }, { id: 3, name: "Suite" }]);
    const [selectedRoomType, setSelectedRoomType] = useState([]);
    const [hotelId, setHotelId] = useState(0);

    const [hotel, setHotel] = useState({
        id: 0,
        manager: "",
        name: "",
        rating: 3,
        checkInTime: "",
        checkOutTime: "",
        contact: "",
        alternateContact: "",
        address: "",
        city: "",
        country: '',
        pin: '',
        includeBreakfast: false,
        paymentType: '',
        resturant: false,
        description: "",
    });

    const [checkin, setCheckin] = useState(new Date());
    const [amenities, setAmenities] = useState([{ id: 1, name: 'Wifi' }, { id: 3, name: 'Pool' }, { id: 3, name: 'Wifi' }, { id: 4, name: 'Wifi' }]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [seasonMaster, setSeasonMaster] = useState([]);
    const [mealPlanList, setMealPlanList] = useState([{ id: 0, name: '' }, { id: 1, name: 'AP' }, { id: 2, name: 'MAP' }, { id: 3, name: 'CP' }, { id: 4, name: 'EP' }]);

    const [roomPrice, setRoomPrice] = useState({
        "hotelId": hotel.id || hotelId,
        "seasonId": 1,
        "name": "",
        "roomType": "",
        "basePrice": 0,
        "mealPlan": '',
        "mealPlanId": 0,
        "awebPrice": 0,
        "cwebPrice": 0,
        "cnbPrice": 0
    });

    let x = 1
    const [rooms, setRooms] = useState([x])
    const addRoom = () => { x++; setRooms([...rooms, x]) }
    const navigate = useNavigate();

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/hotels`);
    }


    const showForm1 = () => {
        setRoomPrice({
            "hotelId": hotel.id || hotelId,
            "seasonId": 1,
            "name": "",
            "roomType": "",
            "basePrice": 0,
            "mealPlan": '',
            "mealPlanId": 0,
            "awebPrice": 0,
            "cwebPrice": 0,
            "cnbPrice": 0
        });
        setSelectedRoomType([]);
        setSelectedRateFor([]);
        setSliderShow1(!sliderShow1)
    }

    useEffect(() => {
        if (location.state?.hotel?.id) {
            setHotel(location.state?.hotel);
            HotelService.GetRoomByHotelId(location.state?.hotel?.id).then(
                res => {
                    setRoomList(res);
                }
            )
        }

        HotelService.GetRoomType().then(
            res => {
                setRoomTypeOptions(res)
            }
        );
        
        MasterService.GetSeasonMaster().then(
            res => { setSeasonMaster(res); }
        )

    }, [location.state?.hotel?.id]);

    const onImgChange = (imageList) => {
        //     const userTemp = Hotel;
        //     // data for submit
        //     // Create an object of formData 
        //     const formData = new FormData();
        //     // Update the formData object 
        //     formData.append("userId", Hotel.id);
        //     formData.append("formFile", imageList[0].file);
        //     formData.append("fileName", imageList[0].file.name);

        //     HotelService.UploadImage(formData).then(
        //         res => {
        //             userTemp.picture = res;
        //             setUser({ user: Hotel });
        //         }
        //     );

    };


    const columns = [
        //{ name: 'id', header: 'Id', defaultWidth: 30, type: 'number', flex: 1 },
        //{ name: 'season', header: 'Season', maxtWidth: 50, flex: 1  },
        //{ name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        {
            name: 'roomType', header: 'Room', minWidth: 150, flex: 1, render: ({ data }) => {
                return <>{data.season + '-' + data.roomType}</>
            }
        },
        { name: 'basePrice', header: 'Rate', maxWidth: 80, type: 'number', flex: 1 }

        // {
        //     name: 'country1', header: 'Action', sorrting: false, defaultWidth: 100, render: ({ data }) => {
        //         return <div>
        //             {/* <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
        //             <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a> */}
        //         </div>
        //     }
        // },
    ];

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    //validation Schemas
    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
        // email: yup.string().required(),       
    });

    const formik = useFormik({
        initialValues: hotel,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.checkOutTime = `'${values.checkOutTime}'`
            values.checkInTime = `'${values.checkInTime}'`
            HotelService.CreateHotel(values).then(
                res => { resetForm(); }
            );
        }
    })



    const formikAddRoom = useFormik({
        initialValues: roomPrice,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            //{"hotelId":1, "season":1, "name":"Base", "roomType":"Delux", "roomRate":1000}
            values.hotelId = hotelId || hotel.id;
            //values.name = selectedRateFor[0].name;
            values.roomType = selectedRoomType[0]?.name;
            alert(JSON.stringify(values))
            HotelService.CreateHotelRoom(formikAddRoom.values).then(
                res => {
                    HotelService.GetRoomByHotelId(values.hotelId).then(
                        res => {
                            setRoomList(res);
                            showForm1();
                        }
                    )
                }
            );
        }
    })


    const saveAndAddRoom = () => {
        HotelService.CreateHotel(formik.values).then(
            res => {
                setHotelId(res);
                showForm1();
            }
        );
    }

    const onRoomSelected = (row) => {
        const rowData = row.dataSourceArray[row.rowIndex];
        //alert(JSON.stringify(rowData))
        showForm1();
        setRoomPrice(rowData);
        setSelectedRoomType(roomTypeOptions.filter(rt => rt.name == rowData.roomType));
        setSelectedRateFor(rateForOptions.filter(rt => rt.name == rowData.name));
        //alert(rowData.name)
        //formikAddRoom.values.season = seasonMaster.filter(s=>s.name==rowData.season)[0]?.id;
        //formikAddRoom.values.season=rowData.seasonId;
        formikAddRoom.values.hotelId = rowData.hotelId || hotel.id || hotelId
        formikAddRoom.values.americanPlanPrice = rowData.americanPlanPrice;
        formikAddRoom.values.continentalPlanPrice = rowData.continentalPlanPrice;
        formikAddRoom.values.europeanPlanPrice = rowData.europeanPlanPrice;
        formikAddRoom.values.modifiedAmericanPlanPrice = rowData.modifiedAmericanPlanPrice;
    }

    return <>
        <div className="content">
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Add/Edit Hotel</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="5">
                                        <FormGroup>
                                            <label>Hotel Name</label>
                                            <Input
                                                name="name"
                                                placeholder="Hotel Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <span className='text-red-400'>{formik.errors.name}</span>
                                            )}
                                        </FormGroup>

                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Hotel Type</label>
                                            <Input
                                                name="hotelType"
                                                placeholder="Hotel Type"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.hotelType}
                                            />
                                            {formik.touched.hotelType && formik.errors.hotelType && (
                                                <span className='text-red-400'>{formik.errors.hotelType}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Star</label>
                                            <Input
                                                name="rating"
                                                placeholder=""
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.rating}
                                            >
                                                <option value="0">Select Star Rating</option>
                                                <option value="1">3 Star</option>
                                                <option value="2">4 Star</option>
                                                <option value="3">5 Star</option>

                                            </Input>
                                            {formik.touched.rating && formik.errors.rating && (
                                                <span className='text-red-400'>{formik.errors.rating}</span>
                                            )}
                                        </FormGroup>
                                    </Col>


                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Manager Name</label>
                                            <Input
                                                name="manager"
                                                placeholder="Manager Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.manager}
                                            />
                                            {formik.touched.manager && formik.errors.manager && (
                                                <span className='text-red-400'>{formik.errors.manager}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Contact Number</label>
                                            <Input
                                                name="contact"
                                                placeholder="Contact Number"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact}
                                            />
                                            {formik.touched.contact && formik.errors.contact && (
                                                <span className='text-red-400'>{formik.errors.contact}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Alternate Contact</label>
                                            <Input
                                                name="alternateContact"
                                                placeholder="Contact Number"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.alternateContact}
                                            />
                                            {formik.touched.alternateContact && formik.errors.alternateContact && (
                                                <span className='text-red-400'>{formik.errors.alternateContact}</span>
                                            )}
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Checkin Time</label>
                                            <Input
                                                name="checkInTime"
                                                placeholder=""
                                                type="time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.checkInTime}
                                            />
                                            {formik.touched.checkInTime && formik.errors.checkInTime && (
                                                <span className='text-red-400'>{formik.errors.checkInTime}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Checkout Time</label>
                                            <Input
                                                name="checkOutTime"
                                                placeholder="Checkout Time"
                                                type="time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.checkOutTime}
                                            />
                                            {formik.touched.checkOutTime && formik.errors.checkOutTime && (
                                                <span className='text-red-400'>{formik.errors.checkOutTime}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Address</label>
                                            <Input
                                                name="address"
                                                placeholder="Hotel Address"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <span className='text-red-400'>{formik.errors.address}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="3">
                                        <FormGroup>
                                            <label>City</label>
                                            <Input
                                                placeholder="City"
                                                type="text"
                                                id="city"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.city}
                                            />
                                            {formik.touched.city && formik.errors.city && (
                                                <span className='text-red-400'>{formik.errors.city}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col className="px-md-1" md="3">
                                        <FormGroup>
                                            <label>State</label>
                                            <Input
                                                placeholder="State"
                                                type="text"
                                                id="state"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.state}
                                            />
                                            {formik.touched.state && formik.errors.state && (
                                                <span className='text-red-400'>{formik.errors.state}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col className="px-md-1" md="3">
                                        <FormGroup>
                                            <label>Country</label>
                                            <Input
                                                placeholder="Country"
                                                type="text"
                                                id="country"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.country}
                                            />
                                            {formik.touched.country && formik.errors.country && (
                                                <span className='text-red-400'>{formik.errors.country}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-md-1" md="3">
                                        <FormGroup>
                                            <label>Postal Code</label>
                                            <Input
                                                name="pin"
                                                placeholder="PIN Code"
                                                type="text"
                                                id="pinCode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pin}
                                            />
                                            {formik.touched.pin && formik.errors.pin && (
                                                <span className='text-red-400'>{formik.errors.pin}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup >
                                            <label >Amenities</label>
                                            <Typeahead
                                                id="Amenities"
                                                //allowNew
                                                multiple
                                                placeholder="Select Amenities"
                                                value={selectedAmenities}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setSelectedAmenities}
                                                labelKey={(option) => `${option.name}`}
                                                options={amenities} >
                                            </Typeahead>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" md="8">
                                        <FormGroup>
                                            <label>Description</label>
                                            <Input
                                                name="description"
                                                cols="80"
                                                placeholder="Here can be your description"
                                                rows="4"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                            /> </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="8">
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={() => { saveAndAddRoom() }} type="button" className="btn-fill" color="secondary">
                                            Save & Add Room
                                        </Button>
                                        <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }} className="card-user">
                            <CardHeader>
                                <h5 className="title">Rooms</h5>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={roomList}
                                    onRowClick={onRoomSelected}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <ModalSlider open={sliderShow1} width={450} onClick={showForm1} className={sliderShow1 ? ` showing` : ''}>
                <div className="content">
                    <Form onSubmit={formikAddRoom.handleSubmit}>
                        <Card style={{ padding: "1em 2em" }} className="followupcard">
                            <Row>
                                <Col style={{ marginBottom: "1em" }}><h5 className="title">Add New Room</h5></Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label>Season</label>
                                        <Input
                                            name="seasonId"
                                            placeholder="Select Season"
                                            type="select"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.seasonId}>
                                            {seasonMaster.map((sm) => {
                                                return <option value={sm.id}>{sm.name + ': ' + sm.startDate + ' ' + sm.endDate}</option>
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup>
                                        <label>Room Type</label>
                                        <Typeahead
                                            id="roomType"
                                            labelKey={(option) => `${option.name}`}
                                            onChange={setSelectedRoomType}
                                            options={roomTypeOptions}
                                            placeholder="Select User"
                                            selected={selectedRoomType}
                                            clearButton
                                        />

                                        {formikAddRoom.touched.executiveId && formikAddRoom.errors.executiveId && (
                                            <span className='text-red-400'>{formikAddRoom.errors.executiveId}</span>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup>
                                        <label>Meal Plan</label>
                                        <Input
                                            name="mealPlanId"
                                            type="select"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values.mealPlanId}
                                        >
                                            {mealPlanList.map(mealPlan => {
                                                return <option value={mealPlan.id}>{mealPlan?.name}</option>;
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Base Price</label>
                                        <Input
                                            name="basePrice"
                                            placeholder="AP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.basePrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>AWEB Price</label>
                                        <Input
                                            name="awebPrice"
                                            placeholder="MAP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.awebPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label>CWEB Price</label>
                                        <Input
                                            name="cwebPrice"
                                            placeholder="CWEB Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.cwebPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>CNB Price</label>
                                        <Input
                                            name="cnbPrice"
                                            placeholder="CNB Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.cnbPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label>EP Price</label>
                                        <Input
                                            name="europeanPlanPrice"
                                            placeholder="EP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.europeanPlanPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>CP Price</label>
                                        <Input
                                            name="continentalPlanPrice"
                                            placeholder="CP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.continentalPlanPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label>AP Price</label>
                                        <Input
                                            name="americanPlanPrice"
                                            placeholder="AP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.americanPlanPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>MAP Price</label>
                                        <Input
                                            name="modifiedAmericanPlanPrice"
                                            placeholder="MAP Price"
                                            type="number"
                                            onChange={formikAddRoom.handleChange}
                                            onBlur={formikAddRoom.handleBlur}
                                            value={formikAddRoom.values?.modifiedAmericanPlanPrice}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col style={{ marginTop: "1em" }}>
                                    <Button disabled={!formikAddRoom.isValid} type="submit" className="btn-fill" color="primary">
                                        Add Room
                                    </Button>
                                    <Button onClick={showForm1} type="button" className="btn-fill" color="secondary">
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                </div>
            </ModalSlider>
        </div>
    </>
} 