import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

async function GetPerson() {
    const response = await axios.get(API_URL + `person/GetPerson`);
    console.info(response.data);
    return response.data;
}

async function CreatePersonAddress(person) {
    alert(JSON.stringify(person))
    const response = await axios.post(API_URL + `person/AddPerson`, person);
    return response.data;
}
async function CreatePersonContact(person) {
    alert(JSON.stringify(person))
    const response = await axios.post(API_URL + `person/AddPerson`, person);
    return response.data;
}

async function DeletePerson(person) {
    const response = await axios.delete(API_URL + `person/DeletePerson?Id=${person}`);
    return response.data;
}
async function UploadImage(userFormData) {
    // const response = await client.post(`/UploadImage`, userFormData);
    // return response.data;
}

const PersonService = {
    GetPerson,
    CreatePersonAddress,
    CreatePersonContact,
    DeletePerson,
    UploadImage
}

export default PersonService;