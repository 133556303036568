
import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";
import { button } from "react-validation/build/button";
import { Sidebar as Sidebar1, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Typography } from "components/Typography"
import { Switch } from "components/Switch";
import { InkBottle, Book } from "components/Common/Icons/InkBottle";
var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  const [collapsed, setCollapsed] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const [broken, setBroken] = React.useState(false)
  const [hasImage, setHasImage] = React.useState(false)
  const [theme, setTheme] = React.useState("dark")

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#607489"
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#0098e5",
        hover: {
          backgroundColor: "#c5e4ff",
          color: "#44596e"
        },
        disabled: {
          color: "#9fb6cf"
        }
      }
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7"
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9"
        },
        disabled: {
          color: "#3e5e7e"
        }
      }
    }
  }

  // hex to rgba converter
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      }
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9"
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
            themes[theme].menu.menuContent,
            hasImage && !collapsed ? 0.4 : 1
          )
          : "transparent"
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color
      }
    },
    label: ({ open }) => ({
      color: '#b6b7b9',
      fontWeight: open ? 600 : undefined
    })
  }


  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });

  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };
  const { routes, rtlActive, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }
  return (
    <Sidebar1
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
      image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
      breakPoint="md"
      backgroundColor={hexToRgba(
        themes[theme].sidebar.backgroundColor,
        hasImage ? 0.9 : 1
      )}
      rootStyles={{
        color: themes[theme].sidebar.color,
        height: '100vh'
      }}
    >

      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh!important" }}
      >
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <Menu menuItemStyles={menuItemStyles}>
            {routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (prop.subMenu) {
                return (
                  <SubMenu
                    label={<p style={{ color: '#b6b7b9', fontWeight: 600, padding: '0px', margin: "0" }}>{prop.name}</p>}
                    icon={<i style={{ fontSize: '18px' }} className={prop.icon}></i>}
                  >

                    {prop.items.map((prop1, key) => {
                      return (
                        <MenuItem icon={
                          <NavLink
                            to={prop1.layout + prop1.path}
                            className="nav-link"
                            style={{ padding: '0' }}
                          >
                            <i style={{ fontSize: '18px' }} className={prop1.icon}></i>
                          </NavLink>}>
                          <li
                            className={
                              activeRoute(prop1.path) + (prop1.pro ? " active-pro" : "")
                            }
                            key={key}
                          >
                            <NavLink
                              to={prop1.layout + prop1.path}
                              className="nav-link"
                              style={{ padding: '0' }}
                            >
                              <p style={{ color: '#b6b7b9', fontWeight: 600, padding: '0px', margin: "0" }}>{prop1.name}</p>
                            </NavLink>
                          </li>
                        </MenuItem>)
                    })
                    }
                  </SubMenu>
                );
              }
              else {

                return (
                  <MenuItem icon={<NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    style={{ padding: '0' }}
                  >
                    <i style={{ fontSize: '18px' }} className={prop.icon}></i>
                  </NavLink>}>
                    <li
                      className={
                        activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        onClick={props.toggleSidebar}
                        style={{ padding: '0' }}
                      >
                        <p style={{ color: '#b6b7b9', fontWeight: 600, margin: "0" }}>{prop.name}</p>
                      </NavLink>
                    </li>
                  </MenuItem>
                );
              }
            })}
          </Menu>
          <Link style={{ display: "block", paddingLeft: '30px', paddingBottom: "30px", margin: "0", color: "#59d0ff", position: 'absolute', bottom: "0", color: "#59d0ff" }} onClick={() => { setCollapsed((s) => !s) }}><i style={{ color: "#59d0ff" }} className={collapsed ? "fas fa-arrow-right" : "fas fa-arrow-left"} /> &nbsp; &nbsp;   </Link>
        </div>
      </div>

    </Sidebar1>

  );
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
