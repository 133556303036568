import { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    Nav,
    NavLink,
    NavItem
} from "reactstrap";
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import PersonService from "services/person.service";
export default function Contact() {
    const contact = { email: "", whatsapp: "", phone: "", }
    const schema = {}
    const [mode, setMode] = useState("View");
    const formik = useFormik({
        initialValues: contact,
        // validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            PersonService.CreatePersonContact(values);
            alert("saving Data")
        }
    });

    function onModeChange(mode) {
        setMode(mode);
    }
    {
        if (mode === "Edit" || mode === "New") {
            return <>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="exampleInputEmail1">
                                    Email address</label>
                                <Input
                                    name="email"
                                    placeholder="mike@email.com"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <span className='text-red-400'>{formik.errors.email}</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md="4" >
                            <FormGroup>
                                <label>Contact Number</label>
                                <Input
                                    name="phone"
                                    placeholder="Contact Number"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <span className='text-red-400'>{formik.errors.phone}</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md="4" >
                            <FormGroup>
                                <label>Whatsapp Number</label>
                                <Input
                                    name="whatsapp"
                                    placeholder="Contact Number"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.whatsapp}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <span className='text-red-400'>{formik.errors.phone}</span>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" style={{ marginTop: "1em" }}>
                            <Button type="submit" className="btn-fill" color="primary">
                                Save
                            </Button>
                            <Button onClick={() => { onModeChange("View") }} className="btn-fill" color="secondary">
                                Go Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        }
        else {
            return <>
                <Row>
                    <Col>
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    <th>Contact Type</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Whatsapp </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Primary</td>
                                    <td>twrvidhu18@gmail.com</td>
                                    <td>9568235684</td>
                                    <td>6398370468</td>
                                    <td>
                                        <Nav>
                                            <NavLink onClick={(e) => { onModeChange("Edit") }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                            <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                        </Nav>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Alternate</td>
                                    <td>twrvidhu18@gmail.com</td>
                                    <td>9568235684</td>
                                    <td>6398370468</td>
                                    <td>
                                        <Nav>
                                            <NavLink onClick={(e) => { onModeChange("Edit") }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                            <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                        </Nav>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Alternate</td>
                                    <td>twrvidhu18@gmail.com</td>
                                    <td>9568235684</td>
                                    <td>6398370468</td>
                                    <td>
                                        <Nav>
                                            <NavLink onClick={(e) => { onModeChange("Edit") }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                            <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                        </Nav>
                                    </td>
                                </tr>




                            </tbody>
                        </Table>
                        <Row>
                            <Col md="12">
                                <div style={{ marginTop: "2em" }}>
                                    <Button onClick={() => { onModeChange("New") }} className="btn-fill" color="secondary">
                                        Add New
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        }
    }
}