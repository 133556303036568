import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import UserList from "views/UserList";
import User from "views/User";
import Enquiry from "views/Enquiry";
import Prospect from "views/Prospect";
import Customer from "views/Customer";
import Quotation from "views/Quotation";
import Invoice from "views/Invoice";
import Package from "views/Package.new";
import { ControlledBoard, UncontrolledBoard } from "views/Report";
import Setting from "views/Setting";
import AddEnquiry from "views/AddEnquiry";
import FollowUp from "views/FollowUp";
import AddProspect from "views/AddProspect";
import Login from "layouts/Authentication/components/Login";
import AddProposal from "views/AddProposal";
import AddPackage from "views/AddPackage";
import AddMember from "views/AddMember";
import Booking from "views/Bookings";
import ComingSoon from "components/CustomComponents/ComingSoon";
import ViewPackage from "views/ViewPackage";
import Hotel from "views/Hotel";
import Attractions from "views/Attractions";
import AddHotel from "views/AddHotel";
import AddInvoice from "views/AddInvoice";
import AllBookings from "views/AddBooking";
import AddAttraction from "views/AddAttraction";
import Itinerary from "views/Itinerary";
import Proposals from "views/Proposals";
import AddCustomer from "views/AddCustomer";
import PackageTemplate from "views/PackageTemplate";
import ClientProfile from "views/ClientProfile";
import Supplier from "views/Supplier";
import AddSupplier from "views/AddSupplier";
import Template from "views/Template";
import AddQuotation from "views/AddQuotation";
import Transport from "views/Transport";
import AddTransport from "views/AddTransport";
import Tasks from "views/Tasks";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",    
  },
  {    
    name: "Leads",
    icon: "fa fa-people-group",
    path: "/enquiries",
    component: <Enquiry />,
    subMenu:true,
    layout: "/admin",
    items: [
      {
        path: "/enquiries",
        name: "Leads",
        icon: "tim-icons icon-badge",
        component: <Enquiry />,
        layout: "/admin",
      },
      {
        path: "/prospect",
        name: "Prospects",
        icon: "tim-icons icon-spaceship",
        component: <Prospect />,
        layout: "/admin",
      },
      {
        path: "/proposals",
        name: "Proposals",
        icon: "far fa-edit",
        component: <Proposals />,
        layout: "/admin",
      },
      {
        path: "/bookings",
        name: "Bookings",
        icon: "tim-icons icon-notes",
        component: <Booking />,
        layout: "/admin",
    
      },   

    ]
  },  
  {
    name: "Client",
    icon: "fa fa-users",
    subMenu:true,
    layout: "/admin",
    items: [     
      {
        path: "/customers",
        name: "Clients",
        icon: "fa fa-person-walking-luggage",
        component: <Customer />,
        layout: "/admin",
      }       
    ]
  },
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/client",
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/user",
  },        
  {
    path: "/add-proposal",
    name: "Proposal",
    icon: "tim-icons icon-paper",
    component: <AddProposal />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/quotation",
    name: "Quotation",
    icon: "tim-icons icon-tag",
    component: <Notifications />,
    layout: "/admin",
    redirect: true
  },      
  {
    path: "/Itinerary",
    name: "Itinery",
    icon: "tim-icons icon-app",
    component: <Itinerary />,
    layout: "/admin",
    redirect: true
  },    
  {
    name: "Masters",
    icon: "fa fa-gear",
    subMenu:true,
    layout: "/admin",
    items: [     
      {
        path: "/hotels",
        name: "Hotels",
        icon: "far fa-building",
        component: <Hotel />,
        layout: "/admin"
      },
      {
        path: "/suppliers",
        name: "Suppliers",
        icon: "tim-icons icon-align-center",
        component: <Supplier />,
        layout: "/admin",        
      },
      {
        path: "/attractions",
        name: "Attractions",
        icon: "fa fa-map-marker",
        component: <Attractions />,
        layout: "/admin"
      },
      {
        path: "/templates",
        name: "Templates",
        icon: "tim-icons icon-align-center",
        component: <Template />,
        layout: "/admin",
            
      },
      {
        path: "/packages",
        name: "Packages",
        icon: "tim-icons icon-app",
        component: <Package />,
        layout: "/admin",
      },
    ]
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    component: <UserList />,
    layout: "/admin",
  },
  {
    path: "/add-invoice",
    name: "New Booking",
    icon: "tim-icons icon-chart-bar-32",    
    component: <AddInvoice />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/booking-details",
    name: "Booking",
    icon: "tim-icons icon-chart-bar-32",
    // component: <ControlledBoard />,
    component: <AllBookings />,
    layout: "/admin",
    redirect: true
  },

  {
    path: "/setting",
    name: "Setting",
    icon: "tim-icons icon-align-center",
    component: <Setting />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-enquiry",
    name: "Add Enquiry",
    icon: "tim-icons icon-align-center",
    component: <AddEnquiry />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/follow-up",
    name: "Follow Up",
    icon: "tim-icons icon-align-center",
    component: <FollowUp />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-prospect",
    name: "Add Prospect",
    icon: "tim-icons icon-align-center",
    component: <AddProspect />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-package",
    name: "Add Package",
    icon: "tim-icons icon-align-center",
    component: <AddPackage />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-member",
    name: "Add Member",
    icon: "tim-icons icon-align-center",
    component: <AddMember />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: "tim-icons icon-align-center",
    component: <AddCustomer />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-hotel",
    name: "Add Hotel",
    icon: "tim-icons icon-align-center",
    component: <AddHotel />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-attraction",
    name: "Add Attraction",
    icon: "tim-icons icon-align-center",
    component: <AddAttraction />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/view-package",
    name: "View Package",
    component: <ViewPackage />,
    layout: "/admin",
    redirect: true
  },      
  {
    path: "/icons",
    name: "Hotel",
    icon: "tim-icons icon-align-center",
    component: <Icons />,
    layout: "/admin",
    redirect: "true"
  },
  
  
  {
    path: "/create-templates",
    name: "Templates",
    icon: "tim-icons icon-align-center",
    component: <PackageTemplate />,
    layout: "/admin",
    redirect: "true"
  },
  
  {
    path: "/add-supplier",
    name: "Suppliers",
    icon: "tim-icons icon-align-center",
    component: <AddSupplier />,
    layout: "/admin",
    redirect: "true"
  },
  {
    path: "/client-profile",
    name: "Client Profile",
    icon: "tim-icons icon-align-center",
    component: <ClientProfile />,
    layout: "/admin",    
    redirect: "false"
  },
  {
    path: "/Add-Member",
    name: "Add Member",
    icon: "tim-icons icon-align-center",
    component: <AddMember />,
    layout: "/admin",
    redirect: "true"
  },
  {    
    path: "/add-quotation",
    name: "Add Quotation",
    icon: "tim-icons icon-align-center",
    component: <AddQuotation />,
    layout: "/admin",
    redirect: "true"    
  },
  {    
    path: "/transport",
    name: "Transport",
    icon: "fa fa-automobile",
    component: <Transport />,
    layout: "/admin",      
  }
  ,
  {    
    path: "/tasks",
    name: "Task",
    icon: "fa fa-tasks",
    component: <Tasks />,
    layout: "/admin",      
  }
  ,
  {    
    path: "/add-transport",
    name: "Transport",
    icon: "fa fa-automobile",
    component: <AddTransport />,
    layout: "/admin",      
    redirect: "true"    
  }  
];
export default routes;