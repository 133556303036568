import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import BaseService from "../../services/base.service";
import RemiderService from "services/reminder.service";
import NotificationAlert from "react-notification-alert";

function AdminNavbar(props) {
  const navigate = useNavigate();
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [showReminder, setShowReminder] = React.useState(false);
  const [reminders, setReminders] = React.useState([]);
  const [color, setcolor] = React.useState("navbar-transparent");
  const notificationAlertRef = React.useRef(null);

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  //Set current User 
  const currentUser = AuthService.getCurrentUser();

  const notify = (place, message) => {
    var color = 2;
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef?.current?.notificationAlert(options);
  };


  // setTimeout(
  //   () => {
  //     RemiderService.GetLatestRemindersByUserId(currentUser?.id).then(
  //       res => {
  //         if (res.length > 0) {
  //           setShowReminder(true);
  //           notify('br', 'New notification received!');
  //           setReminders(res);
  //         }
  //       }
  //     );
  //   },
  //   60000
  // );

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  const onLogout = () => {
    localStorage.removeItem("token");
    navigate('/login');
  };

  const onProfile = () => {
    //navigate(`${BaseService.NavigateToPath()}/enquiries`);
    navigate(`${BaseService.NavigateToPath()}/user-profile`, { state: { 'user': currentUser } });
  };

  const onSetting = () => {
    navigate(`${BaseService.NavigateToPath()}/setting`);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const getImage = (user) => {
    //https://crmapi.trektrail.in/api/app-images/ProfilePics/121223.jpg
    if (currentUser?.picture)
      return BaseService.API_URL + `app-images/${currentUser?.picture}`
    else
      return "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
  }

  function getRole(roleId) {

    switch (roleId) {
      case 1:
        return "Admin"
        break;

      case 2:
        return "User"
        break;
      case 3:
        return "Client"
        break;

      default:
        break;
    }
  }

  const reminderDelete = (reminder, i) => {
    setShowReminder(false);
    setReminders(reminders.splice(i, 1));
    // RemiderService.CreateReminder(reminder).then(
    //   res => {
    //     RemiderService.GetRemindersByUserId(currentUser?.id).then(
    //       res => setReminders(res)
    //     );
    //   }
    // );
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            {/* <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>              
              <h3 className="CardTitle" style={{ textAlign: 'center', marginTop:'20px' }}>{props.brandText}</h3>
            </NavbarBrand> */}
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar">
                <Button color="link" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  {showReminder && <div className="notification d-none d-lg-block d-xl-block" />}
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">

                  {reminders?.map((reminder, i) => (
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        <div>
                          {reminder?.comment}
                          <a
                            aria-label="Close"
                            onClick={() => reminderDelete(reminder, i)}
                          >
                            <i style={{ fontSize: '10px', color: 'red', paddingLeft: '10px' }} className="tim-icons icon-simple-remove" />
                          </a>
                        </div>
                      </DropdownItem>
                    </NavLink>)
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." title={currentUser?.firstName} src={getImage()} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out - {currentUser?.firstName} </p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem onClick={onProfile} className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem onClick={onSetting} className="nav-item">Settings</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem onClick={onLogout} className="nav-item">Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default AdminNavbar;
