import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Nav,
    NavLink
} from "reactstrap";
import HotelService from "services/hotel.service";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormik } from 'formik';
import * as yup from 'yup';
import QuotationService from "services/quotation.service"
import { forEach } from "underscore";
import MasterService from "services/master.service";

function HotelBookingList(props) {
    const location = useLocation();
    const [hotelList, setHotelList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [hotelPriceList, setHotelPriceList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState([]);
    const [mealPlanList, setMealPlanList] = useState([{ id: 0, name: '' }, { id: 1, name: 'AP' }, { id: 2, name: 'MAP' }, { id: 3, name: 'CP' }, { id: 4, name: 'EP' }]);
    const [roomTypeOptions, setRoomTypeOptions] = useState([{ id: 0, name: "" }, { id: 1, name: "Deluxe" }, { id: 2, name: "Super Deluxe" }, { id: 3, name: "Suite" }]);
    const [hotelTypeOptions, setHotelTyprOptions] = useState([{ id: 1, name: "Deluxe" }, { id: 2, name: "Semi Super Deluxe" }, { id: 3, name: "Super Deluxe" }]);
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [city, setCity] = useState([])
    const [selectedCity, setSelectedCity] = useState([])
    const [selectedHotelType, setSelectedHotelType] = useState([])
    const [days, setDays] = useState([])
    const [selectedDays, setSelectedDays] = useState([])
    const [hotelBooking, setHotelBooking] = useState({
        id: 0,
        hotelTypeId: 0,
        hotelId: 0,
        mealPlanId: 2,
        hotel: '',
        hotelName: '',
        mealPlan: 'MAP',
        roomTypeId: 1,
        roomType: '',
        pax_Room: 0,
        rooms: 0,
        aweb: 0,
        cweb: 0,
        cnb: 0,
        hotelTypeName: '',
        days: 0,
        hotelDays: [],
        roomsRate: 0,
        awebRate: 0,
        cwebRate: 0,
        cnbRate: 0
    });


    useEffect(() => {
        HotelService.GetHotels().then(
            res => {
                setHotelList(res)
            }
        );

        HotelService.GetRoomType().then(
            res => {
                setRoomTypeOptions(res)
            }
        );

        MasterService.GetDestination().then(res => {
            setDestination(res)
        });

        MasterService.GetCities().then(res => {
            setCity(res)
        });
    }, [])


    useEffect(() => {
        //alert(props.QuotationHotelId)
        if (props.QuotationHotelId) {
            QuotationService.GetQuotationHotelById(props.QuotationHotelId).then(
                res => {
                    //setHotelList(res)
                    //alert(JSON.stringify(res))
                }
            );
        }
    }, [])


    useEffect(() => {
        if (props.prospect) {            
            hotelBooking.pax_Room = 2;
            hotelBooking.rooms = props.prospect?.numberOfAdults && (props.prospect?.numberOfAdults - (props.prospect?.numberOfAdults % hotelBooking?.pax_Room)) / hotelBooking?.pax_Room;
            hotelBooking.aweb = props.prospect?.numberOfAdults && (props.prospect?.numberOfAdults % hotelBooking.pax_Room) || 0
            hotelBooking.cweb = props.prospect?.numberOfChild || 0;
            hotelBooking.cnb = props.prospect?.numberOfInfant || 0;
            setDays(props?.prospect?.days || [])
        }
        else if (props.booking) {
            hotelBooking.pax_Room = 2;
            hotelBooking.rooms = props.booking?.adult && (props.booking?.adult - (props.booking?.adult % hotelBooking?.pax_Room)) / hotelBooking?.pax_Room;
            hotelBooking.aweb = props.booking?.adult && (props.prospect?.adult % hotelBooking.pax_Room) || 0
            hotelBooking.cweb = props.booking?.kids || 0;
            hotelBooking.cnb = props.booking?.infant || 0;
            setDays(props?.booking?.days || [])
        }

    }, [props.prospect, props.booking])



    const onHotelTypeSelected = (hotelType) => {
        let type = hotelType[0]?.name;
        setSelectedHotelType(hotelType);
        console.log(selectedDestination[0])
        HotelService.GetHotelsByDestination(selectedDestination[0]?.name, selectedCity[0]?.name).then(
            res => {
                setHotelList(res.filter(h => h.hotelType == type))
                setSelectedHotel(res.filter(h => h.hotelType == type && h.preferred))
            }
        );
    }

    const onSelectedHotel = (hotel) => {
        if (hotel) {
            setSelectedHotel(hotel[0]);
            HotelService.GetRoomByHotelId(hotel[0]?.id).then(
                res => {
                    setRoomList(res);
                }
            )
        }
    }

    const schema = yup.object().shape({
        //id: yup.string().min(3).max(40).required(),
        //rooms: yup.number().required("Room is required"),
        //days: yup.number().required("Please enter Day"),
        //day: yup.number().min(1).required("Enter Day")
        //email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    const formik = useFormik({
        initialValues: hotelBooking,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values, { resetForm }) {
            let hotels = [];
            selectedHotelType.map((hotelType) => {
                values.hotelId = hotelType?.id;
                values.hotelTypeId = hotelType?.id;
                values.hotelTypeName = hotelType?.name;
                values.hotelName = selectedHotel?.map((item) => { return item.name }).join(', ')
                values.mealPlan = mealPlanList.find(m => m.id == values.mealPlanId)?.name;
                values.roomType = roomTypeOptions.find(m => m.id == values.roomTypeId)?.name;
                //values.roomPrice = hotelPriceList.find(p => p.hotelType == values.hotelTypeName && p.mealPlanId == values.mealPlanId && p.roomType == values.roomType);
                values.roomPrice = values.roomPrice || 1;
                values.hotelDays = selectedDays;
                values.destination = selectedDestination[0].name;
                //hotels = [...hotels, values];
            })

            props.addHotelBooking(values);

            props.onClose(false);
            setSelectedDestination([]);
            setSelectedHotelType([]);
            setSelectedHotel([]);
            setSelectedDays([]);
            resetForm({ values: '' });
        }
    });

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        // PackageService.GetPackages()
        //     .then(res => {
        //         setPackages(res.filter(p => p.destination == destination[0]?.name))
        //     });
    }

    const onSelectedDays = (day) => {
        setSelectedDestination(destination);
        // PackageService.GetPackages()
        //     .then(res => {
        //         setPackages(res.filter(p => p.destination == destination[0]?.name))
        //     });
    }

    //GetRoomPrice

    const onHotelChange = (value) => {
        setSelectedHotel(value)
        let data = value.map((obj) => obj.id).join();
        // formik.values.awebRate = 0;
        // formik.values.cnbRate = 0;
        // formik.values.roomsRate = 0;
        // formik.values.cwebRate = 0;
        HotelService.GetRoomPrice(formik.values.roomTypeId, data, formik.values.mealPlanId).then(
            res => {
                setHotelPriceList(res)
                formik.values.awebRate = res.awebQuotePrice;
                formik.values.cnbRate = res.cnbQuotePrice;
                formik.values.roomsRate = res.basePrice;
                formik.values.cwebRate = res.cwebQuotePrice;
            }
        );
    }

    return <div >

        <Form onSubmit={formik.handleSubmit} >
            <Row>
                <Col>
                    <FormGroup>
                        <label>Select Days</label>
                        <Typeahead
                            id="destination"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedDays}
                            options={days}
                            placeholder="Select Days"
                            selected={selectedDays}
                            multiple
                            clearButton
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Destination</label>
                        <Typeahead
                            id="destination"
                            labelKey={(option) => `${option.name}`}
                            onChange={onSelectedDestination}
                            options={destination}
                            placeholder="Select Destination"
                            selected={selectedDestination}
                            //multiple
                            clearButton
                        ></Typeahead>                        
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>City</label>
                        <Typeahead
                            id="destination"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedCity}
                            options={city}
                            placeholder="Select City"
                            selected={selectedCity}
                            //multiple
                            clearButton
                        ></Typeahead>                        
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Hotal Type</label>

                        <Typeahead
                            id="hotalType"
                            labelKey={(option) => ` ${option.name}`}
                            onChange={onHotelTypeSelected}
                            options={hotelTypeOptions}
                            placeholder="Select Hotel"
                            selected={selectedHotelType}
                            clearButton
                        //multiple
                        ></Typeahead>

                        {/* <Input
                            name="hotalType"
                            type="select"
                            //onChange={onHotelTypeSelected}
                            onChange={e => onHotelTypeSelected(e.target)}
                            onBlur={formik.handleBlur}
                            value={selectedTotelType}
                        >
                            {hotelTypeOptions.map(hotelType => {
                                return <option value={hotelType.id} tag={hotelType?.name} >{hotelType?.name}</option>;
                            })}

                        </Input> */}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Hotel</label>
                        <Typeahead
                            id="hotel"
                            labelKey={(option) => ` ${option.name}`}
                            onChange={onHotelChange}
                            options={hotelList}
                            placeholder="Select Hotel"
                            selected={selectedHotel}
                            clearButton
                            multiple
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col >
                    <FormGroup>
                        <label>Room Type</label>
                        <Input
                            name="roomTypeId"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.roomTypeId}
                        >
                            <option value=""></option>
                            {roomTypeOptions.map(roomType => {
                                return <option value={roomType.id}>{roomType?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Meal Plan</label>
                        <Input
                            name="mealPlanId"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mealPlanId}
                        >
                            {mealPlanList.map(mealPlan => {
                                return <option value={mealPlan.id}>{mealPlan?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Pax/Room</label>
                        <Input
                            name="pax_Room"
                            placeholder="Pax per Room"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pax_Room}
                            maxLength={50}
                        />
                        {formik.touched.pax_Room && formik.errors.pax_Room && (
                            <span className='text-red-400'>{formik.errors.pax_Room}</span>
                        )}

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Rooms</label>
                        <Input
                            name="rooms"
                            placeholder="Rooms"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rooms}
                            maxLength={50}
                        />
                        {formik.touched.rooms && formik.errors.rooms && (
                            <span className='text-red-400'>{formik.errors.rooms}</span>
                        )}

                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>AWEB</label>
                        <Input
                            name="aweb"
                            placeholder="AWEB"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.aweb}
                            maxLength={50}
                        />
                        {formik.touched.aweb && formik.errors.aweb && (
                            <span className='text-red-400'>{formik.errors.aweb}</span>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>CWEB</label>
                        <Input
                            name="cweb"
                            placeholder="CWEB"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cweb}
                            maxLength={50}
                        />
                        {formik.touched.cweb && formik.errors.cweb && (
                            <span className='text-red-400'>{formik.errors.cweb}</span>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>CNB</label>
                        <Input
                            name="cnb"
                            placeholder="CNB"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cnb}
                            maxLength={50}
                        />
                        {formik.touched.cnb && formik.errors.cnb && (
                            <span className='text-red-400'>{formik.errors.cnb}</span>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Rooms Rate</label>
                        <Input
                            name="roomsRate"
                            placeholder="Room Rate"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.roomsRate}
                            maxLength={50}
                        />
                        {formik.touched.roomsRate && formik.errors.roomsRate && (
                            <span className='text-red-400'>{formik.errors.roomsRate}</span>
                        )}

                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>AWEB Rate</label>
                        <Input
                            name="awebRate"
                            placeholder="AWEB Rate"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.awebRate}
                            maxLength={50}
                        />
                        {formik.touched.awebRate && formik.errors.awebRate && (
                            <span className='text-red-400'>{formik.errors.awebRate}</span>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>CWEB Rate</label>
                        <Input
                            name="cwebRate"
                            placeholder="CWEB Rate"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cwebRate}
                            maxLength={50}
                        />
                        {formik.touched.cwebRate && formik.errors.cwebRate && (
                            <span className='text-red-400'>{formik.errors.cwebRate}</span>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>CNB Rate</label>
                        <Input
                            name="cnbRate"
                            placeholder="CNB Rate"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cnbRate}
                            maxLength={50}
                        />
                        {formik.touched.cnbRate && formik.errors.cnbRate && (
                            <span className='text-red-400'>{formik.errors.cnbRate}</span>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>{formik.values.rooms * formik.values.roomsRate}</Col>
                <Col>{formik.values.aweb * formik.values.awebRate}</Col>
                <Col>{formik.values.cweb * formik.values.cwebRate}</Col>
                <Col>{formik.values.cnb * formik.values.cnbRate}</Col>
            </Row>

            <div style={{ marginTop: "2em" }}>
                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                    Save
                </Button>
                <Button onClick={() => { setHotelBooking({}); props.onClose(false) }} className="btn-fill" color="secondary">
                    Cancel
                </Button>
            </div>
        </Form >
    </div >
}

export default HotelBookingList;