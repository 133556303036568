import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle

} from "reactstrap";
import ModalSlider from "../ModalSlider"
import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";

export default function SupplierDetails(props) {
    const supplierPayments = props.payments
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }
    return <>
        <div className="custom-card" >
            <h3 style={{ borderBottom: "1px solid #aaa", padding: ".25em 0" }} className="formheadings">Supplier/Vendor Payment</h3>
            <Table  >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Supplier Name</th>
                        <th>Date & added by</th>
                        <th>Payment description</th>
                        <th>Cost</th>
                        <th>Collection</th>
                        <th>Remaining</th>
                        <th>Actions</th>

                    </tr>
                </thead>
                <tbody>

                    {supplierPayments?.map(pay => {
                        return <tr>
                            <td>{pay.id}</td>
                            <td>{pay.name}</td>
                            <td>{pay.date}-{pay.addedBy}</td>
                            <td>{pay.description}</td>
                            <td>{pay.cost}</td>
                            <td>{pay.collection}</td>
                            <td>{pay.cost - pay.collection}</td>
                            <td><button><i className="tim-icons icon-pencil" /></button> | <button><i className="far fa-trash-o"></i></button> | <button><i className="far fa-comment-dots"></i></button></td>
                        </tr>
                    })}
                </tbody>
            </Table>
            <ModalSlider open={sliderShow} onClick={showForm} width='500'>
                <Card>
                    <CardHeader>
                        <h3 className="formheadings">Add Supplier Payment</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                        <Col md="12">
                                <FormGroup>
                                    <label>Supplier Type</label>
                                    <Input
                                        type="select"
                                        placeholder="Select"
                                        name="Supplier Type">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <label>Hotel</label>
                                    <Input
                                        type="select"
                                        placeholder="Hotel Name"
                                        name="Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <label>Transport</label>
                                    <Input
                                        type="select"
                                        placeholder="Hotel Name"
                                        name="Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <label>Others (Specify)</label>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        name="other">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Mode</label>
                                    <Input
                                        type="select"
                                        placeholder="Payment Mode"
                                        name="mode">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Date</label>
                                    <Input
                                        type="date"
                                        placeholder="Hotel Name"
                                        name="Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Amount Due</label>
                                    <Input
                                        type="text"
                                        placeholder="12345.00"
                                        name="Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Amount Paid</label>
                                    <Input
                                        type="text"
                                        placeholder="1425.00"
                                        name="Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <label>Description</label>
                                    <Input
                                        type="textarea"
                                        name="description">
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <button style={{ width: "max-content", marginTop: "2em" }} onClick={showForm} className="btn btn-primary">Save and Close</button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>







            </ModalSlider>
            <Link style={{ gridColumn: '3/4', justifySelf: 'end', color: 'var(--primary)', cursor: "pointer", marginTop: ".5em" }} onClick={showForm}> + Add Supplier Payment</Link>
        </div >
    </>
}