import { useState, useEffect } from 'react';
import { enquiryState, enquirySelector } from '../store/EnquiryState'
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL,

});


async function GetEnquiries() {
  const response = await client.get(`lead/getLead`);
  //console.log(response.data)
  return response.data;
}

async function GetEnquiriesByUserId(userId) {
  const response = await client.get(`lead/GetLeadByUserId?userId=${userId}`);
  return response.data;
}

async function GetEnquiryStatus() {
  const response = await client.get(`lead/GetLeadStatus`);
  return response.data;
}

async function GetEnquirySource() {
  const response = await client.get(`lead/GetLeadSource`);
  return response.data;
}

async function CreateEnquiry(enquiry) {
  //alert(JSON.stringify(enquiry));

  const response = await client.post(`lead/AddLead`, enquiry).catch(err => console.log(response));
  return response?.data;
}

async function DeleteEnquiry(enquiryId) {
  const response = await client.delete(`lead/DeleteLead?id=${enquiryId}`);
  return response.data;
}

async function ConvertToProspect(enquiryId) {
  const response = await client.post(`lead/convertToProspect?id=${enquiryId}`);
  return response.data;
}

async function ConvertToCustomer(enquiryId) {
  const response = await client.post(`lead/convertToCustomer?id=${enquiryId}`);
  return response.data;
}

async function AssignLeads(leadIds, userId) {
  const response = await client.post(`lead/AssignLeads?userId${userId}`, { 'userId': userId, 'leadIds': leadIds });
  return response.data;
}

async function LoadData({ skip, sortInfo, limit }) {
  return await client.get(`lead/GetLeadForLivePagging?skip=${skip}`)
    .then(response => {
      const totalCount = response.headers.get('X-Total-Count') || 100;
      const data = response.data;
      return { data, count: parseInt(totalCount) };
    })
}


const EnquiryService = {
  GetEnquiries,
  CreateEnquiry,
  DeleteEnquiry,
  ConvertToProspect,
  ConvertToCustomer,
  AssignLeads,
  GetEnquiriesByUserId,
  GetEnquiryStatus,
  GetEnquirySource,
  LoadData
}

export default EnquiryService;