import { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    Nav,
    NavLink,
    NavItem
} from "reactstrap";
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import PersonService from "services/person.service";
export default function BasicDetails() {
    const contact = { email: "", whatsapp: "", phone: "", }
    const schema = {}
    const [mode, setMode] = useState("View");
    const formik = useFormik({
        initialValues: contact,
        // validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            PersonService.CreatePersonContact(values);
            alert("saving Data")
        }
    });

    function onModeChange(mode) {
        setMode(mode);
    }
    {
        if (mode === "Edit" || mode === "New") {
            return <>
                <Form onSubmit={formik.handleSubmit}>
                    <Row style={{ marginBottom: "1em" }}>
                        <Col md="6"><h5 className="title">Basic Details</h5>
                        </Col>
                        <Col md="6" style={{ textAlign: "right" }}>
                            <button onClick={() => { }} className="btn btn-primary"><i className="fas fa-pencil"></i></button>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "1em" }}>
                        <Col md="2">
                            <FormGroup>
                                <label>Title</label>
                                <Input
                                    name="title"
                                    placeholder="Mr"
                                    type="select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.titleId}
                                >
                                    <option value="1">Mr.</option>
                                    <option value="1">Ms.</option>
                                    <option value="1">Mrs.</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label>First Name</label>
                                <Input
                                    name="firstName"
                                    placeholder="Name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <span className='text-red-400'>{formik.errors.name}</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label>Last Name</label>
                                <Input
                                    name="lastName"
                                    placeholder="Name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <span className='text-red-400'>{formik.errors.name}</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <label>Profile Photo</label>
                                <button style={{ margin: "0" }} className="btn">Upload Image</button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </>
        }
        else {
            return <>
                <Row style={{ marginBottom: "1em" }}>
                    <Col md="6">
                        <h5 className="title">Basic Details</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md="1">
                        <FormGroup>
                            <label>Title</label>
                            <Input
                                name="title"
                                placeholder="Mr"
                                type="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.titleId}
                            >
                                <option value="1">Mr.</option>
                                <option value="1">Ms.</option>
                                <option value="1">Mrs.</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>First Name</label>
                            <Input
                                name="firstName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <span className='text-red-400'>{formik.errors.name}</span>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Last Name</label>
                            <Input
                                name="lastName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <span className='text-red-400'>{formik.errors.name}</span>
                            )}
                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                    <Col md="3">
                        <FormGroup>
                            <label>Facebook</label>
                            <Input
                                name="lastName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}></Input>
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Google+</label>
                            <Input
                                name="lastName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}></Input>
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Twitter</label>
                            <Input
                                name="lastName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}></Input>
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Linkedin</label>
                            <Input
                                name="lastName"
                                placeholder="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}></Input>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        }
    }
}