import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
export default function ClientDetails(props) {
    const client = props.client
    const pkg = props.package

    return <>
        <div className="custom-card" style={{ borderBottom: "none" }}>
            <h3 className="formheadings">Client Details</h3>
            <hr style={{ margin: "0", marginBottom: "1em" }} />
            <div className="custom-card-grid">
                <FormGroup>
                    <label>Customer Name</label>
                    <Input
                        type="text"
                        placeholder="Customer Name"
                        name="Name"
                        value={client?.name}>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <label>Email</label>
                    <Input
                        type="email"
                        placeholder="abc@xyz.com"
                        name="Email"
                        value={client?.email}>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <label>Contact</label>
                    <Input
                        type="text"
                        placeholder="Contact"
                        name="Contact"
                        value={client?.phone}>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <label>Destination</label>
                    <Input
                        type="text"
                        placeholder="Destination"
                        name="destination"
                        value={client?.destination}>                        
                    </Input>
                </FormGroup>                
                <div className="grid-flex-3">
                    <FormGroup>
                        <label>Adults</label>
                        <Input
                            type="text"
                            placeholder=""
                            value={client?.adult}
                            name="">
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <label>Kids</label>
                        <Input
                            type="text"
                            placeholder=""
                            value={client?.kids}
                            name="">
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <label>Infants</label>
                        <Input
                            type="text"
                            placeholder=""
                            value={client?.infant}
                            name="">
                        </Input>
                    </FormGroup>

                </div>
                
            </div>

            <div style={{ display: 'flex' }}>
                {/* <button style={{ display: "block", marginTop: "2em",marginRight:'1em' }} className="btn btn-primary">Save Details</button>
                <button style={{ display: "block", marginTop: "2em" }} className="btn ">Generate Client Voucher</button> */}
            </div>
        </div>

    </>
}