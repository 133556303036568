import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});

async function GetEnquiries() {
    const response = await client.get(`Dashboard/GetDashbordLead`);
    console.info(response.data);
    return response?.data;
  }

  async function GetDashboardByuserId(userId) {    
    const response = await client.get(`Dashboard/GetDashbordDataByUserId?userId=${userId}`);    
    return response?.data;
  }

  async function GetMonthWiseLeads(userId) {    
    const response = await client.get(`Dashboard/GetMonthWiseLeads?userId=${userId}`);    
    return response?.data;
  }

  async function GetStatusWiseLeads(userId) {    
    const response = await client.get(`Dashboard/GetStatusWiseLeads?userId=${userId}`);    
    return response?.data;
  }

  async function GetUserWiseLeads() {    
    const response = await client.get(`Dashboard/GetUserWiseLeads`);    
    return response?.data;
  }
  
  



  const DashboardService = {
    GetEnquiries,
    GetDashboardByuserId,
    GetMonthWiseLeads,
    GetStatusWiseLeads,
    GetUserWiseLeads
}

export default DashboardService;