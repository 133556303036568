import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import UserService from '../services/user.service'
import { userState } from '../store/UserStore'
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import axios, * as others from 'axios';
import NotificationAlert from "react-notification-alert";
import classNames from "classnames";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { format, parseISO } from 'date-fns'

// reactstrap components
import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    NavbarToggler,
    ModalBody,
    ModalFooter,
    Form,
    CardFooter,
} from "reactstrap";

import BaseService from 'services/base.service'

const API_URL = BaseService.API_URL;

function UserList() {
    const navigate = useNavigate();
    const notificationAlertRef = React.useRef(null);
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [filter, setfilter] = useState(false);
    //const [users, setUsers] = useRecoilState(userState);
    const [userList, setUserList] = useState([]);
    const [show, setShow] = useState(false);
    const [enableFiltering, setEnableFiltering] = useState(false);

    useEffect(() => {
        UserService.GetUsers().then(res => {
            setUserList(res);
            console.log(res);
        });
    }, []);

    const handleClose = () => {
        setShow(false)
    };

    const handleSave = () => {
        setShow(false)

    };

    const handleShow = () => setShow(true);

    const setBgChartData = (name) => {
        setfilter(false);
        navigate(`${BaseService.NavigateToPath()}/${name}`);

    };

    const setFilterData = (name) => {
        setfilter(true);
    };

    const onDelete = (e, id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete?")) {
            UserService.DeleteUserById(id).then((r) => {
                UserService.GetUsers().then(res => {
                    setUserList(res);
                    notify('br');
                });
            })
        }
    };

    const onEdit = (e, user) => {
        e.preventDefault();        
        navigate(`${BaseService.NavigateToPath()}/user-profile`, { state: { user: user } });
    };

    const notify = (place) => {
        var color = 2; //Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        The User has been deleted successfully!
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };


    const getImage = (user) => {        
        if (user?.picture)
            return API_URL + `app-images${user?.picture}`
        else
            return "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
    }

    
    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'userId', operator: 'contains', type: 'string', value: '' },
        { name: 'email', operator: 'contains', type: 'string', value: '' },
        { name: 'firstName', operator: 'contains', type: 'string', value: '' },
        { name: 'lastName', operator: 'contains', type: 'string', value: '' },
        { name: 'phone', operator: 'contains', type: 'string', value: '' },        
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'userId', header: 'User Id', defaultWidth: 50, flex: 1 },
        { name: 'firstName', header: 'First Name', defaultWidth: 50, flex: 1 },
        { name: 'lastName', header: 'Last Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },        
        {
            name: 'picture', header: 'User', defaultWidth: 80, render: ({ data }) => {                
                return <div className="photo"><img height={35} width={35} alt="..." title={data?.firstName} src={(data?.picture) ? BaseService.API_URL + `app-images/${data?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            }
        },

        {
            name: 'action', header: 'Action', sorrting: false, defaultWidth: 80, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(e, data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(e, data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>                    
                </div>
            }
        },
    ];

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
 
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }
    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle tag="h4">Users</CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: filter === true,
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={() => setEnableFiltering(!enableFiltering)}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data3",
                                                })}
                                                onClick={() => setBgChartData("user-profile")}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Add New
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={userList}
                                    enableFiltering={enableFiltering}
                                    defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default UserList;
