import React, { useRef, useState } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import BaseService from "services/base.service";
import SupplierService from "services/supplier.service";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

export default function Supplier() {
    const [bigChartData] = React.useState("data1");
    const [supplierList, setSupplierList] = useState([]);
    const navigate = useNavigate();
    const [sliderShow, setSliderShow] = useState(false);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const setBgChartData = (name) => {
        // setSliderShow(1)
        // navigate(`/admin/${name}`);
    };

    React.useEffect(() => {
        SupplierService.GetSuppliers().then(
            res => { setSupplierList(res); console.log(supplierList) }
        );
    }, []);

    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'leadCode', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'startsWith', type: 'string', value: '' },
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'phone', operator: 'startsWith', type: 'string', value: '' },
        { name: 'destination', operator: 'startsWith', type: 'string', value: '' },
        { name: 'package', operator: 'startsWith', type: 'string', value: '' },
        { name: 'plannedTravelDate', operator: 'startsWith', type: 'string', value: '' },
        { name: 'statusId', operator: 'startsWith', type: 'string', value: '' }
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'supplierType', header: 'Type', defaultWidth: 100 },
        { name: 'firstName', header: 'First Name', defaultWidth: 50, flex: 1 },
        { name: 'lastName', header: 'Last Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },
        { name: 'address', header: 'Address', defaultWidth: 50, flex: 1 },
        { name: 'city', header: 'City', defaultWidth: 50, flex: 1 },
        { name: 'country', header: 'Country', defaultWidth: 50, flex: 1 },
        {
            header: '', sorrting: false, defaultWidth: 130, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /></a>&nbsp;
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /></a>&nbsp;
                </div>
            }
        },
    ];

    const gridStyle = { minHeight: 550 }
    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const onEdit = (supplier) => {
        navigate(`${BaseService.NavigateToPath()}/add-supplier`, { state: { supplier: supplier } });
    };

    function onDelete(id) {
        if (window.confirm("Are you sure want to delete?")) {
            SupplierService.DeleteSupplier(id).then(res => {
                SupplierService.GetSuppliers().then(
                    res => { setSupplierList(res); }
                )
            })
        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle><h3>Suppliers</h3></CardTitle>
                                    </Col>
                                    <Col sm="6" >
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data1",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={{}}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Bulk Upload
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data3",
                                                })}
                                                onClick={e => navigate(`${BaseService.NavigateToPath()}/add-supplier`)}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Add New
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={supplierList}
                                    enableFiltering={enableFiltering}
                                    defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

