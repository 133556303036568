import { React, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { GetAllLeads, createLead, SendGetRequest } from '../services/user.service'
import { userState } from '../store/UserStore'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import PackageCard from "components/CustomComponents/PackageCard";
import NotificationAlert from "react-notification-alert";
import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    Alert,
    Nav,
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { enquiryState } from '../store/EnquiryState'
import { format, parseISO } from 'date-fns'
import PackageService from "services/package.service";
import BaseService from "services/base.service";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import classNames from "classnames";

function Package() {
    const navigate = useNavigate();
    const [repos, setRepos] = useState([]);
    const editorRef = useRef(null);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [packages, setPackages] = useState([]);
    const [bigChartData, setbigChartData] = useState("data1");
    const [enableFiltering, setEnableFiltering] = useState(false);
    const notificationAlertRef = useRef(null);
    useEffect(() => {
        // EnquiryService.GetEnquiries().then(
        //     res => setEnquiryList(res)
        // );
        PackageService.GetPackages()
            .then(res => {
                setPackages(res)
            })
    }, []);

    const defaultFilterValue = [
        //{ name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'packageName', operator: 'contains', type: 'string', value: '' },
        { name: 'packageCode', operator: 'contains', type: 'string', value: '' },
        { name: 'destination', operator: 'contains', type: 'string', value: '' },
        { name: 'overView', operator: 'startsWith', type: 'string', value: '' },
        { name: 'destination', operator: 'startsWith', type: 'string', value: '' },
    ];

    const columns = [
        //{ name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        {
            name: 'coverImages', header: 'Img', defaultWidth: 80, render: ({ data }) => {
                return <div className="photo"><img height={35} width={35} alt="..." title={data?.packageName} src={(data?.coverImages) ? BaseService.API_URL + `${data?.coverImages}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            }
        },
        { name: 'packageCode', header: 'Code', maxWidth: 100, flex: 1 },
        { name: 'packageName', header: 'Name', defaultWidth: 50, flex: 1 },

        { name: 'destination', header: 'Destination', maxWidth: 140, flex: 1 },
        { name: 'overView', header: 'Over View', defaultWidth: 50, flex: 1 },
        {
            name: 'action', header: 'Action', sorrting: false, defaultWidth: 90, render: ({ data }) => {
                return <div><a href="#" onClick={(e) => onEdit(data?.id)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                    <a target="_blank" href={data.pdfLink}><i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                </div>
            }
        },
    ];

    const notify = (place, message) => {
        var color = 2;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
    }
    
    function onDelete(id) {

        if (window.confirm("Are you sure want to delete?")) {
            PackageService.DeletePackage(id).then(
                res => {
                    notify('br', 'The Package has been deleted successfully!');
                    PackageService.GetPackages()
                        .then(res => {
                            setPackages(res)
                        })
                }
            )
        }

    }

    const onEdit = (id) => {
        PackageService.GetPackageById(id).then(res => {
            console.log(res);
            navigate(`${BaseService.NavigateToPath()}/add-package`, { state: { pkg: res } });
        })
    };

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }

        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const tourPackage = {
        name: 'SHIMLA VOLVO TOUR PACKAGE',
        description: 'Package cost does not valid during long weekend, National Holidays, Black Out Dates, X-mas , New Year & season time(From 10th April to 10th July).',
        image: '',
        destination: '',
        price: 1500,
    };


    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
        // email: yup.string().required(),
        // phone: yup.string().min(3).max(40).required(),
        // address: yup.string().min(3).max(40).required(),
        // city: yup.string().min(3).max(40).required(),
        // country: yup.string().min(3).max(40).required(),
        // pinCode: yup.string().min(3).max(40).required(),
        // aboutMe: yup.string().min(3).max(40).required(),
        // faceBook: yup.string().min(3).max(40).required(),
        // tweeter: yup.string().min(3).max(40).required(),
        // gmail: yup.string().min(3).max(40).required(),
        // picture: yup.string().min(3).max(40).required(),
        // company: yup.string().min(3).max(40).required(),
        // title: yup.string().min(3).max(40).required(),
    });

    const savePackage = () => { };

    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/enquiry`);
    }
    let newPackage = {
        id: 0,
        name: '',
        description: '',
        coverPhoto: '',
        destination: '',
        duration: '',
        itenary: [
            { day: 1, hotel: 't', attraction: '' },
            { day: 2, hotel: '', attraction: '' },
            { day: 3, hotel: '', attraction: '' },
        ]
    }
    const packageOptions = [
        { value: '1', label: 'Manili' },
        { value: '2', label: 'Shimla' },
        { value: '3', label: 'Palampur' },
        { value: '4', label: 'Dharamshala' },
    ]

    const formik = useFormik({
        initialValues: tourPackage,
        validationSchema: schema,
        onSubmit: function (values) {
            //debugger;
            savePackage(values);
            navigate(`${BaseService.NavigateToPath()}/enquiry`);
        }
    });

    const downloadHandler = (e) => {

    }

    const viewHandler = (e, pkg) => {
        e.preventDefault();
        //alert(pkg);
        navigate(`${BaseService.NavigateToPath()}/add-package`, { state: { 'pkg': pkg } });
    }

    const sendHandler = (e, pkg) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { pkg: pkg } });
    }
    const addPackageHandler = () => { navigate(`${BaseService.NavigateToPath()}/add-package`); }

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1em 1em',
        marginBottom: '1.5em',
        border: '2px solid var(--info)',
        borderRadius: '10px',
        backgroundColor: 'var(--sky)'
    }
    return (
        <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="content">                
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Table><Row>
                                        <Col md="6">
                                            <CardTitle>
                                                <h4>Package Managment</h4>
                                            </CardTitle>
                                        </Col>
                                        <Col md="6">
                                            <ButtonGroup
                                                className="btn-group-toggle float-right"
                                                data-toggle="buttons"
                                            >
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data1" && enableFiltering,
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Filter
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data2",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { setbigChartData("data2") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Import
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data3",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => { setbigChartData("data3") }}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Bulk Upload
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    color="info"
                                                    id="2"
                                                    size="sm"
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data4",
                                                    })}
                                                    onClick={addPackageHandler}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Add Package
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-tap-02" />
                                                    </span>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    </Table>
                                </CardHeader>
                                <CardBody>
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                        showZebraRows={true}
                                        dataSource={packages}
                                        enableFiltering={enableFiltering}
                                        defaultFilterValue={defaultFilterValue}
                                        pagination
                                        defaultLimit={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>                
            </div >
        </>
    );
}

export default Package;
