import { useState, useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});

async function GetProposal() {
  const response = await client.get(`proposal/getProposal`);
  console.info(response.data);
  return response?.data;
}
async function GetProposalsByEnquiryId(id) {
  const response = await client.get(`proposal/getProposalByEnquiryId?enquiryId=${id}`);
  console.info(response.data.length);
  return response?.data?.length;
}

async function CreateProposal(proposal) {  
  const response = await client.post(`proposal/AddProposal`, proposal).catch(err => console.log(response));
  return response?.data;
}

async function AddAndSendEmail(proposal) {  
  const response = await client.post(`proposal/AddAndSendEmail`, proposal).catch(err => console.log(response));
  return response?.data;
}

async function DeleteProposal(proposalId) {
  const response = await client.delete(`proposal/deleteProposal?id=${proposalId}`);
  return response?.data;
}



const ProposalService = {
  GetProposal,
  CreateProposal,
  DeleteProposal,
  GetProposalsByEnquiryId,
  AddAndSendEmail
}

export default ProposalService;