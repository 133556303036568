import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

async function GetFollowUps() {
  const response = await axios.get(API_URL + `Reminder/GetReminders`);
  console.info(response.data);
  return response.data;
}


async function GetFollowUpsByEnquiryId(enquiryId) {
//  http://amitlaptop1:5000/api/Reminder/GetRemindersByEnquiryId?enquiryId=1

  const response = await axios.get(API_URL + `Reminder/GetRemindersByEnquiryId?enquiryId=${enquiryId}`);
  //console.info(response.data);
  return response.data;
}

async function CreateFollowUp(followUp) {
  //alert(followUp)
  const response = await axios.post(API_URL + `Reminder/AddReminder`, followUp);
  return response.data;
}

async function DeleteFollowUp(followUpId) {
  const response = await axios.delete(API_URL + `follow-up/DeletefollowUp?followUpId=${followUpId}`);
  return response.data;
}

const FollowUpService = {
  GetFollowUps,
  CreateFollowUp,
  DeleteFollowUp,
  GetFollowUpsByEnquiryId
}

export default FollowUpService;