import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

async function GetTransports() {
  const response = await axios.get(API_URL + `transport/GetTransport`);
  console.info(response.data);
  return response?.data;
}

async function CreateTransport(template) {
  //alert(JSON.stringify(template))
  const response = await axios.post(API_URL + `transport/AddTransport`, template);
  return response?.data;
}

async function DeleteTransport(template) {
  const response = await axios.delete(API_URL + `transport/DeleteTransport?Id=${template.id}`);
  return response?.data;
}
async function UploadImage(userFormData) {
  // const response = await client.post(`/UploadImage`, userFormData);
  // return response.data;
  //https://localhost:7041/api/Transport/GetTransport

}

const TransportService = {
  GetTransports,
  CreateTransport,
  DeleteTransport,
  UploadImage
}

export default TransportService;