import React, { useState, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../../services/auth.service";
import useToken from './../../../common/useToken';

import {
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  NavbarToggler,
  ModalHeader,
  ModalBody,
  CardFooter,
  FormGroup
} from "reactstrap";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Login your Account");
  const { token, setToken } = useToken();

  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onReset = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(false);
    //e.target.reset();
    setUsername("");
    setPassword("");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(username + '-' + password)
    setMessage("Login your Account");
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        (res) => {
          //alert(111);
          if (res?.id) {
            //alert(JSON.stringify(res))
            setToken({ token: 'token', userType: res?.userTypeId });
            navigate("/admin/dashboard");
            //navigate("/admin/dashboard");
            switch (res?.userTypeId) {
              case 1:
                navigate("/admin/dashboard");
                break;
              case 2:
                navigate("/user/dashboard");
                break;
              case 3:
                navigate("/client/dashboard");
                break;
            }
          }
          else {
            setMessage("Invalid User Id or Password!");
          }
          setLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage("Invalid User Id or Password!");

        }
      );
    } else {
      setLoading(false);
      console.log(username + '-' + password)
      //onReset();
      //setMessage("There is some error while login, please retry after close/refresh browser.");
    }
  };



  return (
    <>
      <div className="login-container">
        <div className="partleft">
          <h1 className="leftheading" style={{ color: "white", fontWeight: '600', paddingRight: ".4em" }}>Tripper Hub <span style={{ fontWeight: '400' }}>CRM Software</span></h1>
          <h4 className="leftsubheading">Discover &#8226;  Dream &#8226;  Adventure  </h4>

        </div>
        <div className="partright">
          <section className="loginform-container">
            <img className="login-logo" src="https://tripperhub.com/images/Yatra-white.png" alt="" />
            {/* <h1 className="rightheading">Welcome</h1> */}
            <p className={message == "Login your Account" ? 'rightsubheading ' : 'rightsubheading alerted'}>{message}</p>

            <Form onSubmit={handleLogin} ref={form}>


              <div>
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  placeholder="Username"
                  onChange={onChangeUsername}
                  validations={[required]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </div>

              <a onClick={e => e.preventDefault()} className="forgot">Forgot Password?</a>
              <div className="form-group">
                <button className="loginbutton" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>

                <button style={{ display: 'none' }} onClick={onReset} className="btn btn-secondary">
                  <span>Reset</span>
                </button>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </section>

        </div>
      </div >
    </>
  );
};

export default Login;