import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle

} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ClientDetails from "components/CustomComponents/Booking/ClientDetails";
import PaymentDetails from "components/CustomComponents/Booking/PaymentDetails";
import PaxDetails from "components/CustomComponents/Booking/PaxDetails";
import HotelDetails from "components/CustomComponents/Booking/HotelDetails";
import TransportDetails from "components/CustomComponents/Booking/TransportDetails";
import SupplierDetails from "components/CustomComponents/Booking/SupplierDetails";
import Vouchers from "components/CustomComponents/Booking/Vouchers";
import RefundDetails from "components/CustomComponents/Booking/RefundDetails";
import InvoiceDetails from "components/CustomComponents/Booking/InvoiceDetails";
import BookingService from "services/booking.service";
import { useLocation } from "react-router-dom";
import moment, * as moments from 'moment';
import NotificationAlert from "react-notification-alert";
export default function Booking() {
    window.moment = moment
    const location = useLocation()
    const [booking, setBooking] = useState({})
    const notificationAlertRef = React.useRef(null);
    useEffect(() => {

        if (location.state?.quotation) {
            console.log(location.state?.quotation)
            console.log(location.state?.bookingOption)
            console.log(location.state?.bookingId)
        }
        let bookingId = location.state?.bookingId || 0;
        BookingService.GetBookingByIdAndQuotationId(bookingId, location.state?.quotation?.id, location.state?.bookingOption).then(
            res => {
                setBooking(res);
                console.log(res);
            }
        );
    }, []);

    const createBooking = () => {
        BookingService.CreateBooking(booking)
    }

    return <>
        <div className="content">
            <NotificationAlert ref={notificationAlertRef} />
            <Card>
                <CardBody>
                    <div style={{ border: '1px solid #aaa', marginBottom: '1em', borderRadius: '5px' }}>
                        <Card style={{ marginBottom: '0' }}>
                            <CardBody className="bookingdetails">
                                <div style={{ display: 'flex' }}>
                                    <div><p>Booking Id: <span>BK{booking?.leadId}</span></p></div>
                                    {/* <div><p>Code: <span>{booking?.leadId}</span></p></div> */}
                                    <div><p>Name: <span>{booking?.name}</span></p></div>
                                    <div><p>Destination: <span>{booking?.destination}</span></p></div>
                                    <div><p>Tour Start: <span>{moment(booking?.tripStartDate).format('DD-MM-yyyy')}</span> </p></div>
                                    <div><p>End: <span>{moment(booking?.tripStartDate).format('DD-MM-yyyy')}</span> </p></div>
                                </div>
                                <div>
                                    <button onClick={() => createBooking()} ><i style={{ paddingRight: ".25em" }} className="fas fa-add" ></i>Update Booking</button>
                                    <button ><i style={{ paddingRight: ".25em" }} className="fas fa-times" ></i>Delete Booking</button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div style={{ border: '1px solid #aaa', marginBottom: '1em', borderRadius: '5px' }}>
                        <Card style={{ marginBottom: '0' }}>
                            <CardBody >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                    <div className="stage passed">Payment Received</div>
                                    <div className=""></div>
                                    <div className="stage passed">Booking Initiated</div>
                                    <div ></div>
                                    <div className="stage current">Book Bus</div>
                                    <div></div>
                                    <div className="stage">Book Hotel</div>
                                    <div></div>
                                    <div className="stage">Tour Arranged</div>
                                    <div></div>
                                    <div className="stage">Sent Vouchers</div>
                                    <div></div>
                                    <div className="stage">Feedback Received</div>
                                    <div></div>
                                    <div className="stage">Booking Completed</div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div style={{ marginTop: '2em' }}>
                        <Tabs>
                            <TabList>
                                <Tab>Client</Tab>
                                <Tab>Payments</Tab>
                                <Tab>Transport/Hotel</Tab>
                                <Tab>Invoice</Tab>
                                {/* <Tab>Supplier Payment</Tab> */}
                                <Tab>Vouchers</Tab>
                                {/* <Tab>Refund Details</Tab> */}
                            </TabList>

                            <TabPanel>
                                <ClientDetails client={booking} package={booking?.package}></ClientDetails>
                                <PaxDetails pax={booking?.pax} ></PaxDetails>
                            </TabPanel>
                            <TabPanel>
                                <PaymentDetails payments={booking} package={booking?.package}></PaymentDetails>
                                <SupplierDetails payments={booking?.vendorPayments}></SupplierDetails>
                            </TabPanel>
                            <TabPanel>
                                <HotelDetails booking={booking}></HotelDetails>
                                <TransportDetails booking={booking}></TransportDetails>
                            </TabPanel>
                            <TabPanel>
                                <InvoiceDetails></InvoiceDetails>
                            </TabPanel>
                            <TabPanel>
                                <Vouchers></Vouchers>
                            </TabPanel>
                        </Tabs></div>
                </CardBody>
            </Card>
        </div>
    </>
}