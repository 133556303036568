import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});

async function GetSuppliers() {
  const response = await client.get(`supplier/getSupplier`);
  console.info(response.data);
  return response?.data;
}

async function DeleteSupplier(id) {
  const response = await client.delete(`supplier/deleteSupplier?id=${id}`);
  return response?.data;
}

async function CreateSupplier(supplier) {
  //alert(API_URL + `prospect​/AddProspect`)
  const response = await client.post(`supplier/AddSupplier`, supplier);
    //.catch(err => console.log(response));
  //const response = await axios.post(API_URL + `prospect​/AddProspect`, prospect);
  return response?.data;
}

async function GetSupplierDocuments(id) {
  const response = await client.get(`supplier/getSupplierDocument?id=${id}`);
  console.info(response.data);
  return response?.data;
}

async function GetSupplierPayments(id) {
  const response = await client.get(`supplier/getSupplierPayment?id=${id}`);
  console.info(response.data);
  return response?.data;
}

async function GetSupplierContacts(id) {
  const response = await client.get(`supplier/GetSupplierContact?id=${id}`);
  console.info(response.data);
  return response?.data;
}

async function GetSupplierBankAccounts(id) {
  const response = await client.get(`supplier/GetSupplierContact?id=${id}`);
  console.info(response.data);
  return response?.data;
}

const SupplierService = {
  GetSuppliers,
  CreateSupplier,
  DeleteSupplier,
  GetSupplierDocuments,
  GetSupplierPayments,
  GetSupplierContacts,
  GetSupplierBankAccounts
}

export default SupplierService;