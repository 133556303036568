import React, { useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import _ from 'underscore';
import FollowUp from './FollowUp'
import BulkUpload from "../components/CustomComponents/BulkUpload";
import Pagination from '../components/Pagination';
import ReactPaginate from 'react-paginate'
import en from 'date-fns/locale/en-IN';
import { Chrono } from "react-chrono";
import ProposalService from "services/proposal.service";
import AuthService from "services/auth.service"
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    UncontrolledAlert,
    Table,
    Row,
    Col,
    NavLink,
    Nav,
    Alert
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { useRecoilState, useRecoilValue } from "recoil";
import { enquiryState, filteredEnquiryState } from '../store/EnquiryState'
import MoadalSlider from "components/CustomComponents/ModalSlider";
import BaseService from '../services/base.service'
import FollowUpService from '../services/followup.service'
import { propsWithBsClassName } from "react-bootstrap-typeahead/types/utils";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DashboardService from "services/dashboard.service";

function Enquiry_new() {
    const location = useLocation();
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [enquiryCount, setEnquiryCount] = React.useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = React.useState([]);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [sliderShow, setSliderShow] = useState(false);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [sliderShow2, setSliderShow2] = useState(false);
    const notificationAlertRef = React.useRef(null);
    const navigate = useNavigate();
    const [parentToChild, setParentToChild] = useState('');
    const [followupItems, setfollowupItems] = useState([{ "title": "10 Aug 23 16:53", "cardTitle": "Meeting Fixed", "cardDetailedText": "sdfsd" }, { "title": "10 Aug 23 16:53", "cardTitle": "Meeting Fixed", "cardDetailedText": "sdfsd" }, { "title": "10 Aug 23 16:53", "cardTitle": "Lost", "cardDetailedText": "sdfsd" }, { "title": "10 Aug 23 17:12", "cardTitle": "Closed", "cardDetailedText": "sdfsdf" }, { "title": "10 Aug 23 17:12", "cardTitle": "Closed", "cardDetailedText": "sdfsdf f sd fsd" }, { "title": "10 Aug 23 17:21", "cardTitle": "Closed", "cardDetailedText": "bcvbcv" }]);
    const showForm = (enquiry) => {
        setParentToChild(enquiry?.id)
        setSelectedEnquiry(enquiry);
        setSliderShow(!sliderShow);
    }

    const enquiryStatusoptions = [
        { value: '1', label: 'Spoke' },
        { value: '2', label: 'Meeting Fixed' },
        { value: '3', label: 'Met' },
        { value: '4', label: 'Closed' },
        { value: '5', label: 'Lost' },
        { value: '6', label: 'Active' },
    ]

    const showForm1 = () => { setSliderShow1(!sliderShow1) }
    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 5;
    const [pageCount, setPageCount] = useState(Math.ceil(enquiryList.length / PER_PAGE));
    const [filterData, setFilterData] = useState([]);
    const [linkState, setLinkState] = useState(false)
    const [leadsCount, setLeadsCount] = useState([])
    const [proposalData, setProposalData] = useState([])
    const currentUser = AuthService.getCurrentUser()

    const [enquiryStatusOptions, setEnquiryStatusOptions] = useState([
        { value: 1, label: 'Unassigned' },
        { value: 2, label: 'New' },
        { value: 3, label: 'Pending' },
        { value: 4, label: 'In-Progress' },
        { value: 5, label: 'Completed' }
    ])



    React.useEffect(() => {
        EnquiryService.GetEnquiriesByUserId(currentUser.id).then(
            res => {
                setEnquiryList(res);
                handlePagination(res);
                handleEnquiryFilter(res);
            }
        );
        ProposalService.GetProposal().then(
            res => {
                setProposalData(res);
            }
        )

        EnquiryService.GetEnquiryStatus().then(res => {
            setEnquiryStatusOptions(res);
        });

        DashboardService.GetStatusWiseLeads(currentUser?.id).then(res => {
            console.log(res)
            setLeadsCount(res);
        });
    }, []);

    const callback = () => {
        return selectedEnquiry;
    }

    const onEdit = (enquiry) => {
        console.log(location.pathname.split('/'));
        navigate(`/${location.pathname.split('/')[1]}/add-enquiry`, { state: { enquiry: enquiry } });

    };

    const showForm2 = (enquiry) => {
        //alert(enquiry?.id)    
        setSliderShow2(!sliderShow2);
        if (enquiry?.id) {
            FollowUpService.GetFollowUpsByEnquiryId(enquiry?.id).then(response => {
                let followups = response?.map(followUp => ({ 'title': format(parseISO(followUp.reminderDateTime), "dd MMM yy HH:mm"), cardTitle: enquiryStatusoptions.filter(e => e.value == followUp.statusId)[0]?.label, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
                //alert(JSON.stringify(followupItems))
            });
        }

        setfollowupItems([{ "title": "29 Aug 23 02:06", "cardTitle": "Spoke", "cardDetailedText": "sdfasd sdf  asd asd das " }]);

    }

    const deleteEnquiry = (e, enquiry) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete?")) {
            EnquiryService.DeleteEnquiry(enquiry?.id).then(
                () => {
                    EnquiryService.GetEnquiriesByUserId(currentUser.id).then(
                        res => {
                            setEnquiryList(res);
                            handleEnquiryFilter(res);
                            handlePagination(res);
                            notify('br', 'The Enquiry has been deleted successfully!');
                        }
                    );
                }
            );
        }
    };

    const navigatePage = (e, page) => {
        e.preventDefault();
        navigate(`/${location.pathname.split('/')[1]}/${page}`);
    };



    const getEnquiryStatus = (name) => {
        switch (name) {
            case 1:
                return 'New';
                break;
            case 2:
                return 'Pending';
                break;
            case 3:
                return 'In-Progress';
                break;
            case 4:
                return 'Completed';
                break;
        }
    };

    const onFilterEnquiry = (e, type) => {
        let statusId = 0;
        if (type != 0) {
            statusId = enquiryStatusOptions.find(s => s.status == type)?.id;
        }
        const enquiryData = (type) ? enquiryList.filter(e => e.statusId === statusId) : enquiryList;
        handlePagination(enquiryData);
        let wTile = e.target.parentNode;
        wTile.childNodes.forEach(element => {
            element.className = "mappedlink";
        });
        e.target.classList.add('activated');
    }

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        setFilterData(
            enquiryList.filter((item, index) => {
                return (index >= selectedPage * PER_PAGE) & (index < (selectedPage + 1) * PER_PAGE);
            })
        );
    }

    function handleEnquiryFilter(enquiryData) {
        var counts = _.countBy(enquiryData, "statusId");
        var countData = _.map(counts, (value, key) => ({ name: key, y: value }));
        setEnquiryCount(countData);
    }

    function handlePagination(data) {
        setCurrentPage(0);
        setPageCount(Math.ceil(data.length / PER_PAGE));
        setFilterData(
            data.filter((item, index) => {
                return (index >= currentPage * PER_PAGE) & (index < (currentPage + 1) * PER_PAGE);
            })
        );
    }



    function convertToProspect(e, enquiry) {
        e.preventDefault();
        const prospect = {
            "id": -1,
            "leadId": enquiry.id,
            "name": enquiry.name,
            "email": enquiry.email,
            "phone": enquiry.phone,
            "userId": enquiry.userId,
            "statusId": enquiry.statusId,
            "reminderId": enquiry.reminderId,
            "modeId": enquiry.modeId,
            "leadType": enquiry.leadType,
            "packageId": enquiry.packageId,
            "plannedTravelDate": enquiry.plannedTravelDate,
            "destination": enquiry.destination,
            "products": enquiry.products,
            "plannedNoOfDays": enquiry.plannedNoOfDays,
            "numberOfAdults": enquiry.numberOfAdults,
            "numberOfChild": enquiry.numberOfChild,
            "numberOfInfant": enquiry.numberOfInfant,
            "budgetPerAdults": enquiry.budgetPerAdults,
            "budgetPerChild": enquiry.budgetPerChild,
            "overAllBudget": enquiry.overAllBudget,
            "leadSourceId": enquiry.leadSourceId,
            "leadCode": enquiry.code,
            "lead": enquiry,
            "proposals": []
        }
        navigate(`${BaseService.NavigateToPath()}/add-prospect`, { state: { prospect: prospect } });
    }

    function convertToCustomer(e, enquiry) {
        e.preventDefault();
        EnquiryService.ConvertToCustomer(enquiry.id).then(res => {
            EnquiryService.GetEnquiriesByUserId(currentUser.id).then(
                res => {
                    setEnquiryList(res);
                    handlePagination(res);
                    notify('br', 'The Enquiry has been converted to Customer!');
                }
            );
        })
    }

    const handleSearch = (event) => {
        let value = event.target.value;
        let result = [];

        result = enquiryList.filter((item, index) => {
            return item.name.search(new RegExp(value, "i")) != -1;
        })
        handlePagination(result);
    }

    const getRowColor = (enquiry) => {

        switch (enquiry?.statusId) {
            case 1:
                return { backgroundColor: '#ecf2f9' };
                break;
            case 2:
                return { backgroundColor: ' #c6d9ec' }
                break;
            case 3:
                return { backgroundColor: '#b3cce6' }
                break;
            case 4:
                return { backgroundColor: '#ecf9f2' }
                break;

        }

    }

    const getStatusColor = (id) => {
        console.log(id);
        switch (id) {
            case 1:
                return { backgroundColor: '#ecf2f9' };
                break;
            case 2:
                return { backgroundColor: ' #c6d9ec' }
                break;
            case 3:
                return { backgroundColor: '#b3cce6' }
                break;
            case 4:
                return { backgroundColor: '#ecf9f2' }
                break;

        }

    }

    function SendQuotation(e, data) {
        //alert( JSON.stringify(data));
        e.preventDefault();
        //navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { enquiry: data } });
        const proposal = {
            id: -1,
            proposalDate: '', //new date(),
            validTillDate: '',//new date(),
            discountType: "",
            destination: data?.destination,
            packageId: data?.packageId,
            subject: "Quotation for your trip",
            message: `        
            <p> Hi <br />
            Greetings from Tripper Hub.<br /><br />
            Our sales team has put up this Quote regarding your upcoming trip. Please go through it and let's know if you
            would like any changes in any of the provided services. <br /><br />Contact details are provided at the end. 
            <br /><br />
                    
            <br /><br />
    
            Thanks<br />
            Tripper Hub Team
            </p>                                                                 
            `,
            leadId: data?.id,
            userId: data?.userId,
            name: data?.name,
            phone: data?.phone,
            email: data?.email,
            total: 0.00,
            discount: 0.00,
            tax: 0.00,
            grandTotal: 0.0
        }

        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    }

    const linkactivate = () => {
        setLinkState(!linkState)
    }

    let linkclass = `mappedlink ${linkState ? 'activated' : ''}`

    const notify = (place) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        New Notification!
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    };

    const addFollowUps = useCallback((followUps) => {
        console.log(followUps)
        FollowUpService.CreateFollowUp(followUps).then(res => {
            FollowUpService.GetFollowUpsByEnquiryId(followUps?.entityId).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        });

    }, []);

    return (
        <>

            <div className="content">
                {/* <UncontrolledAlert color="success">
                    <span>5 new Enquiries! Take Action</span>
                </UncontrolledAlert> */}
                <Table>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle>
                                                <h3> Enquiries</h3>
                                                <Nav >
                                                    <Link id="enquiryfilter" className="mappedlink activated" onClick={(e) => { onFilterEnquiry(e, 0); }}>All</Link>
                                                    {leadsCount.map((enquiryStatus) => {
                                                        return <Link style={getStatusColor(enquiryStatus?.Leads)} className={linkclass} onClick={(e) => { onFilterEnquiry(e, enquiryStatus.Status) }} >
                                                            &nbsp;{enquiryStatus.Status} ({enquiryStatus?.Leads}) &nbsp;</Link >
                                                    })}

                                                    {/* {leadsCount?.map(count => {
                                                        return <>{count.status} {count.leads}</>
                                                    })} */}
                                                </Nav>
                                            </CardTitle>
                                        </Col>
                                        <Col sm="6" >
                                            <ButtonGroup
                                                className="btn-group-toggle float-right"
                                                data-toggle="buttons">

                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data1",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => showForm1()}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Bulk Upload
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data1",
                                                    })}
                                                    color="info"
                                                    id="0"
                                                    size="sm"
                                                    onClick={(e) => showForm1()}>

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Export
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    color="info"
                                                    id="2"
                                                    size="sm"
                                                    tag="label"
                                                    className={classNames("btn-simple", {
                                                        active: bigChartData === "data3",
                                                    })}
                                                    onClick={(e) => navigatePage(e, "add-enquiry")}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        Add New
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-tap-02" />
                                                    </span>
                                                </Button>
                                            </ButtonGroup>
                                            <div style={{ textAlign: "right", marginTop: "4em", marginRight: "0" }}>
                                                <input type="text" placeholder="Search by Name"
                                                    style={{ width: '250px', padding: ".25em .5em", fontSize: "12px", borderRadius: "5px", border: "1px solid #aaa" }}
                                                    onChange={(event) => handleSearch(event)}></input>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>

                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Query Date</th>
                                                <th>Name/Contact</th>
                                                <th>Travel Date</th>
                                                <th>No. of Pax</th>
                                                <th>Destination</th>
                                                <th>Proposals</th>
                                                <th>Lead Stage</th>
                                                <th>Last Updated</th>
                                                {/* <th style={{ textAlign: "center" }}>Executive</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterData?.map((enquiry) => (
                                                <tr style={getRowColor(enquiry)} >
                                                    <td>EQ{enquiry.id}</td>
                                                    <td>{format(parseISO(enquiry?.createdDate), "dd MMM yyyy")}</td>
                                                    <td>{enquiry?.name} <br />{enquiry?.phone} <br /> {enquiry.email}</td>
                                                    <td>{enquiry?.plannedTravelDate && format(parseISO(enquiry?.plannedTravelDate), "dd MMM yyyy")}</td>
                                                    {/* <td>{enquiry.pax}</td> */}
                                                    <td><i className="fas fa-male"></i> {enquiry.numberOfAdults} <br />
                                                        <i className="fas fa-child"></i> {enquiry.numberOfChild} <br />
                                                        <i className="fas fa-baby"></i> {enquiry.numberOfInfant}</td>
                                                    <td>{enquiry?.destination}</td>
                                                    <td>{enquiry?.proposalCount} - <a href="#">View</a> </td>
                                                    <td><div style={{ border: "1px solid green", padding: "0 .5em", maxWidth: "max-content", color: "green" }}>{getEnquiryStatus(enquiry.statusId)}</div></td>
                                                    <td>{enquiry.modifyDate && format(parseISO(enquiry.modifyDate), "dd MMM yyyy")}</td>
                                                    {/* <td><div className="photo"><img alt="..." title={enquiry?.executive?.firstName} src={(enquiry?.executive?.picture) ? BaseService.API_URL + `app-images/${enquiry?.executive?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div> </td> */}
                                                    <td>
                                                        <Nav style={{ flexDirection: "column" }}>
                                                            <NavLink onClick={(e) => { showForm(enquiry) }}><i title="Follow-up" className="far fa-bell" /> </NavLink>
                                                            <NavLink onClick={(e) => onEdit(enquiry)}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                            <NavLink onClick={(e) => showForm2(enquiry)}><i title="Timeline" className="fas fa-clock" /> </NavLink>
                                                            <UncontrolledDropdown style={{ marginTop: "1px" }} nav>
                                                                <DropdownToggle
                                                                    caret
                                                                    color="default"
                                                                    nav
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                    </svg>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-navbar" right tag="ul">
                                                                    {/* <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => e.preventDefault()} className="nav-item">Send Email</DropdownItem>
                                                                    </NavLink> */}
                                                                    {/* <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => { navigatePage(e, `add-proposal`) }} className="nav-item">Send Proposal</DropdownItem>
                                                                    </NavLink> */}
                                                                    <DropdownItem divider tag="li" />
                                                                    {/* <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => e.preventDefault()} className="nav-item">Add Note</DropdownItem>
                                                                    </NavLink> */}
                                                                    <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => deleteEnquiry(e, enquiry)} className="nav-item">Delete</DropdownItem>
                                                                    </NavLink>
                                                                    <DropdownItem divider tag="li" />
                                                                    <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => convertToProspect(e, enquiry)} className="nav-item">Convert To Prospect</DropdownItem>
                                                                    </NavLink>
                                                                    <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => convertToCustomer(e, enquiry)} className="nav-item">Convert To Customer</DropdownItem>
                                                                    </NavLink>
                                                                    <DropdownItem divider tag="li" />
                                                                    <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => { e.preventDefault(); SendQuotation(e, enquiry) }} className="nav-item">Create Itenerary & Quotation</DropdownItem>
                                                                    </NavLink>
                                                                    {/* <NavLink tag="li">
                                                                        <DropdownItem onClick={(e) => e.preventDefault()} className="nav-item">Clone</DropdownItem>
                                                                    </NavLink> */}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Nav>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={10}>
                                                    <ReactPaginate
                                                        previousLabel={"<<"}
                                                        nextLabel={">>"}
                                                        pageCount={pageCount}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Table>

                <Drawer
                    open={sliderShow}
                    onClose={() => setSliderShow((prevState) => !prevState)}
                    direction='right'
                    className='bla bla bla'
                    style={{ 'width': '500px', "zIndex": '10000' }}
                >
                    <FollowUp dataParentToChild={parentToChild} callback={callback} refresh={addFollowUps} onCancel={showForm} />
                </Drawer>
                <MoadalSlider open={sliderShow1} id='uploadSlider' onClick={showForm1} width='450' className={sliderShow1 ? ` showing` : ''}>
                    <BulkUpload></BulkUpload>
                </MoadalSlider>

                <MoadalSlider open={sliderShow2} id='timeline' onClick={showForm2} width='450' className={sliderShow2 ? ` showing` : ''}>
                    <div className="content ">
                        <Card className="followupcard">
                            <Chrono allowDynamicUpdate="true" fontSizes={{
                                cardSubtitle: '12px',
                                cardText: '12px',
                                cardTitle: '13px',
                                title: '13px',
                            }} titleDateFormat='dd MMM yy'
                                contentDetailsHeight="80"
                                cardHeight="100"
                                style={{ height: '950px' }}
                                items={followupItems}
                                mode="VERTICAL" >
                            </Chrono>
                        </Card>
                    </div>
                </MoadalSlider>

            </div >
        </>
    );
}

export default Enquiry_new;
