import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AdminLayout from "layouts/Admin/Admin.js";
import UserLayout from "layouts/User/User.js";
import ClientLayout from "layouts/Client/Client.js";
import Auth from "layouts/Authentication/Auth.js";
import Login from "layouts/Authentication/components/Login";
import useToken from './common/useToken';

const App = () => {
    const { token, setToken } = useToken();
    const getRoutes = (routes) => {
        //alert(token)
        if (token) {
            return (
                <Route path="/admin/*" element={<AdminLayout />} />
            );
        }
    };
    return (

        <BrowserRouter>
            <RecoilRoot>
                <Routes>
                    //{getRoutes({})}
                    <Route path="/admin/*" element={<AdminLayout />} />
                    <Route path="/user/*" element={<UserLayout />} />
                    <Route path="/client/*" element={<ClientLayout />} />
                    <Route path="/login" element={<Auth />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />

                    {/* 
                    <Route path="/login" element={<Auth />} />
                    <Route path="/admin/*" element={<AdminLayout />} /> */}
                </Routes>
            </RecoilRoot>
        </BrowserRouter>

    )
}

export default App