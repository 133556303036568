import axios, * as others from 'axios';
import BaseService from './base.service'
const API_URL = BaseService.API_URL;

async function GetHotels() {
  const response = await axios.get(API_URL + `Hotel/GetHotel`);
  console.info(response.data);
  return response?.data;
}

async function GetRoomType() {
  const response = await axios.get(API_URL + `Hotel/GetRoomType`);
  console.info(response.data);
  return response?.data;
}

async function GetHotelsByDestination(destination, city) {
  const response = await axios.get(API_URL + `Hotel/GetHotelByDestinationByName?name=${destination}&city=${city}` );
  console.info(response.data);
  return response?.data;
}

async function CreateHotel(hotel) {
  const response = await axios.post(API_URL + `Hotel/AddHotel`, hotel);
  return response?.data;
}

async function DeleteHotel(hotelId) {
  const response = await axios.delete(API_URL + `Hotel/DeleteHotel?Id=${hotelId}`);
  return response?.data;
}

async function UploadImage(userFormData) {
  // const response = await client.post(`/UploadImage`, userFormData);
  // return response.data;
}

async function GetRoomByHotelId(hotelId) {
  const response = await axios.get(API_URL + `Hotel/GetRoomByHotelId?hotelId=${hotelId}`);
  console.info(response.data);
  return response?.data;
}

async function CreateHotelRoom(roomPrice) {
  const response = await axios.post(API_URL + `Hotel/AddRoom`, roomPrice);
  console.info(response?.data);
  return response?.data;
}

async function GetRoomPrice(roomType, hotelIds, mealPlan) {
  const response = await axios.get(API_URL + `Hotel/GetHotelRoomPrice?hotelIds=${hotelIds}&mealPlan=${mealPlan}&roomType=${roomType}`);
  console.info(response.data);
  return response?.data;
}


const HotelService = {
  GetHotels,
  CreateHotel,
  DeleteHotel,
  UploadImage,
  GetRoomByHotelId,
  CreateHotelRoom,
  GetRoomPrice,
  GetHotelsByDestination,
  GetRoomType
}

export default HotelService;