import React, { useState, useCallback } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import MoadalSlider from "components/CustomComponents/ModalSlider";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import ProspectService from '../services/prospect.service'
import MasterService from '../services/master.service'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { enquiryState } from './../store/EnquiryState'
import { Link } from "react-router-dom";
import _ from 'underscore';
import BaseService from "services/base.service";
import FollowUp from './FollowUp'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
//import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import AuthService from "services/auth.service";
import FollowUpService from '../services/followup.service'

function Prospect() {
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [prospectList, setProspectList] = useRecoilState(enquiryState);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [parentToChild, setParentToChild] = useState('');
    const navigate = useNavigate();
    const [followupItems, setfollowupItems] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = (enquiry) => {        
        setParentToChild(enquiry?.leadId)        
        setSliderShow(!sliderShow);
    }
    

    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'leadCode', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'startsWith', type: 'string', value: '' },
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'phone', operator: 'startsWith', type: 'string', value: '' },
        { name: 'destination', operator: 'startsWith', type: 'string', value: '' },
        { name: 'package', operator: 'startsWith', type: 'string', value: '' },
        { name: 'plannedTravelDate', operator: 'startsWith', type: 'string', value: '' },
        { name: 'statusId', operator: 'startsWith', type: 'string', value: '' }
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'leadCode', header: 'Code', defaultWidth: 100 },
        { name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },
        {
            name: 'statusId', header: 'Status', defaultWidth: 100, render: ({ value, cellProps }) => {
                return getEnquiryStatus(value)
            }
        },
        {
            name: 'proposals', header: 'Proposals', defaultWidth: 110, render: ({ value, cellProps }) => {
                return value && value?.length
            }
        },
        {
            name: 'plannedTravelDate', header: 'Travel Date', defaultWidth: 150, render: ({ value, cellProps }) => {
                //return moment(value).format('MM-DD-YYYY')
                return value && format(parseISO(value), "dd MMM yyyy")
            }
        },
        { name: 'destination', header: 'Destination' },
        { name: 'overAllBudget', header: 'Budget', defaultWidth: 100, type: 'number' },
        {
            name: 'executive', header: 'User', defaultWidth: 80, render: ({ value, cellProps }) => {
                //return value?.firstName
                return <div className="photo"><img height={35} width={35} alt="..." title={value?.firstName} src={(value?.picture) ? BaseService.API_URL + `app-images/${value?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            }
        },
        {
            name: 'action', header: 'Action', sorrting: false, defaultWidth: 100, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /></a>&nbsp;
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /></a>&nbsp;
                    <a href="#" onClick={(e) => AddQuotation(e, data)}><i title="Send Proposal" className="far fa-edit" /></a>&nbsp;
                    <a href="#" onClick={(e) => showForm(data)}><i title="Follow-up" className="far fa-bell" /> </a>
                </div>
            }
        },
    ];

    function sendProposal(e, prospect) {
        e.preventDefault();
        console.log(prospect);
        var proposal = {
            id: -1,
            proposalDate: '',
            validTillDate: '',
            discountType: "",
            destination: prospect?.destination,
            packageId: prospect?.packageId,
            subject: "Quotation for your trip",
            message: ``,
            leadId: prospect?.leadId,
            prospectId: prospect?.id,
            userId: prospect?.userId,
            name: prospect?.name,
            phone: prospect?.phone,
            email: prospect?.email,
            total: prospect?.overAllBudget,
            discount: 0.00,
            tax: 0.00,
            grandTotal: prospect?.overAllBudget,
            prospect: prospect
        }
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { 'proposal': proposal } });
    }

    function AddQuotation(e, prospect) {
        e.preventDefault();
        console.log(prospect);
        var proposal = {
            id: -1,
            proposalDate: '',
            validTillDate: '',
            discountType: "",
            destination: prospect?.destination,
            packageId: prospect?.packageId,
            subject: "Quotation for your trip",
            message: ``,
            leadId: prospect?.leadId,
            prospectId: prospect?.id,
            userId: prospect?.userId,
            name: prospect?.name,
            phone: prospect?.phone,
            email: prospect?.email,
            total: prospect?.overAllBudget,
            discount: 0.00,
            tax: 0.00,
            grandTotal: prospect?.overAllBudget,
            prospect: prospect
        }
        navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });
    }

    
    const enquiryStatusoptions = [
        { value: '1', label: 'Spoke' },
        { value: '2', label: 'Meeting Fixed' },
        { value: '3', label: 'Met' },
        { value: '4', label: 'Closed' },
        { value: '5', label: 'Lost' },
        { value: '6', label: 'Active' },
    ]

    const gridStyle = { minHeight: 550 }
    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    function pageRefresh() {
        ProspectService.GetProspect().then(
            res => {
                //if(currentUser.use )
                console.log(currentUser);
                if (currentUser.userTypeId == 1) {
                    setProspectList(res);
                }
                else {
                    setProspectList(res.filter(p => p.userId == currentUser.id));
                }
            }
        )
    }

    React.useEffect(() => {
        pageRefresh();
    }, []);

    function onDelete(id) {
        if (window.confirm("Are you sure want to delete?")) {
            ProspectService.DeleteProspect(id).then(
                res => {
                    pageRefresh();
                }
            )
        }
    }

    const onEdit = (prospect) => {
        navigate(`${BaseService.NavigateToPath()}/add-prospect`, { state: { prospect: prospect } });
    };

    const getEnquiryStatus = (name) => {
        switch (name) {
            case 1:
                return 'New';
                break;
            case 2:
                return 'Pending';
                break;
            case 3:
                return 'In-Progress';
                break;
            case 4:
                return 'Completed';
                break;
        }
    };

    const addFollowUps = useCallback((followUps) => {    
        console.log(followUps)
        FollowUpService.CreateFollowUp(followUps).then(res => {
            FollowUpService.GetFollowUpsByEnquiryId(followUps?.entityId).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        });

    }, []);
    

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle> <h3>Prospects</h3> </CardTitle>
                                    </Col>
                                    <Col sm="6" >
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data1" && enableFiltering,
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data2",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data2") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Import
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data3",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data3") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Bulk Upload
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data4",
                                                })}
                                                onClick={e => navigate(`${BaseService.NavigateToPath()}/add-prospect`)}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    New Prospect
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={prospectList}
                                    enableFiltering={enableFiltering}
                                    defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >

            <MoadalSlider open={sliderShow} onClick={showForm}  id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>
                <FollowUp dataParentToChild={parentToChild} refresh={addFollowUps} onCancel={showForm} />
            </MoadalSlider>

        </>
    );
}

export default Prospect;