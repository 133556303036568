import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import React, { useState, useRef, useEffect, useCallback } from "react";
import ModalSlider from "../ModalSlider";
import { Link, useLocation } from 'react-router-dom';
import ServiceBookingList from "components/CustomComponents/Services/Service";
import HotelBookingList from "components/CustomComponents/Hotel/HotelBookingList";
import moment, * as moments from 'moment';

export default function HotelDetails(props) {
    window.moment = moment
    const [sliderShow, setSliderShow] = useState(false);
    const [hotelSlider, setHotelSlider] = useState(false);
    const [hotelBookingId, setHotelBookingId] = useState(0);

    const showForm = () => { setSliderShow(!sliderShow) }

    console.log(props.booking);

    const addHotelBooking = useCallback((hotelBooking) => {
        alert(1)
        // console.log(hotelBooking)
        // debugger;
        // if (quotation.hotel == null) {
        //     quotation.hotel = [];
        // }

        // quotation.hotel = [...quotation?.hotel, hotelBooking];
        // setQuotation(JSON.parse(JSON.stringify(quotation)));
        // console.log(quotation)
    }, [props.booking]);

    const onHotelDelete = (e, hotels, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            // QuotationService.DeleteHotel(id).then(res => {
            //     hotels.splice(index, 1);
            //     setQuotation(JSON.parse(JSON.stringify(quotation)));
            // })
        }
    }

    const onHotelEdit = (e, hotel) => {
        console.log(hotel)
        alert(hotel)
        setHotelSlider(true);
        //setHotelBookingId(id)
        // QuotationService.DeleteHotel(id).then(res => {
        //     hotels.splice(index, 1);
        //     setQuotation(JSON.parse(JSON.stringify(quotation)));
        // })        
    }



    return <>
        <div className="custom-card" >
            <div><div style={{ float: 'Left' }} ><h3 className="formheadings">Hotel Details</h3> </div><div style={{ float: 'Right' }}><Link onClick={() => setHotelSlider(true)} >+Add Hotel</Link></div></div>
            <Table className="tablesorter">
                <thead className="text-primary">
                    {props.booking?.bookingHotel?.length > 0 && <tr>
                        <th>#</th>
                        <th>Hotel Type</th>
                        {/* <th>Hotel Name</th> */}
                        <th>Meal Plan</th>
                        <th>Room Type</th>
                        {/* <th>Pax/Room</th> */}
                        <th>Rooms</th>
                        <th >Rate</th>
                        <th >AWEB</th>
                        <th >CWEB</th>
                        <th >CNB</th>
                        <th >Days</th>
                        <th >Total</th>
                        <th ></th>
                    </tr>}
                </thead>
                <tbody>
                    {props.booking?.bookingHotel?.map((hotel, index) => (
                        <>
                            <tr>
                                <td>{index + 1}</td>
                                {/* <td>{hotel?.hotelTypeId || hotel?.hotelTypeId}</td> */}
                                <td><b> {hotel?.hotelTypeName || hotel?.roomPrice?.hotelType}</b>
                                    <br />
                                    {hotel?.hotelName}
                                    <br />
                                    <b>Check-In</b> {moment(hotel?.startDate).format('DD-MM-yyyy')} <b>Out</b> {moment(hotel?.endDate).format('DD-MM-yyyy')}

                                </td>
                                <td>{hotel?.mealPlan || hotel?.roomPrice?.mealPlan}</td>
                                <td>{hotel?.roomType || hotel?.roomPrice?.roomType}</td>
                                <td>{hotel?.pax_Room || hotel?.pax_Room}</td>
                                <td>{hotel?.rooms} x {hotel?.roomsRate}</td>
                                <td>{hotel?.aweb} x {hotel?.awebRate} </td>
                                <td>{hotel?.cweb} x {hotel?.cwebRate}</td>
                                <td>{hotel?.cnb} x {hotel?.cnbRate}</td>
                                <td>{hotel?.days?.length || 1}</td>
                                <td>{((hotel?.cnb && hotel?.cnb * hotel?.cnbRate) + (hotel?.cweb && hotel?.cweb * hotel?.cwebRate) + (hotel?.aweb && hotel?.aweb * hotel?.awebRate) + (hotel?.rooms && hotel?.rooms * hotel?.roomsRate)) * (hotel?.day?.length || 1)}</td>
                                <td>
                                          <Link title="Edit Hotel" onClick={(e) => { onHotelEdit(e, hotel) }}><i class="fa-solid fa-pencil"></i></Link>
                                    &nbsp;<Link title="Remove Hotel" onClick={(e) => { onHotelDelete(e) }}><i class="fa-solid fa-trash"></i></Link>
                                    
                                </td>
                            </tr>
                        </>
                    ))}
                </tbody>
            </Table>

            <ModalSlider open={hotelSlider} width={450} onClick={() => setHotelSlider(false)} className={hotelSlider ? ` showing` : ''}>
                <Card >
                    <CardHeader>
                        <h4 className="title">Add Hotel</h4>
                    </CardHeader>
                    <CardBody>
                        <HotelBookingList QuotationHotelId={hotelBookingId} booking={props?.booking} destination={props?.booking?.destination} addHotelBooking={addHotelBooking} SelectedItinerary={props.booking} onClose={setHotelSlider} />
                    </CardBody>
                </Card>
            </ModalSlider>

        </div>
    </>
}