import { useState } from "react";
import PaxDetails from "components/CustomComponents/Booking/PaxDetails";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle

} from "reactstrap";
import { useFormik } from 'formik';

export default function AddInvoice() {
    const [paxList, setPaxList] = useState([])
    const [entryList, setEntryList] = useState([])
    const addPax = () => {
        setPaxList([...paxList, <PaxDetails />])
        console.log(paxList)
    }
    const addEntry = (e) => {
        console.log(e)
        setEntryList([...entryList, e])
    }
    const invoice = {}
    const formik = useFormik({
        initialValues: invoice,
        // validationSchema: schema,
        onSubmit: function (values) {
            alert(JSON.stringify(values));
            //debugger;
            // SaveMember(values);
            // navigate(`/admin/enquiry`);
        }
    });
    const paymentModes = [
        { value: '1', label: 'PhonePe' },
        { value: '2', label: 'Gpay' },
        { value: '3', label: 'PayTM' },
        { value: '4', label: 'Cash' }
    ]
    const tourPackages = [{
        id: 1,
        name: 'SHIMLA VOLVO TOUR PACKAGE',
        description: 'Package cost does not valid during long weekend, National Holidays, Black Out Dates, X-mas , New Year & season time(From 10th April to 10th July).',
        image: 'https://tripperhub.com/galleryimg/9678811ShimlaIMG.jpg',
        destination: 'SHIMLA',
        price: 1500,
    }, {
        id: 2,
        name: 'Delhi -Kullu- Manali - Delhi',
        description: 'Package cost does not valid during long weekend, National Holidays, Black Out Dates, X-mas , New Year & season time(From 10th April to 10th July).',
        image: 'https://tripperhub.com/galleryimg/324027smc.jpg',
        destination: 'Kullu',
        price: 5999,
    }, {
        id: 3,
        name: 'Delhi -Kullu-Manali-Delhi',
        description: 'Package cost does not valid during long weekend, National Holidays, Black Out Dates, X-mas , New Year & season time(From 10th April to 10th July).',
        image: 'https://tripperhub.com/galleryimg/324027smc.jpg',
        destination: 'Delhi',
        price: 1500,
    }];

    const fgstyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
    const spanStyle = { display: "inline-block", marginLeft: "2.5em", width: "100px" }
    return <>
        <div className="content ">
            <Card style={{ padding: '1.5em' }}>
                <CardHeader>
                    <h3 className="title new">New Booking</h3>

                </CardHeader>
                <CardBody>
                    <Form onSubmit={formik.handleSubmit} className="form">
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div style={{ display: 'flex', flexDirection: "column", width: '400px' }}>
                                <FormGroup style={fgstyle} >
                                    <label >
                                        Payment Mode*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="paymentMode"
                                        placeholder="First Name"
                                        type="select"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                        {paymentModes.map(payment => {
                                            return <option value={payment.value}>{payment.label}</option>;
                                        })} </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle} >
                                    <label >
                                        Package*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="packageId"
                                        placeholder="First Name"
                                        type="select"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                        {tourPackages.map(tour => {
                                            return <option value={tour.id}>{tour.name}</option>;
                                        })} </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Email*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="email"
                                        placeholder="email"
                                        type="email"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Contact No.*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="contact"
                                        placeholder="Contact Number"
                                        type="text"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle} >
                                    <label >
                                        Billing To
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="billing"
                                        placeholder="Details"
                                        type="textarea"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                        {paymentModes.map(payment => {
                                            return <option value={payment.value}>{payment.label}</option>;
                                        })} </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle} >
                                    <label >
                                        Travel Date
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="travelDate"
                                        placeholder=""
                                        type="date"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "column", width: '400px' }} >
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Invoice No.*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="invoiceId"
                                        placeholder="Invoice Number"
                                        type="text"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Invoice Date*
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="invoiceDate"
                                        placeholder="Invoice Date"
                                        type="date"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Package Cost
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="packageCost"
                                        placeholder="Amount"
                                        type="text"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Advance Amount
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="packageCost"
                                        placeholder="Amount"
                                        type="text"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>

                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Travel Location
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="travelLocation"
                                        placeholder="Location"
                                        type="text"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup style={fgstyle}  >
                                    <label >
                                        Comments
                                    </label>
                                    <Input
                                        style={{ width: '250px' }}
                                        name="settlement"
                                        placeholder="Comments"
                                        type="textarea"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.firstName}
                                    >
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        <h3 style={{ marginTop: '1em' }} className="formheadings">Pricing Details</h3>
                        <Table>
                            <thead>
                                <tr >
                                    <th>Destination</th>
                                    <th style={{ width: "25%" }}>Hotel/Transport</th>
                                    <th>Check-in</th>
                                    <th>Check-out</th>
                                    <th>Cost</th>
                                    <th>Other Charges</th>
                                    <th>GST</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><Input type="text" /></td>
                                    <td><Input type="text" /></td>
                                    <td><Input type="date" /></td>
                                    <td><Input type="date" /></td>
                                    <td><Input type="text" /></td>
                                    <td><Input type="text" /></td>
                                    <td><Input type="text" /></td>
                                    <td onClick={addEntry}><i style={{ cursor: "pointer" }} className="fa fa-plus"></i></td>
                                </tr>
                                {entryList.map(((entry) => {
                                    return <tr>
                                        <td><Input type="text" /></td>
                                        <td><Input type="text" /></td>
                                        <td><Input type="date" /></td>
                                        <td><Input type="date" /></td>
                                        <td><Input type="text" /></td>
                                        <td><Input type="text" /></td>
                                        <td><Input type="text" /></td>
                                        <td onClick={addEntry}><i style={{ cursor: "pointer" }} className="fa fa-plus"></i></td>
                                    </tr>
                                }))}

                            </tbody>
                        </Table>

                        <div style={{ textAlign: 'right', width: '100%' }}>
                            <p>Subtotal  <span style={spanStyle}> 12,000.00 </span></p>
                            <p>Discount  <span style={spanStyle}> 2,000.00 </span></p>
                            <p>GST <span style={spanStyle}> 720.00 </span></p>
                            <p>Other Charges <span style={spanStyle}> 0.00</span></p>

                            <h6 style={{ paddingTop: '1em', }}>Grand Total <span style={spanStyle}> 11,280.00</span></h6>

                        </div>

                        {/* <h3 style={{ marginTop: '1em' }} className="formheadings">Pax Details</h3> */}
                        <div className="outsiderdiv" style={{ borderTop: "1px solid #aaa", marginTop:"1em" }}>
                            <PaxDetails onClick={addPax} />

                            {paxList.map((element) => {
                                return <PaxDetails onClick={addPax} />
                            })}
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>






    </>

}