import { React, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { GetAllLeads, createLead, SendGetRequest } from '../services/user.service'
import { userState } from '../store/UserStore'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import PackageCard from "components/CustomComponents/PackageCard";
import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    Alert,
    Nav,
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { enquiryState } from './../store/EnquiryState'
import { format, parseISO } from 'date-fns'
import PackageService from "services/package.service";
import BaseService from "services/base.service";

function Package() {
    const navigate = useNavigate();
    const [repos, setRepos] = useState([]);
    const editorRef = useRef(null);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [packages, setPackages] = useState([]);


    useEffect(() => {
        // EnquiryService.GetEnquiries().then(
        //     res => setEnquiryList(res)
        // );
        PackageService.GetPackages()
            .then(res => {
                setPackages(res)
            })
    }, []);

    const tourPackage = {
        name: 'SHIMLA VOLVO TOUR PACKAGE',
        description: 'Package cost does not valid during long weekend, National Holidays, Black Out Dates, X-mas , New Year & season time(From 10th April to 10th July).',
        image: '',
        destination: '',
        price: 1500,
    };

    

    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
        // email: yup.string().required(),
        // phone: yup.string().min(3).max(40).required(),
        // address: yup.string().min(3).max(40).required(),
        // city: yup.string().min(3).max(40).required(),
        // country: yup.string().min(3).max(40).required(),
        // pinCode: yup.string().min(3).max(40).required(),
        // aboutMe: yup.string().min(3).max(40).required(),
        // faceBook: yup.string().min(3).max(40).required(),
        // tweeter: yup.string().min(3).max(40).required(),
        // gmail: yup.string().min(3).max(40).required(),
        // picture: yup.string().min(3).max(40).required(),
        // company: yup.string().min(3).max(40).required(),
        // title: yup.string().min(3).max(40).required(),
    });

    const savePackage = () => { };

    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/enquiry`);
    }
    let newPackage = {
        id: 0,
        name: '',
        description: '',
        coverPhoto: '',
        destination: '',
        duration: '',
        itenary: [
            { day: 1, hotel: 't', attraction: '' },
            { day: 2, hotel: '', attraction: '' },
            { day: 3, hotel: '', attraction: '' },
        ]
    }
    const packageOptions = [
        { value: '1', label: 'Manili' },
        { value: '2', label: 'Shimla' },
        { value: '3', label: 'Palampur' },
        { value: '4', label: 'Dharamshala' },
    ]

    const formik = useFormik({
        initialValues: tourPackage,
        validationSchema: schema,
        onSubmit: function (values) {
            //debugger;
            savePackage(values);
            navigate(`${BaseService.NavigateToPath()}/enquiry`);
        }
    });

    const downloadHandler = (e) => {

    }

    const viewHandler = (e, pkg) => {
        e.preventDefault();
        //alert(pkg);
        navigate(`${BaseService.NavigateToPath()}/add-package`, { state: { 'pkg': pkg } });
    }

    const sendHandler = (e, pkg) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { pkg: pkg } });
    }
    const addPackageHandler = () => { navigate(`${BaseService.NavigateToPath()}/add-package`); }

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1em 1em',
        marginBottom: '1.5em',
        border: '2px solid var(--info)',
        borderRadius: '10px',
        backgroundColor: 'var(--sky)'
    }


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>

                            <CardHeader style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', padding: '1em 1.5em' }}>
                                <CardTitle tag="h4">Package Managment</CardTitle> 
                                
                                <button onClick={addPackageHandler} className="btn button">Create New</button>
                            </CardHeader>
                            <CardBody>
                                {packages.map((pkg) => (
                                    // <PackageCard
                                    //     name={pkg.packageName}
                                    //     description={pkg.overView}
                                    //     price={pkg.price}
                                    //     destination={pkg.destination}
                                    //     state={pkg}
                                    //     image={'http://amitlaptop1:5000/api/app-images/'+ pkg.coverImages}
                                    //     onSend={(pkg)=>sendHandler(pkg)}
                                    //     onView={(pkg)=>viewHandler(pkg)}
                                    //     onDownload={(pkg)=>downloadHandler(pkg)}
                                    //     itinerary={pkg.itinerary}
                                    // />
                                    <div>
                                        <div style={divStyle} color="info alert-div" >
                                            <div className="img-text">
                                                <img className="pkgimg" width="200" height="100" src={BaseService.API_URL + pkg.coverImages} />
                                                <div className="titledesc"><h3 className="pkgheading">{pkg.packageName}</h3> <p>{pkg.overView.substring(0, 180) + "..."}</p></div>
                                            </div>
                                            <div className="pkg-itenary">
                                                <h3 className="pkgheading"><i className="tim-icons icon-square-pin" style={{ marginTop: "-.25em", marginRight: ".25em" }}></i> {pkg.destination}</h3>
                                                {/* Itenary Here */}
                                                <div style={{ whiteSpace: "pre" }}>
                                                    <h4 style={{ fontSize: '14px', fontWeight: '300' }}>
                                                        {pkg.itinerary}
                                                    </h4>
                                                </div>
                                            </div>
                                            {/* <div className="dest">{props.destination}</div> */}
                                            <div className="price"><h4>Rs. {pkg.price}.00</h4></div>
                                            <div className="btns">
                                                <button onClick={(e) => viewHandler(e, pkg)} className="btn">Update Package</button>
                                                <button onClick={(e) => sendHandler(e, pkg)} className="btn btn-primary">Send Proposal</button>
                                                <button onClick={downloadHandler} className="btn">Download</button>
                                            </div>
                                        </div></div>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default Package;
