import axios, * as others from 'axios';

import BaseService from './base.service'

const API_URL =  BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});

async function GetCustomerEnquiries() {
  const response = await client.get(`customer/GetCustomer`);
  console.info(response.data);
  return response.data;
}

async function GetCustomer() {
  const response = await axios.get(API_URL + `customer/GetCustomer`);
  console.info(response.data);
  return response.data;
}

async function CreateCustomer(customer) {
  //alert(JSON.stringify(customer))
  const response = await axios.post(API_URL + `customer/AddCustomer`, customer);
  return response.data;
}

async function DeleteCustomer(CustomerId) {
  const response = await axios.delete(API_URL + `customer/DeleteCustomer?CustomerId=${CustomerId}`);
  return response.data;
}

const CustomerService = {
    GetCustomer , 
    CreateCustomer,
    DeleteCustomer,
    GetCustomerEnquiries
}

export default CustomerService;