import { Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap"
import { useState, useRef, useCallback, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MoadalSlider from "components/CustomComponents/ModalSlider";
import HotelMiniView from "components/CustomComponents/Hotel/HotelMiniView";
import Dropzone from "components/Common/Dropzone";
import { components } from "react-select";
import { backgroundColors } from "contexts/BackgroundColorContext";
//import 'grapesjs-preset-webpage';

import { GrapesjsReact } from "grapesjs-react";
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import { customFonts, canvas, customCss } from "./EditorConfig";
import { Editor } from "@tinymce/tinymce-react";

//import css from '../assets/css/enqform.css'

export default function Itinerary(props) {
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }
    const [dragedHotel, setDragedHotel] = useState({});
    const [dragedActivity, setDragedActivity] = useState({});

    const [assets, setAssets] = useState([1]);
    const [editor, setEditor] = useState(null);

    let currentPackage = {
        id: 0,
        name: 'Shimla Volvo Tour',
        description: 'This package is very good.',
        coverPhoto: 'https://images.cnbctv18.com/wp-content/uploads/2022/08/Shimla-shutterstock-1019x573.jpg?impolicy=website&width=617&height=264',
        destination: 'Shimla',
        duration: '2D/3N',
        itenary: [
            { day: 1, hotel: 'Shimla Guest House', attraction: 'Roller Coaster' },

        ]
    }
    const cardheaderstyles = {
        display: "flex",
        background: `url(${currentPackage.coverPhoto}), hsla(245, 100%, 5%, 0.5)`,
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundPosition: 'right top',
        alignItems: "flex-end",
        justifyContent: 'space-between',
        padding: '2em 1.5em',
        height: '180px',
        width: 'auto'
    }

    const Hotels = [
        { id: 1, Name: "Hotel 1", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 2, Name: "Hotel 2", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel3.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 3, Name: "Hotel 3", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel2.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 4, Name: "Hotel 4", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Hotel ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" }
    ]
    const Attractions = [
        { id: 1, Name: "Attraction 1", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 2, Name: "Attraction 2", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel3.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 3, Name: "Attraction 3", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel2.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 4, Name: "Attraction 4", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+123456789", Destination: "Himachal" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" },
        { id: 5, Name: "Attraction ABC ", img: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg", Contact: "+55 9854672135", Destination: "Shimla" }
    ]

    useEffect(() => {
        if (assets.length) {
            setEditor(
                grapesjs.init({
                    // Indicate where to init the editor. You can also pass an HTMLElement
                    container: '#gjs',
                    // Get the content for the canvas directly from the element
                    // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
                    fromElement: true,
                    // Size of the editor
                    height: '600px',
                    width: 'auto',
                    // Disable the storage manager for the moment
                    storageManager: false,
                    // Avoid any default panel
                    panels: { defaults: [] },
                    canvas: canvas
                })
            )
        }
    }, [assets])

    useEffect(() => {
        if (editor) {
            const props = editor.StyleManager.getProperty('typography', 'font-family');
            props.set("option", customFonts);
            const css = editor.Css;
            css.addRules(customCss.btn)

        }
    });

    function showHtml() {
        var html = editor.getHtml();
        //alert(html);
        const cm = editor.Commands;
        cm.run('preview');

    }

    
    function onEdit() {        
        const cm = editor.Commands;
        cm.run('preview');

    }
    //editor.setComponents('<div>Test</div>')
    return <>
        <div className="content">
            <div>
                <div id="gjs">
                    {/* {
                        Hotels.map((Hotel) => {
                            return <div className="hotelmini" draggable="false" >
                                <div draggable="false"> <img draggable="false" style={{ objectFit: "cover" }} width="100" height="100%" src={Hotel.img}></img> </div>
                                <div draggable="false">
                                    <label style={{ fontWeight: "600" }}> {Hotel.Name}</label>
                                    <label>{Hotel.Contact}</label>
                                    <label>{Hotel.Destination}</label>
                                    <label>{Hotel.Destination}</label>
                                </div>
                            </div>
                        })
                    } */}

                    <div>
                        <section>
                            <div>
                                <img src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1174&q=80"
                                    alt=""/>
                            </div>
                            <div>
                                <h3>Dear Ankur Gupta</h3>
                                <h3>Greetings from Tripper Hub.</h3>
                                <p>Our sales team has put up this Quote regarding your upcoming trip. Please go through it and let us know
                                    if you would like any cahnges in any if the provided services. Contact details are provided at the end.
                                </p>
                            </div>
                            <hr />
                                <div class="wrapper">
                                    <div>
                                        <h4>Destination</h4>
                                        <p>Himachal Pradesh</p>
                                    </div>
                                    <div>
                                        <h4>Start Date</h4>
                                        <p>09 February, 2023</p>
                                    </div>
                                    <div>
                                        <h4>Duration</h4>
                                        <p>06 Days</p>
                                    </div>
                                    <div>
                                        <h4>Pax</h4>
                                        <p>02 Adults</p>
                                    </div>
                                    <div>
                                        <h4>Trip ID</h4>
                                        <p>10447</p>
                                    </div>
                                </div>
                                <h4>Price(INR)</h4>
                                <p>10,500 /-</p>
                                <div>
                                    <h2>Hotels</h2>
                                    <div class="hoteldiv">
                                        <p><span>1st</span> Night at <b>Delhi</b></p>
                                        <h2>Delhi to Manali Volvo</h2>
                                        <div class="wrapper">
                                            <div>
                                                <h4>Rooms</h4>
                                                <p>1 Deluxe</p>
                                                <p>2 Pax</p>
                                            </div>
                                            <div>
                                                <h4>Meal Plan</h4>
                                                <p>Dinner + Breakfast</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hoteldiv">
                                        <p><span>1st</span><span>2nd</span> Night at <b>Delhi</b></p>
                                        <h2>Delhi to Manali Volvo</h2>
                                        <div class="wrapper">
                                            <div>
                                                <h4>Rooms</h4>
                                                <p>1 Deluxe</p>
                                                <p>2 Pax</p>
                                            </div>
                                            <div>
                                                <h4>Meal Plan</h4>
                                                <p>Dinner + Breakfast</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hoteldiv">
                                        <p><span>1st</span> Night at <b>Delhi</b></p>
                                        <h2>Delhi to Manali Volvo</h2>
                                        <div class="wrapper">
                                            <div>
                                                <h4>Rooms</h4>
                                                <p>1 Deluxe</p>
                                                <p>2 Pax</p>
                                            </div>
                                            <div>
                                                <h4>Meal Plan</h4>
                                                <p>Dinner + Breakfast</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2>Daywise Schedule</h2>
                                    <div class="daydiv">
                                        <div class="block">
                                            Day <span>1</span>
                                        </div>
                                        <div>
                                            <h3>Thursday 9th February, 2023
                                            </h3>
                                            <h2>Delhi to Manali - By Volvo Overnight Journey</h2>
                                            <p>Catch the Volvo bus from Delhi to Manali in the evening around 05:30 P.M, overnight journey in
                                                the Volvo Bus.</p>
                                        </div>
                                    </div>
                                </div>
                        </section>
                    </div>
                </div>

                <button onClick={() => showHtml()}>Test</button> 
                <button onClick={() => onEdit()}>Edit</button>
            </div>
        </div>
    </>
}