import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle

} from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BookingService from "services/booking.service";
import { useNavigate } from "react-router-dom";
import BookingCard from "components/CustomComponents/Booking/BookingCard"
import BaseService from '../services/base.service'
export default function AllBookings() {
    const location = useLocation()
    const navigate = useNavigate();
    const setCurrentPage = (e) => {
        e.preventDefault();
        navigate(`/${location.pathname.split('/')[1]}/booking-details`);

    };
    const [bookings, setBookings] = useState([])
    useEffect(() => {
        const booking = BookingService.GetBookings().then(res => {
            setBookings(res);
            console.info(booking);
            //alert(booking)
        });
    }, []);


    return <div className="content">
        <Card>
            <CardHeader>
                <h3 className="formheadings">Bookings</h3>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Customer Name</th>
                            <th>Pax</th>
                            <th>Travel Date</th>
                            <th>Travel End Date</th>
                            <th>Destination</th>
                            <th>Total Cost</th>
                            <th>Collection</th>
                            <th>Remaining</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bookings.map((booking) => {
                                return <tr>
                                    <td>{booking?.id}</td>
                                    <td>{booking?.name}</td>
                                    <td><i className="fas fa-male"></i> {booking?.adult} <i className="fas fa-child"></i> {booking?.kids} <i className="fas fa-baby"></i> {booking?.infant}</td>
                                    <td>{booking?.tripStartDate}</td>
                                    <td>{booking?.tripEndDate}</td>
                                    <td>{booking?.destination}</td>
                                    <td>{booking?.price}</td>
                                    <td>{booking?.payments?.reduce((n, { collection }) => n + collection, 0)}</td>
                                    <td>{booking?.price - booking?.payments?.reduce((n, { collection }) => n + collection, 0)}</td>
                                    <td>{booking?.status}</td>
                                    <td> <Link
                                        to={`${BaseService.NavigateToPath()}/booking-details`}
                                        state={{ booking }}
                                        title="view"
                                    ><i className="fas fa-eye"></i></Link> <button title="cancel" onClick={setCurrentPage}><i className="fas fa-trash-o"></i></button></td>
                                </tr>

                            })}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </div >
}