import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Nav,
    NavLink
} from "reactstrap";
import HotelService from "services/hotel.service";
import { Typeahead } from "react-bootstrap-typeahead";
import MasterService from "services/master.service";
import AttractionService from "services/attraction.service";
import { useFormik } from 'formik';
import * as yup from 'yup';

function ServiceBookingList(props) {
    const [destination, setDestination] = useState([])
    const [attractions, setAttractions] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState([])
    const [selectedActivity, setSelectedActivity] = useState([])
    const [fromSelectedDestination, setFromSelectedDestination] = useState([])
    const [toSelectedDestination, setToSelectedDestination] = useState([])
    const [activity, setActivity] = useState(
        {
            "id": 0,
            "quotationId": 0,
            "activityId": 0,
            "activityName": 0,
            "fromPlace": 0,
            "quantity": 0,
            "rate": 0,
            "proposeRate": 0,
            "fromPlaceId": 0,
            'description': '',
            'toDestination': '',
            'hotel': [],
            'transport': [],
            'pics':''
        });

    useEffect(() => {        
        MasterService.GetCities().then(res => {
            setDestination(res)
        });

        AttractionService.GetAttractions().then(
            res => {
                setAttractions(res);
            }
        );
    }, [])


    const schema = yup.object().shape({
        //id: yup.string().min(3).max(40).required(),
        //name: yup.string().min(3).max(40).required("Name is required"),
        //phone: yup.string().min(10).required("Contact Number is required"),
        //email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    const formik = useFormik({
        initialValues: activity,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values, { resetForm }) {
            values.activityName = selectedActivity[0]?.name;
            values.activityId = selectedActivity[0]?.id;
            values.fromPlace = fromSelectedDestination[0].name;
            values.fromPlaceId = fromSelectedDestination[0]?.id;
            values.toPlaceId = toSelectedDestination[0]?.id;
            values.toPlace = toSelectedDestination[0]?.name;
            values.pics= selectedActivity[0]?.images;
            props.addItinerary(JSON.parse(JSON.stringify(values)));
            props.onClose(false);
            resetForm({ values: '' })
            setSelectedActivity([]);
            setFromSelectedDestination([]);
            setToSelectedDestination([]);
            values.description = '';
        }
    });


    const OnSelectName = (name) => {        
        formik.values.description = name[0]?.description;
        setSelectedActivity(name);
    }

    const OnSelectFrom = (destination) => {
        setFromSelectedDestination(destination);
        AttractionService.GetAttractions().then(
            res => {
                setAttractions(res.filter(a => a.fromDestination == destination[0]?.name));
            }
        );
    }

    const OnSelectTo = (destination) => {
        setToSelectedDestination(destination);
        AttractionService.GetAttractions().then(
            res => {
                setAttractions(res.filter(a => a.fromDestination == fromSelectedDestination[0]?.name && a.toDestination == destination[0]?.name));
            }
        );
    }


    return <div >
        <Form onSubmit={formik.handleSubmit} className="form">
            <Row>
                <Col md="12">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <label>From</label>
                                <Typeahead
                                    id="fromDestination"
                                    labelKey={(option) => `${option.name}`}
                                    onChange={OnSelectFrom}
                                    options={destination}
                                    placeholder="Select Destination"
                                    selected={fromSelectedDestination}
                                    clearButton
                                ></Typeahead>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label>To</label>
                                <Typeahead
                                    id="toDestination"
                                    labelKey={(option) => `${option.name}`}
                                    onChange={OnSelectTo}
                                    options={destination}
                                    placeholder="Select Destination"
                                    selected={toSelectedDestination}
                                    clearButton
                                ></Typeahead>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label>Name</label>
                                <Typeahead
                                    id="name"
                                    labelKey={(option) => `${option.name}`}
                                    onChange={OnSelectName}
                                    options={attractions}
                                    placeholder="Select Attraction"
                                    selected={selectedActivity}
                                    clearButton
                                ></Typeahead>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label>Description</label>
                                <Input
                                    name="description"
                                    type="textarea"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ marginTop: "2em" }}>
                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                    Save
                </Button>
                <Button onClick={() => { props.onClose(false) }} className="btn-fill" color="secondary">
                    Cancel
                </Button>
            </div>
        </Form>
    </div>
}

export default ServiceBookingList;