import axios, * as others from 'axios';
import BaseService from './base.service'
const API_URL = BaseService.API_URL;

const client = axios.create({
    baseURL: API_URL
});

const bookings = [
    {
        id: 1,
        bookingDate: "2 July 23",
        userId: 2,
        package: { id: 10, name: "Shimla Volvo", type: "Family Tour", destination: "Shimla", cost: 1000000 },
        proposal: { id: 10, name: "Shimla Volvo", type: "Family Tour", destination: "Shimla", cost: 1000000 },
        statusId: 1,
        startDate: "20/12/2023",
        endDate: "24/12/2023",
        status: "In Progress",
        client: { id: 1, name: "Vidhu" },
        payments: [{
            id: 1,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 10000,
            remaining: 0,
        }, {
            id: 2,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }, {
            id: 3,
            transactionId: '16445FG',
            description: "3rd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }],
        vendorPayments: [
            {
                id: 1,
                name: 'Vidhu tours and Travels',
                addedBy: 'Username',
                description: "payment for Volvo",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            },
            {
                id: 2,
                name: "Hotel ABC",
                addedBy: 'Username',
                description: "Hotel Rooms",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            }
        ],
        transport: [{
            vehicletype: "bus", agentName: "ABC Tours"
        }, {
            vehicletype: "car", agentName: "DEF Tours"
        }],
        hotel: [{
            hotelType: "bus", agentName: "ABC Tours"
        }, {
            hotelType: "car", agentName: "DEF Tours"
        }],
        tasks: [{
            id: 0, name: "Book Bus", userId: 'user', status: "pending"
        }],
        pax: [{ id: 1, name: "Mahendra Kumar", age: 44, gender: "male", documents: [] }, { id: 2, name: "Vidhu Shekhar", age: 44, gender: "male", documents: [] }]
    }, {
        id: 2,
        bookingDate: "2 July 23",
        userId: 2,
        package: { id: 10, name: "Shimla Volvo", type: "Family Tour", destination: "Shimla", cost: 1000000 },
        statusId: 1,
        status: "New Booking",
        client: { id: 1, name: "Vidhu Shekhar", email: "twrvidhu18@gmail.com", contact: 9568235684, },
        payments: [{
            id: 1,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }, {
            id: 2,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }, {
            id: 3,
            transactionId: '16445FG',
            description: "3rd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }],
        vendorPayments: [
            {
                id: 1,
                name: 'Vidhu tours and Travels',
                addedBy: 'Username',
                description: "payment for Volvo",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            },
            {
                id: 2,
                name: "Hotel ABC",
                addedBy: 'Username',
                description: "Hotel Rooms",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            }
        ],
        transport: [{
            vehicletype: "bus", agentName: "ABC Tours"
        }, {
            vehicletype: "car", agentName: "DEF Tours"
        }],
        hotel: [{
            hotelType: "bus", agentName: "ABC Tours"
        }, {
            hotelType: "car", agentName: "DEF Tours"
        }],
        tasks: [{
            id: 0, name: "Book Bus", userId: 'user', status: "pending"
        }],
        pax: [{ id: 1, name: "Mahendra Kumar", age: 44, gender: "male", documents: [], ageGroup: "adult" }, { id: 2, name: "Vidhu Shekhar", age: 44, gender: "male", documents: [], ageGroup: "adult" }]
    }, {
        id: 3,
        bookingDate: "2 July 23",
        userId: 2,
        package: { id: 10, name: "Shimla Volvo", type: "Family Tour", destination: "Shimla", cost: 1000000 },
        statusId: 1,
        client: { id: 1, name: "TeST" },
        payments: [{
            id: 1,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }, {
            id: 2,
            transactionId: '16445FG',
            description: "2nd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }, {
            id: 3,
            transactionId: '16445FG',
            description: "3rd Payment",
            paymentMode: "cash",
            date: "7 Jul 23",
            cost: 420000,
            collection: 15000,
            remaining: 0,
        }],
        vendorPayments: [
            {
                id: 1,
                name: 'Vidhu tours and Travels',
                addedBy: 'Username',
                description: "payment for Volvo",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            },
            {
                id: 2,
                name: "Hotel ABC",
                addedBy: 'Username',
                description: "Hotel Rooms",
                date: "7 Jul 23",
                cost: 420000,
                collection: 15000,
                remaining: 0,

            }
        ],
        transport: [{
            vehicletype: "bus", agentName: "ABC Tours"
        }, {
            vehicletype: "car", agentName: "DEF Tours"
        }],
        hotel: [{
            hotelType: "bus", agentName: "ABC Tours"
        }, {
            hotelType: "car", agentName: "DEF Tours"
        }],
        tasks: [{
            id: 0, name: "Book Bus", userId: 'user', status: "pending"
        }],
        pax: [{ id: 1, name: "Mahendra Kumar", age: 44, gender: "male", documents: [] }, { id: 2, name: "Vidhu Shekhar", age: 44, gender: "male", documents: [] }]
    }
]

function GetBookingById1(id) {
    // const response = await client.get(`lead/getLead`);
    // console.info(response.data);
    // return response.data;
    let bookingNew = bookings.filter(b => b.bookingId == id)
    return bookingNew
}


async function GetBookingByIdAndQuotationId(id, quotationId, hotelOptionId) {
    const response = await client.get(`booking/GetBookingByIdAndQuotationId?id=${id}&qid=${quotationId}&hotelOptionId=${hotelOptionId}`);
    console.info(response.data);
    return response.data;
}

async function GetBookingById(id) {
    const response = await client.get(`booking/GetBookingById?id=${id}`);
    console.info(response.data);
    return response.data;
}

async function GetBookings() {
    const response = await client.get(`booking/getbooking`);
    console.info(response.data);
    return response.data;
}

async function CreateBooking(booking) {
    const response = await client.post(`booking/AddBooking`, booking)
        .catch(err => console.log(err));
    console.info(response.data);
    console.info(booking);
    return response.data;
}

const BookingService = {
    GetBookings,
    GetBookingById,
    CreateBooking,
    GetBookingByIdAndQuotationId
}
export default BookingService;