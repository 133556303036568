import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import { useFormik } from 'formik';
import Payment from "./Payment";
import ModalSlider from "../ModalSlider";
import { useState } from "react";
import { indexOf } from "underscore";
import { Link } from "react-router-dom";
export default function PaymentDetails(props) {

    const [installments, setInstallments] = useState([{
        id: 1,
        transactionId: '12345FG',
        description: "Advance Payment",
        paymentMode: "PayTm",
        date: "4 Jul 23",
        cost: props.payments?.price + (props.payments?.price * 5/100),
        collection: 1500,
    }]);

    const newInstallment = {
        id: 0,
        transactionId: '',
        description: "",
        paymentMode: "",
        date: "",
        cost: props.payments?.price + (props.payments?.price * 5/100),
        collection: 0,
        remaining: 0,
    }

    const payments = props.payments;
    const pkg = props.package;
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }

    const saveHandler = (installment) => {
        installments.push(installment)
    }
    let gst = 5

    const formik = useFormik({
        initialValues: newInstallment,
        // validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            //alert(JSON.stringify(values))
            saveHandler(values)
            showForm();
        }
    })

    const OnDelete = (index) => {
        //alert(index)
        if (window.confirm("Are you sure want to delete?")) {
            //setInstallments(installments.splice(index, 1));
            installments.splice(index, 1)
            setInstallments(JSON.parse(JSON.stringify(installments)));
        }

    }

    return <>
        <div className="custom-card" style={{ borderBottom: 'none' }}>
            <div style={{ marginTop: "1em", borderRadius: '0', padding: ".25em 0" }}>
                <Card style={{ marginBottom: '0' }}>
                    <CardBody className="bookingdetails" style={{ padding: "0" }}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div ><p>Tour Cost: <span>{props.payments?.price}</span></p></div>
                            <div ><p>GST: <span>{5}%</span></p></div>
                            <div ><p>Total Cost: <span>{props.payments?.price + (props.payments?.price * 5/100)}</span></p></div>

                        </div>
                        <div style={{ display: 'flex' }}>
                            <div ><p>Advance: <span>20000.00</span> </p></div>
                            <div ><p>Settlement: <span>58000.00</span> </p></div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <hr style={{ margin: ".25em 0" }} />

            <h3 style={{ borderBottom: "1px solid #aaa", padding: ".25em 0", marginTop: "1.5em" }} className="formheadings">Installments</h3>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Transaction ID</th>
                        <th>Description</th>
                        <th>Payment Mode</th>
                        <th>Date</th>
                        <th>Cost</th>
                        <th>Collection</th>
                        <th>Remaining</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {installments?.map((inst, i) => {
                        return <tr>
                            <td>{inst.id || i}</td>
                            <td>{inst.transactionId}</td>
                            <td>{inst.description}</td>
                            <td>{inst.paymentMode}</td>
                            <td>{inst.date}</td>
                            <td>{inst.cost}</td>
                            <td>{inst.collection}</td>
                            <td>{inst.cost - inst.collection}</td>
                            <td>
                                {/* <button><i className="tim-icons icon-pencil" /></button> */}
                                <button onClick={() => OnDelete(i)}><i className="far fa-trash-o"></i></button>
                                {/* <button><i className="far fa-comment-dots"></i></button> */}
                            </td>
                        </tr>
                    })}

                </tbody>
            </Table>
            {/* <div style={{ gridColumn: '3/4', justifySelf: 'end', color: 'var(--blue)', cursor: "pointer", marginTop: ".5em" }} onClick={showForm}> + Add Installemnt</div> */}
            <Link onClick={showForm}><i className="far fa-add"></i>Add Installemnt</Link>

            <ModalSlider open={sliderShow} onClick={showForm} id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>

                <Card>
                    <CardHeader>
                        <h3 className="formheadings ">Add New Installment</h3>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={formik.handleSubmit} className="form">
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label>Transaction ID</label>
                                        <Input
                                            type="text"
                                            placeholder="Transaction ID"
                                            name="transactionId"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.transactionId}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Payment Mode</label>
                                        <Input
                                            type="select"
                                            placeholder=""
                                            name="paymentMode"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.paymentMode}>
                                        
                                        <option>Cash</option>
                                        <option>UPI</option>
                                        <option>Bank Transfer</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Date</label>
                                        <Input
                                            type="date"
                                            placeholder=""
                                            name="date"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Collection</label>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            name="collection"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.collection}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Remaining</label>
                                        <Input
                                            type="text"
                                            placeholder="0000.00"
                                            name="remaining"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.remaining}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup >
                                        <label>Comments</label>
                                        <Input
                                            type="textarea"
                                            placeholder="comment"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            name="description">
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    {/* <button type="button" onClick={showForm} style={{ width: "max-content", marginTop: "2em" }} className="btn btn-primary"> Save and Close</button> */}
                                    <Button disabled={!formik.isValid} type="submit" className="btn btn-primary" color="primary">
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </ModalSlider>
        </div>
    </>
}