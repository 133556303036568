import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import UserService from "services/user.service";
import Select from 'react-select'
import ImageUploading from "react-images-uploading";
import "../assets/css/enqform.css"
import { currentUserState } from '../store/UserStore'
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { N1ED } from "@edsdk/n1ed-react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ConfirmationModal,
  Modal,
} from "reactstrap";

import BaseService from 'services/base.service'

const API_URL = BaseService.API_URL;

function UserProfile() {

  const defaltUser = {
    "id": 0,
    "userId": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "address": "",
    "city": "",
    "country": "",
    "pinCode": "",
    "aboutMe": "",
    "faceBook": "",
    "tweeter": "",
    "gmail": "",
    "picture": "",
    "company": "",
    "title": ""
  }

  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(defaltUser);
  const [picture, setPicture] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //Validation for input fieldss
  const schema = yup.object().shape({
    // id: yup.string().min(3).max(40).required(),
    //userId: yup.string().min(3).max(40).required("User Id Required"),
    firstName: yup.string().min(3).required("First Name Required"),
    lastName: yup.string().min(3).required("Last Name Required"),
    email: yup.string().email().required("Email Required"),
    phone: yup.string().min(9).max(11).required("Phone Required"),
    //password: yup.string().min(8).required("Password Required"),
    // address: yup.string().min(3).max(40).required(),
    // city: yup.string().min(3).max(40).required(),
    // country: yup.string().min(3).max(40).required(),
    // pinCode: yup.string().min(3).max(40).required(),
    // aboutMe: yup.string().min(3).max(40).required(),
    // faceBook: yup.string().min(3).max(40).required(),
    // tweeter: yup.string().min(3).max(40).required(),
    // gmail: yup.string().min(3).max(40).required(),
    // picture: yup.string().min(3).max(40).required(),
    // company: yup.string().min(3).max(40).required(),
    // title: yup.string().min(3).max(40).required(),
  });

  React.useEffect(() => {
    if (location.state?.user)
      setUser(location.state?.user);
    setPicture(API_URL + 'app-images' + location.state?.user?.picture);
  }, [user]
  );

  const saveUser = async (userData) => {
    UserService.CreateUser(userData).then(res => {
      navigate(`${BaseService.NavigateToPath()}/users`);
    });

  };

  //cancel
  const resetForm = () => {
    navigate(`${BaseService.NavigateToPath()}/users`);
  }

  const formik = useFormik({
    initialValues: user,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: function (values) {
      saveUser(values);
    }
  })

  const onImgChange = (imageList) => {
    const userTemp = user;
    // data for submit
    // Create an object of formData 
    const formData = new FormData();
    // Update the formData object 
    formData.append("userId", user.id);
    formData.append("formFile", imageList[0].file);
    formData.append("fileName", imageList[0].file.name);

    UserService.UploadImage(formData).then(
      res => {
        //alert(API_URL + 'app-images' + res);
        setPicture(API_URL + 'app-images' + res);
      }
    );

  };


  const getImage = (picture) => {
    console.log(API_URL + `app-images${picture}`)
    if (picture)
      return API_URL + `app-images${picture}`
    else
      return "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card style={{ padding: "1em" }}>
              <CardHeader>
                <h5 className="title">Add/Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={formik.handleSubmit} className="form">
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <label>User Type</label>
                        <Input
                          name="userTypeId"
                          placeholder="User Type Id"
                          type="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.userTypeId}
                        >
                          {'<option value="1">Admin</option>'}
                          <option value="2">User</option>
                          <option value="3">Client</option>
                        </Input>
                        {formik.touched.userTypeId && formik.errors.userTypeId && (
                          <span className='text-red-400'>{formik.errors.userTypeId}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>User Role</label>
                        <Input
                          name="userRoleId"
                          placeholder="User Role"
                          type="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.userRoleId}
                        >
                          <option value="1">Operation</option>
                          <option value="2">Sales</option>
                          <option value="3">Other</option>
                        </Input>
                        {formik.touched.userRoleId && formik.errors.userRoleId && (
                          <span className='text-red-400'>{formik.errors.userRoleId}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                    <FormGroup>
                      <label>User Id</label>
                      <Input
                        name="userId"
                        placeholder="Username"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userId}
                      />
                      {formik.touched.userId && formik.errors.userId && (
                        <span className='text-red-400'>{formik.errors.userId}</span>
                      )}
                    </FormGroup>
                  </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          maxLength={100}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                          <span className='text-red-400'>{formik.errors.firstName}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          maxLength={100}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <span className='text-red-400'>{formik.errors.lastName}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address</label>
                        <Input
                          name="email"
                          placeholder="mike@email.com"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          maxLength={100}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <span className='text-red-400'>{formik.errors.email}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Contact Number</label>
                        <Input
                          id="phone"
                          placeholder="Contact Number"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          maxLength={11}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <span className='text-red-400'>{formik.errors.phone}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Whatsapp Number</label>
                        <Input
                          id="whatsapp"
                          placeholder="Whatsapp Number"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.whatsapp}
                          maxLength={11}
                        />
                        {formik.touched.whatsapp && formik.errors.whatsapp && (
                          <span className='text-red-400'>{formik.errors.whatsapp}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          name="address"
                          defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          maxLength={500}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="3">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          placeholder="City"
                          type="text"
                          id="city"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          maxLength={50}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>State</label>
                        <Input
                          placeholder="State"
                          type="text"
                          id="state"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          maxLength={30}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          placeholder="Country"
                          type="text"
                          id="country"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                          maxLength={30}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input

                          placeholder="ZIP Code"
                          type="number"
                          id="pinCode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pinCode}
                          maxLength={8}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="8">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          name="aboutMe"
                          cols="80"
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.aboutMe}
                        />

                        {/* <N1ED
                          apiKey="2GaECmI1"
                          initialValue="<p>N1ED react demo</p>"
                          onEditorChange={handleEditorChange}
                          autoToolbarLines="2"
                        /> */}

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                        Save
                      </Button>
                      <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={picture}
                    />
                    <h5 className="title">{user?.firstName}  {user?.lastName}</h5>
                  </a>
                  <p className="description">{user.title}</p>
                </div>
                <ImageUploading
                  onChange={onImgChange}
                >
                  {({ imageList, onImageUpload }) => (
                    // write your building UI
                    <div className="imageuploader">
                      <div className="mainBtns">
                        <button className="btn btn-primary mr-1  uploadbtn" onClick={onImageUpload}>Upload Image</button>
                      </div>
                      {imageList.map((image) => (
                        <div className="imagecontainer" key={image.key}>
                          <img src={image.dataURL} />
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
                <Row style={{ display: "flex", justifyContent: "center" }}>

                  {/* <Col md="6">
                    <FormGroup>
                      <label>Username</label>
                      <Input
                        name="userId"
                        placeholder="Username"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userId}
                      />
                      {formik.touched.userId && formik.errors.userId && (
                        <span className='text-red-400'>{formik.errors.userId}</span>
                      )}
                    </FormGroup>
                  </Col> */}
                  <Col md="6">
                    <FormGroup className="my-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <span className="mx-2"> User Name -</span>
                      <span> <b>{formik.values.userId}</b></span>
                    </FormGroup>
                  </Col>
                </Row >
                {/* <Row style={{ display: "flex", justifyContent: "center" }}>
                  <Col md="6">
                    <FormGroup style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <span className="mx-2"> Password -</span>
                      <span> <b>{formik.values.password}</b></span>
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="12" style={{ textAlign: "center" }}>
                    <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="">
                      Edit
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google"  >
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>

            </Card>
          </Col>

        </Row>
      </div>

    </>
  );
}

export default UserProfile;
