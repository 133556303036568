import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import NotificationAlert from "react-notification-alert";
import BaseService from "services/base.service";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, } from "reactstrap";
import UserService from "services/user.service";
import EnquiryService from "../services/enquiry.service";
import MasterService from "../services/master.service"
import ProspectService from '../services/prospect.service'
import { useEffect } from "react";
import PackageService from "services/package.service";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { NUMBER_OF_NIGHT } from "common/utils";
import { NUMBER_OF_ADULTS } from "common/utils";
import { NUMBER_OF_CHILD } from "common/utils";
import { NUMBER_OF_INFANT } from "common/utils";
import { BUDGET_PER_ADULT } from "common/utils";
import { utils } from "common/utils";

function AddProspect() {
    const navigate = useNavigate();
    const notificationAlertRef = React.useRef(null);
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [packages, setPackages] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedEnquiryType, setSelectedEnquiryType] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [enquiryType, setEnquiryType] = useState([]);
    const [proposalList, setProposalList] = useState([]);
    const [expextedClosureDate, setExpextedClosureDate] = useState(new Date());
    const [followupDateAndTime, setFollowupDateAndTime] = useState(new Date());
    const [plannedTravelDate, setPlannedTravelDate] = useState(new Date());
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [enquirySource, setEnquirySource] = useState([]);

    const [prospect, setProspect] = useState({
        "id": 0,
        "name": "",
        "email": "",
        "phone": "",
        "userId": 0,
        "statusId": 0,
        "reminderId": 0,
        "modeId": 0,
        "leadType": "",
        "packageId": "",
        "plannedTravelDate": new Date(),
        "destination": "",
        "products": "",
        "plannedNoOfDays": 0,
        "numberOfAdults": 0,
        "numberOfChild": 0,
        "budgetPerAdults": 0,
        "budgetPerChild": 0,
        "overAllBudget": 0,
        "leadCode": '',
        "numberOfInfant": 0
    });


    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        {
            name: 'package', header: 'Package Name', defaultWidth: 260, render: ({ value, cellProps }) => {
                return value?.packageName
            }
        }
        ,
        {
            name: 'id1',
            header: '',
            sorrting: false,
            defaultWidth: 30,
            //enableColumnFilterContextMenu:false,
            render: ({ data }) => {
                return <div><a href="#" onClick={(e) => onViewPoroposal(data)}><i title="View" className="fas fa-eye" /></a></div>
            }
        }
    ];

    const gridStyle = { minHeight: 600 }
    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
        }
    }

    useEffect(() => {
        EnquiryService.GetEnquirySource().then(res => {
            setEnquirySource(res);
            console.log(res);
        });

        if (location.state?.prospect?.id) {
            const prospectState = location.state?.prospect;
            setProspect(prospectState);
            //setProposalList(prospectState?.proposals);
            setPlannedTravelDate(new Date(prospectState.plannedTravelDate || new Date()));
            setFollowupDateAndTime(new Date(prospectState?.followupDateAndTime || new Date()));
            setExpextedClosureDate(new Date(prospectState?.expextedClosureDate || new Date()));
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                    setSelectedUser(res?.filter(u => u.id == prospectState.userId));
                });

            MasterService.GetDestination().then(res => {
                setDestination(res)
                setSelectedDestination(res.filter(d => d.name == prospectState?.destination))
            });

            MasterService.GetTypes('LeadType').then(res => {
                setEnquiryType(res);
                setSelectedEnquiryType(res?.filter(et => et.id == prospectState.leadType));
            });

            PackageService.GetPackages().then(res => {
                setPackages(res);
                setSelectedPackage(res?.filter(p => p.id == prospectState.packageId));
            });
        }
        else {

            //let packageType = MasterService.GetPackageType();
            setPackages(MasterService.GetTourPackages());

            MasterService.GetTypes('LeadType').then(res => {
                setEnquiryType(res);
            });

            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                });

            MasterService.GetDestination().then(res => {
                setDestination(res)
            }
            );

            MasterService.GetTypes('LeadType').then(res => {
                setEnquiryType(res);
            });

            PackageService.GetPackages().then(res => {
                setPackages(res);
            });
        }
    }, []);

    //submit    
    const saveProspect = (prospectData) => {
        ProspectService.CreateProspect(prospectData).then(res => {
            //navigate(`${BaseService.NavigateToPath()}/prospect`);
            setProspect(JSON.parse(JSON.stringify(res)))
            notify('br', 'The Prospect has been updated successfully!');
        });
    }

    const notify = (place, message) => {
        var color = 2;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };


    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/prospect`);
    }

    //validation Schemas
    const schema = yup.object().shape({
        name: yup.string().min(3).max(40).required("Name is required"),
        phone: yup.string().min(10).required("Contact is required"),
        email: yup.string().email().min(3),

        // numberOfAdults: yup.number().max(NUMBER_OF_ADULTS, `Value should be 0 to ${NUMBER_OF_ADULTS}`).min(0, `Value should be 0 to ${NUMBER_OF_ADULTS}`),
        // numberOfChild: yup.number().max(NUMBER_OF_CHILD, `Value should be 0 to ${NUMBER_OF_CHILD}`).min(0, `Value should be 0 to ${NUMBER_OF_CHILD}`),
        // numberOfInfant: yup.number().max(NUMBER_OF_INFANT, `Value should be 0 to ${NUMBER_OF_INFANT}`).min(0, `Value should be 0 to ${NUMBER_OF_INFANT}`),
        // plannedNoOfDays: yup.number().max(NUMBER_OF_NIGHT, `Value should be 0 to ${NUMBER_OF_NIGHT}`).min(0, `Value should be 0 to ${NUMBER_OF_NIGHT}`),
        // plannedTravelDate: yup.date().required().min(new Date()),
        // budgetPerAdults: yup.number().max(BUDGET_PER_ADULT, `Value should be 0 to ${BUDGET_PER_ADULT}`).min(0, `Value should be 0 to ${BUDGET_PER_ADULT}`),
        // budgetPerChild: yup.number().max(BUDGET_PER_ADULT, `Value should be 0 to ${BUDGET_PER_ADULT}`).min(0, `Value should be 0 to ${BUDGET_PER_ADULT}`),
    });

    // handle selection
    const onEdit = value => {
        //setSelectedUser(value);
        //formik.values.overAllBudget=1000;
        //handleChange}
    }

    const formik = useFormik({
        initialValues: prospect,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.userId = selectedUser[0]?.id;
            values.packageId = selectedPackage[0]?.id;
            values.destination = selectedDestination[0]?.name;
            values.followupDateAndTime = followupDateAndTime;
            values.expextedClosureDate = expextedClosureDate;
            values.plannedTravelDate = plannedTravelDate;
            values.leadType = selectedEnquiryType[0]?.id;
            values.statusId = values.statusId || 1;
            saveProspect(values);
        }
    });



    // const enquiryStatusoptions = [
    //     { value: '1', label: 'New' },
    //     { value: '2', label: 'Pending' },
    //     { value: '3', label: 'In-Progress' },
    //     { value: '4', label: 'Completed' }]

    const onViewPoroposal = (proposal) => {
        //alert(proposal)
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    };

    const handleChangeBudgetFor = (e) => {
        if (e.target.value == 1) {
            let adultsBudgut = formik.values.plannedNoOfDays * (formik.values.numberOfAdults * formik.values.budgetPerAdults);
            let childrenBudgut = formik.values.plannedNoOfDays * (formik.values.numberOfChild * formik.values.budgetPerChild);
            formik.values.overAllBudget = adultsBudgut + childrenBudgut;
        }
    }

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        
        PackageService.GetPackages()
            .then(res => {                                
                setPackages(res.filter(p => p.state == destination[0]?.name))
            });
    }

    function sendProposal(e, prospect) {
        e.preventDefault();
        console.log(prospect);
        var proposal = {
            id: -1,
            proposalDate: '',
            validTillDate: '',
            discountType: "",
            destination: prospect?.destination,
            packageId: prospect?.packageId,
            subject: "Quotation for your trip",
            message: ``,
            leadId: prospect?.leadId,
            prospectId: prospect?.id,
            userId: prospect?.userId,
            name: prospect?.name,
            phone: prospect?.phone,
            email: prospect?.email,
            total: prospect?.overAllBudget,
            discount: 0.00,
            tax: 0.00,
            grandTotal: prospect?.overAllBudget,
            prospect: prospect
        }
        navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });
    }

    return <>
        <div className="content ">
            <NotificationAlert ref={notificationAlertRef} />
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Add/Edit Prospect</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <label>Trip Code</label>
                                        <Input
                                            name="leadCode"
                                            placeholder="Lead Code"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.leadCode}
                                            disabled="true"
                                        />
                                    </Col>
                                    <Col md="5">
                                        <FormGroup>
                                            <label>Name</label>
                                            <Input
                                                name="name"
                                                placeholder="Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <span className='text-red-400'>{formik.errors.name}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Contact</label>
                                            <Input
                                                name="phone"
                                                placeholder="Contact"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                            {formik.touched.phone && formik.errors.phone && (
                                                <span className='text-red-400'>{formik.errors.phone}</span>
                                            )}
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Email</label>
                                            <Input
                                                name="email"
                                                placeholder="email@mail.com"
                                                type="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <span className='text-red-400'>{formik.errors.email}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Lead Type</label>
                                            <Typeahead
                                                clearButton
                                                name="enquiryType"
                                                id="enquiryType"
                                                selected={selectedEnquiryType}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setSelectedEnquiryType}
                                                labelKey={(option) => `${option.name}`}
                                                options={enquiryType} />

                                            {formik.touched.enquiryType && formik.errors.enquiryType && (
                                                <span className='text-red-400'>{formik.errors.enquiryType}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="3">
                                        <FormGroup>
                                            <label>Status
                                            </label>
                                            <Input
                                                name="statusId"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.statusId}
                                            >
                                                {enquiryStatusoptions.map(enquiryStatus => {
                                                    return <option value={enquiryStatus.value}>{enquiryStatus.label}</option>;
                                                })}
                                            </Input>
                                            {formik.touched.enquiryStatus && formik.errors.enquiryStatus && (
                                                <span className='text-red-400'>{formik.errors.enquiryStatus}</span>
                                            )}

                                        </FormGroup>
                                    </Col> */}
                                    <Col md="4">
                                        <FormGroup >
                                            <label>Source</label>
                                            <Input
                                                name="leadSourceId"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.leadSourceId}>
                                                {
                                                    enquirySource.map((source) => (<option value={source?.id} >{source?.name}</option>)
                                                    )
                                                }

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><h5 className="title">Travel Details</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Destination</label>
                                            <Typeahead
                                                id="destination"
                                                labelKey={(option) => `${option.name}`}
                                                onChange={onSelectedDestination}
                                                options={destination}
                                                placeholder="Select Destination"
                                                selected={selectedDestination}
                                                clearButton
                                            ></Typeahead>
                                            {formik.touched.destination && formik.errors.destination && (
                                                <span className='text-red-400'>{formik.errors.destination}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Package</label>
                                            <Typeahead
                                                id="package"
                                                labelKey={(option) => `${option.id} ${option.packageName}`}
                                                onChange={setSelectedPackage}
                                                options={packages}
                                                placeholder="Select Package"
                                                selected={selectedPackage}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Tour Date</label>
                                            <DatePicker
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="plannedTravelDate"
                                                selected={plannedTravelDate}
                                                minDate={new Date()}
                                                onChange={setPlannedTravelDate} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><h5 className="title">Budget</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Nights</label>
                                            <Input
                                                name="plannedNoOfDays"
                                                placeholder="Planned No Of Days"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.plannedNoOfDays}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_NIGHT) }}
                                            />

                                            {formik.touched.plannedNoOfDays && formik.errors.plannedNoOfDays && (
                                                <span className='text-red-400'>{formik.errors.plannedNoOfDays}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Number of Adults</label>
                                            <Input
                                                name="numberOfAdults"
                                                placeholder="Number of Adults"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfAdults}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_ADULTS) }}
                                            />
                                            {formik.touched.numberOfAdults && formik.errors.numberOfAdults && (
                                                <span className='text-red-400'>{formik.errors.numberOfAdults}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget per Adult</label>
                                            <Input
                                                name="budgetPerAdults"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetPerAdults}
                                                onKeyDown={(e) => { utils.preventMinus(e, BUDGET_PER_ADULT) }}
                                            />
                                            {formik.touched.budgetPerAdults && formik.errors.budgetPerAdults && (
                                                <span className='text-red-400'>{formik.errors.budgetPerAdults}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Children (6-12)</label>
                                            <Input
                                                name="numberOfChild"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfChild}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_CHILD) }}
                                            />
                                            {formik.touched.numberOfChild && formik.errors.numberOfChild && (
                                                <span className='text-red-400'>{formik.errors.numberOfChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget per Child</label>
                                            <Input
                                                name="budgetPerChild"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetPerChild}
                                                onKeyDown={(e) => { utils.preventMinus(e, BUDGET_PER_ADULT) }}
                                            />
                                            {formik.touched.budgetPerChild && formik.errors.budgetPerChild && (
                                                <span className='text-red-400'>{formik.errors.budgetPerChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Children (0-6)</label>
                                            <Input
                                                name="numberOfInfant"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfInfant}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_CHILD) }}
                                            />
                                            {formik.touched.numberOfChild && formik.errors.numberOfChild && (
                                                <span className='text-red-400'>{formik.errors.numberOfChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row><Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Over All Budget</label>
                                            <Input
                                                name="overAllBudget"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.overAllBudget}
                                                onKeyDown={(e) => { utils.preventMinus(e, 9999999999) }}
                                            />
                                            {formik.touched.overAllBudget && formik.errors.overAllBudget && (
                                                <span className='text-red-400'>{formik.errors.overAllBudget}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget For</label>
                                            <Input
                                                name="budgetFor"
                                                type="select"
                                                onChange={handleChangeBudgetFor}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetFor}>
                                                <option value="1" >Per Day</option>
                                                <option value="2" selected="true" >Over All</option>
                                            </Input>
                                            {formik.touched.budgetFor && formik.errors.budgetFor && (
                                                <span className='text-red-400'>{formik.errors.budgetFor}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12"><h5 className="title">Prospect Details</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Expexted Closure Date</label>
                                            <DatePicker
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="expextedClosureDate"
                                                selected={expextedClosureDate}
                                                onChange={setExpextedClosureDate}
                                                minDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Probablity</label>
                                            <Input
                                                name="probablity"
                                                placeholder=""
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.probablity}
                                            >
                                                <option value="1">Low</option>
                                                <option value="2">Medium</option>
                                                <option value="3">High</option>
                                            </Input>
                                            {formik.touched.probablity && formik.errors.probablity && (
                                                <span className='text-red-400'>{formik.errors.probablity}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Followup Date & Time</label>
                                            <DatePicker
                                                //showTimeInput
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy & HH:MM'}
                                                name="followupDateAndTime"
                                                selected={followupDateAndTime}
                                                onChange={setFollowupDateAndTime} 
                                                minDate={new Date()}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Executive</label>
                                            <Typeahead
                                                clearButton
                                                id="UserId"
                                                labelKey={(option) => `${option.firstName} ${option.lastName}`}
                                                onChange={setSelectedUser}
                                                options={users}
                                                // multiple
                                                placeholder="Select User"
                                                selected={selectedUser}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><div style={{ marginTop: "2em", }}>
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                        <button onClick={(e) => sendProposal(e, prospect)} className="btn btn-primary"><i className="fas fa-send"></i> Send Proposal</button>
                                    </div></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Proposals</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={proposalList}
                                    pagination
                                    defaultLimit={10}
                                    onRowDoubleClick={onViewPoroposal}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}
export default AddProspect;