import React from "react";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function Setting() {
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader className="mb-5">
                                <h5 className="card-category">Black Table Heading</h5>
                                <CardTitle tag="h3">
                                    Settings
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                Created using Poppins Font Family...
                                {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Setting;
