import React, { useState, useEffect } from "react";
import { json, useLocation, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import EnquiryService from '../services/enquiry.service'
import Select from 'react-select'
import "../assets/css/enqform.css"
import { Field, ErrorMessage } from "formik";
import { Radio, RadioGroup } from 'react-radio-group'
import BaseService from "services/base.service";
import SupplierService from "services/supplier.service";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import ModalSlider from "components/CustomComponents/ModalSlider";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    Nav,
    NavLink,
} from "reactstrap";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from '../store/UserStore'
import UserService from "services/user.service";
import MasterService from "services/master.service";

export default function AddTransport() {
    const navigate = useNavigate();
    const location = useLocation();
    const userList = useRecoilValue(userState);
    const [seasonMaster, setSeasonMaster] = useState([]);

    const [selectedValue, setSelectedValue] = useState([]);
    const [inputValue, setValue] = useState('');
    const [sliderShow1, setSliderShow1] = useState(false);
    const [fromLocation, setfromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");
    const [price, setPrice] = useState("");
    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [supplierDcumentList, setSupplierDcumentList] = useState([]);
    const [supplierPaymentList, setSupplierPaymentList] = useState([]);
    const [supplierContactList, setSupplierContactList] = useState([]);
    const [supplierBankList, setSupplierBankList] = useState([]);
    const [supplier, setSupplier] = useState(1);
    const locationOptions = [{ id: 1, name: "shimla" }, { id: 2, name: "Manali" }, { id: 3, name: "Chail" }]
    const vehicleOptions = [{ id: 1, name: "Innova" }, { id: 2, name: "Dzire" }, { id: 3, name: "Ertiga" }]
    useEffect(() => {
        getUserOption();

        MasterService.GetSeasonMaster().then(
            res => { setSeasonMaster(res); }
        )

        SupplierService.GetSupplierDocuments(supplier).then(
            res => { setSupplierDcumentList(res); }
        )

        SupplierService.GetSupplierPayments(supplier).then(
            res => {
                //alert(JSON.stringify(res)); 
                setSupplierPaymentList(res);
            }
        )

        SupplierService.GetSupplierContacts(supplier).then(


            res => {
                setSupplierContactList(res);
            }
        )

        SupplierService.GetSupplierBankAccounts(supplier).then(
            res => { setSupplierBankList(res); }
        )


    }, []);

    const getUserOption = () => {
        console.info(userList)
        return userList.map(user => {
            return <option value={user.id}>{user.firstName}</option>;
        });
    }
    const gridStyle = { minHeight: 250 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }
    const showForm1 = () => { setSliderShow1(!sliderShow1) }
    let Supplier = {
        "id": 0,
        "name": "",
        "email": "",
        "phone": "",
        "userId": 1,
        "statusId": 1,
        "reminderId": 0,
        "modeId": 0,
        "leadType": "Holiday",
        "packageId": "",
        "plannedTravelDate": "2023-06-10T15:42:09.862Z",
        "destination": "",
        "products": "",
        "plannedNoOfDays": 0,
        "numberOfAdults": 0,
        "numberOfChild": 0,
        "budgetPerAdults": 0,
        "budgetPerChild": 0,
        "overAllBudget": 0,
    };


    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'leadCode', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'startsWith', type: 'string', value: '' },
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'statusId', operator: 'startsWith', type: 'string', value: '' }
    ];


    const columnsContact = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'type', header: 'Type', minWidth: 120 },
        { name: 'contact1', header: 'Contact', minWidth: 300 },
    ];

    const columnsPayment = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'transactionId', header: 'Transaction Id', minWidth: 200 },        
        { name: 'paymentMode', header: 'Mode', defaultWidth: 100 },
        { name: 'paymentDate', header: 'Date', minWidth: 200 },
        { name: 'amount', header: 'Amount', mintWidth: 120 },
        { name: 'description', header: 'Description', minWidth: 200 },

    ];

    const columnsDocument = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'contact1', header: 'Contact', defaultWidth: 100 },
    ];

    const columnsBank = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'Contact', header: 'Contact', defaultWidth: 100 },
    ];

    if (location.state?.enquiry?.id) {
        // console.log(JSON.stringify(location.state));
        // Activity = location.state?.enquiry;

        // if (Activity.leadType !== enqueryType)
        //     setEnqueryType(Activity.leadType);
    }


    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // name: yup.string().min(3).max(40).required("Name is required"),
        // phone: yup.string().min(10).required("Contact Number is required"),
        // email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });



    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/suppliers`);
    }

    const formik = useFormik({
        initialValues: Supplier,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            // saveEnquiry(values);
            SupplierService.CreateSupplier(values)
            navigate(`${BaseService.NavigateToPath()}/suppliers`);
        }
    });
    return (
        <>
            <div className="content ">
                <Form onSubmit={formik.handleSubmit} className="form">
                    <Row>
                        <Col md="8">
                            <Card style={{ padding: "1em" }}>
                                <CardHeader>
                                    <h4 className="title new">Add Transport</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label>TransportTypeId</label>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                >
                                                    <option value="">Transport</option>
                                                    <option value="">Hotel</option>
                                                    <option value="">Driver</option>
                                                    <option value="">Bus</option>
                                                    <option value="">Tour Guide</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>SeasonMasterId</label>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                >
                                                    <option value="">Transport</option>
                                                    <option value="">Hotel</option>
                                                    <option value="">Driver</option>
                                                    <option value="">Bus</option>
                                                    <option value="">Tour Guide</option>
                                                </Input>
                                                {formik.touched.name && formik.errors.name && (
                                                    <span className='text-red-400'>{formik.errors.name}</span>
                                                )}
                                            </FormGroup>
                                        </Col>


                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>From Place</label>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                >
                                                    <option value="">Transport</option>
                                                    <option value="">Hotel</option>
                                                    <option value="">Driver</option>
                                                    <option value="">Bus</option>
                                                    <option value="">Tour Guide</option>
                                                </Input>
                                                {formik.touched.address && formik.errors.address && (
                                                    <span className='text-red-400'>{formik.errors.address}</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label>To Place</label>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    type="select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                >
                                                    <option value="">Transport</option>
                                                    <option value="">Hotel</option>
                                                    <option value="">Driver</option>
                                                    <option value="">Bus</option>
                                                    <option value="">Tour Guide</option>
                                                </Input>
                                                {formik.touched.city && formik.errors.city && (
                                                    <span className='text-red-400'>{formik.errors.city}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Rate</label>
                                                <Input
                                                    name="rate"
                                                    placeholder="Rate"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.rate}
                                                />
                                                {formik.touched.rate && formik.errors.rate && (
                                                    <span className='text-red-400'>{formik.errors.rate}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Distance</label>
                                                <Input
                                                    name="distance"
                                                    placeholder="Distance"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                />
                                                {formik.touched.country && formik.errors.distance && (
                                                    <span className='text-red-400'>{formik.errors.country}</span>
                                                )}
                                            </FormGroup>
                                        </Col>                                       
                                    </Row>                                    
                                    <Row>
                                        <Col>
                                            <div style={{ marginTop: "2em", }}>
                                                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                                    Save
                                                </Button>                                                
                                                <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card style={{ padding: "1em" }} className="card-user">
                                <CardHeader>
                                    <h5 className="title">Services</h5>
                                </CardHeader>
                                <CardBody>
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={{ minHeight: 550 }}
                                        rowStyle={rowStyle}
                                        columns={columnsPayment}
                                        showZebraRows={true}
                                        dataSource={supplierPaymentList}
                                        enableFiltering={enableFiltering}
                                        defaultFilterValue={defaultFilterValue}
                                        pagination
                                        defaultLimit={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>

                <ModalSlider width={500} onClick={showForm1} className={sliderShow1 ? ` showing` : ''}>
                    <div className="content">
                        <Form onSubmit={formik.handleSubmit}>
                            <Card style={{ padding: "1em 2em" }} className="followupcard">
                                <Row>
                                    <Col style={{ marginBottom: "1em" }}><h5 className="title">Add New Room</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>From</label>
                                            <Typeahead
                                                id="fromLocation"
                                                allowNew
                                                placeholder="Pickup Location"
                                                value={fromLocation}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setfromLocation}
                                                labelKey={(option) => `${option.name}`}
                                                options={locationOptions} >
                                            </Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>To</label>
                                            <Typeahead
                                                id="toLocation"
                                                allowNew
                                                placeholder="Drop Location"
                                                value={fromLocation}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setfromLocation}
                                                labelKey={(option) => `${option.name}`}
                                                options={locationOptions} >
                                            </Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Distance</label>
                                            <Input
                                                name="distance"
                                                placeholder="Distance"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.distance}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Time Taken</label>
                                            <Input
                                                name="time"
                                                placeholder="Time taken"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.time}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Season</label>
                                            <Input
                                                name="season"
                                                placeholder="Select Season"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.season}>
                                                {seasonMaster.map((sm) => {
                                                    return <option value={sm.id}>{sm.name + ': ' + sm.startDate + ' ' + sm.endDate}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Vehicle Name</label>
                                            <Typeahead
                                                id="vehichleName"
                                                allowNew
                                                placeholder="Vehicle Name"
                                                value={selectedVehicle}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setSelectedVehicle}
                                                labelKey={(option) => `${option.name}`}
                                                options={vehicleOptions} >
                                            </Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Price</label>
                                            <Input
                                                name="price"
                                                placeholder="Price"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.price}

                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Description</label>
                                            <Input
                                                name="description"
                                                placeholder="Description"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values?.description}>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: "1em" }}>
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Add Service
                                        </Button>
                                        <Button onClick={showForm1} type="button" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </div>
                </ModalSlider>
            </div>
        </>
    );
}

