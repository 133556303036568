import ModalSlider from "../ModalSlider"
import { useState } from "react";
import HotelVoucher from "./HotelVoucher";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle

} from "reactstrap";
export default function Vouchers() {
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }
    const data = `<div style="font-family: Sen; margin: 2em;">

    <!-- Page Heading -->
    <h2
        style="font-size: 20px; font-weight: 600; font-family:Sen; padding: 0; margin: 0;color: #00204F;  text-align: center;">
        Voucher
    </h2>
    <h2
        style="font-size: 20px;font-family: Sen; padding: 0;padding-bottom: .35em; margin: 0;  margin-bottom: 1em;color: #F67612;text-align: center;">
        HOTEL / ACCOMODATION
    </h2>

    <!-- Bordered Div Start -->
    <div
        style='display: flex;padding: 0 0; border: 2px solid #F2F2F2;background-color: #FCFCFC;margin-bottom: 1.5em;padding: 25px;'>
        <div style="width: 45%; border-right: 2px solid #F2F2F2">
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                Hotel Details</p>
            <p style="padding:0; margin:0;padding-top: .5em; font-size: 1.25rem; font-weight: 600;color: #373737;">
                Khusboo Resorts</p>
            <p style="font-weight: 500;margin:0; padding-top: 5px;">Manali
            </p>
        </div>
        <div style="padding-left: 5%;">
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                Guest Details</p>
            <p style="padding:0; margin:0;padding-top: .5em; font-size: 1.25rem; font-weight: 600;color: #373737;">
                Chhavi Sharma</p>
            <p style="font-weight: 500;margin:0; padding-top: 5px;">6 Adults
            </p>
        </div>
    </div>
    <!-- Bordered Div End -->


    <!-- Bordered Div Start -->
    <div style='padding: 0 0; border: 2px solid #F2F2F2;background-color: #FCFCFC;margin-bottom: 1.5em;padding: 25px;'>
        <div>
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                Trip Details</p>
            <p style="font-weight: 500;margin:0; padding-top: 5px;">#13254</p>
        </div>
        <br>
        <div style="display: flex;">
            <div style="width: 35%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Check-in</p>
                <p style="font-weight: 500;margin:0; padding-top: 5px;">10 February 2023
                </p>
            </div>
            <div style="width: 35%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Check-out</p>
                <p style="font-weight: 500;margin:0; padding-top: 5px;">13 February 2023
                </p>
            </div>
            <div style="width: 30%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Stay</p>
                <p style="font-weight: 500;margin:0; padding-top: 5px;">3 Nights
                </p>
            </div>
        </div>

    </div>
    <!-- Bordered Div End -->

    <!-- Bordered Div Start -->
    <div style='padding: 0 0; border: 2px solid #F2F2F2;background-color: #FCFCFC;margin-bottom: 1.5em;padding: 25px;'>
        <div>
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                Schedule</p>
        </div>
        <br>

        <!-- Schedule Headers -->
        <div style="display: flex;padding-bottom: 10px;">
            <div style="width: 35%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Nights
                </p>
            </div>
            <div style="width: 35%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Rooms
                </p>

            </div>
            <div style="width: 30%;">
                <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; ">
                    Meal Plan
                </p>

            </div>
        </div>

        <!-- Schedule details -->
        <div style="display: flex; border-top: 1px solid #F2F2F2;">
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 35%;">1st Night
            </p>
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 35%;">3 Deluxe Rooms
            </p>
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 30%; ">MAP
            </p>
        </div>
        <div style="display: flex;border-top: 1px solid #F2F2F2;">
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 35%; ">1st Night
            </p>
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 35%; ">3 Deluxe Rooms
            </p>
            <p style="font-weight: 500;margin:0; padding: 5px 0;width: 30%; ">MAP
            </p>
        </div>

    </div>
    <!-- Bordered Div End -->

    <div style="display: flex;padding-bottom: 10px;">
        <div style="width: 50%;">
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; line-height: 1.5;">
                <b>Contact Us</b>
                <br>
                <br>
                Tripper Hub
                <br>
                Phone : +91-9999466441
                <br>
                Email : thetripperhub@gmail.com
                <br>
            </p>
        </div>
        <div style="width: 50%;">
            <p style="padding:0; margin:0; font-size: .75rem; font-weight: 300; line-height: 1.5;">
                <b> Meet Us</b>
                <br>
                <br>
                Plot No. 24, 3rd floor,
                <br>
                Pillar no. 771-772, Near Pooran store
                <br>
                Dwarka, Uttam Nagar New Delhi,
                <br>
                Delhi, India - 110058
                <br>

            </p>

        </div>

    </div>



</div>`
    return <>
        <div className="custom-card" >
            <h3 className="formheadings">Generate Vouchers</h3>
            <div style={{ display: "flex" }}>
                {/* <div> <button style={{ display: "block", marginTop: "2em", marginRight: "1em" }} className="btn ">Generate Client Voucher</button></div> */}
                <div> <button style={{ display: "block", marginTop: "2em" }} className="btn ">Generate Hotel Voucher</button></div>
            </div>

            <HotelVoucher />

            <ModalSlider open={sliderShow} onClick={showForm} width='500' className={sliderShow ? ` showing` : ''}>
                <div className="slider card">
                    {/* Insert the Content */}
                </div>
            </ModalSlider>


        </div>
    </>
}