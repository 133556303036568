import React, { useRef, useState } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    NavbarToggler,
    ModalHeader,
    ModalBody,
    Form,
    CardFooter,
    NavLink,
    Nav
} from "reactstrap";

import ProposalService from '../services/proposal.service'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { enquiryState } from './../store/EnquiryState'
import Paging from 'components/Common/Paging'
import { Link } from "react-router-dom";
import _ from 'underscore';
import BaseService from "services/base.service";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
//import moment from "react-moment";
//import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import AuthService from "services/auth.service";

function Proposals() {
    const navigate = useNavigate();
    const [proposalList, setProposalList] = React.useState([]);
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [enableFiltering, setEnableFiltering] = useState(false);
    const currentUser = AuthService.getCurrentUser();
    const [proposal, setProposal] = useState({});
    function pageRefresh() {
        ProposalService.GetProposal().then(
            res => {
                
                if(currentUser.userTypeId==1)
                {
                    setProposalList(res);
                }
                else
                {
                    setProposalList(res.filter(p=>p.userId==currentUser.id));
                }  
            }
        )
    }

    const defaultFilterValue = [
        { name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'leadCode', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'startsWith', type: 'string', value: '' },
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'phone', operator: 'startsWith', type: 'string', value: '' },
        { name: 'destination', operator: 'startsWith', type: 'string', value: '' },
        { name: 'proposalDate', operator: 'eq', type: 'date', value: '' },
        { name: 'plannedTravelDate', operator: 'startsWith', type: 'string', value: '' },
        { name: 'statusId', operator: 'startsWith', type: 'string', value: '' }
    ];

    const columns = [
        { name: 'id', header: 'Id', defaultWidth: 70, type: 'number' },
        { name: 'leadCode', header: 'Lead Code', defaultWidth: 50, flex: 1 },
        { name: 'name', header: 'Name', defaultWidth: 50, flex: 1 },
        { name: 'phone', header: 'Contact', defaultWidth: 50, flex: 1 },
        { name: 'email', header: 'Email', defaultWidth: 50, flex: 1 },
        {
            name: 'proposalDate', header: 'Proposal Date', defaultWidth: 150, render: ({ value, cellProps }) => {
                return value && format(parseISO(value), "dd MMM yyyy")
            }
        },
        {
            name: 'validTillDate', header: 'Valid Proposal Date', defaultWidth: 150, render: ({ value, cellProps }) => {
                return value && format(parseISO(value), "dd MMM yyyy")
            }
        },
        {
            name: 'package', header: 'Package', render: ({ value, cellProps }) => {
                return value?.packageName;
            }
        },
        { name: 'destination', header: 'Destination', defaultWidth: 100, flex: 1 },
        {
            name: 'executive', header: 'User', defaultWidth: 80, render: ({ value, cellProps }) => {
                //return value?.firstName
                return <div className="photo"><img height={35} width={35} alt="..." title={value?.firstName} src={(value?.picture) ? BaseService.API_URL + `app-images/${value?.picture}` : "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"} /></div>
            }
        },
        {
            name: 'action', 
            header: 'Action', 
            sorrting: false, 
            minWidth: 80, 
            
            render: ({ data }) => {
                return <div><a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                    <a target="_blank" href={data.pdfLink}><i class="fa fa-file-pdf-o" aria-hidden="true"></i> </a>
                    <a href="#" onClick={(e) => AddQuotation(e, data)}><i title="Quotation" className="fas fa-solid fa-person-walking-luggage" /> </a>
                </div>
            }
        },
    ];

    React.useEffect(() => {
        pageRefresh();
    }, []);

    const onCreateBooking=(proposal)=>{
       var booking  =  {
            "id": 0,
            "name": proposal.name,
            "leadId": proposal.leadId,
            "customerId": 0,
            "proposalId": proposal.id,
            "tripStartDate": proposal.tripStartDate,
            "tripEndDate": proposal.tripEndDate,
            "duration": proposal?.duration,
            "percentForAdult": 0,
            "percentForKids": 0,
            "adult": proposal?.adult,
            "kids": proposal?.kids,
            "infant": proposal?.infant,
            "destination": proposal?.destination,
            "price": proposal?.price,
            "margin": proposal?.margin,
            "discount": proposal?.discount,
            "createdBy": 'System',
            "modifyBy": "System"
          }
        navigate(`${BaseService.NavigateToPath()}/booking-details`, { state: { booking: booking } });
    }

    function convertToCustomer(e, prospect) {
        e.preventDefault();
        console.log(prospect)
        const customer = {
            "id": -1,
            "name": prospect.name,
            "email": prospect.email,
            "phone": prospect.phone,
            "userId": prospect.userId,
            "statusId": prospect.statusId,
            "reminderId": prospect.reminderId,
            "modeId": prospect.modeId,
            "leadType": prospect.leadType,
            "packageId": prospect.packageId,
            "plannedTravelDate": prospect.plannedTravelDate,
            "destination": prospect.destination,
            "products": prospect.products,
            "plannedNoOfDays": prospect.plannedNoOfDays,
            "numberOfAdults": prospect.numberOfAdults,
            "numberOfChild": prospect.numberOfChild,
            "budgetPerAdults": prospect.budgetPerAdults,
            "budgetPerChild": prospect.budgetPerChild,
            "overAllBudget": prospect.overAllBudget
        }
        navigate(`${BaseService.NavigateToPath()}/add-customer`, { state: { customer: customer } });
    }


    function onDelete(id) {
        if(window.confirm("Are you sure want to delete?"))
        {
            ProposalService.DeleteProposal(id).then(
                res => {
                    pageRefresh();
                }
            )
        }
        
    }

    const enquiryStatusOptions = [
        { value: 1, label: 'New' },
        { value: 2, label: 'Pending' },
        { value: 3, label: 'In-Progress' },
        { value: 4, label: 'Completed' }
    ]

    const onDownload = (proposal) => {
        //navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    };

    const onEdit = (proposal) => {
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { proposal: proposal } });
    };

    function getStatus(statusId) {
        return enquiryStatusOptions.filter(s => s.value == statusId)[0]?.label
    }

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
 
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }

    const AddQuotation = (e, proposal) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });

        // if (proposal.id == -1) {
        //     let newProposal = onBeforeSave(proposal)
        //     setProposal(newProposal);
        //     ProposalService.CreateProposal(newProposal).then(r => {
        //         proposal.id = r;
        //         navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });
        //     });
        // }
        // else {
            
        // }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <CardTitle> <h3>Proposals</h3> </CardTitle>
                                    </Col>
                                    <Col sm="6" >
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data1" && enableFiltering,
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Filter
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: bigChartData === "data2",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data2") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Import
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>

                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: "data1" === "data3",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={(e) => { setbigChartData("data3") }}>

                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Export
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: "" === "data3",
                                                })}
                                                onClick={e => navigate(`${BaseService.NavigateToPath()}/add-proposal`)}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Add New
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={proposalList}
                                    enableFiltering={enableFiltering}
                                    defaultFilterValue={defaultFilterValue}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default Proposals;