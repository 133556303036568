export const canvas = {
    styles: [
        "https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap",
        "https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap",
        "http://103.50.213.106/static/css/crm.css"
    ]
}

export const customFonts = [
    { value: "Oswald, sans-serif", name: "Oswald" },
    { value: "MerriWeather, sans-serif", name: "Merriweather" },
    { value: "Rubik Wet Paint, sans-serif", name: "Rubyk" }
];

export const customCss = {
    btn: "* {font-family: 'sans-serif', Courier, monospace; }",
    btnPrimary: ".btn-primary {background-color: #008879;color: white; }",
    btnsecondary: " *.btn - secondary {background - color: white;color: #008879; border: 1px solid $bluegreen;"
}


export const customLinkComponent = {

}


export const customBlock = {
}