import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AdminLayout from "layouts/Admin/Admin.js";
import Auth from "layouts/Authentication/Auth.js";
import App from "./app";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <App />
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);