import Dashboard from "views/Dashboard.User";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import UserList from "views/UserList";
import User from "views/User";
import Enquiry from "views/Enquiry";
import Prospect from "views/Prospect";
import Customer from "views/Customer";
import Quotation from "views/Quotation";
import Invoice from "views/Invoice";
//import Package from "views/Package";
import Package from "views/Package.new";
import { ControlledBoard, UncontrolledBoard } from "views/Report";
import Setting from "views/Setting";
import AddEnquiry from "views/AddEnquiry";
import FollowUp from "views/FollowUp";
import AddProspect from "views/AddProspect";
import Login from "layouts/Authentication/components/Login";
import AddProposal from "views/AddProposal";
import AddPackage from "views/AddPackage";
import AddMember from "views/AddMember";
import Booking from "views/Bookings";
import ComingSoon from "components/CustomComponents/ComingSoon";
import ViewPackage from "views/ViewPackage";
import Hotel from "views/Hotel";
import Attractions from "views/Attractions";
import AddHotel from "views/AddHotel";
import AddInvoice from "views/AddInvoice";
import AllBookings from "views/AddBooking";
import AddAttraction from "views/AddAttraction";
import Itinerary from "views/Itinerary";
import Proposals from "views/Proposals";
import AddCustomer from "views/AddCustomer";
import EnquiryUser from "views/Enquiry.user";
import EnquiryUserNew from "views/Enquiry.user.new";
import UserDashboard from "views/Dashboard.User";
import AddQuotation from "views/AddQuotation";
import Tasks from "views/Tasks";

var routes = [   
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <UserDashboard/>,
    layout: "/user",
  },
  {
    path: "/enquiries-old",
    name: "Enquiry",
    icon: "tim-icons icon-badge",
    component: <EnquiryUser />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/enquiries",
    name: "Enquiry",
    icon: "tim-icons icon-badge",
    component: <EnquiryUserNew />,
    layout: "/user",
  },
  {
    path: "/prospect",
    name: "Prospect",
    icon: "fa fa-user-alt",
    component: <Prospect />,
    layout: "/user",
  },
  {
    path: "/proposals",
    name: "Proposal",
    icon: "far fa-edit",
    component: <Proposals />,
    layout: "/user",
  },
  {
    path: "/add-proposal",
    name: "Proposal",
    icon: "tim-icons icon-paper",
    component: <AddProposal />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/quotation",
    name: "Quotation",
    icon: "tim-icons icon-tag",
    component: <Notifications />,
    layout: "/user",    
    redirect: true
  },
  {
    path: "/customers",
    name: "Customer",
    icon: "fa fa-user-check",
    component: <Customer />,
    layout: "/user",
  },
  {
    path: "/bookings",
    name: "Booking",
    icon: "fa fa-person-walking-luggage",
    component: <Booking />,
    layout: "/user",
  },
  {
    path: "/packages",
    name: "Package",
    icon: "tim-icons icon-app",
    component: <Package />,
    layout: "/user",
  },
  {
    path: "/Itinerary",
    name: "Itinery",
    icon: "tim-icons icon-app",
    component: <Itinerary />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/users",
    name: "User",
    icon: "tim-icons icon-single-02",
    component: <UserList />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-invoice",
    name: "New Booking",
    icon: "tim-icons icon-chart-bar-32",
    // component: <ControlledBoard />,
    component: <AddInvoice />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/booking-details",
    name: "Booking",
    icon: "tim-icons fa-person-walking-luggage",    
    component: <AllBookings />,
    layout: "/user",
    redirect: true
  },

  {
    path: "/setting",
    name: "Setting",
    icon: "tim-icons icon-align-center",
    component: <Setting />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-enquiry",
    name: "Add Enquiry",
    icon: "tim-icons icon-align-center",
    component: <AddEnquiry />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/follow-up",
    name: "Follow Up",
    icon: "tim-icons icon-align-center",
    component: <FollowUp />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-prospect",
    name: "Add Prospect",
    icon: "tim-icons icon-align-center",
    component: <AddProspect />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-package",
    name: "Add Package",
    icon: "tim-icons icon-align-center",
    component: <AddPackage />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-member",
    name: "Add Member",
    icon: "tim-icons icon-align-center",
    component: <AddMember />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: "tim-icons icon-align-center",
    component: <AddCustomer />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-hotel",
    name: "Add Hotel",
    icon: "tim-icons icon-align-center",
    component: <AddHotel />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/add-attraction",
    name: "Add Attraction",
    icon: "tim-icons icon-align-center",
    component: <AddAttraction />,
    layout: "/user",
    redirect: true
  },
  {
    path: "/view-package",
    name: "View Package",
    component: <ViewPackage />,
    layout: "/user",
    redirect: true
  },

  {
    path: "/hotels",
    name: "Hotel",
    icon: "far fa-building",
    component: <Hotel />,
    layout: "/user"
  },
  {
    path: "/attractions",
    name: "Attraction",
    icon: "fa fa-map-marker",
    component: <Attractions />,
    layout: "/user"
  },
  {    
    path: "/add-quotation",
    name: "Add Quotation",
    icon: "tim-icons icon-align-center",
    component: <AddQuotation />,
    layout: "/user",    
    redirect: true
  },
  {    
    path: "/tasks",
    name: "Task",
    icon: "fa fa-tasks",
    component: <Tasks />,
    layout: "/user",      
  }
];
export default routes;