import axios, * as others from 'axios';
import * as XLSX from "xlsx";
import configData from "./../config.json";

const API_URL = "https://crmapi.trektrail.in/api/";
//const API_URL = configData.SERVER_URL
//const API_URL = "https://panaceainfosolutions.com/api/"
//const API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://crmapi.trektrail.in/api/';

const client = axios.create({
    baseURL: API_URL
});

const GetServiceClient = () => {
    return client;
}

const GetServiceUrl = () => {
    return API_URL;
}

const NavigateToPath = () => {
    return localStorage.getItem("path");
}

const ExportToExcel = (data, name) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${name}.xlsx`);
};


const BaseService = {
    GetServiceClient,
    GetServiceUrl,
    NavigateToPath,
    ExportToExcel,
    API_URL
}

export default BaseService;