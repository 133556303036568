import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;
const client = axios.create({
  baseURL: API_URL
});

async function GetAttractions() {
  const response = await axios.get(API_URL + `attraction/GetAttraction`);
  console.info(response.data);
  return response.data;
}

async function CreateAttraction(attraction) {
  //alert(JSON.stringify(attraction))
  const response = await axios.post(API_URL + `attraction/AddAttraction`, attraction);
  return response.data;
}

async function DeleteAttraction(attraction) {
  const response = await axios.delete(API_URL + `attraction/DeleteAttraction?Id=${attraction}`);
  return response.data;
}

async function UploadImage(userFormData) {
  alert( JSON.stringify(userFormData))
  const response = await client.post(`attraction/UploadImage`, userFormData);
  return response.data;
}

const AttractionService = {
  GetAttractions,
  CreateAttraction,
  DeleteAttraction,
  UploadImage
}

export default AttractionService;