import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col, } from "reactstrap";

import React, { useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import UserService from "services/user.service";
import MasterService from "services/master.service";
import PackageService from "services/package.service";
import ProposalService from "services/proposal.service";
import BaseService from "services/base.service";
import ProspectService from "services/prospect.service";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { Oval } from 'react-loader-spinner';
import NotificationAlert from "react-notification-alert";

function AddProposal() {
    const navigate = useNavigate();
    const location = useLocation();
    const editorRef = useRef(null);
    const notificationAlertRef = React.useRef(null);
    const [proposalDate, setProposalDate] = useState(new Date());
    const [validDate, setValidDate] = useState(new Date());
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [prospect, setProspect] = useState();
    const [selectedProspect, setSelectedProspect] = useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState([]);
    const [states, setStates] = useState([])
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [emailContet, setEmailContet] = useState()
    const [loading, setLoading] = useState(false)
    const [proposal, setProposal] = useState({
        id: 0,
        proposalDate: proposalDate,
        validTillDate: validDate,
        discountType: "",
        packageId: 0,
        subject: "Quotation for your trip",
        message: `        
        <p> Hi <br />
        Greetings from Tripper Hub.<br /><br />
        Our sales team has put up this Quote regarding your upcoming trip. Please go through it and let's know if you
        would like any changes in any of the provided services. <br /><br />Contact details are provided at the end. 
        <br /><br />

        Total -  00.00 <br />
        Discount -  00.00 <br />
        Tax -  00.00 <br />
        Grand Total -  00.00 <br />

        <br /><br />

        Thanks<br />
        Tripper Hub Team
        </p>        
    
           
                              
        `,
        destination: '',
        leadId: 0,
        userId: 0,
        name: "",
        phone: "",
        total: 0.00,
        discount: 0.00,
        tax: 0.00,
        grandTotal: 0.0,
        leadCode: ''
    });


    const columns = [
        {
            name: 'packageName', header: 'Bestsalers Packages', minWidth: 150, flex: 1, render: ({ data }) => {
                return <>{data.packageName}</>
            }
        }
    ];

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#034f84',
            '2': '#696969',
            '3': '#36486b',
            '4': '#588c7e'
        }
        return {
            color: colorMap[data.statusId]
            //backgroundColor: colorMap[data.statusId]
        }
    }


    useEffect(() => {
        let currentProposal = {}
        setEmailContet(proposal.message);
        if (location.state?.proposal?.id) {
            currentProposal = location.state?.proposal;
            console.log(currentProposal?.prospectId)
            console.log(currentProposal)
            setEmailContet(`        
            <p> Hi <br />
            Greetings from Tripper Hub.<br /><br />
            Our sales team has put up this Quote regarding your upcoming trip. Please go through it and let's know if you
            would like any changes in any of the provided services. <br /><br />Contact details are provided at the end. 
            <br /><br />            
            `
            );
            currentProposal.total = 0;
            currentProposal.grandTotal = 0;
            setProposal(currentProposal);
            setProposalDate(new Date(location.state?.proposal?.proposalDate || new Date()));
            setValidDate(new Date(location.state?.proposal?.validTillDate || new Date()));
            proposal.proposalDate = proposalDate;
            proposal.validTillDate = validDate;
        }

        MasterService.GetDestination().then(res => {
            setDestination(res)
            if (location.state?.proposal?.id) {
                setSelectedDestination(res.filter(d => d.name == currentProposal?.destination))
            }
        }
        );

        UserService.GetUsers()
            .then(res => {
                setUsers(res);
                if (location.state?.proposal?.id) {
                    setSelectedUser(res?.filter(p => p.id == currentProposal?.userId));
                }
            });

        if (location.state?.proposal?.prospectId && !prospect?.id) {
            ProspectService.GetProspectById(location.state?.proposal?.prospectId)
                .then(res => {
                    //alert(1)
                    //formik.values.prospect = res;
                    setProspect(res);
                });
        }

        PackageService.GetPackages()
            .then(res => {
                setPackages(res)
                if (location.state?.proposal?.id) {
                    setSelectedPackage(res?.filter(p => p.id == currentProposal?.packageId));
                }
            });

        MasterService.GetStates().then(res => setStates(res));
    }, [])

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/proposal`);
    }

    //validation Schemas
    const schema = yup.object().shape({
        name: yup.string().min(3).max(40).required("Name is required"),
        phone: yup.string().min(10).required("Contact Number is required"),
        email: yup.string().email(),
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),       
    });


    const formik = useFormik({
        initialValues: proposal,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.message = editorRef?.current?.getContent();
            values.packageId = selectedPackage[0]?.id;
            //values.leadId = selectedEnquiry[0]?.id;
            //values.prospectId = 14 ;//selectedProspect[0]?.id;
            values.userId = selectedUser[0]?.id;
            values.proposalDate = proposalDate;
            values.validTillDate = validDate;
            values.destination = selectedDestination[0]?.name;
            console.log(values)
            ProposalService.CreateProposal(values).then(res => {
                notify('br', 'The proposal has been saved successfully!');
                //navigate(`${BaseService.NavigateToPath()}/proposals`);
            });
        }
    });


    const onBeforeSave = (values) => {
        values.message = editorRef?.current?.getContent();
        values.packageId = selectedPackage[0]?.id;
        // values.leadId = selectedEnquiry[0]?.id;
        // values.prospectId = selectedProspect[0]?.id;
        values.userId = selectedUser[0]?.id;
        values.proposalDate = proposalDate;
        values.validTillDate = validDate;
        values.destination = selectedDestination[0]?.name;
        return values;
    }


    // handle input change event
    const handlProposal = value => {
        setProposalDate(value);
        setValidDate(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedEnquiry(value);
        formik.values.toContact = selectedEnquiry.name;
    }

    // handle selection
    const onSelectEnquiry = (value) => {
        setSelectedProspect(value);
        console.log(value[0])
        formik.values.name = value[0]?.name;
        formik.values.email = value[0]?.email;
        formik.values.phone = value[0]?.phone;
    }

    // handle selection
    const onSelectProspect = value => {
        setSelectedProspect(value);
        console.log(value[0])
        formik.values.name = value[0]?.name;
        formik.values.email = value[0]?.email;
        formik.values.phone = value[0]?.phone;
    }


    const onViewPackage = (e, pkg) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/add-package`, { state: { 'pkg': pkg[0], 'enquiry': selectedEnquiry } });
    }


    const notify = (place, message) => {
        var color = 2;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const OnCancel = () => {
        navigate(`${BaseService.NavigateToPath()}/proposals`);
    }

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        PackageService.GetPackages()
            .then(res => {
                setPackages(res.filter(p => p.destination == destination[0]?.name))
            });
    }

    const AddQuotation = (e) => {
        e.preventDefault();
        if (proposal.id == -1) {
            let newProposal = onBeforeSave(proposal)
            setProposal(newProposal);
            ProposalService.CreateProposal(newProposal).then(r => {
                proposal.id = r;
                navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });
            });
        }
        else {
            navigate(`${BaseService.NavigateToPath()}/add-quotation`, { state: { 'proposal': proposal } });
        }
    }

    const onPackageSelected = (row) => {
        const rowData = row.dataSourceArray[row.rowIndex];
        setSelectedPackage(row.dataSourceArray.filter(r => r.id == rowData.id))

    }

    const SendProposal = (e) => {
        e.preventDefault();
        setLoading(true)
        let newProposal = onBeforeSave(proposal)
        setProposal(newProposal);
        ProposalService.AddAndSendEmail(proposal).then(res => {
            setLoading(false)
            notify('br', 'The proposal sent successfully!');
            navigate(`${BaseService.NavigateToPath()}/proposals`);
        });
    }

    return <>
        <div className="content ">
            <NotificationAlert ref={notificationAlertRef} />
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader className="proposal-header">
                                <h4 className="title new">Create Proposal</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <label>Trip Code</label>
                                        <Input
                                            name="leadCode"
                                            placeholder="Lead Code"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.leadCode || formik.values?.prospect?.leadCode}
                                            disabled="true"
                                        />
                                    </Col>
                                    <Col md="2">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Proposal Date</label>
                                            <DatePicker
                                                placeholderText="Proposal Date"
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="plannedTravelDate"
                                                selected={proposalDate}
                                                onChange={setProposalDate} 
                                                minDate={new Date()}
                                                />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="4">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Valid TIll</label>
                                            <DatePicker
                                                placeholderText="Proposal Valid TIll"
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="plannedTravelDate"
                                                selected={validDate}
                                                onChange={setValidDate} />
                                        </FormGroup>
                                    </Col> */}
                                    <Col md="3">
                                        <FormGroup className="group">
                                            <label>State</label>
                                            <Input
                                                type="select"
                                                name="discountType"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.discountType}
                                            >
                                                {states?.map((disc) => (<option value={disc.id}>{disc.name}</option>))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup >
                                            <label>Destination</label>
                                            <Typeahead
                                                id="destination"
                                                labelKey={(option) => `${option.name}`}
                                                onChange={onSelectedDestination}
                                                options={destination}
                                                placeholder="Select Destination"
                                                selected={selectedDestination}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="7">
                                        <FormGroup>
                                            <label>Package</label>
                                            <Typeahead
                                                id="package"
                                                labelKey={(option) => `${option.packageName}`}
                                                onChange={setSelectedPackage}
                                                options={packages}
                                                placeholder="Select Package"
                                                selected={selectedPackage}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="1" style={{ display: "flex", alignItems: "center" }}>
                                        {selectedPackage.length > 0 && <Link onClick={(e) => onViewPackage(e, selectedPackage)} style={{ marginTop: "1em" }}>View</Link>}
                                    </Col> */}
                                    <Col md="5">
                                        <FormGroup>
                                            <label>Executive</label>
                                            <Typeahead
                                                id="UserId"
                                                labelKey={(option) => `${option.firstName} ${option.lastName}`}
                                                onChange={setSelectedUser}
                                                options={users}
                                                placeholder="Select User"
                                                selected={selectedUser}
                                                clearButton
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col md="3">
                                        <FormGroup>
                                            <label>Select Lead</label>
                                            <Typeahead
                                                id="enquiry"
                                                labelKey={(option) => `${option.leadCode || option.code} - ${option.name}`}
                                                onChange={onSelectEnquiry}
                                                options={prospect}
                                                placeholder="Select Prospect"
                                                selected={selectedProspect}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col> */}
                                    <Col md="3">
                                        <FormGroup >
                                            <label>To Name<span className="requiredStar">*</span></label>
                                            <Input
                                                name="name"
                                                placeholder="Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <span className='text-red-400'>{formik.errors.name}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup >
                                            <label>To Email ID</label>
                                            <Input
                                                name="email"
                                                placeholder="email"
                                                type="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <span className='text-red-400'>{formik.errors.email}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup >
                                            <label>Contact Number<span className="requiredStar">*</span></label>
                                            <Input
                                                name="phone"
                                                placeholder="Contact"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                            {formik.touched.phone && formik.errors.phone && (
                                                <span className='text-red-400'>{formik.errors.phone}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup >
                                            <label>Subject</label>
                                            <Input
                                                name="subject"
                                                placeholder="Quotation for your trip"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.subject}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup >
                                            <label>Message</label>
                                            {/* <Input style={{ minHeight: "300px" }}
                                                name="message"
                                                placeholder=""
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.message}
                                            /> */}

                                            <Editor apiKey={'ih9a01slzwix1sdsazp38f3vg14m7zqiyzlai5724hb7y8wb'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={emailContet}
                                                init={{
                                                    height: 300,
                                                    menubar: false,

                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }} />

                                            {formik.touched.lastName && formik.errors.lastName && (
                                                <span className='text-red-400'>{formik.errors.lastName}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <button type="submit" className="btn">Save</button>
                                        <Button onClick={(e) => AddQuotation(e)} >Itinerary</Button>
                                        <button onClick={(e) => SendProposal(e)} className="btn btn-primary"><i className="fas fa-send"></i> Send </button>
                                        <button onClick={OnCancel} className="btn">Cancel</button>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }}>
                            {/* <CardHeader>
                                <h4 className="title">Recent Package</h4>
                            </CardHeader> */}
                            <CardBody>
                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    showZebraRows={true}
                                    dataSource={packages}
                                    onRowClick={onPackageSelected}
                                    pagination
                                    defaultLimit={10}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div >
        <div style={{ zIndex: '100100', 'position': 'fixed', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }}>
            <Oval
                visible={loading}
                height={80}
                width={80}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={5}
                strokeWidthSecondary={5}
            />
        </div>

    </>
}
export default AddProposal;