import { selector, atom } from 'recoil'

export const userState = atom({
    key: 'userList',
    default: [],
  });

  export const currentUserState = atom({
    key: 'Currentuser',
    default: {},
  });