import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


export default function CRMEditor(props) {
    const [content, setContent] = useState("<p>Hello World</p>");
    const [editorState, setEditorState] = useState();


    const onEditorStateChange=(edit)=>{

        setEditorState(edit)
    }

    return <>
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
        />
    </>
}