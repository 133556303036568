import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, Pie } from "react-chartjs-2";
import AuthService from "services/auth.service";
import DashboardService from "services/dashboard.service";
import RemiderService from "services/reminder.service";
import { format, parseISO } from 'date-fns'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import FollowUp from './FollowUp'
import Reminder from "./Reminder";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Moment from "react-moment";

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const [chartData, setChartData] = useState({});
  const [monthWiseLeadsData, setMonthWiseLeadsData] = useState([]);
  const [monthWiseLeadsLabel, setMonthWiseLeadsLabel] = useState([]);

  const [statusWiseLeadsData, setStatusWiseLeadsData] = useState([]);
  const [statusWiseLeadsLabel, setStatusWiseLeadsLabel] = useState([]);

  const [userLeadsData, setUserLeadsData] = useState([]);
  const [userLeadsLabel, setUserLeadsLabel] = useState([]);

  const [reminders, setReminders] = React.useState([]);
  const [sliderShow, setSliderShow] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState({});

  const Chart = () => {
    setChartData({
      labels: ['22/03', '23/03', '24/03', '25/03', '26/03', '27/03'],
      datasets: [{
        label: '# of Votes',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }]
    });
  }

  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    Chart();    
    DashboardService.GetMonthWiseLeads(currentUser?.id).then(res => {                  
      let result = res.map(({ Month }) => Month)
      let result1 = res.map(({ Leads }) => Leads)
      setMonthWiseLeadsLabel(result)
      setMonthWiseLeadsData(result1)      
    });

    DashboardService.GetStatusWiseLeads(currentUser?.id).then(res => {            
      let label = res.map(({ Status }) => Status)
      let data = res.map(({ Leads }) => Leads)      
      setStatusWiseLeadsLabel(label)
      setStatusWiseLeadsData(data)
    });

    DashboardService.GetUserWiseLeads().then(res => {            
      let label = res.map(({ FirstName }) => FirstName)
      let data = res.map(({ Leads }) => Leads)      
      setUserLeadsLabel(label)
      setUserLeadsData(data)
    });    
    
    RemiderService.GetLatestLeadAndRemindersByUserId(currentUser?.id).then(
      res => {
        console.log(res)
        setReminders(res);
      }
    );
  }, []);

  const events = [
    { title: 'Trip', start: new Date() }
  ]

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  const refresh = () => {
    RemiderService.GetLatestLeadAndRemindersByUserId(currentUser?.id).then(
      res => {
        console.log(res)
        setReminders(res);
      }
    );
  }

  const handleShow = () => {
    setSliderShow((prevState) => !prevState)    
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="4">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Month wise Leads</h5>                    
                  </Col>                  
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {/* <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  /> */}

                  <Bar
                    data={{
                      labels: monthWiseLeadsLabel,
                      datasets: [{
                        label: 'Month wise Leads',
                        data: monthWiseLeadsData,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      title: { text: "THICCNESS SCALE", display: true },
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true
                          }
                        }
                        ]
                      }
                    }}
                  />

                </div>
              </CardBody>
            </Card>
          </Col>


          <Col xs="4">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Status wise Leads</h5>                    
                  </Col>                  
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {/* <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  /> */}

                  <Bar
                    data={{
                      labels: statusWiseLeadsLabel,
                      datasets: [{
                        label: 'Status wise Leads',
                        data: statusWiseLeadsData,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      title: { text: "THICCNESS SCALE", display: true },
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true
                          }
                        }
                        ]
                      }
                    }}
                  />

                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">User wise Leads</h5>                    
                  </Col>                  
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {/* <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  /> */}

                  <Bar
                    data={{
                      labels: userLeadsLabel,
                      datasets: [{
                        label: 'User wise Leads',
                        data: userLeadsData,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                      }]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      title: { text: "THICCNESS SCALE", display: true },
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true
                          }
                        }
                        ]
                      }
                    }}
                  />

                </div>
              </CardBody>
            </Card>
          </Col>          
        </Row>
        <Row>
          <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">My Follow-ups({reminders.length})</h6>
                <p className="card-category d-inline"> today</p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {reminders.map((reminder) => {

                        return <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  defaultChecked
                                  defaultValue=""
                                  type="checkbox"
                                />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">{reminder?.Code}  - {reminder?.Name} <i style={{ 'font-size': '12px' }} class="fa-solid fa-phone"></i>
                              {reminder?.Phone}</p>                              
                              <p className="text-muted">
                              {reminder?.ReminderStatus}
                            </p>
                            <p className="text-muted">
                              {reminder?.Comment}
                            </p>
                            <p className="text-muted">
                              {/* {Moment.getDatetime(reminder?.ReminderDateTime).format("dd-MM-yyyy")} */}
                              <Moment format="DD/MM/YYYY HH:mm">{reminder?.ReminderDateTime}</Moment>
                            </p>

                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip457194718"
                              title=""
                              type="button"
                              onClick={() => { setSliderShow(true); setSelectedReminder(reminder) }}
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip457194718"
                              placement="right"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">My Booking(5)</h6>
                <p className="card-category d-inline"> Calander</p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    weekends={false}
                    events={events}
                    eventContent={renderEventContent}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer
        open={sliderShow}
        onClose={() => setSliderShow((prevState) => !prevState)}
        direction='right'
        className='bla bla bla'
        style={{ 'width': '500px', "zIndex": '10000' }}
      >
        <Reminder reminder={selectedReminder} onIncrement={handleShow} refresh={refresh} />
      </Drawer>
    </>
  );
}

export default Dashboard;