import React, { useState, useCallback } from "react";
import { format, parseISO } from 'date-fns'
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import _, { reduce } from 'underscore';
import FollowUp from './FollowUp'
import BulkUpload from "../components/CustomComponents/BulkUpload";
import en from 'date-fns/locale/en-IN';
import { Chrono } from "react-chrono";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import * as XLSX from "xlsx";
import NotificationAlert from "react-notification-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import AuthService from "services/auth.service"
import { Link, useLocation } from "react-router-dom";
import DashboardService from "services/dashboard.service";

import {
    Alert,
    UncontrolledAlert,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Label,
    Input,
    FormGroup
} from "reactstrap";

import EnquiryService from '../services/enquiry.service'
import { useRecoilState, useRecoilValue } from "recoil";
import { enquiryState, filteredEnquiryState } from './../store/EnquiryState'
import MoadalSlider from "components/CustomComponents/ModalSlider";
import BaseService from '../services/base.service'
import FollowUpService from '../services/followup.service'
import UserService from "services/user.service";
//import moment from 'moment';
import moment, * as moments from 'moment';
//import moment from 'moment/src/moment'


import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import { date } from "yup";

function EnquiryUserNew() {
    window.moment = moment
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [bigFilterData, setbigFilterData] = React.useState("");
    const [enquiryCount, setEnquiryCount] = React.useState([]);
    const notificationAlertRef = React.useRef(null);
    const navigate = useNavigate();
    const [selectedEnquiry, setSelectedEnquiry] = React.useState([]);
    const [enquiryList, setEnquiryList] = useRecoilState(enquiryState);
    const [sliderShow, setSliderShow] = useState(false);
    const [sliderShow1, setSliderShow1] = useState(false);
    const [sliderShow2, setSliderShow2] = useState(false);
    const [parentToChild, setParentToChild] = useState('');
    const [userList, setUserList] = useState([])
    const [filterUserList, setFilterUserList] = useState([])
    const [linkState, setLinkState] = useState(false)
    const [leadsCount, setLeadsCount] = useState([])
    const [followupItems, setfollowupItems] = useState([]);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [selected, setSelected] = useState([]);
    const [showColumnMenuTool, setShowColumnMenuTool] = useState(true);
    const [enableColumnFilterContextMenu, setEnableColumnFilterContextMenu] = useState(true);
    const showForm = (enquiry) => {
        setParentToChild(enquiry?.id)
        setSelectedEnquiry(enquiry);
        setSliderShow(!sliderShow);
    }

    const enquiryStatusoptions = [
        { value: '1', label: 'Spoke' },
        { value: '2', label: 'Meeting Fixed' },
        { value: '3', label: 'Met' },
        { value: '4', label: 'Closed' },
        { value: '5', label: 'Lost' },
        { value: '6', label: 'Active' },
    ]

    const [enquiryStatusoptions1, setEnquiryStatusoptions1] = useState([])
    const [enquiryStatusoptions2, setEnquiryStatusoptions2] = useState([])


    const showForm1 = () => { setSliderShow1(!sliderShow1) }
    const defaultSelected = {};
    const defaultFilterValue = [
        //{ name: 'id', operator: 'gte', type: 'number', value: 0 },
        { name: 'code', operator: 'contains', type: 'string', value: '' },
        { name: 'email', operator: 'contains', type: 'string', value: '' },
        { name: 'name', operator: 'contains', type: 'string', value: '' },
        { name: 'message', operator: 'contains', type: 'string', value: '' },
        { name: 'phone', operator: 'contains', type: 'string', value: '' },
        { name: 'destination', operator: 'contains', type: 'string', value: '' },
        { name: 'packageName', operator: 'contains', type: 'string', value: '' },
        { name: 'plannedTravelDate', operator: 'contains', type: 'string', value: '' },
        { name: 'statusName', operator: 'eq', type: 'select', value: null },
        { name: 'createdDate', operator: 'eq', type: 'date', value: null },
    ];

    const columns = [
        {
            name: 'statusName',
            header: 'Status',
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 150,
            enableColumnFilterContextMenu: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Select Status',
                dataSource: enquiryStatusoptions2,
            },
            render: ({ data }) => {
                return <>
                    {data.code}<br />
                    <span style={rowStyle1(data)}> {data.statusName} </span>
                </>
            }
        },
        {
            name: 'name', header: 'Name', defaultWidth: 50, flex: 1,
            render: ({ data }) => { return <>{data.name} <br /> {data.phone} <br />{data.email}</> }
        },

        {
            name: 'destination', header: 'Destination', maxWidth: 150, flex: 1,
            render: ({ data }) => {
                return <>
                    {data.destination} <br />

                    {data.plannedNoOfDays}N/{data.plannedNoOfDays + 1}D <br />
                    <i className="fas fa-male"></i>&nbsp;{data.numberOfAdults}&nbsp;
                    <i className="fas fa-child"></i>&nbsp;{data.numberOfChild}&nbsp;
                    <i className="fas fa-baby"></i> {data.numberOfInfant}
                </>
            }
        },
        {
            name: 'packageName', header: 'Package', maxWidth: 250, flex: 1,
            render: ({ data }) => {
                return <>
                    {(data.plannedTravelDate && 'Tour On ' + moment(data.plannedTravelDate).format('DD-MMM-YYYY'))}
                    <br /> {data.leadType}
                    <br />{data?.packageName}
                </>
            }
        },
        {
            name: 'createdDate',
            header: 'Created Date',
            dateFormat: 'YYYY-MMM-DD',
            defaultFlex: 1,
            minWidth: 100,
            enableColumnFilterContextMenu: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'YYYY-MMM-DD',
                    cancelButton: false,
                    highlightWeekends: false,
                    placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
                }
            },
            render: ({ value, cellProps: { dateFormat }, data }) => {
                return <>
                    Created-<span title="Created On">{moment(value).format('DD-MMM-YYYY')} {moment(value).format('HH:mm')}</span><br />
                    Updated-<span title="Last Updated On">{moment(data.modifyDate).format('DD-MMM-YYYY')} {moment(data.modifyDate).format('HH:mm')}</span>
                </>
            }
        },
        // {
        //     name: 'statusName',
        //     header: 'Status',
        //     defaultFlex: 1,
        //     minWidth: 100,
        //     enableColumnFilterContextMenu: true,
        //     filterEditor: SelectFilter,
        //     filterEditorProps: {
        //         placeholder: 'All',
        //         dataSource: enquiryStatusoptions2,
        //     }
        // },
        { name: 'message', header: 'Message', defaultWidth: 50, flex: 1 },
        {
            name: '1s', header: '', sortable: false, minWidth: 150, render: ({ data }) => {
                return <div>
                    <a href="#" onClick={(e) => onEdit(data)}><i title="Edit" className="fas fa-pencil" /> </a>
                    <a href="#" onClick={(e) => onDelete(data.id)}><i title="Delete" className="fas fa-trash-o" /> </a>
                    <a href="#" onClick={(e) => showForm(data)}><i title="Follow-up" className="far fa-bell" /> </a>
                    <a href="#" onClick={(e) => onTimeline(data.id)}><i title="Timeline" className="fas fa-clock" /> </a>
                    <a href="#" onClick={(e) => convertToProspect(e, data)}><i title="Convert To Prospect" className="fa-solid fa-users" /> </a>
                </div>
            }
        },
    ];

    const gridStyle = { height: 'calc(100vh - 200px)' }

    const rowStyle1 = (data) => {
        //alert(data?.statusId)
        const colorMap = {
            '1': '#3b2f02',
            '2': '#696969',
            '3': '#36486b',
            '4': '#273b02',
            '5': '#696969',
            '6': '#36486b',
            '7': '#588c7e'
        }

        const bgColorMap = {
            '1': '#f5f3eb',
            '2': '#ccf7c6',
            '3': '#eae1fa',
            '4': '#e1f1fa',
            '5': '#b6b5bd',
            '6': '#bdbaaa',
            '7': '#c5d6c3'
        }

        return {
            color: data?.isDuplicate ? "#0d0606" : colorMap[data?.statusId],
            backgroundColor: data?.isDuplicate ? "#ffabab" : bgColorMap[data?.statusId],
            //fontWeight: 'bold'
        }
    }

    const rowStyle = ({ data }) => {
        const colorMap = {
            '1': '#3b2f02',
            '2': '#696969',
            '3': '#36486b',
            '4': '#273b02',
            '5': '#696969',
            '6': '#36486b',
            '7': '#588c7e'
        }

        const bgColorMap = {
            '1': '#f5f3eb',
            '2': '#ccf7c6',
            '3': '#eae1fa',
            '4': '#e1f1fa',
            '5': '#b6b5bd',
            '6': '#bdbaaa',
            '7': '#c5d6c3'
        }

        return {
            color: data.isDuplicate ? "#0d0606" : colorMap[data.statusId],
            borderTop: '1px solid #ddd',
            //backgroundColor: data.isDuplicate ? "#ffabab" : bgColorMap[data.statusId],  
            fontWeight: data.isRead ? 'bold' : ''
        }
    }

    const currentUser = AuthService.getCurrentUser()

    React.useEffect(() => {
        EnquiryService.GetEnquiryStatus().then(res => {
            setEnquiryStatusoptions1(res);
            const status = res.reduce((status, p) => {
                status.push({
                    id: p.status,
                    value: p.id,
                    label: p.status
                })
                return status
            }, []);
            setEnquiryStatusoptions2(status);
        });

        EnquiryService.GetEnquiriesByUserId(currentUser?.id).then(
            res => {
                setEnquiryList(res);
            }
        );

        UserService.GetUsers().then(
            res => {
                setUserList(res);
                const filterUserList1 = res.reduce((filterUserList1, p) => {
                    filterUserList1.push({
                        id: p.id,
                        label: p.firstName
                    })

                    return filterUserList1
                }, []);
                setFilterUserList(filterUserList1);
            }
        );

        DashboardService.GetStatusWiseLeads(currentUser?.id).then(res => {
            console.log(res)
            setLeadsCount(res);
        });

    }, []);


    const callback = () => {
        return selectedEnquiry;
    }

    const onEdit = (enquiry) => {
        navigate(`${BaseService.NavigateToPath()}/add-enquiry`, { state: { enquiry: enquiry } });
    };

    const onTimeline = (id) => {
        setSliderShow2(!sliderShow2);
        if (id) {
            FollowUpService.GetFollowUpsByEnquiryId(id).then(response => {
                let followups = response?.map(followUp => ({ 'title': format(parseISO(followUp.reminderDateTime), "dd MMM yy HH:mm"), cardTitle: enquiryStatusoptions.filter(e => e.value == followUp.statusId)[0]?.label, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        }
    }

    const onDelete = (id) => {
        if (window.confirm("Are you sure want to delete?")) {
            EnquiryService.DeleteEnquiry(id).then(
                () => {
                    EnquiryService.GetEnquiries().then(
                        res => {
                            setEnquiryList(res);
                            //handleEnquiryFilter(res);
                            notify('br', 'The Enquiry has been deleted successfully!');
                        }
                    );
                }
            );
        }
    };

    const navigatePage = (e, page) => {
        e.preventDefault();
        navigate(`${BaseService.NavigateToPath()}/${page}`);
    };



    const notify = (place, message) => {
        var color = 2;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    function convertToProspect(e, enquiry) {
        e.preventDefault();
        const prospect = {
            "id": -1,
            "leadId": enquiry.id,
            "name": enquiry.name,
            "email": enquiry.email,
            "phone": enquiry.phone,
            "userId": enquiry.userId,
            "statusId": enquiry.statusId,
            "reminderId": enquiry.reminderId,
            "modeId": enquiry.modeId,
            "leadType": enquiry.leadType,
            "packageId": enquiry.packageId,
            "plannedTravelDate": enquiry.plannedTravelDate,
            "destination": enquiry.destination,
            "products": enquiry.products,
            "plannedNoOfDays": enquiry.plannedNoOfDays,
            "numberOfAdults": enquiry.numberOfAdults,
            "numberOfChild": enquiry.numberOfChild,
            "numberOfInfant": enquiry.numberOfInfant,
            "budgetPerAdults": enquiry.budgetPerAdults,
            "budgetPerChild": enquiry.budgetPerChild,
            "overAllBudget": enquiry.overAllBudget,
            "leadSourceId": enquiry.leadSourceId,
            "lead": enquiry,
            "proposals": []
        }
        navigate(`${BaseService.NavigateToPath()}/add-prospect`, { state: { prospect: prospect } });
    }




    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        setSelected(selectedMap)

    }, [])




    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(enquiryList);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const renderRowContextMenu = (menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Row ' + rowProps.rowIndex
            }
        ]
    }

    const usersWithProfiles = userList.map((user) => {
        const enquiry = enquiryList.filter((enquiry) => (user.id === enquiry.userId)).length;
        return { ...user, enquiry: enquiry };
    });

    const dataSource = useCallback(EnquiryService.LoadData, [])

    const addFollowUps = useCallback((followUps) => {
        console.log(followUps)
        FollowUpService.CreateFollowUp(followUps).then(res => {
            FollowUpService.GetFollowUpsByEnquiryId(followUps?.entityId).then(response => {
                let followups = response?.map(followUp => ({ 'title': followUp.reminderDateTime, cardTitle: enquiryStatusoptions.filter(e => e.id == followUp.statusId)[0]?.status, cardDetailedText: followUp.comment }))
                setfollowupItems(followups);
            });
        });

    }, []);

    let linkclass = `mappedlink ${linkState ? 'activated' : ''}`

    const getStatusColor = (id) => {
        console.log(id);
        switch (id) {
            case 1:
                return { backgroundColor: '#ecf2f9' };
                break;
            case 2:
                return { backgroundColor: ' #c6d9ec' }
                break;
            case 3:
                return { backgroundColor: '#b3cce6' }
                break;
            case 4:
                return { backgroundColor: '#ecf9f2' }
                break;

        }

    }

    const onFilterEnquiry = (e, type) => {
        EnquiryService.GetEnquiriesByUserId(currentUser?.id).then(
            res => {
                if (bigFilterData === type) {
                    setbigFilterData("All");
                    setEnquiryList(res);
                }
                else {
                    setbigFilterData(type);
                    setEnquiryList(res?.filter(e => e.statusName == type));
                }
            }
        );
    }

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <ButtonGroup
                                    className="btn-group-toggle float-left"
                                    data-toggle="buttons">
                                    {leadsCount.map((enquiryStatus) => {
                                        return <Button
                                            color="info"
                                            tag="label"
                                            className={classNames("btn-simple", {
                                                active: bigFilterData === enquiryStatus.Status
                                            })}
                                            onClick={(e) => { onFilterEnquiry(e, enquiryStatus.Status) }} >
                                            {enquiryStatus.Status}({enquiryStatus?.Leads})</Button >
                                    })}
                                </ButtonGroup>
                            </Col>
                            <Col>
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons">
                                    <Button
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: bigChartData === "data1" && enableFiltering,
                                        })}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={(e) => { setEnableFiltering(!enableFiltering); setbigChartData("data1") }}>

                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Filter
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: bigChartData === "data2",
                                        })}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={(e) => { showForm1(); setbigChartData("data2") }}>

                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Import
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: bigChartData === "data4",
                                        })}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={(e) => { exportToExcel(); setbigChartData("data4") }}>

                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Export
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: bigChartData === "data3",
                                        })}
                                        onClick={(e) => { navigatePage(e, "add-enquiry") }}
                                    >
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Add New
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-tap-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>

                        </Row>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <ReactDataGrid
                                idProperty="id"
                                style={gridStyle}
                                rowStyle={rowStyle}
                                columns={columns}
                                showZebraRows={false}
                                showHeader={enableFiltering}
                                showCellBorders={false}
                                dataSource={enquiryList}
                                //pagination
                                //livePagination
                                rowHeight={100}
                                enableFiltering={enableFiltering}
                                //filterTypes={filterTypes}
                                showColumnMenuTool={showColumnMenuTool}
                                onSelectionChange={onSelectionChange}
                                defaultLimit={10}
                                defaultFilterValue={defaultFilterValue}
                                selected={selected}
                                enableColumnFilterContextMenu={enableColumnFilterContextMenu}
                            />
                        </Row>
                    </CardBody>
                </Card>

                <MoadalSlider open={sliderShow} onClick={showForm} id='followupSlider' width='500' className={sliderShow ? ` showing` : ''}>
                    <FollowUp dataParentToChild={parentToChild} callback={callback} refresh={addFollowUps} onCancel={showForm} />
                </MoadalSlider>

                <MoadalSlider open={sliderShow1} id='uploadSlider' onClick={showForm1} width='450' className={sliderShow1 ? ` showing` : ''}>
                    <BulkUpload></BulkUpload>
                </MoadalSlider>

                <MoadalSlider open={sliderShow2} id='timeline' onClick={onTimeline} width='450' className={sliderShow2 ? ` showing` : ''}>
                    <div className="content ">
                        <Card className="followupcard">
                            <Chrono allowDynamicUpdate="true" fontSizes={{
                                cardSubtitle: '12px',
                                cardText: '12px',
                                cardTitle: '13px',
                                title: '13px',
                            }} titleDateFormat='dd MMM yy'
                                contentDetailsHeight="80"
                                cardHeight="100"
                                style={{ height: '950px' }}
                                items={followupItems}
                                renderRowContextMenu={renderRowContextMenu}
                                mode="VERTICAL" >
                            </Chrono>
                        </Card>
                    </div>
                </MoadalSlider>

            </div >
        </>
    );
}
export default EnquiryUserNew;
