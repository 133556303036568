import { Table } from "reactstrap"
export default function InvoiceDetails() {
    return <>
        <div className="custom-card" >
            <h3 className="formheadings">Customer Invoice</h3>
            <table border={1} width={'100%'} style={{ border: "1px solid #aaa", padding: "0", }}>



                <tr>

                    <td colSpan={2} style={{ verticalAlign: "center" }}>
                        <div ><img width={120} src="https://tripperhub.com/images/Yatra-white.png" alt="" /></div>
                        <div style={{ display: 'grid', placeItems: 'center' }}> <h2 >INVOICE</h2></div>
                    </td>

                </tr>


                <tr >
                    <td colSpan={'3'} style={{ backgroundColor: 'var(--blue)', height: "20px" }}></td>
                </tr>
                <tr >


                    <td width={'50%'} style={{ borderRight: "1px solid #aaa", verticalAlign: "top" }}>
                        India Travel Kart <br />
                        A-3, Kiran Garden, Uttam Nagar <br />
                        New Delhi (110059) <br />
                        Mobile : 9999466441 <br />
                        Email : travel@kart.com <br />
                    </td>
                    <td width={'50%'} style={{ verticalAlign: "top" }}>
                        Customer ID: E53<br />
                        Invoice Date: 20-05-2023 <br />
                        Invoice Number: THE53 <br />
                        GSTIN Number: 07DZUPS6212N1ZG <br />
                        SAC: 998555<br />
                    </td>



                </tr>
                <tr>
                    <td colSpan={'3'} style={{ color: "white", backgroundColor: "var(--blue)" }}>INVOICE TO & RECIPIENT</td>
                </tr>


                <tr>
                    <td style={{ borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Customer Name
                    </td>
                    <td style={{ borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Vidhu Shekhar
                    </td>
                </tr>
                <tr>
                    <td width={'30%'} style={{ borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Street Address
                    </td>
                    <td width={'70%'} style={{ borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Nirman Sanskari Sanstha Colony, Khargone
                    </td>
                </tr>
                <tr>
                    <td width={'30%'} style={{ borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        City/State/Zip

                    </td>
                    <td width={'70%'} style={{ borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Madhya Pradesh
                    </td>
                </tr>
                <tr>
                    <td width={'30%'} style={{ borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        GSTIN/Unique ID

                    </td>
                    <td width={'70%'} style={{ borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        NA
                    </td>
                </tr>
                <tr>
                    <td width={'30%'} style={{ borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        Client's Job Title

                    </td>
                    <td width={'70%'} style={{ borderBottom: "1px solid #aaa", verticalAlign: "top" }}>
                        NA
                    </td>
                </tr>



                <tr>


                    <td style={{ color: "white", backgroundColor: "var(--blue)" }}>
                    <table>
                            <tr>
                                <td width={{}}>S no.</td>
                                <td>Particulars</td>
                                
                            </tr>
                        </table>
                    </td>
                    <td style={{ color: "white", backgroundColor: "var(--blue)" }}>
                        <table>
                            <tr>
                                <td>Trip</td>
                                <td>Qty.</td>
                                <td>Amount</td>
                            </tr>
                        </table>
                    </td>
                    


                </tr>
                <tr>


                    <td>
                    <table>
                            <tr>
                                <td width={{}}>1</td>
                                <td>Particulars</td>
                                
                            </tr>
                        </table>
                    </td>
                    <td style={{ color: "white", backgroundColor: "var(--blue)" }}>
                        <table>
                            <tr>
                                <td>Trip</td>
                                <td>Qty.</td>
                                <td>Amount</td>
                            </tr>
                        </table>
                    </td>
                    


                </tr>


            </table>

        </div>
    </>
}