import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;


const BookingStatus = [{ id: 1, name: "New" }, { id: 2, name: "Pending" }, { id: 3, name: "InProgress" }, { id: 4, name: "Completed" }]

const EnquiryStatus = [{ id: 1, name: "New" }, { id: 2, name: "Pending" }, { id: 3, name: "InProgress" }, { id: 4, name: "Completed" }]

const UserType = [{ id: 1, name: "Admin" }, { id: 2, name: "Operation" }, { id: 3, name: "Sales" }]
const LoginType = [{ id: 1, name: "Admin" }, { id: 2, name: "User" }, { id: 3, name: "Client" }]
const MemberType = [{ id: 1, name: "Customer" }, { id: 2, name: "Vendor" }, { id: 3, name: "" }]
const PackageTemplate = [{ id: 1, name: "Busness" }, { id: 2, name: "Family Tour" }, { id: 3, name: "Holiday" }]

const EnquiryType = [
    { id: 1, name: 'Hotel' },
    { id: 2, name: 'Car' },
    { id: 3, name: 'Bus' },
    { id: 4, name: 'Holiday', }
]

const DiscountType = [
    {
        id: 1,
        label: "After Tax",
    },
    {
        id: 2,
        label: "Before Tax"
    },
]

const PackageType = [
    { id: '1', name: 'Business' },
    { id: '2', name: 'Adventure' },
    { id: '3', name: 'Holiday' },
    { id: '3', name: 'Honeymoon' },
    { id: '3', name: 'Family Tour' },
    { id: '3', name: 'Group Tour' },
    { id: '3', name: 'Weekend Trip' }
]


// 

async function GetBookingStatus() {
    return BookingStatus
}
function GetEnquiryStatus() {
    return EnquiryStatus
}
function GetUserType() {
    return UserType
}
function GetLoginType() {
    return LoginType
}
function GetMemberType() {
    return MemberType
}

function GetTourPackages() {
    return []
}

function GetPackageType() {
    return PackageType
}

function GetPackageTemplate() {
    return PackageTemplate
}
function GetDiscountType() {
    return DiscountType
}

async function GetTypes(type) {    
    const response = await axios.get(API_URL + `Master/GetMasterTypes?msterType=${type}`);
    return response.data;
}

async function GetDestination() {    
    const response = await axios.get(API_URL + `Master/GetMasterTypes?msterType=State`);
    return response.data;
}

async function GetStates() {    
    const response = await axios.get(API_URL + `Master/GetMasterTypes?msterType=State`);
    return response.data;
}

async function GetCities() {    
    const response = await axios.get(API_URL + `Master/GetMasterTypes?msterType=Destination`);
    return response.data;
}


async function GetSeasonMaster() {    
    const response = await axios.get(API_URL + `Master/GetSeasonMaster`);
    return response.data;
}

const MasterService = {
    getBookingStatus: GetBookingStatus,
    getEnquiryStatus: GetEnquiryStatus,
    getLoginType: GetLoginType,
    getMemberType: GetMemberType,
    getUserType: GetUserType,
    GetTourPackages,
    GetPackageType,
    GetPackageTemplate,
    GetTypes,
    GetDiscountType,
    GetDestination,
    GetSeasonMaster,
    GetStates,
    GetCities
}

export default MasterService;