import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Nav,
    NavLink
} from "reactstrap";
import HotelService from "services/hotel.service";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormik } from 'formik';
import * as yup from 'yup';
import QuotationService from "services/quotation.service"
import { forEach } from "underscore";
import MasterService from "services/master.service";

function Stay(props) {
    const [hotelList, setHotelList] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState([]);
    const [mealPlanList, setMealPlanList] = useState([{ id: 0, name: '' }, { id: 1, name: 'AP' }, { id: 2, name: 'MAP' }, { id: 3, name: 'CP' }, { id: 4, name: 'EP' }]);
    const [roomTypeOptions, setRoomTyprOptions] = useState([{ id: 0, name: "" }, { id: 1, name: "Deluxe" }, { id: 2, name: "Super Deluxe" }, { id: 3, name: "Suite" }]);
    const [hotelTypeOptions, setHotelTypeOptions] = useState([{ id: 0, name: "" }, { id: 1, name: "Deluxe" }, { id: 2, name: "Super Deluxe" }, { id: 3, name: "Semi Super Deluxe" }]);
    const [destinationOptions, setDestinationOptions] = useState([{ id: 0, name: "" }, { id: 1, name: "Manali" }, { id: 2, name: "Kullu" }, { id: 3, name: "Shimla" }]);
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])

    const [hotelBooking, setHotelBooking] = useState({
        id: 0,
        hotelId: 0,
        mealPlanId: 0,
        hotel: '',
        hotelType :'',
        hotelName: '',
        mealPlan: '',
        roomTypeId: 0,
        roomType: '',
        pax_Room: 0,
        rooms: 0,
        aweb: 0,
        cweb: 0,
        cnb: 0,
        quotePrice:0
    });

    useEffect(() => {
        HotelService.GetHotels().then(
            res => {
                setHotelList(res);

                // const hotelType = res.reduce((HotelType, h) => {
                //     HotelType.push({
                //       id: h.hotalTypeId,
                //       name: h.hotelType
                //     })                  
                //     return HotelType
                //   }, []);                    
                    
                //   setHotelTypeOptions(hotelType);
            }
        );
        MasterService.GetDestination().then(res => {
            setDestination(res)
            //setSelectedDestination(res.filter(p => p?.name == enquiryState?.destination))
        });

        
    }, [])

    const onHotelTypeSelected = (hotelType) => {        
        console.log(hotelType)
        let type = hotelTypeOptions.find(t => t.id == hotelType.value)?.name;
        console.log(type)
        HotelService.GetHotels().then(
            res => {
                //setHotelList(res)
                setHotelList(res.filter(h => h.hotelType == type))
                setSelectedHotel(res.filter(h => h.hotelType == type && h.preferred))
                console.log(hotelType)
                console.log(res)
                console.log(res.filter(h => h.hotelType == type && h.preferred))
                
            }
        );
    }

    const onSelectedHotel = (hotel) => {
        //alert( JSON.stringify(hotel))        
    }

    const schema = yup.object().shape({
        //id: yup.string().min(3).max(40).required(),
        //name: yup.string().min(3).max(40).required("Name is required"),
        //phone: yup.string().min(10).required("Contact Number is required"),
        //email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    const formik = useFormik({
        initialValues: hotelBooking,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values, { resetForm }) {            
            selectedHotel.map((hotel) => {
                values.hotelId = hotel?.id;
                values.hotelType = hotel?.hotelType;
                values.hotelName = hotel?.name;
                values.mealPlan = mealPlanList.find(m => m.id == values.mealPlanId)?.name
                values.roomType = roomTypeOptions.find(m => m.id == values.roomTypeId)?.name      
                values.quotePrice  = hotel?.quotePrice
                props.quotation.quotationStay = JSON.parse(JSON.stringify([...props?.quotation?.quotationStay, values]));
                //alert(JSON.stringify(hotel))
            })
            props.onClose(false);
            resetForm({ values: '' });
            //setSelectedHotel([]);
        }
    });

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        // PackageService.GetPackages()
        //     .then(res => {
        //         setPackages(res.filter(p => p.destination == destination[0]?.name))
        //     });
    }

    return <div >
        <Form onSubmit={formik.handleSubmit} className="form">
            <Row>
                <Col>
                    <FormGroup>
                        <label>Destination</label>
                        <Typeahead
                            id="destination"
                            labelKey={(option) => `${option.name}`}
                            onChange={onSelectedDestination}
                            options={destination}
                            placeholder="Select Destination"
                            selected={selectedDestination}
                            clearButton
                        ></Typeahead>
                        {/* <Input
                            name="destination"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.destination}
                        >
                            {destinationOptions.map(hotelType => {
                                return <option value={hotelType.id}>{hotelType?.name}</option>;
                            })}

                        </Input> */}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Hotal Type</label>
                        <Input
                            name="hotalType"
                            type="select"
                            //onChange={onHotelTypeSelected}
                            onChange={e => onHotelTypeSelected(e.target)}
                            onBlur={formik.handleBlur}
                            value={formik.values.hotalTypeId}
                        >
                            {hotelTypeOptions.map(hotelType => {
                                return <option value={hotelType.id} tag={hotelType?.name} >{hotelType?.name}</option>;
                            })}

                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Hotel</label>
                        <Typeahead
                            id="hotel"
                            labelKey={(option) => ` ${option.name}`}
                            onChange={setSelectedHotel}
                            options={hotelList}
                            placeholder="Select Hotel"
                            selected={selectedHotel}
                            multiple
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Meal Plan</label>
                        <Input
                            name="mealPlanId"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mealPlanId}
                        >
                            {mealPlanList.map(mealPlan => {
                                return <option value={mealPlan.id}>{mealPlan?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col >
                    <FormGroup>
                        <label>Room Type</label>
                        <Input
                            name="roomTypeId"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.roomTypeId}
                        >
                            {roomTypeOptions.map(roomType => {
                                return <option value={roomType.id}>{roomType?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Pax/Room</label>
                        <Input
                            name="pax_Room"
                            placeholder="Pax per Room"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pax_Room}
                            maxLength={50}
                        />
                        {formik.touched.pax_Room && formik.errors.pax_Room && (
                            <span className='text-red-400'>{formik.errors.pax_Room}</span>
                        )}

                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Rooms</label>
                        <Input
                            name="rooms"
                            placeholder="Rooms"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rooms}
                            maxLength={50}
                        />
                        {formik.touched.rooms && formik.errors.rooms && (
                            <span className='text-red-400'>{formik.errors.rooms}</span>
                        )}

                    </FormGroup>
                </Col>
            </Row>

            <div style={{ marginTop: "2em" }}>
                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                    Save
                </Button>
                <Button onClick={() => { setHotelBooking({}); props.onClose(false) }} className="btn-fill" color="secondary">
                    Cancel
                </Button>
            </div>
        </Form >
    </div >
}

export default Stay;