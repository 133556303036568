import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    },    
});

async function GetProspect() {
  const response = await client.get(`prospect/getProspect`);
  console.info(response.data);
  return response?.data;
}

async function GetProspectById(id) {
  const response = await client.get(`prospect/GetProspectById?id=${id}`);
  console.info(response.data);
  return response?.data;
}

async function GetProspectEnquiries() {
  const response = await client.get(`prospect/getProspect`);
  console.info(response.data);
  return response?.data;
}



async function DeleteProspect(id) {
  const response = await client.delete(`prospect/deleteProspect?id=${id}`);
  return response?.data;
}

async function GetFollowUps() {
  const response = await client.get(API_URL + `follow-up/FollowUps`);
  console.info(response.data);
  return response?.data;
}

async function CreateProspect(prospect) {
  //alert(API_URL + `prospect​/AddProspect`)
  let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
    }
  const response = await client.post(`prospect/AddProspect`, prospect,config).catch(err=>console.log(err));
    //.catch(err => console.log(response));
  //const response = await axios.post(API_URL + `prospect​/AddProspect`, prospect);
  return response?.data;
}

const ProspectService = {
  GetProspect,
  CreateProspect,
  DeleteProspect,
  GetProspectEnquiries,
  GetProspectById
}

export default ProspectService;