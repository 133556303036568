import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import { Link, useLocation } from 'react-router-dom';
import Payment from "./Payment";
import Car from "./Car";
import Bus from "./Bus";
import Vehicle from "./Vehicle";
import ModalSlider from "../ModalSlider";
import { useState } from "react";
export default function TransportDetails(props) {
    const [sliderShow, setSliderShow] = useState(false);
    const showForm = () => { setSliderShow(!sliderShow) }

    const onTransportDelete = (e, transport, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            // QuotationService.DeleteTransport(id).then(res => {
            //     transport.splice(index, 1);
            //     setQuotation(JSON.parse(JSON.stringify(quotation)));
            // })
        }
    }

    return <>
        <div className="custom-card" style={{ borderBottom: "none" }}>
            <h3 style={{ borderBottom: "1px solid #aaa", padding: ".25em 0", marginTop: "1.5em" }} className="formheadings">Transport Details</h3>
            <div >
                <Table className="tablesorter" >
                    <thead className="text-primary">
                        {props.booking?.bookingTransport?.length > 0 && <tr>
                            <th>#</th>
                            <th>Transport Name</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Total</th>
                            <th></th>
                        </tr>}
                    </thead>
                    <tbody>
                        {props.booking?.bookingTransport?.map((transport, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{transport?.transportType}</td>
                                <td>{transport?.fromPlace}</td>
                                <td>{transport?.toPlace}</td>
                                <td>{transport?.quantity}</td>
                                <td>{transport?.rate}</td>
                                <td>{transport?.quantity * transport?.rate}</td>
                                <td><Link title="Remove Transport" onClick={(e) => { onTransportDelete(e, props.booking?.bookingTransport, index, transport?.id) }}><i class="fa-solid fa-trash"></i></Link></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <ModalSlider open={sliderShow} onClick={showForm} width='500' className={sliderShow ? ` showing` : ''}>
                <div className="slider card">
                    <div className="custom-card-grid">
                        <h3 className="formheadings">Add New Vehicle</h3>

                        <FormGroup>
                            <label>Travel Date</label>
                            <Input
                                type="date"
                                placeholder=""
                                name="type">
                            </Input>
                        </FormGroup>
                        <div className="grid-flex">
                            <FormGroup>
                                <label>Vehicle Type</label>
                                <Input
                                    type="select"
                                    placeholder=""
                                    name="type">
                                    <option value="Select">Select</option>
                                    <option value="Bus">Bus</option>
                                    <option value="Car">Car</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <label>Seats</label>
                                <Input
                                    type="text"
                                    placeholder=""
                                    name="type">

                                </Input>
                            </FormGroup>
                        </div>
                        <FormGroup>
                            <label>Vehicle No.</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="type">

                            </Input>
                        </FormGroup>



                        <FormGroup>
                            <label>Agent Name</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="pickuplocation">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>Agent's Number</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="droplocation">
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label>Pickup Location</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="pickuplocation">
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>Drop-off Location</label>
                            <Input
                                type="text"
                                placeholder=""
                                name="droplocation">
                            </Input>
                        </FormGroup>


                    </div>
                    <button style={{ width: "max-content", marginTop: "2em" }} className="btn btn-primary"> Save and Close</button>

                </div>



            </ModalSlider >
            <div style={{ gridColumn: '3/4', justifySelf: 'end', color: 'var(--primary)', cursor: "pointer", marginTop: ".5em" }} onClick={showForm}> + Add Vehicle</div>

        </div>

    </>
}