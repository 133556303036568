import { useState, useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;
const client = axios.create({
  baseURL: API_URL
});

async function GetQuotations() {
  const response = await client.get(`quotation/getQuotation`);
  console.info(response.data);
  return response.data;
}

async function GetQuotationsLeadId(leadId) {
  const response = await client.get(`Quotation/GetQuotationByLeadId?Id=${leadId}`);
  console.info(response.data);
  return response.data;
}

async function GetQuotationByProposalId(proposalId) {
  const response = await client.get(`Quotation/GetQuotationByProposalId?Id=${proposalId}`);
  console.info(response.data);
  return response.data;
}

async function GetQuotationByProspectId(prospectId) {
  const response = await client.get(`Quotation/GetQuotationByProspectId?Id=${prospectId}`);
  console.info(response.data);
  return response.data;
}



async function GetQuotationById(id) {
  const response = await client.get(`Quotation/GetQuotationById?id=${id}`);
  console.info(response.data);
  return response.data;
}

async function GetQuotationPrice(id) {
  const response = await client.get(`Quotation/GetQuotationPrice?QuotationId=${id}`);
  console.info(response.data);
  return response.data;
}

async function CreateQuotation(quotation) {
  const response = await client.post(`Quotation/AddQuotation`, quotation)
    .catch(err => console.log(response));
  return response.data;
}

async function DeleteQuotation(quotationId) {
  const response = await client.delete(`quotation/deleteQuotation?id=${quotationId}`);
  return response.data;
}

async function DeleteItinerary(Id) {
  const response = await client.delete(`quotation/DeleteItinerary?id=${Id}`);
  return response.data;
}


async function ConvertToProspect(quotationId) {
  const response = await client.post(`lead/convertToProspect?id=${quotationId}`);
  return response.data;
}

async function ConvertToCustomer(quotationId) {
  const response = await client.post(`lead/convertToCustomer?id=${quotationId}`);
  return response.data;
}

async function UploadImage(userFormData) {
  const response = await client.post(`quotation/UploadImage`, userFormData);
  return response.data;
}

async function Delete(userFormData) {
  const response = await client.post(`quotation/UploadImage`, userFormData);
  return response.data;
}

async function DeleteHotel(id) {
  const response = await client.delete(`quotation/DeleteHotel?id=${id}`);
  return response.data;
}

async function DeleteActivity(id) {
  const response = await client.delete(`quotation/DeleteActivity?id=${id}`);
  return response.data;
}

async function DeleteTransport(id) {
  const response = await client.delete(`quotation/DeleteTransport?id=${id}`);
  return response.data;
}

async function GetQuotationHotelById(id) {
  const response = await axios.get(API_URL + `quotation/GetQuotationHotelById?id=${id}`);
  console.info(response.data);
  return response?.data;
}


const QuotationService = {
  GetQuotations,
  CreateQuotation,
  DeleteQuotation,
  UploadImage,
  GetQuotationsLeadId,
  DeleteHotel,
  DeleteTransport,
  DeleteActivity,
  GetQuotationByProposalId,
  GetQuotationById,
  DeleteItinerary,
  GetQuotationPrice,
  GetQuotationByProspectId,
  GetQuotationHotelById
}

export default QuotationService;