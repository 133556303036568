import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Nav,
    NavLink
} from "reactstrap";
import HotelService from "services/hotel.service";
import { Typeahead } from "react-bootstrap-typeahead";
import MasterService from "services/master.service";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { onChange } from "@builder.io/react";

function TransportBookingList(props) {
    const [vehicalTypeOptions, setVehicalTypeOptions] = useState([{ id: 0, name: '' }, { id: 1, name: 'Bus' }, { id: 2, name: 'Car' }]);
    const [vehicalOptions, setVehicalOptions] = useState([{ id: 0, name: '' }, { id: 1, name: 'Volvo Ticket' }, { id: 2, name: 'Alto' }, { id: 3, name: 'Inova' }]);
    const [destination, setDestination] = useState([])
    const [state, setState] = useState([])
    const [selectedState, setSelectedState] = useState([])    
    const [selectedTransportFrom, setSelectedDestinationFrom] = useState([])
    const [selectedTransportTo, setSelectedDestinationTo] = useState([])
    const [days, setDays] = useState([])
    const [selectedDays, setSelectedDays] = useState([])
    const [quotationTransport, setQuotationTransport] = useState(
        {
            "id": 0,
            "quotationId": 0,
            "transportId": 0,
            "transportTypeId": 0,
            "distance": 0,
            "fromPlace": '',
            "fromPlaceId": 0,
            "toPlace": '',
            "toPlaceId": 0,
            "quantity": 0,
            "rate": 0,
            "proposeRate": 0,
            "transportType": ''
        });

    useEffect(() => {        
        MasterService.GetDestination().then(res => {
            setDestination(res)
        });

        MasterService.GetStates().then(res => {
            setState(res)
        });
        
        MasterService.GetCities().then(res => {
            setDestination(res)
        });

        setSelectedDestinationFrom([]);
        setSelectedDestinationTo([]);
        if (props?.prospect) {
            setDays(props.prospect?.days)
            quotationTransport.quantity = props?.prospect?.numberOfAdults;
        }
    }, [])


    useEffect(() => {
        if (props?.prospect) {
            setDays(props.prospect?.days)
            quotationTransport.quantity = props?.prospect?.numberOfAdults;
        }
    }, [props.prospect])


    // const onSelectedHotel = (hotel) => {        
    //     HotelService.GetRoomByHotelId(hotel[0]?.id).then(
    //         res => {
    //             setRoomList(res);
    //         }
    //     )
    // }

    const schema = yup.object().shape({
        //id: yup.string().min(3).max(40).required(),
        //name: yup.string().min(3).max(40).required("Name is required"),
        //phone: yup.string().min(10).required("Contact Number is required"),
        //email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    const formik = useFormik({
        initialValues: quotationTransport,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values, { resetForm }) {
            values.fromPlace = selectedTransportFrom[0]?.name;
            values.toPlace = selectedTransportTo[0]?.name;
            values.fromPlaceId = selectedTransportFrom[0]?.id;
            values.toPlaceId = selectedTransportTo[0]?.id;
            values.transportType = vehicalTypeOptions.filter(t => t.id == values.transportTypeId)[0]?.name
            values.days = selectedDays;
            props.addTransport(values);
            resetForm({ values: '' });
            setSelectedDestinationFrom([]);
            setSelectedDestinationTo([]);
            setSelectedDays([]);
            props.onClose(false);
        }
    });

    const onTransportTypeChange = (e) => {
        //alert(e.target.value)        
        if (e.target.value == 2) {
            formik.values.quantity = 0
        }
        else {
            formik.values.quantity = props.prospect.numberOfAdults;
        }
        formik.values.transportTypeId = e.target.value;
    }

    return <div >
        <Form onSubmit={formik.handleSubmit} className="form">
            <Row>
                <Col>
                    <FormGroup>
                        <label>Select Days</label>
                        <Typeahead
                            id="days"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedDays}
                            options={days}
                            placeholder="Select Days"
                            selected={selectedDays}
                            clearButton
                            multiple
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label>Destination</label>
                        <Typeahead
                            id="from"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedState}
                            options={state}
                            placeholder="Select Destination"
                            selected={selectedState}
                            clearButton
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <label>From City</label>
                        <Typeahead
                            id="from"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedDestinationFrom}
                            options={destination}
                            placeholder="Select Destination"
                            selected={selectedTransportFrom}
                            clearButton
                        ></Typeahead>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label>To</label>
                        <Typeahead
                            id="to"
                            labelKey={(option) => `${option.name}`}
                            onChange={setSelectedDestinationTo}
                            options={destination}
                            placeholder="Select Destination"
                            selected={selectedTransportTo}
                            clearButton
                        ></Typeahead>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <FormGroup>
                        <label>Vehicle Type</label>
                        <Input
                            name="transportTypeId"
                            type="select"
                            onChange={onTransportTypeChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.transportTypeId}
                        >
                            {vehicalTypeOptions.map(vehicalType => {
                                return <option value={vehicalType.id}>{vehicalType?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label>Vehicle</label>
                        <Input
                            name="transportId"
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.transportId}
                        >
                            {vehicalOptions.map(vehical => {
                                return <option value={vehical.id}>{vehical?.name}</option>;
                            })}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <FormGroup>
                        <label>Quantity</label>
                        <Input
                            name="quantity"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.quantity}
                        >
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label>Rate</label>
                        <Input
                            name="rate"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rate}
                        >
                        </Input>
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col>
                    Total : {formik.values.rate * formik.values.quantity}
                </Col>
            </Row>
            <div style={{ marginTop: "2em" }}>
                <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                    Save
                </Button>
                <Button onClick={() => { props.onClose(false) }} className="btn-fill" color="secondary">
                    Cancel
                </Button>
            </div>
        </Form>
    </div>
}

export default TransportBookingList;