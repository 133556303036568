import { useState, useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import axios, * as others from 'axios';
import BaseService from './base.service'

const API_URL = BaseService.API_URL;

const client = axios.create({
  baseURL: API_URL
});


async function GetPackages() {
  const response = await client.get(`package/getPackage`);
  console.info(response.data);
  return response.data;
}

async function GetPackageById(packageId) {
  const response = await client.get(`package/GetPackageById?id=${packageId}`);  
  return response.data;
}

async function CreatePackage(pkg) {
  //alert(JSON.stringify(pkg))
  const response = await client.post(`package/AddPackage`, pkg).catch(err => console.log(response));
  return response.data;
}

async function DeletePackage(packageId) {
  const response = await client.delete(`package/deletePackage?id=${packageId}`);
  return response.data;
}

async function ConvertToProspect(packageId) {
  const response = await client.post(`lead/convertToProspect?id=${packageId}`);
  return response.data;
}

async function ConvertToCustomer(packageId) {
  const response = await client.post(`lead/convertToCustomer?id=${packageId}`);
  return response.data;
}

async function UploadImage(userFormData) {
  const response = await client.post(`package/UploadImage`, userFormData);
  return response.data;
}

async function DownloadPackage(pkg) {
  const response = await client.post(`package/DownloadPackage`, pkg).catch(err => console.log(response));
  return response.data;
}

async function DeleteStay(id) {  
  //alert(id);
  const response = await client.delete(`package/deleteStay?id=${id}`).catch(err => console.log(response));
  return response.data;
}


async function DeleteItinerary(packageId, id) {    
  const response = await client.delete(`package/deleteItinerary?id=${id}&packageId=${packageId}`).catch(err => console.log(response));
  return response.data;
}



const PackageService = {
  GetPackages,
  CreatePackage,
  DeletePackage,
  UploadImage,
  DownloadPackage,
  DeleteStay,
  GetPackageById,
  DeleteItinerary
}

export default PackageService;