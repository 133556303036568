import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import { GetAllEnquiry, CreateEnquiry, SendGetRequest } from '../services/enquiry.service'
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import BaseService from "services/base.service";
import Address from "components/CustomComponents/Address";
import PackageService from "services/package.service";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,

} from "reactstrap";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from '../store/UserStore'
import UserService from "services/user.service";
import EnquiryService from "../services/enquiry.service";
import MasterService from "../services/master.service"
import ProspectService from '../services/prospect.service'
import { useEffect } from "react";
import Customerervice from "services/customer.service";
import CustomerService from "services/customer.service";
import { utils } from "common/utils";
import { NUMBER_OF_NIGHT } from "common/utils";
import { NUMBER_OF_ADULTS } from "common/utils";
import { BUDGET_PER_ADULT } from "common/utils";
import { NUMBER_OF_CHILD } from "common/utils";
import { NUMBER_OF_INFANT } from "common/utils";

function AddCustomer() {
    const navigate = useNavigate();
    const location = useLocation();
    const userList = useRecoilValue(userState);
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [users, setUsers] = useState({});
    const [selectedProspect, setSelectedProspect] = useState([]);
    const [enquiry, setEnquiry] = useState([]);
    const [packages, setPackages] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [enqueryType, setEnqueryType] = useState("Holiday");
    const [packageSelections, setPackageSelections] = useState([]);
    const [selectedEnquiryType, setSelectedEnquiryType] = useState([]);
    const [enquiryType, setEnquiryType] = useState([]);
    const [proposalList, setProposalList] = useState([]);
    const [expextedClosureDate, setExpextedClosureDate] = useState(new Date());
    const [followupDateAndTime, setFollowupDateAndTime] = useState(new Date());
    const [plannedTravelDate, setPlannedTravelDate] = useState(new Date());
    const [overAllBudget, setOverAllBudget] = useState(0);
    const [destination, setDestination] = useState([])
    const [selectedDestination, setSelectedDestination] = useState([])
    const [enquirySource, setEnquirySource] = useState([]);
    const [customer, setCustomer] = useState({
        id: 0,
        titleId: 1,
        name: "",
        email: "",
        phone: "",
        altPhone: "",
        userId: selectedUser,
        statusId: 0,
        reminderId: 0,
        modeId: 0,
        leadType: "",
        packageId: "",
        plannedTravelDate: "2023-05-29T02:56:50.567Z",
        destination: "",
        products: "",
        plannedNoOfDays: 0,
        numberOfAdults: 0,
        numberOfChildren: 0,
        budgetPerAdult: 0,
        budgetPerChild: 0,
        overAllBudget: 0,
        source: ""
    });


    useEffect(() => {

        if (location.state?.customer?.id) {
            const customerState = location.state?.customer;
            setCustomer(customerState)
            setSelectedProspect(location.state?.customer);
            setStartDate(new Date(customer.plannedTravelDate));
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                    setSelectedUser(res.filter(u => u.id == customerState.userId));
                });
            ProspectService.GetProspectEnquiries()
                .then(res => {
                    setEnquiry(res);
                    setSelectedEnquiry(res.filter(e => e.id == customerState.id))
                });
            //setPackageSelections(MasterService.getTourPackages?.filter(p => p.id == customerState.packageId))
            setEnqueryType(customerState.leadType);
            console.log(packageSelections);
        }
        else {
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                });
            EnquiryService.GetEnquiries()
                .then(res => {
                    setEnquiry(res);
                });
        }
    }, []);

    //submit
    const saveProspect = (prospect) => {
        ProspectService.saveEnquiry(prospect);
    }

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/customers`);
    }

    const handleChangeBudgetFor = (e) => {
        if (e.target.value == 1) {
            let adultsBudgut = formik.values.plannedNoOfDays * (formik.values.numberOfAdults * formik.values.budgetPerAdults);
            let childrenBudgut = formik.values.plannedNoOfDays * (formik.values.numberOfChild * formik.values.budgetPerChild);
            formik.values.overAllBudget = adultsBudgut + childrenBudgut;
        }
    }

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
        PackageService.GetPackages()
            .then(res => {
                setPackages(res.filter(p => p.destination == destination[0]?.name))
            });
    }

    //validation Schemas
    const schema = yup.object().shape({
        name: yup.string().min(3).max(40).required("Name is required"),
        phone: yup.string().min(10).required("Contact Number is required"),
        email: yup.string().email(),

        // numberOfAdults: yup.number().max(NUMBER_OF_ADULTS, `Value should be 0 to ${NUMBER_OF_ADULTS}`).min(0, `Value should be 0 to ${NUMBER_OF_ADULTS}`),
        // numberOfChild: yup.number().max(NUMBER_OF_CHILD, `Value should be 0 to ${NUMBER_OF_CHILD}`).min(0, `Value should be 0 to ${NUMBER_OF_CHILD}`),
        // numberOfInfant: yup.number().max(NUMBER_OF_INFANT, `Value should be 0 to ${NUMBER_OF_INFANT}`).min(0, `Value should be 0 to ${NUMBER_OF_INFANT}`),
        // plannedNoOfDays: yup.number().max(NUMBER_OF_NIGHT, `Value should be 0 to ${NUMBER_OF_NIGHT}`).min(0, `Value should be 0 to ${NUMBER_OF_NIGHT}`),
        // plannedTravelDate: yup.date().required().min(new Date()),
        // budgetPerAdults: yup.number().max(BUDGET_PER_ADULT, `Value should be 0 to ${BUDGET_PER_ADULT}`).min(0, `Value should be 0 to ${BUDGET_PER_ADULT}`),
        // budgetPerChild: yup.number().max(BUDGET_PER_ADULT, `Value should be 0 to ${BUDGET_PER_ADULT}`).min(0, `Value should be 0 to ${BUDGET_PER_ADULT}`),
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),        
    });

    const formik = useFormik({
        initialValues: customer,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            CustomerService.CreateCustomer(values)
        }
    });



    return <>
        <div className="content ">
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Add/Edit Customer</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <label>Trip Code</label>
                                        <Input
                                            name="leadCode"
                                            placeholder="Lead Code"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.leadCode}
                                            disabled="true"
                                        />
                                    </Col>
                                    <Col md="5">
                                        <FormGroup>
                                            <label>Name</label>
                                            <Input
                                                name="name"
                                                placeholder="Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <span className='text-red-400'>{formik.errors.name}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Contact</label>
                                            <Input
                                                name="phone"
                                                placeholder="Contact"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                            {formik.touched.phone && formik.errors.phone && (
                                                <span className='text-red-400'>{formik.errors.phone}</span>
                                            )}
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Email</label>
                                            <Input
                                                name="email"
                                                placeholder="email@mail.com"
                                                type="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <span className='text-red-400'>{formik.errors.email}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Lead Type</label>
                                            <Typeahead
                                                clearButton
                                                name="enquiryType"
                                                id="enquiryType"
                                                selected={selectedEnquiryType}
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                onChange={setSelectedEnquiryType}
                                                labelKey={(option) => `${option.name}`}
                                                options={enquiryType} />

                                            {formik.touched.enquiryType && formik.errors.enquiryType && (
                                                <span className='text-red-400'>{formik.errors.enquiryType}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="3">
                                        <FormGroup>
                                            <label>Status
                                            </label>
                                            <Input
                                                name="statusId"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.statusId}
                                            >
                                                {enquiryStatusoptions.map(enquiryStatus => {
                                                    return <option value={enquiryStatus.value}>{enquiryStatus.label}</option>;
                                                })}
                                            </Input>
                                            {formik.touched.enquiryStatus && formik.errors.enquiryStatus && (
                                                <span className='text-red-400'>{formik.errors.enquiryStatus}</span>
                                            )}

                                        </FormGroup>
                                    </Col> */}
                                    <Col md="4">
                                        <FormGroup >
                                            <label>Source</label>
                                            <Input
                                                name="leadSourceId"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.leadSourceId}>
                                                {
                                                    enquirySource.map((source) => (<option value={source?.id} >{source?.name}</option>)
                                                    )
                                                }

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><h5 className="title">Travel Details</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Destination</label>
                                            <Typeahead
                                                id="destination"
                                                labelKey={(option) => `${option.name}`}
                                                onChange={onSelectedDestination}
                                                options={destination}
                                                placeholder="Select Destination"
                                                selected={selectedDestination}
                                                clearButton
                                            ></Typeahead>
                                            {formik.touched.destination && formik.errors.destination && (
                                                <span className='text-red-400'>{formik.errors.destination}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Package</label>
                                            <Typeahead
                                                id="package"
                                                labelKey={(option) => `${option.id} ${option.packageName}`}
                                                onChange={setSelectedPackage}
                                                options={packages}
                                                placeholder="Select Package"
                                                selected={selectedPackage}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Tour Date</label>
                                            <DatePicker
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="plannedTravelDate"
                                                selected={plannedTravelDate}
                                                onChange={setPlannedTravelDate}
                                                minDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><h5 className="title">Budget</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Nights</label>
                                            <Input
                                                name="plannedNoOfDays"
                                                placeholder="Planned No Of Days"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.plannedNoOfDays}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_NIGHT) }}
                                            />

                                            {formik.touched.plannedNoOfDays && formik.errors.plannedNoOfDays && (
                                                <span className='text-red-400'>{formik.errors.plannedNoOfDays}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Number of Adults</label>
                                            <Input
                                                name="numberOfAdults"
                                                placeholder="Number of Adults"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfAdults}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_ADULTS) }}
                                            />
                                            {formik.touched.numberOfAdults && formik.errors.numberOfAdults && (
                                                <span className='text-red-400'>{formik.errors.numberOfAdults}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget per Adult</label>
                                            <Input
                                                name="budgetPerAdults"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetPerAdults}
                                                onKeyDown={(e) => { utils.preventMinus(e, BUDGET_PER_ADULT) }}
                                            />
                                            {formik.touched.budgetPerAdults && formik.errors.budgetPerAdults && (
                                                <span className='text-red-400'>{formik.errors.budgetPerAdults}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Children (6-12)</label>
                                            <Input
                                                name="numberOfChild"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfChild}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_CHILD) }}
                                            />
                                            {formik.touched.numberOfChild && formik.errors.numberOfChild && (
                                                <span className='text-red-400'>{formik.errors.numberOfChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget per Child</label>
                                            <Input
                                                name="budgetPerChild"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetPerChild}
                                                onKeyDown={(e) => { utils.preventMinus(e, BUDGET_PER_ADULT) }}
                                            />
                                            {formik.touched.budgetPerChild && formik.errors.budgetPerChild && (
                                                <span className='text-red-400'>{formik.errors.budgetPerChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Children (0-6)</label>
                                            <Input
                                                name="numberOfInfant"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.numberOfInfant}
                                                onKeyDown={(e) => { utils.preventMinus(e, NUMBER_OF_CHILD) }}
                                            />
                                            {formik.touched.numberOfChild && formik.errors.numberOfChild && (
                                                <span className='text-red-400'>{formik.errors.numberOfChild}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row><Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Over All Budget</label>
                                            <Input
                                                name="overAllBudget"
                                                placeholder=""
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.overAllBudget}
                                                onKeyDown={(e) => { utils.preventMinus(e, 9999999999) }}
                                            />
                                            {formik.touched.overAllBudget && formik.errors.overAllBudget && (
                                                <span className='text-red-400'>{formik.errors.overAllBudget}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Budget For</label>
                                            <Input
                                                name="budgetFor"
                                                type="select"
                                                onChange={handleChangeBudgetFor}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.budgetFor}>
                                                <option value="1" >Per Day</option>
                                                <option value="2" selected="true" >Over All</option>
                                            </Input>
                                            {formik.touched.budgetFor && formik.errors.budgetFor && (
                                                <span className='text-red-400'>{formik.errors.budgetFor}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12"><h5 className="title">Prospect Details</h5></Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Expexted Closure Date</label>
                                            <DatePicker
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="expextedClosureDate"
                                                selected={expextedClosureDate}
                                                onChange={setExpextedClosureDate}
                                                minDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Probablity</label>
                                            <Input
                                                name="probablity"
                                                placeholder=""
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.probablity}
                                            >
                                                <option value="1">Low</option>
                                                <option value="2">Medium</option>
                                                <option value="3">High</option>
                                            </Input>
                                            {formik.touched.probablity && formik.errors.probablity && (
                                                <span className='text-red-400'>{formik.errors.probablity}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Followup Date & Time</label>
                                            <DatePicker
                                                //showTimeInput
                                                isClearable
                                                showIcon
                                                className="form-control"
                                                dateFormat={'dd-MM-yyyy & HH:MM'}
                                                name="followupDateAndTime"
                                                selected={followupDateAndTime}
                                                minDate={new Date()}
                                                onChange={setFollowupDateAndTime} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Executive</label>
                                            <Typeahead
                                                clearButton
                                                id="UserId"
                                                labelKey={(option) => `${option.firstName} ${option.lastName}`}
                                                onChange={setSelectedUser}
                                                options={users}
                                                // multiple
                                                placeholder="Select User"
                                                selected={selectedUser}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><div style={{ marginTop: "2em", }}>
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                    </div></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <Row >
                                    <Col md="12">
                                        <div className="author">
                                            <div className="block block-one" />
                                            <div className="block block-two" />
                                            <div className="block block-three" />
                                            <div className="block block-four" />
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img
                                                    alt="..."
                                                    className="avatar"
                                                    src="https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
                                                />
                                                <h4 className="title">Vidhu Shekhar</h4>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <div style={{ fontWeight: "600" }}>B2C</div>
                                            <div style={{ fontWeight: "600" }}>9568235684</div>
                                            <div style={{ fontWeight: "600" }}>customer@gmail.com</div>
                                            <div > Address: <span style={{ fontWeight: "600" }}>Dwarka Mod</span> </div>
                                            <div > created On: <span style={{ fontWeight: "600" }}>12.08.2023</span> </div>
                                            <button onClick={() => {
                                                navigate(`${BaseService.NavigateToPath()}/add-customer`);
                                            }} style={{ marginTop: "2em" }} className="btn btn-primary">Edit Profile</button>

                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <div className="button-container">
                                    <Button className="btn-icon btn-round" color="facebook">
                                        <i className="fab fa-facebook" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="twitter">
                                        <i className="fab fa-twitter" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="google"  >
                                        <i className="fab fa-google-plus" />
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}
export default AddCustomer;