import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import Select from 'react-select'
import { Editor } from "@tinymce/tinymce-react";
import { Typeahead } from "react-bootstrap-typeahead";
import ImageUploading from "react-images-uploading";
import JsPDF from 'jspdf';
import BaseService from '../services/base.service'
import HotelService from "services/hotel.service";
import AddPackageItinerary from "./AddPackageItinerary";
import Stay from "components/CustomComponents/Hotel/Stay";
import ModalSlider from "components/CustomComponents/ModalSlider";
import ServiceBookingList from "components/CustomComponents/Services/Service";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle,
    Nav,
    NavLink,
    NavItem

} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MasterService from "services/master.service";
import PackageService from "services/package.service";
import TemplateService from 'services/template.service';
import { string } from "prop-types";
import ItineraryService from "services/quotation.service"
import HotelBookingList from "components/CustomComponents/Hotel/HotelBookingList";
import TransportBookingList from "components/CustomComponents/Transport/Transport"
import CRMEditor from "components/CRMEditor";

export default function AddPackage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
    const editorRef = useRef(null);
    const editorTandCRef = useRef(null);
    const editorIandERef = useRef(null);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [packages, setPackages] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [packageTemplate, setPackageTemplate] = useState([]);
    const [content, setContent] = useState("");
    const [contentCurrent, setContentCurrent] = useState("");
    const [contentIandE, setContentIandE] = useState("");
    const [contentIandECurrent, setContentIandECurrent] = useState("");
    const [contentTandC, setContentTandC] = useState("");
    const [contentTandCCurrent, setContentTandCCurrent] = useState("");
    const [coverImage, setCoverImage] = useState("");
    const [saveCoverImage, setSaveCoverImage] = useState("");
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [packageItinerary, setPackageItinerary] = useState([]);
    const [days, setDays] = useState([]);
    const [itineraryList, setItineraryList] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState([])
    const [destinationList, setDestination] = useState([])
    const [newPackage, setNewPackage] = useState(
        {
            id: 0,
            packageType: "",
            packageName: "",
            startTime: "",
            endTime: "",
            duration: "",
            destination: "",
            overView: "",
            details: "",
            images: "",
            coverImages: "",
            timeZone: "",
            adult: 65,
            kids: 35,
            infant: 0,
            enable: true,
            createdDate: "2023-07-27T16:28:30.438Z",
            createdBy: "",
            modifyDate: "2023-07-27T16:28:30.438Z",
            modifyBy: "",
            price: "",
            itinerary: "",
            contentIandE: "",
            contentTandC: contentTandCCurrent,
            margin: 0,
            discount: 0,
            packageItinerary: [
            ]

        }
    );

    let itn = {};

    const [staySlider, setStaySlider] = useState(false);
    const [selectedItinerary, setSelectedItinerary] = useState();
    const [activitySlider, setActivitySlider] = useState(false);
    const [transportSlider, setTransportSlider] = useState(false);
    const [hotelSlider, setHotelSlider] = useState(false);
    let itinerary = {
        "id": 0,
        "packageId": "",
        "day": "",
        "fromPlaceId": "",
        "fromPlace": '',
        "toPlaceId": 0,
        "toPlace": "",
        "description": "",
    }



    useEffect(() => {
        const pkg = location.state?.pkg;
        if (pkg?.id) {
            console.log(pkg)
            debugger;
            setNewPackage(pkg)
            setSelectedPackage(pkg)
            setSaveCoverImage(pkg.coverImages)
            setContent(pkg.details)
            setContentCurrent(pkg.details)
            setContentIandE(pkg.contentIandE)
            setContentIandECurrent(pkg.contentIandE)
            setContentTandC(pkg.contentTandC)
            setContentTandCCurrent(pkg.contentTandC)
            setCoverImage(BaseService.API_URL + pkg.coverImages);
        }
        setPackageType(MasterService.GetPackageType());
        setPackageTemplate(MasterService.GetPackageTemplate());

        TemplateService.GetTemplates().then(
            res => {
                setTemplates(res);
            }
        );

        MasterService.GetDestination().then(res => {
            setDestination(res)
            if (pkg?.id) {
                setSelectedDestination(res.filter(p => p?.name == pkg?.destination))
            }
        });

    }, []);

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/packages`);
    }

    const onSave = (packageId) => {
        // itinerary = (itineraryList.length > 0) ? itineraryList[0] : itinerary;
        // itinerary.packageId = packageId;
        // itinerary.day = day;
        // itinerary.fromPlaceId = formik.values.fromPlaceId;
        // itinerary.fromPlace = selectedItinerary?.fromPlace;
        // itinerary.toPlaceId = formik.values.toPlaceId;
        // itinerary.toPlace = formik.values.toPlace;;
        // itinerary.description = formik.values.description;
        // ItineraryService.CreateItinerary(packageItinerary).then(res => {
        //     //navigate(`${BaseService.NavigateToPath()}/packages`);
        //     navigate(-1);
        // });
    }

    //validation Schemas
    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
        // email: yup.string().required(),
        // phone: yup.string().min(3).max(40).required(),
        // address: yup.string().min(3).max(40).required(),
        // city: yup.string().min(3).max(40).required(),        
    });

    const formik = useFormik({
        initialValues: newPackage,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            debugger;
            formik.values.details = contentCurrent;
            formik.values.destination = selectedDestination[0]?.name;
            formik.values.contentIandE = contentIandECurrent;
            formik.values.contentTandC = contentTandCCurrent;
            formik.values.coverImages = saveCoverImage;
            console.log(values);
            PackageService.CreatePackage(values).then(
                (res) => {
                    resetForm();
                }
            )
        }
    });

    const onImgChange = (imageList) => {
        //alert(1)
        const userTemp = newPackage;
        // data for submit
        // Create an object of formData 
        const formData = new FormData();
        // Update the formData object 
        formData.append("userId", newPackage.id);
        formData.append("formFile", imageList[0].file);
        formData.append("fileName", imageList[0].file.name);
        PackageService.UploadImage(formData).then(
            res => {
                setSaveCoverImage('app-images/' + res)
                setCoverImage(BaseService.API_URL + 'app-images' + res);
            }
        );
    };
    
    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
    }

    const generatePDF = () => {
        const report = new JsPDF("p", "px", [1200, 1700]);
        report.marginLeft = 50;
        report.html('<div>' + editorRef.current.getContent() + '</div>').then(() => {
            report.save('report.pdf');
        });
    }

    const DownloadPackage = () => {
        PackageService.DownloadPackage(newPackage);
    }

    const onTemplateSelect = (template) => {
        setSelectedTemplate(template);
        if (template.length) {
            if (window.confirm("Are you sure want to apply")) {
                editorRef?.current?.setContent(template[0]?.content);
            }
        }
    }

    let day = {
        "id": 0,
        "dayId": 0,
        "quotationTransport": [],
        "quotationHotel": [],
        "quotationActivity": []
    };

    const addDay = () => {
        setActivitySlider(true)
        // day.dayId = days.length + 1;
        // setDays([...days, day]);
    }


    const removeDay = () => {
        PackageService.RemoveDay(newPackage);
    }


    const onChange = (dataContent) => {
        setContentCurrent(dataContent);
    }

    const onChangeIandE = (dataContent) => {
        setContentIandECurrent(dataContent);
    }

    const onChangeTandC = (dataContent) => {
        setContentTandCCurrent(dataContent);

    }

    const onActivateTandC = (dataContent) => {
        //alert(contentTandCCurrent)        
        console.log('Activate ' + dataContent)
        setContentTandC(contentTandCCurrent);
    }


    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
    }

    const onDeleteItinerary = (e, packageItinerary, index) => {
        if (window.confirm("Are you sure want to delete?")) {
            let id = packageItinerary[index]?.id
            PackageService.DeleteItinerary(newPackage?.id, id).then(
                res => {
                    newPackage.packageItinerary.splice(index, 1);
                    setNewPackage(JSON.parse(JSON.stringify(newPackage)));
                }
            );
        }
    }

    const addItinerary = useCallback((itinerary) => {
        //alert(itinerary)
        console.log(newPackage?.packageItinerary)
        if (newPackage.packageItinerary == null) {
            newPackage.packageItinerary = [];
        }
        newPackage.packageItinerary = [...newPackage?.packageItinerary, itinerary];
        setNewPackage(JSON.parse(JSON.stringify(newPackage)));
    }, [newPackage]);

    const addHotelBooking = useCallback((hotelBooking) => {
        //console.log(hotelBooking)
        //console.log(newPackage.packageItinerary)
        //setNewPackage(JSON.parse(JSON.stringify(newPackage)));
        //console.log(newPackage)
        //alert(JSON.stringify(newPackage.packageItinerary.hotel))
    }, [newPackage]);

    const addTransport = useCallback((transports, itinerary) => {
        console.log(transports)
        console.log(selectedItinerary)

        if (selectedItinerary == undefined || selectedItinerary?.transport == null) {
            selectedItinerary.transport = []
        }
        selectedItinerary.transport = [...selectedItinerary.transport, transports];
        //setSelectedItinerary(JSON.parse(JSON.stringify(itinerary)));
        console.log(selectedItinerary)

        let itnrIndex = newPackage.packageItinerary.findIndex(pi => pi.activityId == itinerary.activityId);
        newPackage.packageItinerary.splice(itnrIndex, 1);
        //newPackage.packageItinerary = [...newPackage?.packageItinerary, itinerary];
        newPackage.packageItinerary.splice(itnrIndex, 0, itinerary);
        setNewPackage(JSON.parse(JSON.stringify(newPackage)));
    }, [newPackage]);

    const onHotelDelete = (e, hotels, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            hotels.splice(index, 1);
            // PackageService.DeleteItineraryHotel(newPackage?.id, id).then(
            //     res => {
            //         newPackage.packageItinerary.splice(index, 1);
            //         setNewPackage(JSON.parse(JSON.stringify(newPackage)));
            //     }
            // );          
        }

    }

    const onTransportDelete = (e, transport, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            transport.splice(index, 1);
            // PackageService.DeleteItineraryTransport(newPackage?.id, id).then(
            //     res => {
            //         newPackage.packageItinerary.splice(index, 1);
            //         setNewPackage(JSON.parse(JSON.stringify(newPackage)));
            //     }
            // );
        }

    }

    return <>
        <div className="content">

            <Row>
                <Col md="8">
                    <Card style={{ padding: "1em" }}>
                        <CardHeader>
                            <h4 className="title">Create/Edit Package</h4>
                        </CardHeader>
                        <CardBody>
                            <Tabs>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    {/* <Tab>Stay</Tab> */}
                                    <Tab>Itinerary</Tab>
                                    <Tab>Inclusions & Exclusions</Tab>
                                    <Tab>Term & Conditions</Tab>
                                </TabList>

                                <TabPanel>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <div id="report">
                                                    {/* <Editor style={{ border: '1px solid black' }} apiKey={'ih9a01slzwix1sdsazp38f3vg14m7zqiyzlai5724hb7y8wb'}
                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                        initialValue={content}
                                                        onEditorChange={onChange}
                                                        onBeforeSetContent={() => { setContent(contentCurrent) }}
                                                        init={{
                                                            selector: 'div#image-tools',
                                                            height: "480",
                                                            menubar: true,
                                                            toolbar_mode: 'wrap',
                                                            //inline: true,
                                                            paste_data_images: true,
                                                            template_mdate_format: '%m/%d/%Y : %H:%M',
                                                            template_replace_values: {
                                                                username: 'Jack Black',
                                                                staffid: '991234',
                                                                inboth_username: 'Famous Person',
                                                                inboth_staffid: '2213',
                                                            },
                                                            template_preview_replace_values: {
                                                                preview_username: 'Jack Black',
                                                                preview_staffid: '991234',
                                                                inboth_username: 'Famous Person',
                                                                inboth_staffid: '2213',
                                                            },
                                                            templates: templates,
                                                            //toolbar: 'template | export | undo redo | formatselect | ' +
                                                            //    'export | bold italic backcolor | alignleft aligncenter ' +
                                                            //   'alignright alignjustify | bullist numlist outdent indent | link image' +
                                                            //   'removeformat | help',
                                                            plugins: 'code template anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                            //toolbar: 'code template | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough '+
                                                            //' link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                            toolbar: [
                                                                { name: 'history', items: ['undo', 'redo'] },
                                                                { name: 'styles', items: ['styles'] },
                                                                { name: 'formatting', items: ['bold', 'italic'] },
                                                                { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
                                                                { name: 'indentation', items: ['outdent', 'indent'] }
                                                            ],
                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }} /> */}

                                                        <CRMEditor>
                                                            
                                                        </CRMEditor>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Link onClick={addDay}>+Add Day</Link>
                                    {newPackage?.packageItinerary?.map((itin, i) => {
                                        return (<>
                                            <br></br><b> Day - {i + 1} : {itin.activityName}</b>
                                            <br /> {itin.description}
                                            <br />

                                            <Table className="tablesorter">
                                                <thead className="text-primary">
                                                    {itin?.hotel?.length > 0 && <tr>
                                                        <th>#</th>
                                                        <th>Hotel Type</th>
                                                        {/* <th>Hotel Name</th> */}
                                                        <th>Meal Plan</th>
                                                        <th>Room Type</th>
                                                        {/* <th>Pax/Room</th> */}
                                                        <th>Rooms</th>
                                                        <th className="text-center">AWEB</th>
                                                        <th className="text-center">CWEB</th>
                                                        <th className="text-center">CNB</th>
                                                        <th className="text-center">Days</th>
                                                        <th className="text-center">Total</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>}
                                                </thead>
                                                <tbody>
                                                    {itin?.hotel?.map((hotel, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            {/* <td>{hotel?.hotelTypeId || hotel?.hotelTypeId}</td> */}
                                                            <td>{hotel?.hotelTypeName || hotel?.roomPrice?.hotelType}</td>
                                                            <td>{hotel?.mealPlan || hotel?.roomPrice?.mealPlan}</td>
                                                            <td>{hotel?.roomType || hotel?.roomPrice?.roomType}</td>
                                                            {/* <td>{hotel?.pax_Room || hotel?.pax_Room}</td> */}
                                                            <td>{hotel?.rooms} x {hotel?.roomPrice?.basePrice}</td>
                                                            <td>{hotel?.aweb} x {hotel?.roomPrice?.awebQuotePrice} </td>
                                                            <td>{hotel?.cweb} x {hotel?.roomPrice?.cwebQuotePrice}</td>
                                                            <td>{hotel?.cnb} x {hotel?.roomPrice?.cnbQuotePrice}</td>
                                                            <td>{hotel?.days || 1}</td>
                                                            <td>{((hotel?.cnb && hotel?.cnb * hotel?.roomPrice?.cnbQuotePrice) + (hotel?.cweb && hotel?.cweb * hotel?.roomPrice?.cwebQuotePrice) + (hotel?.aweb && hotel?.aweb * hotel?.roomPrice?.awebQuotePrice) + (hotel?.rooms && hotel?.rooms * hotel?.roomPrice?.basePrice)) * (hotel?.days || 1)}</td>
                                                            <td className="text-center">
                                                                <Link onClick={(e) => {
                                                                    onHotelDelete(e, itin?.hotel, index, hotel?.id);
                                                                }} title="Delete"> <i className="fa fa-trash-o"></i> </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                            <Table className="tablesorter" >
                                                <thead className="text-primary">
                                                    {itin?.transport?.length > 0 && <tr>
                                                        <th>#</th>
                                                        <th>Transport Name</th>
                                                        <th>From</th>
                                                        <th>To</th>
                                                        <th>Quantity</th>
                                                        <th>Rate</th>
                                                        <th>Total</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>}
                                                </thead>
                                                <tbody>
                                                    {itin?.transport?.map((transport, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{transport?.transportType}</td>
                                                            <td>{transport?.fromPlace}</td>
                                                            <td>{transport?.toPlace}</td>
                                                            <td>{transport?.quantity}</td>
                                                            <td>{transport?.rate}</td>
                                                            <td>{transport?.quantity * transport?.rate}</td>
                                                            <td className="text-center">
                                                                <Link onClick={(e) => {
                                                                    onTransportDelete(e, itin?.transport, index, transport?.id);
                                                                }} title="Delete"> <i className="fa fa-trash-o"></i> </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Link title="Remove Day" onClick={(e) => onDeleteItinerary(e, newPackage?.packageItinerary, i)}><i class="fa fa-trash"></i></Link>&nbsp;
                                            <Link title="Add Hotel" onClick={(e) => { setSelectedItinerary(itin); setHotelSlider(true) }}><i class="fa-solid fa-hotel"></i></Link> &nbsp;
                                            <Link title="Add Transport" onClick={(e) => { setSelectedItinerary(itin); itn = itin; setTransportSlider(true) }}><i class="fa fa-bus"></i></Link>
                                            <hr></hr>
                                        </>)
                                    })}


                                    {/* <AddPackageItinerary itinerary={packageItinerary} /> */}
                                </TabPanel>
                                <TabPanel>
                                    <div id="report">
                                        <Editor style={{ border: '1px solid black' }} apiKey={'ih9a01slzwix1sdsazp38f3vg14m7zqiyzlai5724hb7y8wb'}
                                            onInit={(evt, editor) => editorIandERef.current = editor}
                                            initialValue={contentIandE}
                                            onEditorChange={onChangeIandE}
                                            onBeforeSetContent={() => { setContentIandE(contentIandECurrent) }}
                                            init={{
                                                //selector: 'div#image-tools',
                                                height: "480",
                                                menubar: true,
                                                toolbar_mode: 'wrap',
                                                paste_data_images: true,
                                                template_mdate_format: '%m/%d/%Y : %H:%M',
                                                template_replace_values: {
                                                    username: 'Jack Black',
                                                    staffid: '991234',
                                                    inboth_username: 'Famous Person',
                                                    inboth_staffid: '2213',
                                                },
                                                template_preview_replace_values: {
                                                    preview_username: 'Jack Black',
                                                    preview_staffid: '991234',
                                                    inboth_username: 'Famous Person',
                                                    inboth_staffid: '2213',
                                                },
                                                templates: templates,
                                                plugins: 'code template anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                toolbar: [
                                                    { name: 'history', items: ['undo', 'redo'] },
                                                    { name: 'styles', items: ['styles'] },
                                                    { name: 'formatting', items: ['bold', 'italic'] },
                                                    { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
                                                    { name: 'indentation', items: ['outdent', 'indent'] }
                                                ],
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }} />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div id="report">
                                        <Editor style={{ border: '1px solid black' }} apiKey={'ih9a01slzwix1sdsazp38f3vg14m7zqiyzlai5724hb7y8wb'}
                                            onInit={(evt, editor) => editorTandCRef.current = editor}
                                            initialValue={contentTandC}
                                            onEditorChange={onChangeTandC}
                                            onBeforeSetContent={() => { setContentTandC(contentTandCCurrent) }}
                                            init={{
                                                //selector: 'div#image-tools',
                                                height: "480",
                                                menubar: true,
                                                toolbar_mode: 'wrap',
                                                //inline: true,
                                                paste_data_images: true,
                                                template_mdate_format: '%m/%d/%Y : %H:%M',
                                                template_replace_values: {
                                                    username: 'Jack Black',
                                                    staffid: '991234',
                                                    inboth_username: 'Famous Person',
                                                    inboth_staffid: '2213',
                                                },
                                                template_preview_replace_values: {
                                                    preview_username: 'Jack Black',
                                                    preview_staffid: '991234',
                                                    inboth_username: 'Famous Person',
                                                    inboth_staffid: '2213',
                                                },
                                                templates: templates,
                                                plugins: 'code template anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                toolbar: [
                                                    { name: 'history', items: ['undo', 'redo'] },
                                                    { name: 'styles', items: ['styles'] },
                                                    { name: 'formatting', items: ['bold', 'italic'] },
                                                    { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
                                                    { name: 'indentation', items: ['outdent', 'indent'] }
                                                ],
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }} />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Form onSubmit={formik.handleSubmit} className="form">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Package Attribute</h4>
                            </CardHeader>
                            <CardBody>
                                <Tabs>
                                    <TabList>
                                        <Tab>Details </Tab>
                                        <Tab>Img</Tab>
                                        <Tab>Attribute</Tab>
                                        <Tab>Template</Tab>

                                    </TabList>
                                    <TabPanel>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Package Name</label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Package Name"
                                                        name="packageName"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageName}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Package Code</label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Package Code"
                                                        name="packageCode"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageCode}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="5">
                                                <FormGroup>
                                                    <label>Package Type</label>
                                                    <Input
                                                        type="select"
                                                        placeholder="Package Type"
                                                        name="packageType"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageType}>
                                                        {packageType?.map((pt) => (<option value={pt.id}>{pt.name}</option>))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="7">
                                                <FormGroup>
                                                    <label>Destination</label>
                                                    <Typeahead
                                                        id="destination"
                                                        labelKey={(option) => `${option.name}`}
                                                        onChange={onSelectedDestination}
                                                        options={destinationList}
                                                        placeholder="Select Destination"
                                                        selected={selectedDestination}
                                                        clearButton
                                                    ></Typeahead>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Overview</label>
                                                    <Input style={{ height: '100px !important' }}
                                                        type="textarea"
                                                        placeholder="Heading"
                                                        name="overView"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.overView}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>% per Adult </label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Price per Adult"
                                                        name="adult"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.adult}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>%  per Child </label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Price"
                                                        name="kids"
                                                        Row="5"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.kids}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Discount</label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Discount"
                                                        name="discount"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.discount}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Margin</label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Margin"
                                                        name="margin"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.margin}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </TabPanel>
                                    <TabPanel>
                                        <Row>
                                            <Col>
                                                <img
                                                    alt="..."
                                                    //className="avatar"
                                                    src={coverImage}
                                                />
                                            </Col>
                                            <Col md="12">
                                                <ImageUploading onChange={onImgChange}>
                                                    {({ imageList, onImageUpload }) => (
                                                        // write your building UI
                                                        <div className="imageuploader">
                                                            <div className="mainBtns">
                                                                {/* <button className="btn btn-primary mr-1  uploadbtn" onClick={onImageUpload}>Upload Image</button> */}
                                                                <Link onClick={onImageUpload}>Upload Image</Link>
                                                            </div>
                                                            {imageList.map((image) => (
                                                                <div className="imagecontainer" key={image.key}>
                                                                    <img src={image.dataURL} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col md="8">
                                                <Input type="text" placeholder="Name"></Input>
                                            </Col>
                                            <Col md="3">
                                                <Input type="number" placeholder="Price"></Input>
                                            </Col>
                                            <Col md="1">
                                                <a className="fas fa-add" href="#"></a>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel>
                                        <Row >
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Package Template</label>
                                                    <Typeahead
                                                        id="package"
                                                        labelKey={(option) => `${option.title}`}
                                                        onChange={(e) => { onTemplateSelect(e) }}
                                                        options={templates}
                                                        placeholder="Select Package"
                                                        selected={selectedTemplate}
                                                        clearButton
                                                    ></Typeahead>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                </Tabs>
                            </CardBody>
                            <CardFooter>
                                <div>
                                    <Button style={{ marginRight: "1em" }} disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                        Save
                                    </Button>
                                    <Button onClick={resetForm} className="btn-fill" color="secondary">
                                        Cancel
                                    </Button>
                                    <Button onClick={DownloadPackage} >Export</Button>
                                    <Button onClick={() => navigate(-1)} >Back</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <ModalSlider open={activitySlider} width={450} onClick={() => setActivitySlider(false)} className={activitySlider ? ` showing` : ''}>
                <Card  >
                <CardHeader>
                <h4 className="title" >Add Day</h4>
                    </CardHeader>
                    <CardBody>
                        <ServiceBookingList addItinerary={addItinerary} onClose={setActivitySlider} />
                    </CardBody>
                </Card>

            </ModalSlider>

            <ModalSlider open={hotelSlider} width={450} onClick={() => setHotelSlider(false)} className={hotelSlider ? ` showing` : ''}>
                <Card >
                <CardHeader>
                <h4 className="title" >Add Hotel</h4>
                    </CardHeader>
                    <CardBody>
                        <HotelBookingList destination={newPackage?.destination} addHotelBooking={addHotelBooking} SelectedItinerary={selectedItinerary} onClose={setHotelSlider} />
                    </CardBody>
                </Card>
            </ModalSlider>

            <ModalSlider open={transportSlider} width={450} onClick={() => setTransportSlider(false)} className={transportSlider ? ` showing` : ''}>
                <Card >
                <CardHeader>
                        <h4 className="title" >Add Transport</h4>
                    </CardHeader>
                    <CardBody>
                        <TransportBookingList addTransport={addTransport} SelectedItinerary={selectedItinerary} onClose={setTransportSlider} />
                    </CardBody>
                </Card>
            </ModalSlider>

        </div>
    </>
}