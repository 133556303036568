import React, { useState, useEffect } from "react";
import { json, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import EnquiryService from '../services/enquiry.service'
import Select from 'react-select'
import "../assets/css/enqform.css"
import { Radio, RadioGroup } from 'react-radio-group'
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ImageUploading from "react-images-uploading";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    Nav,
    NavLink,
    NavItem
} from "reactstrap";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from '../store/UserStore'
import UserService from "services/user.service";
import PackageService from "services/package.service";
import BaseService from "services/base.service";

function ClientProfile() {
    const navigate = useNavigate();
    const location = useLocation();
    const userList = useRecoilValue(userState);
    const [selectedValue, setSelectedValue] = useState([]);
    const [inputValue, setValue] = useState('');
    const [enqueryType, setEnqueryType] = useState("Holiday");
    const [singleSelections, setUserSelections] = useState([]);
    const [packageSelections, setPackageSelections] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [enquiry, setEnquiry] = useState(
        {
            "id": 1,
            "name": "Sagar ",
            "email": "Sagar@email.com",
            "phone": "9955859199",
            "userId": 1,
            "statusId": 1,
            "reminderId": 0,
            "modeId": 0,
            "leadType": "Holiday",
            "packageId": "",
            "plannedTravelDate": new Date(),
            "destination": "Shimla",
            "Enquries": 100
        });

    //console.log(startDate);
    //registerLocale('en', en)
    // setDefaultLocale('en');

    useEffect(() => {
        PackageService.GetPackages().then(res => {
            setPackages(res)

        })

        if (location.state?.enquiry?.id) {
            const enquiryState = location.state?.enquiry;
            setEnquiry(location.state?.enquiry);
            setStartDate(new Date(enquiryState.plannedTravelDate));
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                    setUserSelections(res.filter(u => u.id == enquiryState.userId));
                });
            //setPackageSelections(tourPackages?.filter(p => p.id == enquiryState.packageId))
            setEnqueryType(enquiryState.leadType);
            console.log(packageSelections);
        }
        else {
            UserService.GetUsers()
                .then(res => {
                    setUsers(res);
                });
        }
    }, []);

    const getUserOption = () => {
        console.info(userList)
        return userList.map(user => {
            return <option value={user.id}>{user.firstName}</option>;
        });
    }

    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        name: yup.string().min(3).max(40).required("Name is required"),
        phone: yup.string().min(10).required("Contact Number is required"),
        email: yup.string().email().min(3),
        //package: '',
        //enquiryType: '',
        //enquiryStatus: '',
        //enquirySource: '',
        //executiveId: 0,
        //followUp: "",
        //message: ""
    });

    //saving enquiry after calling createEnquiry        
    const saveEnquiry = async (enquiryData) => {
        EnquiryService.CreateEnquiry(enquiryData).then(res => {
            resetForm();
        })
    };


    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/enquiries`);
    }

    const formik = useFormik({
        initialValues: enquiry,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            saveEnquiry(values);
        }
    });

    // handle input change event
    const handleInputChange = (date) => {
        setStartDate(date);
        formik.values.plannedTravelDate = date;
    };

    // handle selection
    const handleChange = (e) => {
        setUserSelections(e)
        formik.values.userId = e[0]?.id;
    }

    const handlePackageChange = (e) => {
        setPackageSelections(e)
        formik.values.packageId = e[0]?.id;
    }

    const handleOnChange = (e) => {
        console.log(e);
        setEnqueryType(e);
        formik.values.leadType = e;
    }


    // Image related Code
    const onImgChange = (imageList) => {
        // // const userTemp = user;
        // // data for submit
        // // Create an object of formData 
        // const formData = new FormData();
        // // Update the formData object 
        // formData.append("userId", user.id);
        // formData.append("formFile", imageList[0].file);
        // formData.append("fileName", imageList[0].file.name);

        // // UserService.UploadImage(formData).then(
        // //   res => {
        // //     userTemp.picture = res;
        // //     setUser({ user: userTemp });
        // //   }
        // // );

    };

    const handleEditorChange = () => {

    }

    const getImage = (user) => {
        // console.log(API_URL + `app-images${user?.picture}`)
        // if (user?.picture)
        //   return API_URL + `app-images${user?.picture}`
        // else
        //   return "https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
    }
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title new">Customer Profile</h4>
                            </CardHeader>
                            <CardBody>
                                <Row style={{ padding: "2em 0", marginBottom: "2em" }}>
                                    <Col md="3">
                                        <div style={{ display: "grid", placeItems: "center" }}>
                                            <div style={{ fontSize: "30px" }}>1</div>
                                            <div>Enquiries Made</div>
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div style={{ display: "grid", placeItems: "center" }}>
                                            <div style={{ fontSize: "30px" }}>5</div>
                                            <div>Proposals Sent</div>
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div style={{ display: "grid", placeItems: "center" }}>
                                            <div style={{ fontSize: "30px" }}>2</div>
                                            <div>Bookings</div>
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div style={{ display: "grid", placeItems: "center" }}>
                                            <div style={{ fontSize: "30px" }}>₹25000</div>
                                            <div>Total Revenue</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Enquired</Tab>
                                                <Tab>Proposal Sent</Tab>
                                                <Tab>Payment History</Tab>
                                                <Tab>Family Member</Tab>
                                                <Tab>Documents</Tab>
                                            </TabList>
                                            <TabPanel>

                                                <Table className="tablesorter" responsive>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Enquiry Id</th>
                                                            <th>Enquird on</th>
                                                            <th>Handaled By</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>EQ0001</td>
                                                            <td>10-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>EQ0002</td>
                                                            <td>10-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>EQ0003</td>
                                                            <td>1-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>

                                            </TabPanel>
                                            <TabPanel>

                                                <Table className="tablesorter" responsive>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Proposal Id</th>
                                                            <th>Enquird on</th>
                                                            <th>Handaled By</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>PR0001</td>
                                                            <td>10-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>PR0002</td>
                                                            <td>10-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>PR0003</td>
                                                            <td>11-02-2023</td>
                                                            <td>Vidhu</td>
                                                            <td>
                                                                <Nav>
                                                                    <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                    <NavLink onClick={(e) => { }}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                                </Nav>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </TabPanel>
                                            <TabPanel>

                                            </TabPanel>
                                            <TabPanel>
                                                <Table responsive>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Contact Details</th>
                                                            <th>Gender</th>
                                                            <th>Married</th>
                                                            <th>Relationship</th>
                                                            <th>Date Of Birth</th>
                                                            <th>Anniversary</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <td>9568235684</td>
                                                        <td>Male</td>
                                                        <td>yes</td>
                                                        <td>Spouse</td>
                                                        <td>18.12.2000</td>
                                                        <td>30 February</td>
                                                        <td>
                                                            <Nav style={{ flexDirection: "row" }}>
                                                                <NavLink onClick={(e) => { }}><i title="Follow-up" className="far fa-bell" /> </NavLink>
                                                                <NavLink onClick={(e) => { }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                                <NavLink onClick={(e) => { }}><i title="Timeline" className="fas fa-clock" /> </NavLink>
                                                            </Nav>
                                                        </td>
                                                    </tbody>
                                                </Table>
                                            </TabPanel>
                                            <TabPanel>

                                            </TabPanel>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <Row >
                                    <Col md="12">
                                        <div className="author">
                                            <div className="block block-one" />
                                            <div className="block block-two" />
                                            <div className="block block-three" />
                                            <div className="block block-four" />
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img
                                                    alt="..."
                                                    className="avatar"
                                                    src="https://ik.imagekit.io/2q7cgnqzi/default-avatar.png?updatedAt=1686427878861"
                                                />
                                                <h4 className="title">Vidhu Shekhar</h4>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <div style={{ fontWeight: "600" }}>B2C</div>
                                            <div style={{ fontWeight: "600" }}>9568235684</div>
                                            <div style={{ fontWeight: "600" }}>customer@gmail.com</div>
                                            <div > Address: <span style={{ fontWeight: "600" }}>Dwarka Mod</span> </div>
                                            <div > created On: <span style={{ fontWeight: "600" }}>12.08.2023</span> </div>
                                            <button onClick={() => {
                                                navigate(`${BaseService.NavigateToPath()}/add-customer`);
                                            }} style={{ marginTop: "2em" }} className="btn btn-primary">Edit Profile</button>

                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <div className="button-container">
                                    <Button className="btn-icon btn-round" color="facebook">
                                        <i className="fab fa-facebook" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="twitter">
                                        <i className="fab fa-twitter" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="google"  >
                                        <i className="fab fa-google-plus" />
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ClientProfile;
