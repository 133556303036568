import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, * as others from 'axios';
import { useFormik } from 'formik';
import Select from 'react-select'
import { Editor } from "@tinymce/tinymce-react";
import { Typeahead } from "react-bootstrap-typeahead";
import ImageUploading from "react-images-uploading";
import JsPDF from 'jspdf';
import BaseService from '../services/base.service'
import HotelService from "services/hotel.service";
import AddPackageItinerary from "./AddPackageItinerary";
import Stay from "components/CustomComponents/Hotel/Stay";
import ModalSlider from "components/CustomComponents/ModalSlider";
import ServiceBookingList from "components/CustomComponents/Services/Service";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    CardTitle,
    Nav,
    NavLink,
    NavItem

} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MasterService from "services/master.service";
import PackageService from "services/package.service";
import TemplateService from 'services/template.service';
import { string } from "prop-types";
import QuotationService from "services/quotation.service"
import HotelBookingList from "components/CustomComponents/Hotel/HotelBookingList";
import TransportBookingList from "components/CustomComponents/Transport/Transport"
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import ProspectService from "services/prospect.service";
import moment, * as moments from 'moment';

export default function AddPackage() {
    window.moment = moment
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
    const editorRef = useRef(null);
    const editorTandCRef = useRef(null);
    const editorIandERef = useRef(null);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [packages, setPackages] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [packageTemplate, setPackageTemplate] = useState([]);
    const [content, setContent] = useState("");
    const [contentCurrent, setContentCurrent] = useState("");
    const [contentIandE, setContentIandE] = useState("");
    const [contentIandECurrent, setContentIandECurrent] = useState("");
    const [contentTandC, setContentTandC] = useState("");
    const [contentTandCCurrent, setContentTandCCurrent] = useState("");
    const [coverImage, setCoverImage] = useState("");
    const [saveCoverImage, setSaveCoverImage] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [packageItinerary, setPackageItinerary] = useState([]);
    const [days, setDays] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState([])
    const [destinationList, setDestination] = useState([])
    const [quotation, setQuotation] = useState(
        {
            id: 0,
            packageType: "",
            packageName: "",
            startTime: "",
            endTime: "",
            duration: "",
            destination: "",
            overView: "",
            details: "",
            images: "",
            coverImages: "",
            timeZone: "",
            adult: 0,
            kids: 0,
            infant: 0,
            enable: true,
            createdDate: "2023-07-27T16:28:30.438Z",
            createdBy: "",
            modifyDate: "2023-07-27T16:28:30.438Z",
            modifyBy: "",
            price: "",
            itinerary: "",
            contentIandE: "",
            contentTandC: contentTandCCurrent,
            margin: 0,
            discount: 0,
            proposalId: 0,
            transport: [],
            hotel: [],
            packageItinerary: []
        }
    );

    const [staySlider, setStaySlider] = useState(false);
    const [selectedItinerary, setSelectedItinerary] = useState();
    const [activitySlider, setActivitySlider] = useState(false);
    const [transportSlider, setTransportSlider] = useState(false);
    const [hotelSlider, setHotelSlider] = useState(false);
    const [proposal, setProposal] = useState({});
    const [plannedTravelDate, setPlannedTravelDate] = useState(new Date());
    const [quotationPrice, setQuotationPrice] = useState([]);
    const [prospect, setProspect] = useState({
        "id": 0,
        "name": "",
        "email": "",
        "phone": "",
        "userId": 0,
        "statusId": 0,
        "reminderId": 0,
        "modeId": 0,
        "leadType": "",
        "packageId": "",
        "plannedTravelDate": new Date(),
        "destination": "",
        "products": "",
        "plannedNoOfDays": 0,
        "numberOfAdults": 0,
        "numberOfChild": 0,
        "budgetPerAdults": 0,
        "budgetPerChild": 0,
        "overAllBudget": 0,
        "leadCode": '',
        'numberOfInfant': 0
    });

    useEffect(() => {        
        let pProposal = location.state?.proposal;
        setProposal(location.state?.proposal);
        
        if (pProposal?.prospect) {
            setProspect(pProposal?.prospect)
        }

        if (pProposal?.prospect?.plannedTravelDate) {
            setPlannedTravelDate(new Date(pProposal?.prospect?.plannedTravelDate))
        }

        if (pProposal?.quotationId) {
            QuotationService.GetQuotationById(pProposal?.quotationId).then(quotation => {
                setQuotation(quotation);
                setCoverImage(quotation.coverImages);
                setSaveCoverImage(BaseService.API_URL + quotation.coverImages)
            });

            if (pProposal?.quotationId) {
                QuotationService.GetQuotationPrice(pProposal?.quotationId).then(quotationPrice => {
                    setQuotationPrice(quotationPrice);
                });
            }
        }
        else if (pProposal?.prospect?.id) {
            QuotationService.GetQuotationByProspectId(pProposal?.prospect?.id).then(quotationRes => {
                console.log(quotationRes)
                setQuotation(quotationRes);
                setCoverImage(quotationRes.coverImages);
                setSaveCoverImage(BaseService.API_URL + quotationRes.coverImages)
                if (quotationRes?.id) {
                    QuotationService.GetQuotationPrice(quotationRes?.id).then(quotationPrice => {
                        setQuotationPrice(quotationPrice);
                    });
                }

                if (pProposal?.prospect?.packageId) {
                    PackageService.GetPackageById(pProposal?.prospect?.packageId).then(selectedPkg => {
                        setSelectedPackage(selectedPkg)
                        setSaveCoverImage(selectedPkg.coverImages)
                        setContent(selectedPkg.details)
                        setContentCurrent(selectedPkg.details)
                        setContentIandE(selectedPkg.contentIandE)
                        setContentIandECurrent(selectedPkg.contentIandE)
                        setContentTandC(selectedPkg.contentTandC)
                        setContentTandCCurrent(selectedPkg.contentTandC)
                        quotation.packageName = selectedPkg.packageName
                        quotation.packageCode = selectedPkg.packageCode
                        quotation.overView = selectedPkg.overView
                        quotation.margin = selectedPkg.margin
                        quotation.discount = selectedPkg.discount
                        MasterService.GetDestination().then(res => {
                            setDestination(res)
                            if (selectedPkg?.destination) {
                                setSelectedDestination(res.filter(p => p?.name == selectedPkg?.destination))
                            }
                        });
                    })
                }
            });
        }



        setPackageType(MasterService.GetPackageType());
        setPackageTemplate(MasterService.GetPackageTemplate());

        if (pProposal?.prospectId && !prospect?.id) {
            ProspectService.GetProspectById(pProposal?.prospectId)
                .then(res => {
                    setProspect(res);
                });
        }
    }, [])

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/prospect`);
    }

    const onSave = (packageId) => {
        // itinerary = (itineraryList.length > 0) ? itineraryList[0] : itinerary;
        // itinerary.packageId = packageId;
        // itinerary.day = day;
        // itinerary.fromPlaceId = formik.values.fromPlaceId;
        // itinerary.fromPlace = selectedItinerary?.fromPlace;
        // itinerary.toPlaceId = formik.values.toPlaceId;
        // itinerary.toPlace = formik.values.toPlace;;
        // itinerary.description = formik.values.description;
        // ItineraryService.CreateItinerary(packageItinerary).then(res => {
        //     //navigate(`${BaseService.NavigateToPath()}/packages`);
        //     navigate(-1);
        // });
    }

    //validation Schemas
    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),    
    });

    const formik = useFormik({
        initialValues: quotation,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.details = contentCurrent;
            values.destination = selectedDestination[0]?.name;
            values.contentIandE = contentIandECurrent;
            values.contentTandC = contentTandCCurrent;
            values.coverImages = coverImage;
            values.tripStartDate = plannedTravelDate;
            values.duration = prospect?.plannedNoOfDays;
            values.prospectId = prospect?.id;
            values.proposalId = proposal?.id;
            values.packageId = selectedPackage?.id;

            QuotationService.CreateQuotation(values).then(
                (res) => {
                    setQuotation(res);
                    QuotationService.GetQuotationPrice(res.id).then(quotationPrice => {
                        setQuotationPrice(quotationPrice);
                    });
                }
            )
        }
    });

    const onImgChange = (imageList) => {
        const userTemp = quotation;
        //Data for submit
        //Create an object of formData 
        const formData = new FormData();
        //Update the formData object
        formData.append("userId", quotation.id);
        formData.append("formFile", imageList[0].file);
        formData.append("fileName", imageList[0].file.name);
        QuotationService.UploadImage(formData).then(
            res => {
                setSaveCoverImage('app-images/' + res)
                setCoverImage(BaseService.API_URL + 'app-images' + res);
            }
        );
    };

    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
    }

    const generatePDF = () => {
        const report = new JsPDF("p", "px", [1200, 1700]);
        report.marginLeft = 50;
        report.html('<div>' + editorRef.current.getContent() + '</div>').then(() => {
            report.save('report.pdf');
        });
    }

    const onSendProposal = () => {
        proposal.quotationId = quotation.id;
        navigate(`${BaseService.NavigateToPath()}/add-proposal`, { state: { 'proposal': proposal } });
    }

    const onTemplateSelect = (template) => {
        setSelectedTemplate(template);
        if (template?.length) {
            if (window.confirm("Are you sure want to apply")) {
                editorRef?.current?.setContent(template[0]?.content);
            }
        }
    }

    const addDay = () => {
        setActivitySlider(true)
    }


    const removeDay = () => {
        PackageService.RemoveDay(quotation);
    }


    const onChange = (dataContent) => {
        setContentCurrent(dataContent);
    }

    const onChangeIandE = (dataContent) => {
        setContentIandECurrent(dataContent);
    }

    const onChangeTandC = (dataContent) => {
        console.log('Change ' + dataContent)
        setContentTandCCurrent(dataContent);
    }

    const onActivateTandC = (dataContent) => {
        console.log('Activate ' + dataContent)
    }

    const onSelectedDestination = (destination) => {
        setSelectedDestination(destination);
    }

    const onDeleteItinerary = (e, packageItinerary, index, id) => {
        if (window.confirm("Are you sure want to delete?")) {
            let id = packageItinerary[index]?.id
            QuotationService.DeleteItinerary(id).then(
                res => {
                    quotation.packageItinerary.splice(index, 1);
                    setQuotation(JSON.parse(JSON.stringify(quotation)));
                }
            );
        }
    }

    const addItinerary = useCallback((itinerary) => {
        console.log(quotation?.packageItinerary)
        if (quotation.packageItinerary == null) {
            quotation.packageItinerary = [];
        }
        quotation.packageItinerary = [...quotation?.packageItinerary, itinerary];
        setQuotation(JSON.parse(JSON.stringify(quotation)));
    }, [quotation]);

    const addHotelBooking = useCallback((hotelBooking) => {
        console.log(hotelBooking)
        debugger;
        if (quotation.hotel == null) {
            quotation.hotel = [];
        }

        quotation.hotel = [...quotation?.hotel, hotelBooking];
        setQuotation(JSON.parse(JSON.stringify(quotation)));
        console.log(quotation)
    }, [quotation]);

    const addTransport = useCallback((transports) => {
        if (quotation.transport == null) {
            quotation.transport = [];
        }
        quotation.transport = [...quotation.transport, transports];
        setQuotation(JSON.parse(JSON.stringify(quotation)));
    }, [quotation]);

    const onHotelDelete = (e, hotels, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            QuotationService.DeleteHotel(id).then(res => {
                hotels.splice(index, 1);
                setQuotation(JSON.parse(JSON.stringify(quotation)));
            })
        }
    }

    const onHotelEdit = (e, hotels, index, id) => {
        setHotelSlider(true);
    }

    const onTransportDelete = (e, transport, index, id) => {
        if (window.confirm("Are you sure want to delete!")) {
            QuotationService.DeleteTransport(id).then(res => {
                transport.splice(index, 1);
                setQuotation(JSON.parse(JSON.stringify(quotation)));
            })

        }
    }

    const onBooking = (bookingOption) => {
        navigate(`${BaseService.NavigateToPath()}/booking-details`, { state: { 'quotation': quotation, 'bookingOption': bookingOption } });
    }

    return <>
        <div className="content">
            <Row>
                <Col md="8">
                    <Card style={{ padding: "1em" }}>
                        <CardHeader>
                            <h4 className="title">Proposal Detail</h4>
                        </CardHeader>
                        <CardBody>
                            <div> <div style={{ 'float': 'left' }}> <h4>Hotels</h4></div> <div style={{ 'float': 'right' }}>  <Link title="Add Hotel" onClick={(e) => { setHotelSlider(true) }}>Add Hotel</Link> &nbsp;</div></div>
                            <Table className="tablesorter">
                                <thead className="text-primary">
                                    {quotation?.hotel?.length > 0 && <tr>
                                        <th>#</th>
                                        <th>Hotel Type</th>
                                        {/* <th>Hotel Name</th> */}
                                        <th>Meal Plan</th>
                                        <th>Room Type</th>
                                        {/* <th>Pax/Room</th> */}
                                        <th>Rooms</th>
                                        <th >Rate</th>
                                        <th >AWEB</th>
                                        <th >CWEB</th>
                                        <th >CNB</th>
                                        <th >Days</th>
                                        <th >Total</th>
                                        <th ></th>
                                    </tr>}
                                </thead>
                                <tbody>
                                    {quotation?.hotel?.map((hotel, index) => (
                                        <>
                                            {/* <tr><td colSpan="11">{hotel?.hotelDays?.map(day => (<>{day.name} &nbsp; </>))} </td></tr> */}
                                            <tr>
                                                <td>{index + 1}</td>
                                                {/* <td>{hotel?.hotelTypeId || hotel?.hotelTypeId}</td> */}
                                                <td><b> {hotel?.hotelTypeName || hotel?.roomPrice?.hotelType}</b>
                                                    <br />
                                                    {hotel?.hotelName}
                                                    <br />
                                                    Check-In { moment(hotel?.startDate).format('DD/MM/yyyy')} Out {moment(hotel?.endDate).format('DD/MM/yyyy')}
                                                </td>
                                                <td>{hotel?.mealPlan || hotel?.roomPrice?.mealPlan}</td>
                                                <td>{hotel?.roomType || hotel?.roomPrice?.roomType}</td>
                                                <td>{hotel?.pax_Room || hotel?.pax_Room}</td>
                                                <td>{hotel?.rooms} x {hotel?.roomsRate}</td>
                                                <td>{hotel?.aweb} x {hotel?.awebRate} </td>
                                                <td>{hotel?.cweb} x {hotel?.cwebRate}</td>
                                                <td>{hotel?.cnb} x {hotel?.cnbRate}</td>
                                                <td>{hotel?.days|| 1}</td>
                                                <td>{((hotel?.cnb && hotel?.cnb * hotel?.cnbRate) + (hotel?.cweb && hotel?.cweb * hotel?.cwebRate) + (hotel?.aweb && hotel?.aweb * hotel?.awebRate) + (hotel?.rooms && hotel?.rooms * hotel?.roomsRate)) * (hotel?.days || 1)}</td>
                                                <td>
                                                    {/* <Link title="Edit Hotel" onClick={(e) => { onHotelEdit(e, quotation?.hotel, index, hotel?.id) }}><i class="fa-solid fa-pencil"></i></Link> */}
                                                    &nbsp;<Link title="Remove Hotel" onClick={(e) => { onHotelDelete(e, quotation?.hotel, index, hotel?.id) }}><i class="fa-solid fa-trash"></i></Link>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                            <div> <div style={{ 'float': 'left' }} > <h4>Transports</h4> </div> <div style={{ 'float': 'right' }}> <Link title="Add Transport" onClick={(e) => { setTransportSlider(true) }}>Add Transport</Link></div></div>
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    {quotation?.transport?.length > 0 && <tr>
                                        <th>#</th>
                                        <th>Transport Name</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>}
                                </thead>
                                <tbody>
                                    {quotation?.transport?.map((transport, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{transport?.transportType}</td>
                                            <td>{transport?.fromPlace}</td>
                                            <td>{transport?.toPlace}</td>
                                            <td>{transport?.quantity}</td>
                                            <td>{transport?.rate}</td>
                                            <td>{transport?.quantity * transport?.rate}</td>
                                            <td><Link title="Remove Transport" onClick={(e) => { onTransportDelete(e, quotation?.transport, index, transport?.id) }}><i class="fa-solid fa-trash"></i></Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>


                            <div> <div style={{ 'float': 'left' }} >  <h4>Itinerary</h4></div> <div style={{ 'float': 'right' }} ><Link onClick={addDay}>Add Itinerary</Link></div></div>
                            <hr></hr>
                            <br style={{ "clear": "both" }} />
                            {quotation?.packageItinerary?.map((itin, i) => {
                                return (<>
                                    <b> Day - {i + 1} : {itin.activityName}</b>
                                    <br /> {itin.description}
                                    <br />
                                    <Link title="Remove Day" onClick={(e) => onDeleteItinerary(e, quotation?.packageItinerary, i, itin?.id)}><i class="fa fa-trash"></i></Link>&nbsp;
                                    {/* <Link title="Add Hotel" onClick={(e) => { setSelectedItinerary(itin); setHotelSlider(true) }}><i class="fa-solid fa-hotel"></i></Link> &nbsp;
                                    <Link title="Add Transport" onClick={(e) => { setSelectedItinerary(itin); setTransportSlider(true) }}><i class="fa fa-bus"></i></Link> */}
                                    <hr></hr>
                                </>)
                            })}
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Form onSubmit={formik.handleSubmit} className="form">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h4 className="title">Proposal Attribute</h4>
                            </CardHeader>
                            <CardBody>
                                <Tabs>
                                    <TabList>
                                        <Tab>Tour</Tab>
                                        <Tab>Package</Tab>
                                        <Tab>Pics</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Name</label>
                                                    <Input
                                                        disabled="true"
                                                        type="text"
                                                        placeholder="Name"
                                                        name="name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.name}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Destination</label>
                                                    <Input
                                                        disabled="true"
                                                        type="text"
                                                        placeholder="Destination"
                                                        name="destination"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.destination}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                                    <label>Tour Date</label>
                                                    <DatePicker
                                                        disabled="true"
                                                        isClearable
                                                        showIcon
                                                        className="form-control"
                                                        dateFormat={'dd-MM-yyyy'}
                                                        name="plannedTravelDate"
                                                        selected={plannedTravelDate}
                                                        onChange={setPlannedTravelDate} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Nights</label>
                                                    <Input
                                                        disabled="true"
                                                        type="text"
                                                        placeholder="Nights"
                                                        name="duration"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.duration}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label>Adults</label>
                                                    <Input
                                                        type="text"
                                                        disabled="true"
                                                        placeholder="Adults"
                                                        name="adult"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.adult}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label>Children [6-12]</label>
                                                    <Input
                                                        type="text"
                                                        disabled="true"
                                                        placeholder="Children [6-12]"
                                                        name="kids"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.kids}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label>Infant [0-6]</label>
                                                    <Input
                                                        type="text"
                                                        disabled="true"
                                                        placeholder="Children [0-6]"
                                                        name="infant"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={quotation?.infant}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Table className="tablesorter">
                                                    <thead className="text-primary">
                                                        {quotationPrice?.length > 0 && <tr>
                                                            <th>#</th>
                                                            <th>Option</th>
                                                            <th>Total</th>
                                                            <th></th>
                                                        </tr>}
                                                    </thead>
                                                    <tbody>
                                                        {quotationPrice?.map((hotel, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{hotel?.Id}</td>
                                                                    <td>{hotel?.HotelTypeName}</td>
                                                                    <td>{hotel?.Total}</td>
                                                                    <td>
                                                                        {/* <Link title="Booking" onClick={onBooking}>Book It</Link> */}
                                                                        <Button onClick={() => onBooking(hotel?.Id)} className="btn-fill" color="secondary">
                                                                            Booking
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Package Name</label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Package Name"
                                                        name="packageName"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageName}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Package Code</label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Package Code"
                                                        name="packageCode"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageCode}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="5">
                                                <FormGroup>
                                                    <label>Package Type</label>
                                                    <Input
                                                        type="select"
                                                        placeholder="Package Type"
                                                        name="packageType"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.packageType}>
                                                        {packageType?.map((pt) => (<option value={pt.id}>{pt.name}</option>))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="7">
                                                <FormGroup>
                                                    <label>Destination</label>
                                                    <Typeahead
                                                        id="destination"
                                                        labelKey={(option) => `${option.name}`}
                                                        onChange={onSelectedDestination}
                                                        options={destinationList}
                                                        placeholder="Select Destination"
                                                        selected={selectedDestination}
                                                        clearButton
                                                    ></Typeahead>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Overview</label>
                                                    <Input style={{ height: '100px !important' }}
                                                        type="textarea"
                                                        placeholder="Heading"
                                                        name="overView"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.overView}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>% per Adult </label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Price per Adult"
                                                        name="percentForAdult"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.percentForAdult}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>%  per Child </label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Price"
                                                        name="percentForKids"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.percentForKids}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Discount</label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Discount"
                                                        name="discount"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.discount}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Margin</label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Margin"
                                                        name="margin"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.margin}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel>
                                        <Row>
                                            <Col>
                                                <img
                                                    alt="..."
                                                    //className="avatar"
                                                    src={coverImage}
                                                />
                                            </Col>
                                            <Col md="12">
                                                <ImageUploading onChange={onImgChange}>
                                                    {({ imageList, onImageUpload }) => (
                                                        // write your building UI
                                                        <div className="imageuploader">
                                                            <div className="mainBtns">
                                                                {/* <button className="btn btn-primary mr-1  uploadbtn" onClick={onImageUpload}>Upload Image</button> */}
                                                                <Link onClick={onImageUpload}>Upload Image</Link>
                                                            </div>
                                                            {imageList.map((image) => (
                                                                <div className="imagecontainer" key={image.key}>
                                                                    <img src={image.dataURL} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                </Tabs>
                            </CardBody>
                            <CardFooter>
                                <div>
                                    <Button style={{ marginRight: "1em" }} disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                        Save
                                    </Button>
                                    <Button onClick={resetForm} className="btn-fill" color="secondary">
                                        Cancel
                                    </Button>
                                    <Button onClick={onSendProposal} >Send Proposal</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <ModalSlider open={activitySlider} width={450} onClick={() => setActivitySlider(false)} className={activitySlider ? ` showing` : ''}>
                <Card  >
                    <CardHeader>
                        <h4 className="title" >Add Day</h4>
                    </CardHeader>
                    <CardBody>
                        <ServiceBookingList addItinerary={addItinerary} onClose={setActivitySlider} />
                    </CardBody>
                </Card>

            </ModalSlider>

            <ModalSlider open={hotelSlider} width={450} onClick={() => setHotelSlider(false)} className={hotelSlider ? ` showing` : ''}>
                <Card >
                    <CardHeader>
                        <h4 className="title" >Add Hotel</h4>
                    </CardHeader>
                    <CardBody>
                        <HotelBookingList prospect={prospect} destination={quotation?.destination} addHotelBooking={addHotelBooking} SelectedItinerary={selectedItinerary} onClose={setHotelSlider} />
                    </CardBody>
                </Card>
            </ModalSlider>

            <ModalSlider open={transportSlider} width={450} onClick={() => setTransportSlider(false)} className={transportSlider ? ` showing` : ''}>
                <Card >
                    <CardHeader>
                        <h4 className="title" >Add Transport</h4>
                    </CardHeader>
                    <CardBody>
                        <TransportBookingList prospect={prospect} addTransport={addTransport} SelectedItinerary={selectedItinerary} onClose={setTransportSlider} />
                    </CardBody>
                </Card>
            </ModalSlider>

        </div>
    </>
}