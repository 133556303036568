import Dashboard from "views/Dashboard.Client";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import UserList from "views/UserList";
import User from "views/User";
import Enquiry from "views/Enquiry";
import Prospect from "views/Prospect";
import Customer from "views/Customer";
import Quotation from "views/Quotation";
import Invoice from "views/Invoice";
import Package from "views/Package";
import { ControlledBoard, UncontrolledBoard } from "views/Report";
import Setting from "views/Setting";
import AddEnquiry from "views/AddEnquiry";
import FollowUp from "views/FollowUp";
import AddProspect from "views/AddProspect";
import Login from "layouts/Authentication/components/Login";
import AddProposal from "views/AddProposal";
import AddPackage from "views/AddPackage";
import AddMember from "views/AddMember";
//import Booking from "views/Booking";
import ComingSoon from "components/CustomComponents/ComingSoon";
import ViewPackage from "views/ViewPackage";
import Hotel from "views/Hotel";
import Attractions from "views/Attractions";
import AddHotel from "views/AddHotel";
import AddInvoice from "views/AddInvoice";
//import AllBookings from "views/AllBookings";
import AddAttraction from "views/AddAttraction";
import Itinerary from "views/Itinerary";
import Proposals from "views/Proposals";
import AddCustomer from "views/AddCustomer";

var routes = [
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/client",
  },

  {
    path: "/enquiries",
    name: "Enquiries",
    icon: "tim-icons icon-badge",
    component: <Enquiry />,
    layout: "/admin",
  },
  {
    path: "/prospect",
    name: "Prospects",
    icon: "tim-icons icon-spaceship",
    component: <Prospect />,
    layout: "/admin",
  },
  {
    path: "/proposals",
    name: "Proposals",
    icon: "far fa-edit",
    component: <Proposals />,
    layout: "/admin",
  },
  {
    path: "/add-proposal",
    name: "Proposal",
    icon: "tim-icons icon-paper",
    component: <AddProposal />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/quotation",
    name: "Quotation",
    icon: "tim-icons icon-tag",
    component: <Notifications />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "fa fa-person-walking-luggage",
    component: <Customer />,
    layout: "/admin",
  },
  // {
  //   path: "/bookings",
  //   name: "Bookings",
  //   icon: "tim-icons icon-notes",
  //   component: <AllBookings />,
  //   layout: "/client",

  // },

  {
    path: "/packages",
    name: "Packages",
    icon: "tim-icons icon-app",
    component: <Package />,
    layout: "/admin",
  },
  {
    path: "/Itinerary",
    name: "Itinery",
    icon: "tim-icons icon-app",
    component: <Itinerary />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    component: <UserList />,
    layout: "/admin",
  },
  {
    path: "/add-invoice",
    name: "New Booking",
    icon: "tim-icons icon-chart-bar-32",
    // component: <ControlledBoard />,
    component: <AddInvoice />,
    layout: "/admin",
    redirect: true
  },
  // {
  //   path: "/booking-details",
  //   name: "Booking",
  //   icon: "tim-icons icon-chart-bar-32",
  //   // component: <ControlledBoard />,
  //   component: <Booking />,
  //   layout: "/client",
  //   redirect: true
  // },

  {
    path: "/setting",
    name: "Setting",
    icon: "tim-icons icon-align-center",
    component: <Setting />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-enquiry",
    name: "Add Enquiry",
    icon: "tim-icons icon-align-center",
    component: <AddEnquiry />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/follow-up",
    name: "Follow Up",
    icon: "tim-icons icon-align-center",
    component: <FollowUp />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-prospect",
    name: "Add Prospect",
    icon: "tim-icons icon-align-center",
    component: <AddProspect />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-package",
    name: "Add Package",
    icon: "tim-icons icon-align-center",
    component: <AddPackage />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-member",
    name: "Add Member",
    icon: "tim-icons icon-align-center",
    component: <AddMember />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-customer",
    name: "Add Customer",
    icon: "tim-icons icon-align-center",
    component: <AddCustomer />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-hotel",
    name: "Add Hotel",
    icon: "tim-icons icon-align-center",
    component: <AddHotel />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add-attraction",
    name: "Add Attraction",
    icon: "tim-icons icon-align-center",
    component: <AddAttraction />,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/view-package",
    name: "View Package",
    component: <ViewPackage />,
    layout: "/admin",
    redirect: true
  },

  {
    path: "/hotels",
    name: "Hotels",
    icon: "far fa-building",
    component: <Hotel />,
    layout: "/admin"
  },
  {
    path: "/attractions",
    name: "Attractions",
    icon: "far fa-map",
    component: <Attractions />,
    layout: "/admin"
  },
  

];
export default routes;