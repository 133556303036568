import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label
} from "reactstrap";
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AttractionService from "services/attraction.service";
import DatePicker, { setDefaultLocale, registerLocale } from "react-datepicker";
import BaseService from "services/base.service";
import { Typeahead } from "react-bootstrap-typeahead";
import MasterService from "services/master.service";
import { json, useLocation } from 'react-router-dom';
import ImageUploading from "react-images-uploading";

export default function AddAttraction() {
    const [startDate, setStartDate] = useState(new Date());
    const [fromSelectedDestination, setFromSelectedDestination] = useState([])
    const [toSelectedDestination, setToSelectedDestination] = useState([])
    const [destination, setDestination] = useState([])
    const location = useLocation();
    const [coverImage, setCoverImage] = useState("");
    const [saveCoverImage, setSaveCoverImage] = useState("");

    const [activity, setActivity] = useState({
        id: 0,
        name: "",
        typeId: 1,
        startDate: startDate,
        duration: "",
        description: "",
        images: [],
        'fromDestination': '',
        'toDestination': ''
    })


    useEffect(() => {
        if (location.state?.attraction?.id) {
            setActivity(location.state?.attraction)
            setCoverImage(BaseService.API_URL + location.state?.attraction?.images);
        }

        MasterService.GetDestination().then(res => {
            setDestination(res)
            if (location.state?.attraction?.id) {
                setFromSelectedDestination(res.filter(p => p?.name == location.state?.attraction?.fromDestination))
                setToSelectedDestination(res.filter(p => p?.name == location.state?.attraction?.toDestination))
            }
        });
    }, [])




    const Images = [
        { id: 1, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
        { id: 2, image: "https://crmapi.trektrail.in/api/app-images/Hotels/Hotel1.jpg" },
    ]
    const navigate = useNavigate();

    //cancel
    const resetForm = () => {
        navigate(`${BaseService.NavigateToPath()}/attractions`);
    }
    const formik = useFormik({
        initialValues: activity,
        // validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.fromDestination = fromSelectedDestination[0]?.name;
            values.toDestination = toSelectedDestination[0]?.name;
            values.images = saveCoverImage;
            AttractionService.CreateAttraction(values).then(res => {
                navigate(`${BaseService.NavigateToPath()}/attractions`);
            })
        }
    })
    const handleInputChange = (date) => {
        setStartDate(date);
        //formik.values.plannedTravelDate = date;        
    };

    const onFromSelectedDestination = (destination) => {
        setFromSelectedDestination(destination);
        // PackageService.GetPackages()
        //     .then(res => {
        //         setPackages(res.filter(p => p.destination == destination[0]?.name))
        //     });
    }

    const onToSelectedDestination = (destination) => {
        setToSelectedDestination(destination);
        // PackageService.GetPackages()
        //     .then(res => {
        //         setPackages(res.filter(p => p.destination == destination[0]?.name))
        //     });
    }

    const onImgChange = (imageList) => {
        debugger;
        const formData = new FormData();
        // Update the formData object 
        formData.append("userId", activity?.id);
        formData.append("formFile", imageList[0].file);
        formData.append("fileName", imageList[0].file.name);
        AttractionService.UploadImage(formData).then(
            res => {
                setSaveCoverImage('app-images' + res)
                setCoverImage(BaseService.API_URL + 'app-images' + res);
            }
        );
    };

    return <>
        <div className="content">
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader>
                                <h5 className="title">Add/Edit Attraction</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label>From</label>
                                            <Typeahead
                                                id="destination"
                                                labelKey={(option) => `${option.name}`}
                                                onChange={onFromSelectedDestination}
                                                options={destination}
                                                placeholder="Select Destination"
                                                selected={fromSelectedDestination}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label>To</label>
                                            <Typeahead
                                                id="destination"
                                                labelKey={(option) => `${option.name}`}
                                                onChange={onToSelectedDestination}
                                                options={destination}
                                                placeholder="Select Destination"
                                                selected={toSelectedDestination}
                                                clearButton
                                            ></Typeahead>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Activity Type</label>
                                            <Input
                                                name="typeId"
                                                placeholder="Activity Type"
                                                type="select"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.typeId}
                                            >
                                                <option value="1">Sight Seeing</option>
                                                <option value="2">Museum</option>
                                                <option value="3">Zoo</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="8">
                                        <FormGroup>
                                            <label>Activity Name</label>
                                            <Input
                                                name="name"
                                                placeholder="Activity Name"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Location</label>
                                            <Input
                                                name="location"
                                                placeholder="Enter Location"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.location}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup style={{ display: "flex", flexDirection: 'column', marginTop: ".2em" }}>
                                            <label>Start Date</label>
                                            <DatePicker className="form-control"
                                                dateFormat={'dd-MM-yyyy'}
                                                name="startDate"
                                                selected={startDate}
                                                showTimeInput
                                                onChange={(date) => { handleInputChange(date) }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.startDate} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Duration</label>
                                            <Input
                                                name="duration"
                                                placeholder="Enter Duration"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.duration}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Price</label>
                                            <Input
                                                name="price"
                                                placeholder="Enter Price per person"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.price}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" md="8">
                                        <FormGroup>
                                            <label>Description</label>
                                            <Input
                                                name="description"
                                                cols="80"
                                                placeholder="Here can be your description"
                                                rows="8"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="8">
                                        <Button disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={resetForm} type="cancel" className="btn-fill" color="secondary">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }} className="card-user">
                            <CardHeader>
                                <h5 className="title">Images</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {/* <Col md="12">
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {Images.map(img => { return <div style={{ marginRight: "1em", marginBottom: "1em" }}> <img width={50} height={50} src={img.image} alt="" /> </div> })}
                                            <Link style={{ display: "inline-grid", placeItems: "center", border: "1px dashed var(--primary)", marginRight: "1em", marginBottom: "1em", width: "50px", height: "50px" }}>+</Link>
                                        </div>
                                    </Col> */}
                                    <Col>
                                        <img
                                            alt="..."
                                            //className="avatar"
                                            src={coverImage}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <ImageUploading onChange={onImgChange}>
                                            {({ imageList, onImageUpload }) => (
                                                // write your building UI
                                                <div className="imageuploader">
                                                    <div className="mainBtns">
                                                        {/* <button className="btn btn-primary mr-1  uploadbtn" onClick={onImageUpload}>Upload Image</button> */}
                                                        <Link onClick={onImageUpload}>Upload Image</Link>
                                                    </div>
                                                    {imageList.map((image) => (
                                                        <div className="imagecontainer" key={image.key}>
                                                            <img src={image.dataURL} />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}