import { React, useEffect, useState, useRef } from "react";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Editor } from "@tinymce/tinymce-react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Label,
    NavLink,
    Nav
} from "reactstrap";
import TemplateService from 'services/template.service';
import BaseService from 'services/base.service';
import * as yup from 'yup';
import ImageUploading from "react-images-uploading";
import PackageService from "services/package.service";
import { CKEditor } from 'ckeditor4-react';


export default function PackageTemplate() {
    const [templates, setTemplates] = useState([]);
    const editorRef = useRef(null);
    const [content, setContent] = useState("<p>Hello World</p>");
    const [coverImage, setCoverImage] = useState("");
    const [template, setTemplate] = useState(
        {
            id: 0,
            title: "",
            description: "",
            content: "",
        }
    );

    const onPageRefresh = () => {
        TemplateService.GetTemplates().then(
            res => {
                setTemplates(res)
            }
        );
    };


    useEffect(() => {
        onPageRefresh();
    }, []);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: template,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: function (values) {
            values.content = content;
            values.coverImages = coverImage;
            TemplateService.CreateTemplate(values).then(res => {
                onPageRefresh();
            });
        }
    });

    const schema = yup.object().shape({
        // id: yup.string().min(3).max(40).required(),
        // userId: yup.string().min(3).max(40).required(),
        // firstName: yup.string().min(3).required(),
        // lastName: yup.string().min(3).required(),
        // email: yup.string().required(),
        // phone: yup.string().min(3).max(40).required(),
        // address: yup.string().min(3).max(40).required(),
        // city: yup.string().min(3).max(40).required(),
        // country: yup.string().min(3).max(40).required(),
        // pinCode: yup.string().min(3).max(40).required(),
        // aboutMe: yup.string().min(3).max(40).required(),
        // faceBook: yup.string().min(3).max(40).required(),
        // tweeter: yup.string().min(3).max(40).required(),
        // gmail: yup.string().min(3).max(40).required(),
        // picture: yup.string().min(3).max(40).required(),
        // company: yup.string().min(3).max(40).required(),
        // title: yup.string().min(3).max(40).required(),
    });

    //cancel
    const resetForm = () => {
        setTemplate(
            {
                id: 0,
                title: "",
                description: "",
                content: "",
            });
            setContent("");
            setCoverImage("");
    }

    const onEdit = (template) => {
        setTemplate(template);
        setContent(template.content);
        setCoverImage(template.coverImages);
    };

    const onDelete = (template) => {

        TemplateService.DeleteTemplate(template).then(
            res => {
                onPageRefresh();

            }
        );
    };

    const onImgChange = (imageList) => {
        const formData = new FormData();
        formData.append("userId", template.id);
        formData.append("formFile", imageList[0].file);
        formData.append("fileName", imageList[0].file.name);
        PackageService.UploadImage(formData).then(
            res => {
                setCoverImage('app-images/' + res)
            }
        );
    };

    return <>
        <div className="content ">
            <Form onSubmit={formik.handleSubmit} className="form">
                <Row>
                    <Col md="8">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader >
                                <h4 className="title new">Create Package Template</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12">
                                        <FormGroup className="">
                                            <label>Template Name</label>
                                            <Input
                                                name="title"
                                                type="text"
                                                placeholder='Template Name'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.title}
                                            />

                                            {formik.touched.package && formik.errors.title && (
                                                <span className='text-red-400'>{formik.errors.title}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup className="">
                                            <label>Template Description</label>
                                            <Input
                                                name="description"
                                                type="textarea"
                                                placeholder='Template Description'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                            />
                                            {formik.touched.description && formik.errors.description && (
                                                <span className='text-red-400'>{formik.errors.description}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ marginRight: "1em", marginBottom: "1em" }}>
                                                <img width={'100%'} height={200} src={BaseService.API_URL + coverImage} title={coverImage} alt="Cover Image" />
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <ImageUploading
                                                    onChange={onImgChange}
                                                >
                                                    {({ imageList, onImageUpload }) => (
                                                        // write your building UI
                                                        <div className="imageuploader">
                                                            <div className="mainBtns">
                                                                <button type="button" className="btn-primary  uploadbtn" onClick={onImageUpload}>Upload Image</button>
                                                            </div>
                                                            {/* {imageList.map((image) => (
                                                                <div className="imagecontainer" key={image.key}>
                                                                    <img src={image.dataURL} />
                                                                </div>
                                                            ))} */}
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <FormGroup className="">
                                            <label>Template Content</label>
                                            <Editor style={{ border: '1px solid black' }} apiKey={'ih9a01slzwix1sdsazp38f3vg14m7zqiyzlai5724hb7y8wb'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={content}
                                                init={{
                                                    selector: 'div#image-tools',
                                                    height: "480",
                                                    menubar: true,
                                                    toolbar_mode: 'wrap',
                                                    setup: function (editor) {

                                                        editor.ui.registry.addButton('customInsertButton', {
                                                            text: 'My Button',
                                                            onAction: function (_) {
                                                                //editor.insertContent('&nbsp;<strong>It\'s my button!</strong>&nbsp;');
                                                                //ImageUploading.onImageUpload();
                                                            }
                                                        });
                                                    },

                                                    //inline: true,
                                                    paste_data_images: true,
                                                    template_mdate_format: '%m/%d/%Y : %H:%M',
                                                    template_replace_values: {
                                                        username: 'Jack Black',
                                                        staffid: '991234',
                                                        inboth_username: 'Famous Person',
                                                        inboth_staffid: '2213',
                                                    },
                                                    template_preview_replace_values: {
                                                        preview_username: 'Jack Black',
                                                        preview_staffid: '991234',
                                                        inboth_username: 'Famous Person',
                                                        inboth_staffid: '2213',
                                                    },
                                                    templates: templates,
                                                    //toolbar: 'template | export | undo redo | formatselect | ' +
                                                    //    'export | bold italic backcolor | alignleft aligncenter ' +
                                                    //   'alignright alignjustify | bullist numlist outdent indent | link image' +
                                                    //   'removeformat | help',
                                                    plugins: 'code template anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                    //toolbar: 'code template | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough '+
                                                    //' link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                    toolbar: [
                                                        { name: 'customInsertButton', items: ['customInsertButton'] },
                                                        { name: 'history', items: ['undo', 'redo'] },
                                                        { name: 'styles', items: ['styles'] },
                                                        { name: 'formatting', items: ['bold', 'italic'] },
                                                        { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
                                                        { name: 'indentation', items: ['outdent', 'indent'] }
                                                    ],
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }} /> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <div style={{ marginTop: "2em" }}>
                                    <Button style={{ marginRight: "1em" }} disabled={!formik.isValid} onClick={resetForm} className="btn-fill" color="primary">
                                        New
                                    </Button>
                                    <Button style={{ marginRight: "1em" }} disabled={!formik.isValid} type="submit" className="btn-fill" color="primary">
                                        Save
                                    </Button>
                                    <Button onClick={resetForm} className="btn-fill" color="secondary">
                                        Cancel
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card style={{ padding: "1em" }}>
                            <CardHeader >
                                <h4 className="title new">All Templates</h4>
                            </CardHeader>
                            <CardBody>
                                <Table className="tablesorter" responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templates.map((template) => (
                                            <tr>
                                                <td>TP{template.id}</td>
                                                <td>{template.title}</td>
                                                <td>
                                                    <Nav>
                                                        <NavLink onClick={(e) => { onEdit(template) }}><i title="Edit" className="fas fa-pencil" /> </NavLink>
                                                        <NavLink onClick={(e) => onDelete(template)}><i title="Delete" className="fas fa-trash-o" /> </NavLink>
                                                    </Nav>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}