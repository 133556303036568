export default function HotelVoucher() {
    return <div>

        <div style={{ fontFamily: "Sen, Poppins", margin: "2em 0", width: "700px", border: "2px solid black", padding: "2.5em 20px" }}>
            {/* Page Heading */}
            <h2
                style={{
                    fontSize: 20,
                    fontWeight: 600,
                    fontFamily: "Sen, Poppins",
                    padding: 0,
                    margin: 0,
                    color: "#00204F",
                    textAlign: "center"
                }}
            >
                Voucher
            </h2>
            <h2
                style={{
                    fontSize: 20,
                    fontFamily: "Sen, Poppins",
                    padding: 0,
                    paddingBottom: ".35em",
                    margin: 0,
                    marginBottom: "1em",
                    color: "#F67612",
                    textAlign: "center"
                }}
            >
                HOTEL / ACCOMODATION
            </h2>
            {/* Bordered Div Start */}
            <div
                style={{
                    display: "flex",
                    padding: 25,
                    border: "2px solid #F2F2F2",
                    backgroundColor: "#FCFCFC",
                    marginBottom: "1.5em"
                }}
            >
                <div style={{ width: "45%", borderRight: "2px solid #F2F2F2" }}>
                    <p style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}>
                        Hotel Details
                    </p>
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            paddingTop: ".5em",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            color: "#373737"
                        }}
                    >
                        Hotel Hill View
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>Dharamshala</p>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <p style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}>
                        Guest Details
                    </p>
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            paddingTop: ".5em",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            color: "#373737"
                        }}
                    >
                        Mahfoz Khan
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>2 Adults</p>
                </div>
            </div>
            {/* Bordered Div End */}
            {/* Bordered Div Start */}
            <div
                style={{
                    padding: 25,
                    border: "2px solid #F2F2F2",
                    backgroundColor: "#FCFCFC",
                    marginBottom: "1.5em"
                }}
            >
                <div>
                    <p style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}>
                        Trip Details
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>#13254</p>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                    <div style={{ width: "35%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Check-in
                        </p>
                        <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>
                            10 February 2023
                        </p>
                    </div>
                    <div style={{ width: "35%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Check-out
                        </p>
                        <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>
                            13 February 2023
                        </p>
                    </div>
                    <div style={{ width: "30%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Stay
                        </p>
                        <p style={{ fontWeight: 500, margin: 0, paddingTop: 5 }}>3 Nights</p>
                    </div>
                </div>
            </div>
            {/* Bordered Div End */}
            {/* Bordered Div Start */}
            <div
                style={{
                    padding: 25,
                    border: "2px solid #F2F2F2",
                    backgroundColor: "#FCFCFC",
                    marginBottom: "1.5em"
                }}
            >
                <div>
                    <p style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}>
                        Schedule
                    </p>
                </div>
                <br />
                {/* Schedule Headers */}
                <div style={{ display: "flex", paddingBottom: 10 }}>
                    <div style={{ width: "35%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Nights
                        </p>
                    </div>
                    <div style={{ width: "35%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Rooms
                        </p>
                    </div>
                    <div style={{ width: "30%" }}>
                        <p
                            style={{ padding: 0, margin: 0, fontSize: ".75rem", fontWeight: 300 }}
                        >
                            Meal Plan
                        </p>
                    </div>
                </div>
                {/* Schedule details */}
                <div style={{ display: "flex", borderTop: "1px solid #F2F2F2" }}>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "35%" }}>
                        1st Night
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "35%" }}>
                        3 Deluxe Rooms
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "30%" }}>
                        MAP
                    </p>
                </div>
                <div style={{ display: "flex", borderTop: "1px solid #F2F2F2" }}>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "35%" }}>
                        1st Night
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "35%" }}>
                        3 Deluxe Rooms
                    </p>
                    <p style={{ fontWeight: 500, margin: 0, padding: "5px 0", width: "30%" }}>
                        MAP
                    </p>
                </div>
            </div>
            {/* Bordered Div End */}
            <div style={{ display: "flex", paddingBottom: 10 }}>
                <div style={{ width: "50%" }}>
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            fontSize: ".75rem",
                            fontWeight: 300,
                            lineHeight: "1.5"
                        }}
                    >
                        <b>Contact Us</b>
                        <br />
                        <br />
                        Tripper Hub
                        <br />
                        Phone : +91-9999466441
                        <br />
                        Email : thetripperhub@gmail.com
                        <br />
                    </p>
                </div>
                <div style={{ width: "50%" }}>
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            fontSize: ".75rem",
                            fontWeight: 300,
                            lineHeight: "1.5"
                        }}
                    >
                        <b> Meet Us</b>
                        <br />
                        <br />
                        Plot No. 24, 3rd floor,
                        <br />
                        Pillar no. 771-772, Near Pooran store
                        <br />
                        Dwarka, Uttam Nagar New Delhi,
                        <br />
                        Delhi, India - 110058
                        <br />
                    </p>
                </div>
            </div>
        </div>
    </div>
}