import { selector, atom } from 'recoil'

export const enquiryState = atom({
  key: 'enquiryList',
  default: [],
});

const enquirySelector = selector({
  key: "getEnquiry",
  get: ({ get }) => get(enquiryState),
});

export const filteredEnquiryState = selector({
  key: 'filteredEnquiryState',
  get: ({get}) => get(enquiryState).filter((enquiry) => enquiry.statusId == 1),
});
